<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 text-left">
        <h3><strong>Examen físico</strong></h3>
      </div>
      <div *ngIf="isMobileMenu();then content_mobile else content_web"></div>
      <ng-template #content_web>
        <div class="col-md-6 text-right">
          <a routerLink="/panel/crear-caso">
            <button class="btn btn-success" (click)="guardarCambios()">Guardar cambios</button>
          </a>
        </div>
      </ng-template>
      <ng-template #content_mobile>
        <div class="col-md-6 text-left">
          <a routerLink="/panel/crear-caso">
            <button class="btn btn-success" (click)="guardarCambios()">Guardar cambios</button>
          </a>
        </div>
      </ng-template>
    </div>
    <div class="row">
      <div class="col-md-12 text-left">
        <p><a (click)="guardarCambios()" routerLink="/panel/casos">Casos</a>/<a (click)="guardarCambios()" routerLink="/panel/crear-caso">Crear caso</a>/<strong>Examen físico</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-9">
        <h5>Estado general</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[0]" class="form-control" placeholder="Escribe aquí el resultado de este examen físico" rows="5"></textarea> 
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="row">
          <h5>Recursos</h5>
          <div class="col-md-12">
            <div *ngIf="recursosASubir[0] != null">
              <p>
                {{recursosASubir[0].name}}
                <a><i class="material-icons" (click)="handleFileInput(null, 0)">delete</i></a>
              </p>
            </div>
          </div>
          <div class="form-group adjuntar-recursos">
            <label for="file">Adjunta un recurso</label>
            <input type="file" 
                  class="file-upload"
                  (change)="handleFileInput($event.target.files[0], 0)"
            #fileUpload0 />
          </div>
          <button class="btn btn-adjuntar" (click)="fileUpload0.click()">Adjunta un recurso</button>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h5>Reacción</h5>
            <p *ngIf="isMobileMenu()">{{etnia}}</p>
            <div class="dropdown">
              <a class="btn dropdown-toggle" data-toggle="dropdown">
                  Selecciona la reacción
                  <b class="caret"></b>
              </a>
              <ul class="dropdown-menu">
                <li><a (click)="emociones[0] = 'Ninguna'">Ninguna</a></li>
                <li><a (click)="emociones[0] = 'Dolor'">Dolor</a></li>
                <li><a (click)="emociones[0] = 'Enfermo'">Enfermo</a></li>
                <li><a (click)="emociones[0] = 'Enojado'">Enojado</a></li>
                <li><a (click)="emociones[0] = 'Triste'">Triste</a></li>
                <li><a (click)="emociones[0] = 'Vergüenza'">Vergüenza</a></li>
              </ul>
            </div>
            <p *ngIf="!isMobileMenu()">{{etnia}}</p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="alert_archivo[0]" class="row">
      <br />
      <div class="col-md-1">
      </div>
      <div class="alert alert-danger col-md-10">
        <h4>
          <strong>Error:</strong>
          El formato del archivo seleccionado no es válido. Si es una imagen, recomendamos que esté en formato .png o .jpg, y si es un audio 
          el formato deseado es .mp3 o .wav
          <i class="material-icons cerrar_alerta" (click)="alert_archivo[0] = false">close</i>
        </h4>
      </div>
    </div>

    <hr />
    
    <div class="row">
      <div class="col-md-9">
        <h5>Cabeza y cuello</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[1]" class="form-control" placeholder="Escribe aquí el resultado de este examen físico" rows="5"></textarea> 
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="row">
          <h5>Recursos</h5>
          <div class="col-md-12">
            <div *ngIf="recursosASubir[1] != null">
              <p>
                {{recursosASubir[1].name}}
                <a><i class="material-icons" (click)="handleFileInput(null, 1)">delete</i></a>
              </p>
            </div>
          </div>
          <div class="form-group adjuntar-recursos">
            <label for="file">Adjunta un recurso</label>
            <input type="file" 
                  class="file-upload"
                  (change)="handleFileInput($event.target.files[0], 1)"
            #fileUpload1 />
          </div>
          <button class="btn btn-adjuntar" (click)="fileUpload1.click()">Adjunta un recurso</button>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h5>Reacción</h5>
            <p *ngIf="isMobileMenu()">{{emociones[1]}}</p>
            <div class="dropdown">
              <a class="btn dropdown-toggle" data-toggle="dropdown">
                  Selecciona la reacción
                  <b class="caret"></b>
              </a>
              <ul class="dropdown-menu">
                <li><a (click)="emociones[1] = 'Ninguna'">Ninguna</a></li>
                <li><a (click)="emociones[1] = 'Dolor'">Dolor</a></li>
                <li><a (click)="emociones[1] = 'Enfermo'">Enfermo</a></li>
                <li><a (click)="emociones[1] = 'Enojado'">Enojado</a></li>
                <li><a (click)="emociones[1] = 'Triste'">Triste</a></li>
                <li><a (click)="emociones[1] = 'Vergüenza'">Vergüenza</a></li>
              </ul>
            </div>
            <p *ngIf="!isMobileMenu()">{{emociones[1]}}</p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="alert_archivo[1]" class="row">
      <br />
      <div class="col-md-1">
      </div>
      <div class="alert alert-danger col-md-10">
        <h4>
          <strong>Error:</strong>
          El formato del archivo seleccionado no es válido. Si es una imagen, recomendamos que esté en formato .png o .jpg, y si es un audio 
          el formato deseado es .mp3 o .wav
          <i class="material-icons cerrar_alerta" (click)="alert_archivo[1] = false">close</i>
        </h4>
      </div>
    </div>

    <hr />
    
    <div class="row">
      <div class="col-md-9">
        <h5>Cardiovascular</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[2]" class="form-control" placeholder="Escribe aquí el resultado de este examen físico" rows="5"></textarea> 
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="row">
          <h5>Recursos</h5>
          <div class="col-md-12">
            <div *ngIf="recursosASubir[2] != null">
              <p>
                {{recursosASubir[2].name}}
                <a><i class="material-icons" (click)="handleFileInput(null, 2)">delete</i></a>
              </p>
            </div>
          </div>
          <div class="form-group adjuntar-recursos">
            <label for="file">Adjunta un recurso</label>
            <input type="file" 
                  class="file-upload"
                  (change)="handleFileInput($event.target.files[0], 2)"
            #fileUpload2 />
          </div>
          <button class="btn btn-adjuntar" (click)="fileUpload2.click()">Adjunta un recurso</button>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h5>Reacción</h5>
            <p *ngIf="isMobileMenu()">{{emociones[2]}}</p>
            <div class="dropdown">
              <a class="btn dropdown-toggle" data-toggle="dropdown">
                  Selecciona la reacción
                  <b class="caret"></b>
              </a>
              <ul class="dropdown-menu">
                <li><a (click)="emociones[2] = 'Ninguna'">Ninguna</a></li>
                <li><a (click)="emociones[2] = 'Dolor'">Dolor</a></li>
                <li><a (click)="emociones[2] = 'Enfermo'">Enfermo</a></li>
                <li><a (click)="emociones[2] = 'Enojado'">Enojado</a></li>
                <li><a (click)="emociones[2] = 'Triste'">Triste</a></li>
                <li><a (click)="emociones[2] = 'Vergüenza'">Vergüenza</a></li>
              </ul>
            </div>
            <p *ngIf="!isMobileMenu()">{{emociones[2]}}</p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="alert_archivo[2]" class="row">
      <br />
      <div class="col-md-1">
      </div>
      <div class="alert alert-danger col-md-10">
        <h4>
          <strong>Error:</strong>
          El formato del archivo seleccionado no es válido. Si es una imagen, recomendamos que esté en formato .png o .jpg, y si es un audio 
          el formato deseado es .mp3 o .wav
          <i class="material-icons cerrar_alerta" (click)="alert_archivo[2] = false">close</i>
        </h4>
      </div>
    </div>

    <hr />
    
    <div class="row">
      <div class="col-md-9">
        <h5>Respiratorio</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[3]" class="form-control" placeholder="Escribe aquí el resultado de este examen físico" rows="5"></textarea> 
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="row">
          <h5>Recursos</h5>
          <div class="col-md-12">
            <div *ngIf="recursosASubir[3] != null">
              <p>
                {{recursosASubir[3].name}}
                <a><i class="material-icons" (click)="handleFileInput(null, 3)">delete</i></a>
              </p>
            </div>
          </div>
          <div class="form-group adjuntar-recursos">
            <label for="file">Adjunta un recurso</label>
            <input type="file" 
                  class="file-upload"
                  (change)="handleFileInput($event.target.files[0], 3)"
            #fileUpload3 />
          </div>
          <button class="btn btn-adjuntar" (click)="fileUpload3.click()">Adjunta un recurso</button>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h5>Reacción</h5>
            <p *ngIf="isMobileMenu()">{{emociones[3]}}</p>
            <div class="dropdown">
              <a class="btn dropdown-toggle" data-toggle="dropdown">
                  Selecciona la reacción
                  <b class="caret"></b>
              </a>
              <ul class="dropdown-menu">
                <li><a (click)="emociones[3] = 'Ninguna'">Ninguna</a></li>
                <li><a (click)="emociones[3] = 'Dolor'">Dolor</a></li>
                <li><a (click)="emociones[3] = 'Enfermo'">Enfermo</a></li>
                <li><a (click)="emociones[3] = 'Enojado'">Enojado</a></li>
                <li><a (click)="emociones[3] = 'Triste'">Triste</a></li>
                <li><a (click)="emociones[3] = 'Vergüenza'">Vergüenza</a></li>
              </ul>
            </div>
            <p *ngIf="!isMobileMenu()">{{emociones[3]}}</p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="alert_archivo[3]" class="row">
      <br />
      <div class="col-md-1">
      </div>
      <div class="alert alert-danger col-md-10">
        <h4>
          <strong>Error:</strong>
          El formato del archivo seleccionado no es válido. Si es una imagen, recomendamos que esté en formato .png o .jpg, y si es un audio 
          el formato deseado es .mp3 o .wav
          <i class="material-icons cerrar_alerta" (click)="alert_archivo[3] = false">close</i>
        </h4>
      </div>
    </div>

    <hr />
    
    <div class="row">
      <div class="col-md-9">
        <h5>Abdomen</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[4]" class="form-control" placeholder="Escribe aquí el resultado de este examen físico" rows="5"></textarea> 
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="row">
          <h5>Recursos</h5>
          <div class="col-md-12">
            <div *ngIf="recursosASubir[4] != null">
              <p>
                {{recursosASubir[4].name}}
                <a><i class="material-icons" (click)="handleFileInput(null, 4)">delete</i></a>
              </p>
            </div>
          </div>
          <div class="form-group adjuntar-recursos">
            <label for="file">Adjunta un recurso</label>
            <input type="file" 
                  class="file-upload"
                  (change)="handleFileInput($event.target.files[0], 4)"
            #fileUpload4 />
          </div>
          <button class="btn btn-adjuntar" (click)="fileUpload4.click()">Adjunta un recurso</button>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h5>Reacción</h5>
            <p *ngIf="isMobileMenu()">{{emociones[4]}}</p>
            <div class="dropdown">
              <a class="btn dropdown-toggle" data-toggle="dropdown">
                  Selecciona la reacción
                  <b class="caret"></b>
              </a>
              <ul class="dropdown-menu">
                <li><a (click)="emociones[4] = 'Ninguna'">Ninguna</a></li>
                <li><a (click)="emociones[4] = 'Dolor'">Dolor</a></li>
                <li><a (click)="emociones[4] = 'Enfermo'">Enfermo</a></li>
                <li><a (click)="emociones[4] = 'Enojado'">Enojado</a></li>
                <li><a (click)="emociones[4] = 'Triste'">Triste</a></li>
                <li><a (click)="emociones[4] = 'Vergüenza'">Vergüenza</a></li>
              </ul>
            </div>
            <p *ngIf="!isMobileMenu()">{{emociones[4]}}</p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="alert_archivo[4]" class="row">
      <br />
      <div class="col-md-1">
      </div>
      <div class="alert alert-danger col-md-10">
        <h4>
          <strong>Error:</strong>
          El formato del archivo seleccionado no es válido. Si es una imagen, recomendamos que esté en formato .png o .jpg, y si es un audio 
          el formato deseado es .mp3 o .wav
          <i class="material-icons cerrar_alerta" (click)="alert_archivo[4] = false">close</i>
        </h4>
      </div>
    </div>

    <hr />
    
    <div class="row">
      <div class="col-md-9">
        <h5>Extremidades</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[5]" class="form-control" placeholder="Escribe aquí el resultado de este examen físico" rows="5"></textarea> 
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="row">
          <h5>Recursos</h5>
          <div class="col-md-12">
            <div *ngIf="recursosASubir[5] != null">
              <p>
                {{recursosASubir[5].name}}
                <a><i class="material-icons" (click)="handleFileInput(null, 5)">delete</i></a>
              </p>
            </div>
          </div>
          <div class="form-group adjuntar-recursos">
            <label for="file">Adjunta un recurso</label>
            <input type="file" 
                  class="file-upload"
                  (change)="handleFileInput($event.target.files[0], 5)"
            #fileUpload5 />
          </div>
          <button class="btn btn-adjuntar" (click)="fileUpload5.click()">Adjunta un recurso</button>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h5>Reacción</h5>
            <p *ngIf="isMobileMenu()">{{emociones[5]}}</p>
            <div class="dropdown">
              <a class="btn dropdown-toggle" data-toggle="dropdown">
                  Selecciona la reacción
                  <b class="caret"></b>
              </a>
              <ul class="dropdown-menu">
                <li><a (click)="emociones[5] = 'Ninguna'">Ninguna</a></li>
                <li><a (click)="emociones[5] = 'Dolor'">Dolor</a></li>
                <li><a (click)="emociones[5] = 'Enfermo'">Enfermo</a></li>
                <li><a (click)="emociones[5] = 'Enojado'">Enojado</a></li>
                <li><a (click)="emociones[5] = 'Triste'">Triste</a></li>
                <li><a (click)="emociones[5] = 'Vergüenza'">Vergüenza</a></li>
              </ul>
            </div>
            <p *ngIf="!isMobileMenu()">{{emociones[5]}}</p>
          </div>
        </div>
      </div>      
    </div>
    <div *ngIf="alert_archivo[5]" class="row">
      <br />
      <div class="col-md-1">
      </div>
      <div class="alert alert-danger col-md-10">
        <h4>
          <strong>Error:</strong>
          El formato del archivo seleccionado no es válido. Si es una imagen, recomendamos que esté en formato .png o .jpg, y si es un audio 
          el formato deseado es .mp3 o .wav
          <i class="material-icons cerrar_alerta" (click)="alert_archivo[5] = false">close</i>
        </h4>
      </div>
    </div>

    <hr />
    
    <div class="row">
      <div class="col-md-9">
        <h5>Neurológico</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[6]" class="form-control" placeholder="Escribe aquí el resultado de este examen físico" rows="5"></textarea> 
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="row">
          <h5>Recursos</h5>
          <div class="col-md-12">
            <div *ngIf="recursosASubir[6] != null">
              <p>
                {{recursosASubir[6].name}}
                <a><i class="material-icons" (click)="handleFileInput(null, 6)">delete</i></a>
              </p>
            </div>
          </div>
          <div class="form-group adjuntar-recursos">
            <label for="file">Adjunta un recurso</label>
            <input type="file" 
                  class="file-upload"
                  (change)="handleFileInput($event.target.files[0], 6)"
            #fileUpload6 />
          </div>
          <button class="btn btn-adjuntar" (click)="fileUpload6.click()">Adjunta un recurso</button>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h5>Reacción</h5>
            <p *ngIf="isMobileMenu()">{{emociones[6]}}</p>
            <div class="dropdown">
              <a class="btn dropdown-toggle" data-toggle="dropdown">
                  Selecciona la reacción
                  <b class="caret"></b>
              </a>
              <ul class="dropdown-menu">
                <li><a (click)="emociones[6] = 'Ninguna'">Ninguna</a></li>
                <li><a (click)="emociones[6] = 'Dolor'">Dolor</a></li>
                <li><a (click)="emociones[6] = 'Enfermo'">Enfermo</a></li>
                <li><a (click)="emociones[6] = 'Enojado'">Enojado</a></li>
                <li><a (click)="emociones[6] = 'Triste'">Triste</a></li>
                <li><a (click)="emociones[6] = 'Vergüenza'">Vergüenza</a></li>
              </ul>
            </div>
            <p *ngIf="!isMobileMenu()">{{emociones[6]}}</p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="alert_archivo[6]" class="row">
      <br />
      <div class="col-md-1">
      </div>
      <div class="alert alert-danger col-md-10">
        <h4>
          <strong>Error:</strong>
          El formato del archivo seleccionado no es válido. Si es una imagen, recomendamos que esté en formato .png o .jpg, y si es un audio 
          el formato deseado es .mp3 o .wav
          <i class="material-icons cerrar_alerta" (click)="alert_archivo[6] = false">close</i>
        </h4>
      </div>
    </div>

    <hr />
    
    <div class="row">
      <div class="col-md-9">
        <h5>Piel y anexos</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[7]" class="form-control" placeholder="Escribe aquí el resultado de este examen físico" rows="5"></textarea> 
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="row">
          <h5>Recursos</h5>
          <div class="col-md-12">
            <div *ngIf="recursosASubir[7] != null">
              <p>
                {{recursosASubir[7].name}}
                <a><i class="material-icons" (click)="handleFileInput(null, 7)">delete</i></a>
              </p>
            </div>
          </div>
          <div class="form-group adjuntar-recursos">
            <label for="file">Adjunta un recurso</label>
            <input type="file" 
                  class="file-upload"
                  (change)="handleFileInput($event.target.files[0], 7)"
            #fileUpload7 />
          </div>
          <button class="btn btn-adjuntar" (click)="fileUpload7.click()">Adjunta un recurso</button>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h5>Reacción</h5>
            <p *ngIf="isMobileMenu()">{{emociones[7]}}</p>
            <div class="dropdown">
              <a class="btn dropdown-toggle" data-toggle="dropdown">
                  Selecciona la reacción
                  <b class="caret"></b>
              </a>
              <ul class="dropdown-menu">
                <li><a (click)="emociones[7] = 'Ninguna'">Ninguna</a></li>
                <li><a (click)="emociones[7] = 'Dolor'">Dolor</a></li>
                <li><a (click)="emociones[7] = 'Enfermo'">Enfermo</a></li>
                <li><a (click)="emociones[7] = 'Enojado'">Enojado</a></li>
                <li><a (click)="emociones[7] = 'Triste'">Triste</a></li>
                <li><a (click)="emociones[7] = 'Vergüenza'">Vergüenza</a></li>
              </ul>
            </div>
            <p *ngIf="!isMobileMenu()">{{emociones[7]}}</p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="alert_archivo[7]" class="row">
      <br />
      <div class="col-md-1">
      </div>
      <div class="alert alert-danger col-md-10">
        <h4>
          <strong>Error:</strong>
          El formato del archivo seleccionado no es válido. Si es una imagen, recomendamos que esté en formato .png o .jpg, y si es un audio 
          el formato deseado es .mp3 o .wav
          <i class="material-icons cerrar_alerta" (click)="alert_archivo[7] = false">close</i>
        </h4>
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col-md-9">
        <h5>Otros</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[8]" class="form-control" placeholder="Escribe aquí el resultado de este examen físico" rows="5"></textarea> 
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="row">
          <h5>Recursos</h5>
          <div class="col-md-12">
            <div *ngIf="recursosASubir[8] != null">
              <p>
                {{recursosASubir[8].name}}
                <a><i class="material-icons" (click)="handleFileInput(null, 8)">delete</i></a>
              </p>
            </div>
          </div>
          <div class="form-group adjuntar-recursos">
            <label for="file">Adjunta un recurso</label>
            <input type="file" 
                  class="file-upload"
                  (change)="handleFileInput($event.target.files[0], 8)"
            #fileUpload8 />
          </div>
          <button class="btn btn-adjuntar" (click)="fileUpload8.click()">Adjunta un recurso</button>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h5>Reacción</h5>
            <p *ngIf="isMobileMenu()">{{emociones[8]}}</p>
            <div class="dropdown">
              <a class="btn dropdown-toggle" data-toggle="dropdown">
                  Selecciona la reacción
                  <b class="caret"></b>
              </a>
              <ul class="dropdown-menu">
                <li><a (click)="emociones[8] = 'Ninguna'">Ninguna</a></li>
                <li><a (click)="emociones[8] = 'Dolor'">Dolor</a></li>
                <li><a (click)="emociones[8] = 'Enfermo'">Enfermo</a></li>
                <li><a (click)="emociones[8] = 'Enojado'">Enojado</a></li>
                <li><a (click)="emociones[8] = 'Triste'">Triste</a></li>
                <li><a (click)="emociones[8] = 'Vergüenza'">Vergüenza</a></li>
              </ul>
            </div>
            <p *ngIf="!isMobileMenu()">{{emociones[8]}}</p>
          </div>
        </div>
      </div>      
    </div>
    <div *ngIf="alert_archivo[8]" class="row">
      <br />
      <div class="col-md-1">
      </div>
      <div class="alert alert-danger col-md-10">
        <h4>
          <strong>Error:</strong>
          El formato del archivo seleccionado no es válido. Si es una imagen, recomendamos que esté en formato .png o .jpg, y si es un audio 
          el formato deseado es .mp3 o .wav
          <i class="material-icons cerrar_alerta" (click)="alert_archivo[8] = false">close</i>
        </h4>
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col-md-12 text-right">
        <a routerLink="/panel/crear-caso">
          <button class="btn btn-success" (click)="guardarCambios()">Guardar cambios</button>
        </a>
      </div>
    </div>
  </div>
</div>