<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 text-left">
        <h3><strong>Diagnóstico final</strong></h3>
      </div>
      <div *ngIf="isMobileMenu();then content_mobile else content_web"></div>
      <ng-template #content_web>
        <div class="col-md-6 text-right">
          <a routerLink="/panel/crear-caso">
            <button class="btn btn-success" (click)="guardarCambios()">Guardar cambios</button>
          </a>
        </div>
      </ng-template>
      <ng-template #content_mobile>
        <div class="col-md-6 text-left">
          <a routerLink="/panel/crear-caso">
            <button class="btn btn-success" (click)="guardarCambios()">Guardar cambios</button>
          </a>
        </div>
      </ng-template>
    </div>
    <div class="row">
      <div class="col-md-12 text-left">
        <p><a (click)="guardarCambios()" routerLink="/panel/casos">Casos</a>/<a (click)="guardarCambios()" routerLink="/panel/crear-caso">Crear caso</a>/<strong>Diagnóstico final</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h4>Indica el diagnóstico final del caso</h4>
        <div class="row">
          <div class="col-md-7">
            <h5>Diagnóstico</h5>
            <br *ngIf="lbl_diagnostico[0] != null" />
            <div class="card">
              <div class="card-content">
                <div class="form-group">
                  <textarea [(ngModel)]="diagnosticos[0]" class="form-control" placeholder="Escribe un diagnóstico" rows="1"></textarea> 
                </div>
              </div>
            </div>   
          </div>
          <div class="col-md-5">
            <h5>Tipo de diagnóstico</h5>
            <p>{{lbl_diagnostico[0]}}</p>
            <div class="dropdown">
              <a class="btn dropdown-toggle" data-toggle="dropdown">
                  Selecciona el tipo de diagnóstico correcto
                  <b class="caret"></b>
              </a>
              <ul class="dropdown-menu">
                <li><a (click)="setLblDiagnostico(0, 'Diagnóstico final')">Diagnóstico final</a></li>
                <li><a (click)="setLblDiagnostico(0, 'Diagnóstico relacionado')">Diagnóstico relacionado</a></li>
                <li><a (click)="setLblDiagnostico(0, 'Diagnóstico descartado')">Diagnóstico descartado</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-7">
            <h5>Diagnóstico</h5>
            <br *ngIf="lbl_diagnostico[1] != null" />
            <div class="card">
              <div class="card-content">
                <div class="form-group">
                  <textarea [(ngModel)]="diagnosticos[1]" class="form-control" placeholder="Escribe un diagnóstico" rows="1"></textarea> 
                </div>
              </div>
            </div>   
          </div>
          <div class="col-md-5">
            <h5>Tipo de diagnóstico</h5>
            <p>{{lbl_diagnostico[1]}}</p>
            <div class="dropdown">
              <a class="btn dropdown-toggle" data-toggle="dropdown">
                Selecciona el tipo de diagnóstico correcto
                  <b class="caret"></b>
              </a>
              <ul class="dropdown-menu">
                <li><a (click)="setLblDiagnostico(1, 'Diagnóstico final')">Diagnóstico final</a></li>
                <li><a (click)="setLblDiagnostico(1, 'Diagnóstico relacionado')">Diagnóstico relacionado</a></li>
                <li><a (click)="setLblDiagnostico(1, 'Diagnóstico descartado')">Diagnóstico descartado</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-7">
            <h5>Diagnóstico</h5>
            <br *ngIf="lbl_diagnostico[2] != null" />
            <div class="card">
              <div class="card-content">
                <div class="form-group">
                  <textarea [(ngModel)]="diagnosticos[2]" class="form-control" placeholder="Escribe un diagnóstico" rows="1"></textarea> 
                </div>
              </div>
            </div>   
          </div>
          <div class="col-md-5">
            <h5>Tipo de diagnóstico</h5>
            <p>{{lbl_diagnostico[2]}}</p>
            <div class="dropdown">
              <a class="btn dropdown-toggle" data-toggle="dropdown">
                Selecciona el tipo de diagnóstico correcto
                  <b class="caret"></b>
              </a>
              <ul class="dropdown-menu">
                <li><a (click)="setLblDiagnostico(2, 'Diagnóstico final')">Diagnóstico final</a></li>
                <li><a (click)="setLblDiagnostico(2, 'Diagnóstico relacionado')">Diagnóstico relacionado</a></li>
                <li><a (click)="setLblDiagnostico(2, 'Diagnóstico descartado')">Diagnóstico descartado</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-7">
            <h5>Diagnóstico</h5>
            <br *ngIf="lbl_diagnostico[3] != null" />
            <div class="card">
              <div class="card-content">
                <div class="form-group">
                  <textarea [(ngModel)]="diagnosticos[3]" class="form-control" placeholder="Escribe un diagnóstico" rows="1"></textarea> 
                </div>
              </div>
            </div>   
          </div>
          <div class="col-md-5">
            <h5>Tipo de diagnóstico</h5>
            <p>{{lbl_diagnostico[3]}}</p>
            <div class="dropdown">
              <a class="btn dropdown-toggle" data-toggle="dropdown">
                Selecciona el tipo de diagnóstico correcto
                  <b class="caret"></b>
              </a>
              <ul class="dropdown-menu">
                <li><a (click)="setLblDiagnostico(3, 'Diagnóstico final')">Diagnóstico final</a></li>
                <li><a (click)="setLblDiagnostico(3, 'Diagnóstico relacionado')">Diagnóstico relacionado</a></li>
                <li><a (click)="setLblDiagnostico(3, 'Diagnóstico descartado')">Diagnóstico descartado</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-7">
            <h5>Diagnóstico</h5>
            <br *ngIf="lbl_diagnostico[4] != null" />
            <div class="card">
              <div class="card-content">
                <div class="form-group">
                  <textarea [(ngModel)]="diagnosticos[4]" class="form-control" placeholder="Escribe un diagnóstico" rows="1"></textarea> 
                </div>
              </div>
            </div>   
          </div>
          <div class="col-md-5">
            <h5>Tipo de diagnóstico</h5>
            <p>{{lbl_diagnostico[4]}}</p>
            <div class="dropdown">
              <a class="btn dropdown-toggle" data-toggle="dropdown">
                Selecciona el tipo de diagnóstico correcto
                  <b class="caret"></b>
              </a>
              <ul class="dropdown-menu">
                <li><a (click)="setLblDiagnostico(4, 'Diagnóstico final')">Diagnóstico final</a></li>
                <li><a (click)="setLblDiagnostico(4, 'Diagnóstico relacionado')">Diagnóstico relacionado</a></li>
                <li><a (click)="setLblDiagnostico(4, 'Diagnóstico descartado')">Diagnóstico descartado</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <br />

    <div *ngIf="alert_repetido" class="row">
      <br />
      <div class="col-md-1">
      </div>
      <div class="alert alert-danger col-md-10">
        <h4>
          <strong>Error:</strong>
          Sólo puede haber un único diagnóstico final. Los demás deben ser relacionados o descartados.
          <i class="material-icons cerrar_alerta" (click)="alert_repetido = false">close</i>
        </h4>
      </div>
    </div>

    <br />
    
    <div class="row">
      <div class="col-md-11">
        <h4>Razón del caso</h4>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="razon" class="form-control" placeholder="Escribe la razón del diagnóstico" rows="5"></textarea> 
            </div>
          </div>
        </div>   
      </div>
    </div>
    <div class="row">
      <div class="col-md-11">
        <h4>Bibliografía</h4>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="bibliografia[0]" class="form-control" placeholder="Adjunta un link con información del caso" rows="1"></textarea> 
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="bibliografia[1]" class="form-control" placeholder="Adjunta un link con información del caso" rows="1"></textarea> 
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="bibliografia[2]" class="form-control" placeholder="Adjunta un link con información del caso" rows="1"></textarea> 
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="bibliografia[3]" class="form-control" placeholder="Adjunta un link con información del caso" rows="1"></textarea> 
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="bibliografia[4]" class="form-control" placeholder="Adjunta un link con información del caso" rows="1"></textarea> 
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col-md-6 text-left">
        <a routerLink="/panel/crear-caso">
          <button class="btn btn-success" (click)="guardarCambios()">Guardar cambios</button>
        </a>
      </div>
    </div>

  </div>
</div>