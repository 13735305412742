import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { RdbUsersService } from 'app/services/firebase/rdb-users.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private msalService: MsalService, private rdb_users: RdbUsersService, private httpClient: HttpClient) { }

  username: string = "";
  isAdmin: boolean = false;

  ngOnInit(): void {
    this.msalService.instance.handleRedirectPromise().then(
      res => {
        if (res != null && res.account != null) {
          this.msalService.instance.setActiveAccount(res.account);
          this.username = this.msalService.instance.getActiveAccount().username;
          this.rdb_users.getAdministradores().toPromise().then(
            resp => {
              var user_aux = this.username.split(".").join(",");
              for(let user in resp) {
                if (user == user_aux) {
                  this.isAdmin = true;
                  break;
                }
              }
            }
          );
        }
      }
    ).catch(
      error => {
        console.error("handleRedirectPromise() error: " + error);
      }
    );
  }

  isLoggedIn(): boolean {
    return this.msalService.instance.getActiveAccount() != null;
  }
  
  login() {
    this.msalService.loginRedirect();
  }
  
  logout() {
    this.msalService.logout();
    this.msalService.instance.setActiveAccount(null);
    this.isAdmin = false;
  }
  
  //Recupera la información del perfil desde la API de Microsoft
  callProfile() {
    this.httpClient.get("https://graph.microsoft.com/v1.0/me").subscribe( resp => {
      console.log(resp);
    });
  }

}
