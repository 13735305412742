import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RdbUsersService {

  constructor(private http: HttpClient) { }

  urlFirebaseUsers = "https://medicina-uniandes-default-rtdb.firebaseio.com/usuarios/";

  //Nombre y correo del estudiante cuyo detalle se quiere consultar
  nombreActual: string = "";
  correoActual: string = "";

  getEstudiantes() {
    return this.http.get(this.urlFirebaseUsers + "estudiantes/.json");
  }

  getAdministradores() {
    return this.http.get(this.urlFirebaseUsers + "administradores/.json");
  }

  getEstudiantePorCorreo(pCorreo: string) {
    return this.http.get(this.urlFirebaseUsers + "estudiantes/" + pCorreo.split(".").join(",") + "/.json");
  }

  putEstudiante(pCorreo: string, json_est: string) {
    return this.http.put(this.urlFirebaseUsers + "estudiantes/" + pCorreo +"/.json", JSON.parse(json_est));
  }
}
