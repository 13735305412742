<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 text-left">
        <h3><strong>{{laboratorio_seleccionado}}</strong></h3>
      </div>
      <div *ngIf="isMobileMenu();then content_mobile else content_web"></div>
      <ng-template #content_web>
        <div class="col-md-6 text-right">
          <button class="btn btn-success" (click)="guardarCambios()">Guardar cambios</button>
        </div>
      </ng-template>
      <ng-template #content_mobile>
        <div class="col-md-6 text-left">
          <button class="btn btn-success" (click)="guardarCambios()">Guardar cambios</button>
        </div>
      </ng-template>
    </div>
    <div class="row">
      <div class="col-md-12 text-left">
        <p><a (click)="guardarCambios()" routerLink="/panel/laboratorio">Laboratorios</a>/<strong>{{laboratorio_seleccionado}}</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div *ngFor="let lab of labs; let i = index; trackBy: trackByFn">
          <div *ngIf="lab != null" class="row">
            <div class="col-md-12">
              <h5>Laboratorio {{i + 1}}</h5>
            </div>
          </div>
          <div *ngIf="lab != null" class="row">
            <div class="col-md-10">
              <div class="card">
                <div class="card-content">
                  <!--El primer laboratorio no es posible editarlo-->
                  <div *ngIf="i == 0" class="form-group">
                    <textarea [(ngModel)]="labs[i]" class="form-control" placeholder="Escribe aquí el laboratorio que quieras agregar" rows="1" readonly></textarea> 
                  </div>
                  <div *ngIf="i != 0" class="form-group">
                    <textarea [(ngModel)]="labs[i]" class="form-control" placeholder="Escribe aquí el laboratorio que quieras agregar" rows="1"></textarea> 
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="i != 0" class="col-md-2">
              <br />
              <i class="material-icons" (click)="eliminarLab(i)">close</i>
            </div>
          </div>
          <div *ngIf="lab != null">
            <hr />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-left">
        <h4 class="agregar_preg" (click)="agregarLab()"><strong>Agregar laboratorio</strong></h4>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-right">
        <button class="btn btn-success" (click)="guardarCambios()">Guardar cambios</button>
      </div>
    </div>
  </div>
</div>