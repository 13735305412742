<div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 text-left">
          <h3><strong>Editar caso</strong></h3>
        </div>
        <div *ngIf="isMobileMenu()">
          <div *ngIf="caso_listo && !alert_repetido" class="col-md-6 text-left">
            <button class="btn btn-success" (click)="terminarCaso()">Gaurdar cambios y subir</button>
          </div>
        </div>
        <div *ngIf="!isMobileMenu()">
          <div *ngIf="caso_listo && !alert_repetido" class="col-md-6 text-right">
            <button class="btn btn-success" (click)="terminarCaso()">Guardar cambios y subir</button>
          </div>
        </div>
      </div>
      
      <div class="row">
        <div class="col-md-12 text-left">
          <p><a routerLink="/panel/casos">Casos</a>/<strong>Editar caso</strong></p>
        </div>
      </div>
      <div class="row">
          <div class="col-md-4">
            <h5>Nombre del caso</h5>
            <p>{{ nombre_caso }}</p>
          </div>
          <div class="col-md-4">
            <h5>Modo</h5>
            <p>{{ modo }}</p>
          </div>
          <div class="col-md-4">
            <h5>Especialidad</h5>
            <p>{{ especialidad }}</p>
          </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <h5>Dificultad</h5>
          <div class="dropdown">
            <a class="btn dropdown-toggle" data-toggle="dropdown">
              Selecciona una dificultad
              <b class="caret"></b>
            </a>
            <ul class="dropdown-menu">
              <li><a (click)="setDificultad('Fácil')">Fácil</a></li>
              <li><a (click)="setDificultad('Intermedio')">Intermedio</a></li>
              <li><a (click)="setDificultad('Avanzado')">Avanzado</a></li>
            </ul>
          </div>
          <p>{{dificultad}}</p>
        </div>
        <div class="col-md-6">
          <h5>Escenario</h5>
          <div class="dropdown">
            <a class="btn dropdown-toggle" data-toggle="dropdown">
              Selecciona el escenario
              <b class="caret"></b>
            </a>
            <ul class="dropdown-menu">
              <li><a (click)="setEscenario('Cirugía')">Cirugía</a></li>
              <li><a (click)="setEscenario('Consultorio')">Consultorio</a></li>
              <li><a (click)="setEscenario('Hospitalización')">Hospitalización</a></li>
              <li><a (click)="setEscenario('Urgencias')">Urgencias</a></li>
            </ul>
          </div>
          <p>{{escenario}}</p>
        </div>
      </div>
      <br />
      <div *ngIf="verificarRepetido() && alert_repetido" class="row">
        <br />
        <div class="col-md-1">
        </div>
        <div class="alert alert-danger col-md-10">
          <h4>
            <strong>Error:</strong>
            Ya existe un caso con el nombre "{{this.aux_alert_nombre}}" en {{this.especialidad}}
            <i class="material-icons cerrar_alerta" (click)="alert_repetido = false">close</i>
          </h4>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-md-6">
          <br />
          <div class="card">
            <a routerLink="/panel/detalle-caso-paciente" (click)="guardarCambios()">
              <div class="card-content card-secciones bg-white">
                <h4 class="title-seccion"><strong>Paciente</strong></h4>
  
                <img *ngIf="pacienteListo()"
                class="img-listo text-right"
                src="../../assets/img/crear-caso-listo.png"
                alt="Sección diagnóstico final lista"
                />
              </div>
            </a>
          </div>
        </div>
        <div class="col-md-6">
          <br />
          <div class="card">
            <a routerLink="/panel/detalle-caso-enfermedad-actual" (click)="guardarCambios()">
              <div class="card-content card-secciones bg-white">
                  <h4 class="title-seccion"><strong>Enfermedad actual</strong></h4>
  
                  <img *ngIf="enfermedadActualListo()"
                  class="img-listo text-right"
                  src="../../assets/img/crear-caso-listo.png"
                  alt="Sección enfermedad actual lista"
                  />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <br />
          <div class="card">
            <a routerLink="/panel/detalle-caso-antecedentes" (click)="guardarCambios()">
              <div class="card-content card-secciones bg-white">
                <h4 class="title-seccion"><strong>Antecedentes</strong></h4>
  
                <img *ngIf="antecedentesListo()"
                class="img-listo text-right"
                src="../../assets/img/crear-caso-listo.png"
                alt="Sección antecedentes lista"
                />
              </div>
            </a>
          </div>
        </div>
        <div class="col-md-6">
          <br />
          <div class="card">
            <a routerLink="/panel/detalle-caso-sistemas" (click)="guardarCambios()">
              <div class="card-content card-secciones bg-white">
                <h4 class="title-seccion"><strong>Revisión por sistemas</strong></h4>
  
                <img *ngIf="sistemasListo()"
                class="img-listo text-right"
                src="../../assets/img/crear-caso-listo.png"
                alt="Sección revisión por sistemas lista"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <br />
          <div class="card">
            <a routerLink="/panel/detalle-caso-examen-fisico" (click)="guardarCambios()">
              <div class="card-content card-secciones bg-white">
                <h4 class="title-seccion"><strong>Examen físico</strong></h4>
  
                <img *ngIf="examenListo()"
                class="img-listo text-right"
                src="../../assets/img/crear-caso-listo.png"
                alt="Sección examen físico lista"
                />
              </div>
            </a>
          </div>
        </div>
        <div class="col-md-6">
          <br />
          <div class="card">
            <a routerLink="/panel/detalle-caso-impresion" (click)="guardarCambios()">
              <div class="card-content card-secciones bg-white">
                <h4 class="title-seccion"><strong>Impresión diagnóstica</strong></h4>
  
                <img *ngIf="impresionListo()"
                class="img-listo text-right"
                src="../../assets/img/crear-caso-listo.png"
                alt="Sección impresión diagnóstica lista"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <br />
          <div class="card">
            <a routerLink="/panel/detalle-caso-ayudas" (click)="guardarCambios()">
              <div class="card-content card-secciones bg-white">
                <h4 class="title-seccion"><strong>Ayudas diagnósticas</strong></h4>
  
                <img *ngIf="ayudasListo()"
                class="img-listo text-right"
                src="../../assets/img/crear-caso-listo.png"
                alt="Sección ayudas diangósticas lista"
                />
              </div>
            </a>
          </div>
        </div>
        <div class="col-md-6">
          <br />
          <div class="card">
            <a routerLink="/panel/detalle-caso-tratamiento" (click)="guardarCambios()">
              <div class="card-content card-secciones bg-white">
                <h4 class="title-seccion"><strong>Tratamiento</strong></h4>
  
                <img *ngIf="tratamientoListo()"
                class="img-listo text-right"
                src="../../assets/img/crear-caso-listo.png"
                alt="Sección tratamiento lista"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <br />
          <div class="card">
            <a routerLink="/panel/detalle-caso-diagnostico" (click)="guardarCambios()">
              <div class="card-content card-secciones bg-white">
                <h4 class="title-seccion"><strong>Diagnóstico final</strong></h4>
  
                <img *ngIf="diagnosticoListo()"
                class="img-listo text-right"
                src="../../assets/img/crear-caso-listo.png"
                alt="Sección diagnóstico final lista"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
</div>