import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'app/services/storage/local-storage.service';
import { RdbService } from '../../services/firebase/rdb.service';

declare const $: any;

@Component({
  selector: 'app-crear-caso-enfermedad-actual',
  templateUrl: './crear-caso-enfermedad-actual.component.html',
  styleUrls: ['./crear-caso-enfermedad-actual.component.css']
})
export class CrearCasoEnfermedadActualComponent implements OnInit {

  resps: string[] = [];

  constructor(private firebase_rdb: RdbService, private local_db: LocalStorageService) { }

  ngOnInit() {
    //Recupera el último estado guardado del componente    
    this.local_db.getItem<string[]>("enfermedad_actual_resps").then(
      resp => {
        if (resp != null){
          this.firebase_rdb.enfermedad_actual_resps = resp;
          this.resps = this.firebase_rdb.enfermedad_actual_resps;
        }
      }
    );
  }

  guardarCambios() {
    //Guarda el estado del componente
    this.firebase_rdb.enfermedad_actual_resps = this.resps;    
    this.local_db.setItem<string[]>("enfermedad_actual_resps", this.resps);
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
        return false;
    }
    return true;
  };

}
