import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RdbEditarCasoService } from 'app/services/firebase/rdb-editar-caso.service';
import { RdbService } from 'app/services/firebase/rdb.service';

declare const $: any;

@Component({
  selector: 'app-casos',
  templateUrl: './casos.component.html',
  styleUrls: ['./casos.component.css']
})
export class CasosComponent implements OnInit {

  constructor(private firebase_rdb: RdbService, private firebase_caso_rdb: RdbEditarCasoService,
    private router: Router) { }

  nombres: string[] = [];
  modos: string[] = [];
  especialidades: string[] = [];
  dificultades: string[] = [];
  diagnosticos_finales: string[] = [];

  ngOnInit() {
    this.firebase_caso_rdb.clearSeleccionado();

    var esp_actual;
    var caso_actual;
    var diagnosticos: string[] = [];
    var diag_final_aux: string = "";
    this.firebase_rdb.getAllCasos().toPromise().then(
      esps => {
        //Especialidades
        for(let esp_aux in esps) {
          esp_actual = esps[esp_aux];
          if(esp_actual != null) {
            //Casos dentro de la especialidad
            for(let caso in esp_actual) {
              diagnosticos = [];
              diag_final_aux = "";
              caso_actual = esp_actual[caso];
              if(caso_actual != null) {
                this.nombres.push(caso_actual["título"]);
                this.modos.push(caso_actual["modo"]);
                this.especialidades.push(esp_aux);
                this.dificultades.push(caso_actual["dificultad"]);
                
                //Determinar cuál es el diagnóstico final del caso
                diagnosticos.push(caso_actual["impresión diagnóstica"]["d_1"]);
                diagnosticos.push(caso_actual["impresión diagnóstica"]["d_2"]);
                diagnosticos.push(caso_actual["impresión diagnóstica"]["d_3"]);
                diagnosticos.push(caso_actual["impresión diagnóstica"]["d_4"]);
                diagnosticos.push(caso_actual["impresión diagnóstica"]["d_5"]);

                for(var i = 1; i <= 5; i++) {
                  if (caso_actual["diagnóstico final"]["f_" + i] == "Diagnóstico final") {
                    diag_final_aux = diagnosticos[i-1];
                    break;
                  }
                }

                if (diag_final_aux == "") {
                  diag_final_aux = "No hay diagnóstico final";
                }

                this.diagnosticos_finales.push(diag_final_aux);
              }
            }
          }
        }
      }
    );
  }

  cntCasos(): number {
    var ans = 0;

    this.nombres.forEach(
      e => {
        if (e != null && e != "") {
          ans++;
        }
      }
    );

    return ans;
  }
  
  deleteCaso(index: number) {
    if(confirm("¿Quieres eliminar el caso \"" + this.nombres[index] + "\" de " + this.especialidades[index] + 
      "? No se podrá recuperar la información del caso ni los recursos asociados después de esto...")) {
      this.firebase_rdb.deleteCasoDeEspecialidad(this.nombres[index], this.especialidades[index]);

      this.nombres[index] = null;
      this.modos[index] = null;
      this.especialidades[index] = null;
      this.dificultades[index] = null;
      this.diagnosticos_finales[index] = null;
    }
  }

  verDetalleCaso(index: number) {
    this.firebase_caso_rdb.nombre_caso_seleccionado = this.nombres[index];
    this.firebase_caso_rdb.especialidad_caso_seleccionado = this.especialidades[index];
    
    this.firebase_caso_rdb.getCasosEspecialdiad(this.firebase_caso_rdb.especialidad_caso_seleccionado).toPromise()
    .then(
      data => {
        for(let key in data) {
          if (data.hasOwnProperty(key) && data[key]["título"] == this.firebase_caso_rdb.nombre_caso_seleccionado) {
            this.firebase_caso_rdb.id_caso_seleccionado = key;
            this.firebase_caso_rdb.json_caso_seleccionado = data[key];

            this.firebase_caso_rdb.nombre_caso = this.firebase_caso_rdb.nombre_caso_seleccionado;
            this.firebase_caso_rdb.especialidad = this.firebase_caso_rdb.especialidad_caso_seleccionado;
            this.firebase_caso_rdb.dificultad = data[key]["dificultad"];
            this.firebase_caso_rdb.escenario = data[key]["escenario"];
            this.firebase_caso_rdb.modo = data[key]["modo"];

            var json_pts: JSON = this.firebase_caso_rdb.json_caso_seleccionado["tipo puntuación"];
            //Antecedentes------------------------------------------------------------------------------------------
            //Respuestas
            var json_antecedentes: JSON = this.firebase_caso_rdb.json_caso_seleccionado["antecedentes"];
            var resp_aux: string;
            var cnt: number = 0;
            while (true) {
              resp_aux = json_antecedentes["r_" + cnt];
              if (resp_aux != null) {
                this.firebase_caso_rdb.antecedentes_resps.push(resp_aux);
              }
              else {
                break;
              }
              cnt++;
            }
            //Puntuación
            var json_antecedentes_pts: JSON = json_pts["antecedentes"];
            for(let antecedente_aux in this.firebase_caso_rdb.antecedentes_pregs) {
              this.firebase_caso_rdb.antecedentes_tipos.push(
                json_antecedentes_pts[this.firebase_caso_rdb.antecedentes_pregs[antecedente_aux]]
              );
            }
            
            //Ayudas diagnósticas-----------------------------------------------------------------------------------
            var json_ayudas_diagnosticas: JSON = this.firebase_caso_rdb.json_caso_seleccionado["ayudas diagnósticas"];
            var json_ayudas_diagnosticas_pts: JSON = json_pts["ayudas diagnósticas"];

            this.firebase_caso_rdb.ayudas_diagnosticas_json =  json_ayudas_diagnosticas;
            this.firebase_caso_rdb.ayudas_diagnosticas_pts_json = json_ayudas_diagnosticas_pts;

            var json_resp_aux: JSON = null;
            resp_aux = null;
            var file_aux: string = null;
            var nombre_aux: string = null;
            var pts_aux: string = null;

            if (json_ayudas_diagnosticas != null && json_ayudas_diagnosticas_pts != null) {
              //Laboratorios
              var json_labs: JSON = json_ayudas_diagnosticas["laboratorios"];
              var json_labs_pts: JSON = json_ayudas_diagnosticas_pts["laboratorios"];

              if (json_labs != null && json_labs_pts != null) {
                for(let key_lab in json_labs) {
                  if (json_labs.hasOwnProperty(key_lab) && json_labs_pts.hasOwnProperty(key_lab)) {
                    nombre_aux = key_lab;
                    json_resp_aux = json_labs[nombre_aux];
                    resp_aux = json_resp_aux["respuesta"];
                    file_aux = json_resp_aux["adjunto"];
                    pts_aux = json_labs_pts[nombre_aux];

                    if (nombre_aux != null && pts_aux != null) {
                      this.firebase_caso_rdb.laboratorios_nombres.push(nombre_aux);
                      this.firebase_caso_rdb.laboratorios_resps.push(resp_aux);

                      //No es eficiente descargar el archivo adjunto desde storage solo para mostrar el nombre
                      //Se recupera el nombre del archivo, se pone un placeholder en el arreglo de archivos,
                      //y cuando el usuario haga algún cambio sobre el archivo se levanta un flag que indica
                      //que se debe subir el nuevo archivo y eliminar el viejo
                      //Esto aplica para laboratorios, imágenes diagnósticas, y otras ayudas
                      this.firebase_caso_rdb.laboratorios_adjuntos.push(null);
                      this.firebase_caso_rdb.laboratorios_adjuntos_str.push(file_aux);
                      this.firebase_caso_rdb.laboratorios_cambiar.push(false);

                      this.firebase_caso_rdb.laboratorios_pts.push(pts_aux);
                    }
                  }
                }
              }

              //Imágenes diagnósticas
              var json_imgs: JSON = json_ayudas_diagnosticas["imágenes diagnósticas"];
              var json_imgs_pts: JSON = json_ayudas_diagnosticas_pts["imágenes diagnósticas"];

              if (json_imgs != null && json_imgs_pts != null) {
                for(let key in json_imgs) {
                  if (json_imgs.hasOwnProperty(key) && json_imgs_pts.hasOwnProperty(key)) {
                    this.firebase_caso_rdb.imagenes_diagnosticas.push(key);

                    this.firebase_caso_rdb.imgs_adjuntos.push(null);
                    this.firebase_caso_rdb.imgs_adjuntos_str.push(json_imgs[key]["adjunto"]);

                    this.firebase_caso_rdb.imgs_cambiar.push(false);

                    this.firebase_caso_rdb.imgs_puntuaciones.push(json_imgs_pts[key]);
                  }
                }
              }

              //Otras ayudas
              var json_otras: JSON = json_ayudas_diagnosticas["otras ayudas"];
              var json_otras_pts: JSON = json_ayudas_diagnosticas_pts["otras ayudas"];

              if (json_otras != null && json_otras_pts != null) {
                for(let key_lab in json_otras) {
                  if (json_otras.hasOwnProperty(key_lab) && json_otras_pts.hasOwnProperty(key_lab)) {
                    nombre_aux = key_lab;
                    json_resp_aux = json_otras[nombre_aux];
                    resp_aux = json_resp_aux["respuesta"];
                    file_aux = json_resp_aux["adjunto"];
                    pts_aux = json_otras_pts[nombre_aux];

                    if (nombre_aux != null && pts_aux != null) {
                      this.firebase_caso_rdb.otras_nombres.push(nombre_aux);
                      this.firebase_caso_rdb.otras_resps.push(resp_aux);

                      this.firebase_caso_rdb.otras_adjuntos.push(null);
                      this.firebase_caso_rdb.otras_adjuntos_str.push(file_aux);
                      this.firebase_caso_rdb.otras_cambiar.push(false);

                      this.firebase_caso_rdb.otras_pts.push(pts_aux);
                    }
                  }
                }
              }

            }
            
            //Diagnóstico final-------------------------------------------------------------------------------------
            var json_diagnostico_final: JSON = this.firebase_caso_rdb.json_caso_seleccionado["diagnóstico final"];
            var json_bibliografia: JSON = this.firebase_caso_rdb.json_caso_seleccionado["bibliografía"];
            cnt = 1;
            var lbl_aux: string = "";
            var biblio_aux: string = "";
            //Esto sólo funciona porque tanto la bibliografía como los diagnósticos
            //finales tienen la misma cantidad de elementos
            while(true) {
              lbl_aux = json_diagnostico_final["f_" + cnt];
              biblio_aux = json_bibliografia["b_" + cnt];
              if (lbl_aux != null && biblio_aux != null) {
                this.firebase_caso_rdb.lbl_diagnostico.push(lbl_aux);
                this.firebase_caso_rdb.bibliografia.push(biblio_aux);
              }
              else {
                break;
              }
              cnt++;
            }
            this.firebase_caso_rdb.motivo_diagnostico = this.firebase_caso_rdb.json_caso_seleccionado["motivo del diagnóstico"];

            //Enfermedad actual-------------------------------------------------------------------------------------
            var json_enfermedad_actual: JSON = this.firebase_caso_rdb.json_caso_seleccionado["enfermedad actual"];
            cnt = 0;
            while(true) {
              resp_aux = json_enfermedad_actual["r_" + cnt];
              if (resp_aux != null) {
                this.firebase_caso_rdb.enfermedad_actual_resps.push(resp_aux);
              }
              else {
                break;
              }
              cnt++;
            }

            //Examen físico-----------------------------------------------------------------------------------------
            var json_examen_fisico = this.firebase_caso_rdb.json_caso_seleccionado["examen físico"];
            //Respuestas
            var json_examen_fisico_resps = json_examen_fisico["respuestas"];
            cnt = 0;
            while(true) {
              resp_aux = json_examen_fisico_resps["r_" + cnt];
              if (resp_aux != null) {
                this.firebase_caso_rdb.examen_fisico_resps.push(resp_aux);
              }
              else {
                break;
              }
              cnt++;
            }
            
            //Archivos
            this.firebase_caso_rdb.examen_fisico_archivos_json = json_examen_fisico["archivos"];
            
            this.firebase_caso_rdb.examen_fisico_archivos_str.push(this.firebase_caso_rdb.examen_fisico_archivos_json["Estado general"]);
            this.firebase_caso_rdb.examen_fisico_archivos_str.push(this.firebase_caso_rdb.examen_fisico_archivos_json["Cabeza y cuello"]);
            this.firebase_caso_rdb.examen_fisico_archivos_str.push(this.firebase_caso_rdb.examen_fisico_archivos_json["Cardiovascular"]);
            this.firebase_caso_rdb.examen_fisico_archivos_str.push(this.firebase_caso_rdb.examen_fisico_archivos_json["Respiratorio"]);
            this.firebase_caso_rdb.examen_fisico_archivos_str.push(this.firebase_caso_rdb.examen_fisico_archivos_json["Abdomen"]);
            this.firebase_caso_rdb.examen_fisico_archivos_str.push(this.firebase_caso_rdb.examen_fisico_archivos_json["Extremidades"]);
            this.firebase_caso_rdb.examen_fisico_archivos_str.push(this.firebase_caso_rdb.examen_fisico_archivos_json["Neurológico"]);
            this.firebase_caso_rdb.examen_fisico_archivos_str.push(this.firebase_caso_rdb.examen_fisico_archivos_json["Piel y anexos"]);
            this.firebase_caso_rdb.examen_fisico_archivos_str.push(this.firebase_caso_rdb.examen_fisico_archivos_json["Otros"]);

            //Dolor
            var json_dolor = this.firebase_caso_rdb.json_caso_seleccionado["dolor"];
            this.firebase_caso_rdb.dolor_abdomen = json_dolor["abdomen"] != null ? json_dolor["abdomen"] : false;
            this.firebase_caso_rdb.dolor_cabeza = json_dolor["cabeza"] != null ? json_dolor["cabeza"] : false;
            this.firebase_caso_rdb.dolor_extremidades = json_dolor["extremidades"] != null ? json_dolor["extremidades"] : false;
            this.firebase_caso_rdb.dolor_torax = json_dolor["torax"] != null ? json_dolor["torax"] : false;
            

            //Impresión diagnóstica---------------------------------------------------------------------------------
            var json_impresion: JSON = this.firebase_caso_rdb.json_caso_seleccionado["impresión diagnóstica"];
            var diag_aux: string;
            var prob_aux: string;
            cnt = 1;
            while(true) {
              diag_aux = json_impresion["d_" + cnt];
              prob_aux = json_impresion["p_" + cnt];
              if (diag_aux != null && prob_aux != null) {
                this.firebase_caso_rdb.diagnosticos.push(diag_aux);
                this.firebase_caso_rdb.probabilidades.push(prob_aux);
              }
              else {
                break;
              }
              cnt++;
            }

            //Paciente----------------------------------------------------------------------------------------------
            //Datos personales
            this.firebase_caso_rdb.nombre_paciente = this.firebase_caso_rdb.json_caso_seleccionado["nombre"];
            this.firebase_caso_rdb.genero = this.firebase_caso_rdb.json_caso_seleccionado["género"];
            this.firebase_caso_rdb.edad = this.firebase_caso_rdb.json_caso_seleccionado["edad"];
            this.firebase_caso_rdb.peso = json_examen_fisico["peso"];
            this.firebase_caso_rdb.talla = json_examen_fisico["talla"];
            this.firebase_caso_rdb.etnia = this.firebase_caso_rdb.json_caso_seleccionado["etnia"];
            this.firebase_caso_rdb.motivo_consulta = this.firebase_caso_rdb.json_caso_seleccionado["motivo de consulta"];
            if (this.firebase_caso_rdb.json_caso_seleccionado["apariencia"] == "Embarazada") {
              this.firebase_caso_rdb.embarazada = true;
            }
            else {
              this.firebase_caso_rdb.embarazada = false;
            }

            //Signos vitales
            this.firebase_caso_rdb.frecuencia_cardiaca = json_examen_fisico["FC"];
            this.firebase_caso_rdb.frecuencia_respiratoria = json_examen_fisico["FR"];
            this.firebase_caso_rdb.saturacion = json_examen_fisico["SAT"];
            this.firebase_caso_rdb.tension_arterial = json_examen_fisico["TA"];
            this.firebase_caso_rdb.VAS = JSON.stringify(json_examen_fisico["VAS"]);
            this.firebase_caso_rdb.temperatura = json_examen_fisico["Temperatura"];
            this.firebase_caso_rdb.paciente_listo = true;

            //Sistemas----------------------------------------------------------------------------------------------
            var json_sistemas: JSON = this.firebase_caso_rdb.json_caso_seleccionado["sistemas"];

            //JSON de cada sistema particular
            var json_cardiovascular: JSON = json_sistemas["cardiovascular"];
            var json_endocrino: JSON = json_sistemas["endocrino"];
            var json_gastrointestinal: JSON = json_sistemas["gastrointestinal"];
            var json_nervioso: JSON = json_sistemas["nervioso"];
            var json_osteomuscular: JSON = json_sistemas["osteomuscular"];
            var json_respiratorio: JSON = json_sistemas["respiratorio"];

            //Variables auxiliares para cada respuesta de cada sistema
            var resp_cardio: string;
            var resp_endo: string;
            var resp_gastro: string;
            var resp_nerv: string;
            var resp_osteo: string;
            var resp_respir: string;
            
            //Indican si la variable auxiliar correspondiente es null (true) o no (false)
            var cardio: boolean;
            var endo: boolean;
            var gastro: boolean;
            var nerv: boolean;
            var osteo: boolean;
            var respir: boolean;

            //Cuentan cuántos elementos debe tener cada sistema
            var cnt_cardio: number = 0;
            var cnt_endo: number = 0;
            var cnt_gastro: number = 0;
            var cnt_nerv: number = 0;
            var cnt_osteo: number = 0;
            var cnt_respir: number = 0;

            cnt = 0;

            while(true) {
              resp_cardio = json_cardiovascular["r_" + cnt];
              resp_endo = json_endocrino["r_" + cnt];
              resp_gastro = json_gastrointestinal["r_" + cnt];
              resp_nerv = json_nervioso["r_" + cnt];
              resp_osteo = json_osteomuscular["r_" + cnt];
              resp_respir = json_respiratorio["r_" + cnt];

              cardio = resp_cardio == null;
              endo = resp_endo == null;
              gastro = resp_gastro == null;
              nerv = resp_nerv == null;
              osteo = resp_osteo == null;
              respir = resp_respir == null;

              if (cardio && endo && gastro && nerv && osteo && respir) {
                break; //Ya no hay más respuestas por agregar
              }

              //Los siguientes escenarios no son mutuamente excluyentes
              if (!cardio) {
                this.firebase_caso_rdb.cardiovascular_resps.push(resp_cardio);
                cnt_cardio++;
              }
              if (!endo) {
                this.firebase_caso_rdb.endocrino_resps.push(resp_endo);
                cnt_endo++;
              }
              if (!gastro) {
                this.firebase_caso_rdb.gastrointestinal_resps.push(resp_gastro);
                cnt_gastro++;
              }
              if (!nerv) {
                this.firebase_caso_rdb.nervioso_resps.push(resp_nerv);
                cnt_nerv++;
              }
              if (!osteo) {
                this.firebase_caso_rdb.osteomuscular_resps.push(resp_osteo);
                cnt_osteo++;
              }
              if (!respir) {
                this.firebase_caso_rdb.respiratorio_resps.push(resp_respir);
                cnt_respir++;
              }

              cnt++;
            }

            this.firebase_caso_rdb.cardiovascular_pregs = cnt_cardio;
            this.firebase_caso_rdb.endocrino_pregs = cnt_endo;
            this.firebase_caso_rdb.gastrointestinal_pregs = cnt_gastro;
            this.firebase_caso_rdb.nervioso_pregs = cnt_nerv;
            this.firebase_caso_rdb.osteomuscular_pregs = cnt_osteo;
            this.firebase_caso_rdb.respiratorio_pregs = cnt_respir;

            var json_sistemas_pts: JSON = json_pts["sistemas"];

            this.firebase_caso_rdb.cardiovascular_tipo = json_sistemas_pts["Cardiovascular"];
            this.firebase_caso_rdb.endocrino_tipo = json_sistemas_pts["Endocrino"];
            this.firebase_caso_rdb.gastrointestinal_tipo = json_sistemas_pts["Gastrointestinal"];
            this.firebase_caso_rdb.genitourinario_tipo = json_sistemas_pts["Genitourinario"];
            this.firebase_caso_rdb.nervioso_tipo = json_sistemas_pts["Nervioso"];
            this.firebase_caso_rdb.osteomuscular_tipo = json_sistemas_pts["Osteomuscular"];
            this.firebase_caso_rdb.respiratorio_tipo = json_sistemas_pts["Respiratorio"];

            //Tratamiento-------------------------------------------------------------------------------------------
            var json_tratamientos: JSON = json_pts["tratamiento"];
            for(let key_trat in json_tratamientos) {
              if (json_tratamientos.hasOwnProperty(key_trat)) {
                this.firebase_caso_rdb.tratamientos.push(key_trat);
                this.firebase_caso_rdb.tratamientos_pts.push(json_tratamientos[key_trat]);
              }
            }
            this.firebase_caso_rdb.clasificarTratamientos();

            break;
          }
        }

        this.router.navigate(["/panel/detalle-caso"]);
      }
    ).catch(
      error => {
        this.firebase_caso_rdb.clearSeleccionado(); //En caso de error se borran los datos que se hayan alcanzado a recuperar
        console.error("Error recuperando casos de " + this.especialidades[index] + ": " + error);
      }
    );
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
        return false;
    }
    return true;
  };

}
