import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RdbCasoResueltoService } from 'app/services/firebase/rdb-caso-resuelto.service';
import { RdbStatsService } from 'app/services/firebase/rdb-stats.service';
import { RdbUsersService } from 'app/services/firebase/rdb-users.service';
import { RdbService } from 'app/services/firebase/rdb.service';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  //Indicadores
  casos_completados: number = 0;
  estudiantes_activos: number = 0;
  especialidades: number = 0;
  casos_creados: number = 0;

  //Tipo de caso
  casos_estudio: number = 0;
  casos_evaluacion: number = 0;
  @ViewChild('graficoTipoCasos', { static: false }) graficoTipoCasos: ElementRef;
  dataGraficoTipoCasos;

  constructor(
    private firebase_casos: RdbService,
    private firebase_casos_resueltos: RdbCasoResueltoService,
    private firebase_users: RdbUsersService,
    private firebase_stats: RdbStatsService
  ) { 
    // let TIME_IN_MS = 1500;
    // setTimeout( () => {

    // }, TIME_IN_MS);
  }

  //Dificultad
  casos_facil: number = 0;
  casos_intermedio: number = 0;
  casos_avanzado: number = 0;
  @ViewChild('graficoDificultad', { static: false }) graficoDificultad: ElementRef;
  dataGraficoDificultad;

  //Especialidad
  especialidades_lbl: string[] = [];
  especialidades_casos: number[] = [];
  especialidades_lbl_final: string[] = [];
  especialidades_casos_final: number[] = [];
  @ViewChild('graficoEspecialidad', { static: false }) graficoEspecialidad: ElementRef;
  dataGraficoEspecialidad;

  //Casos por día
  fechas: string[] = [];
  cnt_casos_en_dia: number[] = [];
  @ViewChild('graficoCasosPorDia', { static: false }) graficoCasosPorDia: ElementRef;
  dataGraficoCasosPorDia

  ngOnInit() {
    //Recuperar indicadores
    this.firebase_users.getEstudiantes().toPromise().then(
      data_estudiantes => {
        for(let correo in data_estudiantes) {
          if (data_estudiantes.hasOwnProperty(correo)) {
            this.firebase_casos_resueltos.getCasosResueltos(correo).toPromise().then(
              data_casos_estudiantes => {
                for(let caso_resuelto in data_casos_estudiantes) {
                  if (data_casos_estudiantes.hasOwnProperty(caso_resuelto)) {
                    //Cantidad de casos completados por todxs lxs estudiantes
                    this.casos_completados++;
                  }
                }
              }
            ).catch(
              error_casos_estudiantes => {
                console.log("Error recuperando los casos del estudiante " + correo.split(",").join("."));
                console.log(error_casos_estudiantes);
              }
            );

            this.estudiantes_activos++;
          }
        }        
      }
    ).catch(
      error_estudiantes => {
        console.log("Error recuperando los estudiantes activos");
        console.log(error_estudiantes);
      }
    );

    //Recuperar estadísticas de casos por día
    this.firebase_stats.getCasosRealizadosPorDia().toPromise().then(
      data => {
        this.fechas = data["fechas"];
        this.cnt_casos_en_dia = data["casos"];

        //Instanciar datos y pasarlos a los gráficos
        //Datos Casos por día
        this.dataGraficoCasosPorDia = {
          labels: this.fechas,
          datasets: [{
            label: 'Casos realizados',
            data: this.cnt_casos_en_dia,
            fill: false,
            borderColor: 'rgb(0, 188, 212)',
            tension: 0.1
          }]
        }

        this.generarGraficosCasosRealizados();
      }
    ).catch(
      error => {
        console.log("Error recuperando las estadísticas de casos por día");
        console.log(error);
      }
    );

    var cnt_casos_especialidad: number;
    this.firebase_casos.getAllCasos().toPromise().then(
      data_casos => {
        for(let especialidad in data_casos) {
          if (data_casos.hasOwnProperty(especialidad)) {
            //Número de especialidades con casos
            this.especialidades++;

            this.especialidades_lbl.push(especialidad);

            cnt_casos_especialidad = 0;

            for(let caso in data_casos[especialidad]) {
              if (data_casos[especialidad].hasOwnProperty(caso)) {
                //Casos creados
                this.casos_creados++;

                //Distribución de casos entre Estudio y Evaluación
                if (data_casos[especialidad][caso]["modo"] == "Evaluación") {
                  this.casos_evaluacion++;
                }
                else if (data_casos[especialidad][caso]["modo"] == "Estudio") {
                  this.casos_estudio++;
                }

                //Distribución de casos entre Fácil, Intermedio, y Avanzado
                if (data_casos[especialidad][caso]["dificultad"] == "Fácil") {
                  this.casos_facil++;
                }
                else if (data_casos[especialidad][caso]["dificultad"] == "Intermedio") {
                  this.casos_intermedio++;
                }
                else if (data_casos[especialidad][caso]["dificultad"] == "Avanzado") {
                  this.casos_avanzado++;
                }

                //Conteo de casos para esta especialidad
                cnt_casos_especialidad++;
              }
            }

            this.especialidades_casos.push(cnt_casos_especialidad);
          }
        }

        //Instanciar datos y pasarlos a los gráficos
        //Datos Tipo Casos
        this.dataGraficoTipoCasos = {
          labels: [
            'Estudio',
            'Evaluación'
          ],
          datasets: [
            {
              label: 'Tipo de casos',
              data: [this.casos_estudio, this.casos_evaluacion],
              backgroundColor: [
                'rgb(0, 188, 212)',
                'rgb(47, 72, 88)'
              ],
              hoverOffset: 5
            }
          ]
        }

        //Datos Dificultad
        this.dataGraficoDificultad = {
          labels: [
            "Fácil",
            "Intermedio",
            "Avanzado"
          ],
          datasets: [
            {
              label: 'Dificultad',
              data: [this.casos_facil, this.casos_intermedio, this.casos_avanzado],
              backgroundColor: [
                'rgb(0, 188, 212)',
                'rgb(51, 101, 138)',
                'rgb(47, 72, 88)'
              ],
              hoverOffset: 5
            }
          ]
        }

        //Datos Especialidades
        var lbl_max: string = "";
        var cnt_max: number = 0;
        var index_max: number = -1;

        for(var i = 0; i < 5; i++) { //Solo necesito las 5 especialidades con mas casos
          lbl_max = "";
          cnt_max = 0;
          index_max = -1;

          for(var j = 0; j < this.especialidades_lbl.length; j++) {
            if (this.especialidades_casos[j] > cnt_max) {
              lbl_max = this.especialidades_lbl[j];
              cnt_max = this.especialidades_casos[j];
              index_max = j;
            }
          }

          if (index_max != -1) {
            this.especialidades_lbl_final.push(lbl_max);
            this.especialidades_casos_final.push(cnt_max);

            this.especialidades_casos[index_max] = 0; //Se descarta este como candito ya que acaba de ser agregado
          }
        }

        this.dataGraficoEspecialidad = {
          labels: this.especialidades_lbl_final,
          datasets: [
            {
              label: 'Casos disponibles',
              data: this.especialidades_casos_final,
              backgroundColor: [
                'rgb(0, 188, 212, 0.2)',
                'rgb(51, 101, 138, 0.2)',
                'rgb(47, 72, 88, 0.2)',
                'rgb(197, 230, 166, 0.2)',
                'rgb(189, 210, 166, 0.2)'
              ],
              borderColor: [
                'rgb(0, 188, 212)',
                'rgb(51, 101, 138)',
                'rgb(47, 72, 88)',
                'rgb(197, 230, 166)',
                'rgb(189, 210, 166)'
              ],
              borderWidth: 1
            }
          ]
        }

        this.generarGraficosCasosDisponibles();
      }
    ).catch(
      error_casos => {
        console.log("Error recuperando todos los casos del sistema");
        console.log(error_casos);
      }
    );
  }

  //Instancias de los gráficos
  chartTipoCasos;
  chartDificultad;
  chartEspecialidad;
  chartCasosPorDia;

  /**
   * Se generan los gráficos cuyos datos dependen de la cantidad de casos disponibles en el sistema
   */
  generarGraficosCasosDisponibles() {
    //Tipo de casos (piechart)
    var ctxTipoCasos = this.graficoTipoCasos.nativeElement.getContext('2d');
    
    if (this.chartTipoCasos) {
      this.chartTipoCasos.destroy();
    }

    this.chartTipoCasos = new Chart(ctxTipoCasos, {
      type: 'pie',
      data: this.dataGraficoTipoCasos,
      options: {
        responsive: true
      }
    });

    //Dificultad (piechart)
    var ctxDificultad = this.graficoDificultad.nativeElement.getContext('2d');

    if (this.chartDificultad) {
      this.chartDificultad.destroy();
    }

    this.chartDificultad = new Chart(ctxDificultad, {
      type: 'pie',
      data: this.dataGraficoDificultad,
      options: {
        responsive: true
      }
    });

    //Especialidad (barchart)
    var ctxEspecialidad = this.graficoEspecialidad.nativeElement.getContext('2d');

    if (this.chartEspecialidad) {
      this.chartEspecialidad.destroy();
    }

    this.chartEspecialidad = new Chart(ctxEspecialidad, {
      type: 'bar',
      data: this.dataGraficoEspecialidad,
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      },
    });
  }

  /**
   * Se generan los gráficos cuyos datos dependen de los casos que los estudiantes hayan completados
   */
  generarGraficosCasosRealizados() {
    var ctxCasosPorDia = this.graficoCasosPorDia.nativeElement.getContext('2d');

    if (this.chartCasosPorDia) {
      this.chartCasosPorDia.destroy();
    }

    this.chartCasosPorDia = new Chart(ctxCasosPorDia, {
      type: 'line',
      data: this.dataGraficoCasosPorDia
    });
  }
}
