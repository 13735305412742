import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RdbEditarCasoService } from 'app/services/firebase/rdb-editar-caso.service';

declare const $: any;

@Component({
  selector: 'app-detalle-sistema',
  templateUrl: './detalle-sistema.component.html',
  styleUrls: ['./detalle-sistema.component.css']
})
export class DetalleSistemaComponent implements OnInit {

  constructor(private router: Router, private firebase_rdb: RdbEditarCasoService, private http: HttpClient) { }

  pregs: string[] = [];
  sistema_actual: string = "";

  urlPregsSistemas: string = "https://medicina-uniandes-default-rtdb.firebaseio.com/preguntas/sistemas/";

  ngOnInit(): void {
    this.pregs = [];
    if (this.firebase_rdb.sistema_actual == "") {
      this.router.navigate(["/panel/sistemas"]);
    }
    else {
      this.sistema_actual = this.firebase_rdb.sistema_actual;
      this.firebase_rdb.getPregsSistema(this.sistema_actual).toPromise().then(
        data => {
          let i = 0;
          for(let key in data) {
            if (data.hasOwnProperty(key)) {
              this.pregs.push(data[`p_${i}`]);
              i++;
            }
          }
        }
      ).catch(
        error => {
          console.log("Error recuperando las preguntas del sistema " + this.firebase_rdb.sistema_actual);
          console.log(error);
        }
      );
    }
  }

  agregarPreg() {
    this.pregs.push("");
  }

  eliminarPreg(index: number) {
    this.pregs[index] = null;

    var pregs_aux: string[] = [];

    this.pregs.forEach(
      (e,i) => {
        if (e != null) {
          pregs_aux.push(e);
        }
      }
    );

    this.pregs = pregs_aux;
  }

  guardarCambios() {
    var url = this.urlPregsSistemas + this.sistema_actual + "/.json";

    var put_pregs = {};

    var pregs_aux: string[] = [];

    this.pregs.forEach(
      (e,i) => {
        if (e != null && e.trim() != "") {
          pregs_aux.push(e.trim());
        }
      }
    );

    this.pregs = pregs_aux;

    this.pregs.forEach(
      (e,i) => {
        put_pregs["p_" + i] = e;
      }
    );

    this.http.put(url, put_pregs).toPromise().then(
      resp => {
        this.router.navigate(["/panel/sistemas"]);
      }
    ).catch(
      error => {
        console.log("Error subiendo cambios del sistema " + this.sistema_actual);
        console.log(error);
      }
    );
  }

  trackByFn(index, item) {
    return index;
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
        return false;
    }
    return true;
  };

}
