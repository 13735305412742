<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 text-left">
        <h3><strong>Enfermedad actual</strong></h3>
      </div>
      <div *ngIf="isMobileMenu();then content_mobile else content_web"></div>
      <ng-template #content_web>
        <div class="col-md-6 text-right">
          <a routerLink="/panel/crear-caso">
            <button class="btn btn-success" (click)="guardarCambios()">Guardar cambios</button>
          </a>
        </div>
      </ng-template>
      <ng-template #content_mobile>
        <div class="col-md-6 text-left">
          <a routerLink="/panel/crear-caso">
            <button class="btn btn-success" (click)="guardarCambios()">Guardar cambios</button>
          </a>
        </div>
      </ng-template>
    </div>
    <div class="row">
      <div class="col-md-12 text-left">
        <p><a (click)="guardarCambios()" routerLink="/panel/casos">Casos</a>/<a (click)="guardarCambios()" routerLink="/panel/crear-caso">Crear caso</a>/<strong>Enfermedad actual</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h5>1. ¿Tiene dolor?</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[0]" class="form-control" placeholder="Escribe aquí la respuesta del paciente" rows="5"></textarea> 
            </div>
          </div>
        </div>

        <hr />

        <h5>2. ¿Dónde le duele?</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[1]" class="form-control" placeholder="Escribe aquí la respuesta del paciente" rows="5"></textarea> 
            </div>
          </div>
        </div>

        <hr />

        <h5>3. ¿Desde hace cuánto le duele?</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[2]" class="form-control" placeholder="Escribe aquí la respuesta del paciente" rows="5"></textarea> 
            </div>
          </div>
        </div>

        <hr />

        <h5>4. ¿Cómo es el dolor?</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[3]" class="form-control" placeholder="Escribe aquí la respuesta del paciente" rows="5"></textarea> 
            </div>
          </div>
        </div>

        <hr />

        <h5>5. ¿El dolor se le va para alguna parte?</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[4]" class="form-control" placeholder="Escribe aquí la respuesta del paciente" rows="5"></textarea> 
            </div>
          </div>
        </div>

        <hr />

        <h5>6. ¿Qué cosas le exacerban el dolor?</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[5]" class="form-control" placeholder="Escribe aquí la respuesta del paciente" rows="5"></textarea> 
            </div>
          </div>
        </div>

        <hr />

        <h5>7. ¿Qué cosas le mejoran el dolor?</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[6]" class="form-control" placeholder="Escribe aquí la respuesta del paciente" rows="5"></textarea> 
            </div>
          </div>
        </div>

        <hr />

        <h5>8. De 0 a 10 donde 0 es 'nada' y 10 es 'el dolor más intenso que he sentido', ¿cómo calificaría su dolor?</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[7]" class="form-control" placeholder="Escribe aquí la respuesta del paciente" rows="5"></textarea> 
            </div>
          </div>
        </div>

        <hr />

        <h5>9. ¿Había tenido antes este dolor?</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[8]" class="form-control" placeholder="Escribe aquí la respuesta del paciente" rows="5"></textarea> 
            </div>
          </div>
        </div>

        <hr />

        <h5>10. ¿Ha tenido fiebre?</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[9]" class="form-control" placeholder="Escribe aquí la respuesta del paciente" rows="5"></textarea> 
            </div>
          </div>
        </div>

        <hr />

        <h5>11. ¿Ha tenido náuseas o vómito?</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[10]" class="form-control" placeholder="Escribe aquí la respuesta del paciente" rows="5"></textarea> 
            </div>
          </div>
        </div>

        <hr />

        <h5>12. ¿Se ha sentido fatigado?</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[11]" class="form-control" placeholder="Escribe aquí la respuesta del paciente" rows="5"></textarea> 
            </div>
          </div>
        </div>

        <hr />

        <h5>13. ¿Se ha hinchado en los últimos días?</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[12]" class="form-control" placeholder="Escribe aquí la respuesta del paciente" rows="5"></textarea> 
            </div>
          </div>
        </div>

        <hr />

        <h5>14. ¿Alguna otra información?</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[13]" class="form-control" placeholder="Escribe aquí la respuesta del paciente" rows="5"></textarea> 
            </div>
          </div>
        </div>

        <hr />

      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-right">
        <a routerLink="/panel/crear-caso">
          <button class="btn btn-success" (click)="guardarCambios()">Guardar cambios</button>
        </a>
      </div>
    </div>
  </div>
</div>