<div class="main-content">
  <div class="container-fluid">
      <div class="row">
        <div class="col-md-2 text-left">
          <h3><strong>Sistemas</strong></h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-content bg-white">
              <div class="table-responsive p-3 mx-auto">
                <table class="table table-striped table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th class="text-center" scope="col">
                        <p>
                          <strong>Nombre del sistema</strong>
                        </p>
                      </th>
                      <th class="text-center" scope="col">
                        <p>
                          <strong>Número de preguntas</strong>
                        </p>
                      </th>
                      <th class="text-center" scope="col">
                        <p>
                          <strong>Acciones</strong>
                        </p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr scope="row">
                      <td>
                        Sistema cardiovascular
                      </td>
                      <td class="text-center">
                        {{cnt_pregs[0]}}
                      </td>
                      <td class="text-center">
                        <i class="material-icons" (click)="verDetalleSistema('cardiovascular')">search</i>
                      </td>
                    </tr>
                    <tr scope="row">
                      <td>
                        Sistema respiratorio
                      </td>
                      <td class="text-center">
                        {{cnt_pregs[1]}}
                      </td>
                      <td class="text-center">
                        <i class="material-icons" (click)="verDetalleSistema('respiratorio')">search</i>
                      </td>
                    </tr>
                    <tr scope="row">
                      <td>
                        Sistema genitourinario
                      </td>
                      <td class="text-center">
                        {{cnt_pregs[2]}}
                      </td>
                      <td class="text-center">
                        <i class="material-icons" (click)="verDetalleSistema('genitourinario')">search</i>
                      </td>
                    </tr>
                    <tr scope="row">
                      <td>
                        Sistema endocrino
                      </td>
                      <td class="text-center">
                        {{cnt_pregs[3]}}
                      </td>
                      <td class="text-center">
                        <i class="material-icons" (click)="verDetalleSistema('endocrino')">search</i>
                      </td>
                    </tr>
                    <tr scope="row">
                      <td>
                        Sistema gastrointestinal
                      </td>
                      <td class="text-center">
                        {{cnt_pregs[4]}}
                      </td>
                      <td class="text-center">
                        <i class="material-icons" (click)="verDetalleSistema('gastrointestinal')">search</i>
                      </td>
                    </tr>
                    <tr scope="row">
                      <td>
                        Sistema osteomuscular
                      </td>
                      <td class="text-center">
                        {{cnt_pregs[5]}}
                      </td>
                      <td class="text-center">
                        <i class="material-icons" (click)="verDetalleSistema('osteomuscular')">search</i>
                      </td>
                    </tr>
                    <tr scope="row">
                      <td>
                        Sistema nervioso
                      </td>
                      <td class="text-center">
                        {{cnt_pregs[6]}}
                      </td>
                      <td class="text-center">
                        <i class="material-icons" (click)="verDetalleSistema('nervioso')">search</i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>