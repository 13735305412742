<div class="main-content">
  <div class="container-fluid">
      <div class="row">
        <div class="col-md-2 text-left">
          <h3><strong>Laboratorios</strong></h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-content bg-white">
              <div class="table-responsive p-3 mx-auto">
                <table class="table table-striped table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th class="text-center" scope="col">
                        <p>
                          <strong>Nombre del tipo</strong>
                        </p>
                      </th>
                      <th class="text-center" scope="col">
                        <p>
                          <strong>Número de laboratorios</strong>
                        </p>
                      </th>
                      <th class="text-center" scope="col">
                        <p>
                          <strong>Acciones</strong>
                        </p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr scope="row">
                      <td>
                        Inmunología
                      </td>
                      <td class="text-center">
                        {{cnt_labs[0]}}
                      </td>
                      <td class="text-center">
                        <i class="material-icons" (click)="verDetalleLab('Inmunología')">search</i>
                      </td>
                    </tr>
                    <tr scope="row">
                      <td>
                        Inmunología rutinaria
                      </td>
                      <td class="text-center">
                        {{cnt_labs[1]}}
                      </td>
                      <td class="text-center">
                        <i class="material-icons" (click)="verDetalleLab('Inmunología rutinaria')">search</i>
                      </td>
                    </tr>
                    <tr scope="row">
                      <td>
                        Laboratorios clínicos
                      </td>
                      <td class="text-center">
                        {{cnt_labs[2]}}
                      </td>
                      <td class="text-center">
                        <i class="material-icons" (click)="verDetalleLab('Laboratorios clínicos')">search</i>
                      </td>
                    </tr>
                    <tr scope="row">
                      <td>
                        Laboratorios hematológicos
                      </td>
                      <td class="text-center">
                        {{cnt_labs[3]}}
                      </td>
                      <td class="text-center">
                        <i class="material-icons" (click)="verDetalleLab('Laboratorios hematológicos')">search</i>
                      </td>
                    </tr>
                    <tr scope="row">
                      <td>
                        Marcadores tumorales
                      </td>
                      <td class="text-center">
                        {{cnt_labs[4]}}
                      </td>
                      <td class="text-center">
                        <i class="material-icons" (click)="verDetalleLab('Marcadores tumorales')">search</i>
                      </td>
                    </tr>
                    <tr scope="row">
                      <td>
                        Mediciones farmacológicas
                      </td>
                      <td class="text-center">
                        {{cnt_labs[5]}}
                      </td>
                      <td class="text-center">
                        <i class="material-icons" (click)="verDetalleLab('Mediciones farmacológicas')">search</i>
                      </td>
                    </tr>
                    <tr scope="row">
                      <td>
                        Minerales y vitaminas
                      </td>
                      <td class="text-center">
                        {{cnt_labs[6]}}
                      </td>
                      <td class="text-center">
                        <i class="material-icons" (click)="verDetalleLab('Minerales y vitaminas')">search</i>
                      </td>
                    </tr>
                    <tr scope="row">
                      <td>
                        Niveles de anticuerpos bacterianos
                      </td>
                      <td class="text-center">
                        {{cnt_labs[7]}}
                      </td>
                      <td class="text-center">
                        <i class="material-icons" (click)="verDetalleLab('Niveles de anticuerpos bacterianos')">search</i>
                      </td>
                    </tr>
                    <tr scope="row">
                      <td>
                        Pruebas de coprología
                      </td>
                      <td class="text-center">
                        {{cnt_labs[8]}}
                      </td>
                      <td class="text-center">
                        <i class="material-icons" (click)="verDetalleLab('Pruebas de coprología')">search</i>
                      </td>
                    </tr>
                    <tr scope="row">
                      <td>
                        Pruebas de enzimas
                      </td>
                      <td class="text-center">
                        {{cnt_labs[9]}}
                      </td>
                      <td class="text-center">
                        <i class="material-icons" (click)="verDetalleLab('Pruebas de enzimas')">search</i>
                      </td>
                    </tr>
                    <tr scope="row">
                      <td>
                        Pruebas de hematología rutinarias
                      </td>
                      <td class="text-center">
                        {{cnt_labs[10]}}
                      </td>
                      <td class="text-center">
                        <i class="material-icons" (click)="verDetalleLab('Pruebas de hematología rutinarias')">search</i>
                      </td>
                    </tr>
                    <tr scope="row">
                      <td>
                        Pruebas de orina
                      </td>
                      <td class="text-center">
                        {{cnt_labs[11]}}
                      </td>
                      <td class="text-center">
                        <i class="material-icons" (click)="verDetalleLab('Pruebas de orina')">search</i>
                      </td>
                    </tr>
                    <tr scope="row">
                      <td>
                        Pruebas inmunológicas para microorganismos
                      </td>
                      <td class="text-center">
                        {{cnt_labs[12]}}
                      </td>
                      <td class="text-center">
                        <i class="material-icons" (click)="verDetalleLab('Pruebas inmunológicas para microorganismos')">search</i>
                      </td>
                    </tr>
                    <tr scope="row">
                      <td>
                        Test endocrinología
                      </td>
                      <td class="text-center">
                        {{cnt_labs[13]}}
                      </td>
                      <td class="text-center">
                        <i class="material-icons" (click)="verDetalleLab('Test endocrinología')">search</i>
                      </td>
                    </tr>
                    <tr scope="row">
                      <td>
                        Toxicología
                      </td>
                      <td class="text-center">
                        {{cnt_labs[14]}}
                      </td>
                      <td class="text-center">
                        <i class="material-icons" (click)="verDetalleLab('Toxicología')">search</i>
                      </td>
                    </tr>
                    <tr scope="row">
                      <td>
                        Otras ayudas
                      </td>
                      <td class="text-center">
                        {{cnt_labs[15]}}
                      </td>
                      <td class="text-center">
                        <i class="material-icons" (click)="verDetalleLab('Otras ayudas')">search</i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>