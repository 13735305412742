<div class="container-fluid">
  <div class="row">
    <div class="col-md-3">

    </div>
    <div class="col-md-6 col-sm-12 text-center">
        <img class="logo" src="../../assets/img/uniandes-medicina.png" alt="Universidad de Los Andes - Facultad de Medicina"/>
    </div>
    <div class="col-md-3">

    </div>
  </div>
  <br />
  <br />
  <br />
  <div *ngIf="!isLoggedIn()" class="row">
      <div class="col-md-12 text-center">
          <h4>¡Bienvenidx al panel administrador del hospital simulado!</h4>
          <br />
          <div class="row">
            <div class="col-md-4">

            </div>
            <div class="col-md-4 col-sm-12">
              <button class="btn btn-login" (click)="login()">
                  Sign in
              </button>
            </div>
            <div class="col-md-4">

            </div>
          </div>
      </div>
  </div>
  <div *ngIf="isLoggedIn()" class="row">
      <div class="col-md-12 text-center">
          <h5><strong>Usuario:</strong> {{username}}</h5>
          <div class="row">
            <div class="col-md-4">

            </div>
            <div class="col-md-4 col-sm-12">
              <button *ngIf="isAdmin" routerLink="/panel/dashboard" class="btn btn-login">
                Panel administrador
              </button>
              <button *ngIf="!isAdmin" routerLink="/panel/dashboard" class="btn btn-login" disabled>
                Panel administrador
              </button>
            </div>
            <div class="col-md-4">

            </div>
          </div>
          <div *ngIf="!isAdmin" class="text-danger">
            <p>
              <strong>Error:</strong> su usuario no tiene permisos suficientes para acceder al Panel Administrador
            </p>
          </div>
          <br />
          <br />
          <hr />
          <p><strong>Atención:</strong> si el usuario de arriba no coincide con tu usuario, por favor cierra sesión e intenta de nuevo.</p>
          <br />
          <div class="row">
            <div class="col-md-4 col-sm-1">

            </div>
            <div class="col-md-4 col-sm-10">
              <button class="btn btn-logout" (click)="logout()">
                  Sign out
              </button>
            </div>
            <div class="col-md-4 col-sm-1">

            </div>
          </div>
      </div>
  </div>
</div>