import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RdbEditarCasoService } from 'app/services/firebase/rdb-editar-caso.service';

declare const $: any;

@Component({
  selector: 'app-detalle-tratamiento',
  templateUrl: './detalle-tratamiento.component.html',
  styleUrls: ['./detalle-tratamiento.component.css']
})
export class DetalleTratamientoComponent implements OnInit {

  constructor(private router: Router, private firebase_rdb: RdbEditarCasoService, private http: HttpClient) { }

  tratamiento_seleccionado: string = "";

  trats: string[] = [];

  urlTrats: string = "https://medicina-uniandes-default-rtdb.firebaseio.com/preguntas/tratamiento/";

  ngOnInit(): void {
    if (this.firebase_rdb.tratamiento_seleccionado == "") {
      this.router.navigate(["/panel/tratamiento"]);
    }
    else {
      this.trats = [];

      this.tratamiento_seleccionado = this.firebase_rdb.tratamiento_seleccionado;

      this.firebase_rdb.getTratamientos(this.tratamiento_seleccionado).toPromise().then(
        data => {
          for (let key in data) {
            if (data.hasOwnProperty(key)) {
              this.trats.push(data[key]);
            }
          }
        }
      ).catch(
        error => {
          console.log("Error recuperando tratamientos de " + this.tratamiento_seleccionado);
          console.log(error);
        }
      );
    }
  }
  
  guardarCambios() {
    var url: string = "";
    url = this.urlTrats + this.tratamiento_seleccionado.toLowerCase().trim().split(" ").join("%20") + "/.json";

    var put_trats = {};

    var trats_aux: string[] = [];

    var e_aux: string = "";
    this.trats.forEach(
      (e,i) => {
        if (e != null && e.trim() != "") {
          //El último split/join hace que los espacios múltiples se vuelvan sólo uno
          //Los demás eliminan los caracteres no permitidos por Firebase
          e_aux = e.trim()
          .split(".").join("")
          .split("$").join("")
          .split("#").join("")
          .split("[").join("")
          .split("]").join("")
          .split("/").join("")
          .split(/  +/g).join(" ");;
          trats_aux.push(e);
        }
      }
    );

    this.trats = trats_aux;

    this.trats.forEach(
      (e,i) => {
        put_trats["t_" + i] = e;
      }
    );

    this.http.put(url, put_trats).toPromise().then(
      resp => {
        this.router.navigate(["/panel/tratamiento"]);
      }
    ).catch(
      error => {
        console.log("Error subiendo cambios a " + this.tratamiento_seleccionado);
        console.log(error);
      }
    );
  }

  agregarTrat() {
    this.trats.push("");
  }

  eliminarTrat(index: number) {
    this.trats[index] = null;

    var trats_aux: string[] = [];

    this.trats.forEach(
      (e,i) => {
        if (e != null) {
          trats_aux.push(e);
        }
      }
    );

    this.trats = trats_aux;
  }

  trackByFn(index, item) {
    return index;
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
        return false;
    }
    return true;
  };

}
