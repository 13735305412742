import { Component, OnInit } from '@angular/core';
import { RdbService } from '../../services/firebase/rdb.service';
import { LocalStorageService } from 'app/services/storage/local-storage.service';

declare const $: any;

@Component({
  selector: 'app-crear-caso-sistemas',
  templateUrl: './crear-caso-sistemas.component.html',
  styleUrls: ['./crear-caso-sistemas.component.css']
})
export class CrearCasoSistemasComponent implements OnInit {

  constructor(private firebase_rdb: RdbService, private local_db: LocalStorageService) { }


  sistemaSeleccionado = "Cardiovascular";

  indexActual = -1;
  pregSeleccionada = "";

  pregsActuales: string[] = [];

  respsActuales: string[] = [];

  tipoActual = "";

  ngOnInit() {
    //Cargar respuestas desde localstorage
    this.local_db.getItem<string[]>("endocrino_resps").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.endocrino_resps = resp;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando endocrino_resps");
      }
    );

    this.local_db.getItem<string[]>("gastrointestinal_resps").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.gastrointestinal_resps = resp;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando gastronintestinal_resps");
      }
    );

    this.local_db.getItem<string[]>("genitourinario_resps").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.genitourinario_resps = resp;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando genitourinario_resps");
      }
    );

    this.local_db.getItem<string[]>("nervioso_resps").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.nervioso_resps = resp;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando nervioso_resps");
      }
    );
    
    this.local_db.getItem<string[]>("osteomuscular_resps").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.osteomuscular_resps = resp;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando osteomuscular_resps");
      }
    );
    
    this.local_db.getItem<string[]>("respiratorio_resps").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.respiratorio_resps = resp;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando respiratorio_resps");
      }
    );

    //Cargar número de preguntas desde localstorage
    this.local_db.getItem<number>("endocrino_pregs").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.endocrino_pregs = resp;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando endocrino_pregs");
      }
    );

    this.local_db.getItem<number>("gastrointestinal_pregs").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.gastrointestinal_pregs = resp;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando gastrointestinal_pregs");
      }
    );

    this.local_db.getItem<number>("genitourinario_pregs").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.genitourinario_pregs = resp;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando genitourinario_pregs");
      }
    );

    this.local_db.getItem<number>("nervioso_pregs").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.nervioso_pregs = resp;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando nervioso_pregs");
      }
    );

    this.local_db.getItem<number>("osteomuscular_pregs").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.osteomuscular_pregs = resp;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando osteomuscular_pregs");
      }
    );

    this.local_db.getItem<number>("respiratorio_pregs").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.respiratorio_pregs = resp;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando respiratorio_pregs");
      }
    );

    //Cargar tipo desde localstorage
    this.local_db.getItem<string>("endocrino_tipo").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.endocrino_tipo = resp
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando respiratorio_pregs");
      }
    );

    this.local_db.getItem<string>("gastrointestinal_tipo").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.gastrointestinal_tipo = resp;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando gastrointestinal_tipo");
      }
    );
    
    this.local_db.getItem<string>("genitourinario_tipo").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.genitourinario_tipo = resp;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando genitourinario_tipo");
      }
    );
    
    this.local_db.getItem<string>("nervioso_tipo").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.nervioso_tipo = resp;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando nervioso_tipo");
      }
    );
    
    this.local_db.getItem<string>("osteomuscular_tipo").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.osteomuscular_tipo = resp;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando osteomuscular_tipo");
      }
    );
    
    this.local_db.getItem<string>("respiratorio_tipo").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.respiratorio_tipo = resp;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando respiratorio_tipo");
      }
    );    

    //Cuando el panel inicia este es el sistema seleccionado, entonces cargarlo es un caso especial
    this.local_db.getItem<string[]>("cardiovascular_resps").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.cardiovascular_resps = resp;
        }
        this.cargarRespuestasSistemaActual();
        
        this.local_db.getItem<number>("cardiovascular_pregs").then(
          resp2 => {
            if (resp2 != null) {
              this.firebase_rdb.cardiovascular_pregs = resp2;
            }

            this.local_db.getItem<string>("cardiovascular_tipo").then(
              resp3 => {
                if (resp3 != null) {
                  this.firebase_rdb.cardiovascular_tipo = resp3;
                }
                
                this.cargarTipoSistemaActual();
                this.seleccionarSistema(this.sistemaSeleccionado);
              }
            ).catch(
              error => {
                console.log("Error recuperando cardiovascular_tipo");
              }
            );
          }
        ).catch(
          error => {
            console.log("Error recuperando cardiovascular_pregs");
          }
        );
      }
    ).catch(
      error => {
        console.log("Error recuperando cardiovascular_resps");
      }
    );
  }

  /**
   * Guarda el estado de las respuestas ingresadas por el usuario para el sistema indicado
   */
  guardarRespuestasSistemaActual() {
    if (this.sistemaSeleccionado == "Cardiovascular") {
      this.firebase_rdb.cardiovascular_resps = this.respsActuales;
      this.local_db.setItem<string[]>("cardiovascular_resps", this.firebase_rdb.cardiovascular_resps);
    }
    else if (this.sistemaSeleccionado == "Endocrino") {
      this.firebase_rdb.endocrino_resps = this.respsActuales;
      this.local_db.setItem<string[]>("endocrino_resps", this.firebase_rdb.endocrino_resps);
    }
    else if (this.sistemaSeleccionado == "Gastrointestinal") {
      this.firebase_rdb.gastrointestinal_resps = this.respsActuales;
      this.local_db.setItem<string[]>("gastrointestinal_resps", this.firebase_rdb.gastrointestinal_resps);
    }
    else if (this.sistemaSeleccionado == "Genitourinario") {
      this.firebase_rdb.genitourinario_resps = this.respsActuales;
      this.local_db.setItem<string[]>("genitourinario_resps", this.firebase_rdb.genitourinario_resps);
    }
    else if (this.sistemaSeleccionado == "Nervioso") {
      this.firebase_rdb.nervioso_resps = this.respsActuales;
      this.local_db.setItem<string[]>("nervioso_resps", this.firebase_rdb.nervioso_resps);
    }
    else if (this.sistemaSeleccionado == "Osteomuscular") {
      this.firebase_rdb.osteomuscular_resps = this.respsActuales;
      this.local_db.setItem<string[]>("osteomuscular_resps", this.firebase_rdb.osteomuscular_resps);
    }
    else if (this.sistemaSeleccionado == "Respiratorio") {
      this.firebase_rdb.respiratorio_resps = this.respsActuales;
      this.local_db.setItem<string[]>("respiratorio_resps", this.firebase_rdb.respiratorio_resps);
    }
    
  }

  /**
   * Recupera las respuestas ya ingresadas por el usuario para el sistema indicado
   */
  cargarRespuestasSistemaActual() {
    if (this.sistemaSeleccionado == "Cardiovascular")
      this.respsActuales = this.firebase_rdb.cardiovascular_resps;
    else if (this.sistemaSeleccionado == "Endocrino")
      this.respsActuales = this.firebase_rdb.endocrino_resps;
    else if (this.sistemaSeleccionado == "Gastrointestinal")
      this.respsActuales = this.firebase_rdb.gastrointestinal_resps;
    else if (this.sistemaSeleccionado == "Genitourinario")
      this.respsActuales = this.firebase_rdb.genitourinario_resps;
    else if (this.sistemaSeleccionado == "Nervioso")
      this.respsActuales = this.firebase_rdb.nervioso_resps;
    else if (this.sistemaSeleccionado == "Osteomuscular")
      this.respsActuales = this.firebase_rdb.osteomuscular_resps;
    else if (this.sistemaSeleccionado == "Respiratorio")
      this.respsActuales = this.firebase_rdb.respiratorio_resps;
  }

  /**
   * Guarda el tipo de preguntas para el sistema indicado
   */
  guardarTipoSistemaActual() {
    var tipoAux = "";

    //tipoActual es cómo se ve en la interfaz, pero tipoAux es como se debe guardar en la DB
    if (this.tipoActual == "Preguntas correctas")
      tipoAux = "positivo";
    else if (this.tipoActual == "Preguntas incorrectas")
      tipoAux = "negativo";
    else
      tipoAux = "omitible";

    if (this.sistemaSeleccionado == "Cardiovascular") {
      this.firebase_rdb.cardiovascular_tipo = tipoAux;
      this.local_db.setItem<string>("cardiovascular_tipo", this.firebase_rdb.cardiovascular_tipo);
    }
    else if (this.sistemaSeleccionado == "Endocrino") {
      this.firebase_rdb.endocrino_tipo = tipoAux;
      this.local_db.setItem<string>("endocrino_tipo", this.firebase_rdb.endocrino_tipo);
    }
    else if (this.sistemaSeleccionado == "Gastrointestinal") {
      this.firebase_rdb.gastrointestinal_tipo = tipoAux;
      this.local_db.setItem<string>("gastrointestinal_tipo", this.firebase_rdb.gastrointestinal_tipo);
    }
    else if (this.sistemaSeleccionado == "Genitourinario") {
      this.firebase_rdb.genitourinario_tipo = tipoAux;
      this.local_db.setItem<string>("genitourinario_tipo", this.firebase_rdb.genitourinario_tipo);
    }
    else if (this.sistemaSeleccionado == "Nervioso") {
      this.firebase_rdb.nervioso_tipo = tipoAux;
      this.local_db.setItem<string>("nervioso_tipo", this.firebase_rdb.nervioso_tipo);
    }
    else if (this.sistemaSeleccionado == "Osteomuscular") {
      this.firebase_rdb.osteomuscular_tipo = tipoAux;
      this.local_db.setItem<string>("osteomuscular_tipo", this.firebase_rdb.osteomuscular_tipo);
    }
    else if (this.sistemaSeleccionado == "Respiratorio") {
      this.firebase_rdb.respiratorio_tipo = tipoAux;
      this.local_db.setItem<string>("respiratorio_tipo", this.firebase_rdb.respiratorio_tipo);
    }
    
  }

  /**
   * Recupera el tipo de preguntas antes indicado por el usuario para el sistema seleccionado 
   */
  cargarTipoSistemaActual() {
    var tipoAux = "";

    if (this.sistemaSeleccionado == "Cardiovascular")
      tipoAux = this.firebase_rdb.cardiovascular_tipo;
    else if (this.sistemaSeleccionado == "Endocrino")
      tipoAux = this.firebase_rdb.endocrino_tipo;
    else if (this.sistemaSeleccionado == "Gastrointestinal")
      tipoAux = this.firebase_rdb.gastrointestinal_tipo;
    else if (this.sistemaSeleccionado == "Genitourinario")
      tipoAux = this.firebase_rdb.genitourinario_tipo;
    else if (this.sistemaSeleccionado == "Nervioso")
      tipoAux = this.firebase_rdb.nervioso_tipo;
    else if (this.sistemaSeleccionado == "Osteomuscular")
      tipoAux = this.firebase_rdb.osteomuscular_tipo;
    else if (this.sistemaSeleccionado == "Respiratorio")
      tipoAux = this.firebase_rdb.respiratorio_tipo;

    if (tipoAux == "positivo")
      this.tipoActual = "Preguntas correctas";
    else if (tipoAux == "negativo")
      this.tipoActual = "Preguntas incorrectas";
    else if (tipoAux == "omitible")
      this.tipoActual = "Preguntas omitibles";
    else
      this.tipoActual = "";

  }

  /**
   * Guarda el estado del panel y transiciona hacia el nuevo sistema seleccionado
   * @param sistema El nuevo sistema a seleccionar
   */
  seleccionarSistema(sistema: string) {
    this.guardarRespuestasSistemaActual();

    this.sistemaSeleccionado = sistema;

    this.respsActuales = [];
    this.pregsActuales = [];
    this.indexActual = -1;
    this.pregSeleccionada = "";

    this.tipoActual = "";

    this.firebase_rdb.getPregsSistema(this.sistemaSeleccionado).toPromise().then(
      data => {
        let i = 0;
        for (let key in data) {
          if (data.hasOwnProperty(key)) {
            this.pregsActuales.push(data[`p_${i}`]);
            i++;
          }
        }

        this.contarPregsSistema();
      }
    );

    this.cargarRespuestasSistemaActual();
    this.cargarTipoSistemaActual();
  }

  /**
   * Guarda el número de preguntas encontradas para el sistema seleccionado
   * Se hace con el fin de verificar la completitud de este panel
   */
  contarPregsSistema() {
    var pregsCnt = this.pregsActuales.length;

    if (this.sistemaSeleccionado == "Cardiovascular") {
      this.firebase_rdb.cardiovascular_pregs = pregsCnt;
      this.local_db.setItem<number>("cardiovascular_pregs", this.firebase_rdb.cardiovascular_pregs);
    }
    else if (this.sistemaSeleccionado == "Endocrino") {
      this.firebase_rdb.endocrino_pregs = pregsCnt;
      this.local_db.setItem<number>("endocrino_pregs", this.firebase_rdb.endocrino_pregs);
    }
    else if (this.sistemaSeleccionado == "Gastrointestinal") {
      this.firebase_rdb.gastrointestinal_pregs = pregsCnt;
      this.local_db.setItem<number>("gastrointestinal_pregs", this.firebase_rdb.gastrointestinal_pregs);
    }
    else if (this.sistemaSeleccionado == "Genitourinario") {
      this.firebase_rdb.genitourinario_pregs = pregsCnt;
      this.local_db.setItem<number>("genitourinario_pregs", this.firebase_rdb.genitourinario_pregs);
    }
    else if (this.sistemaSeleccionado == "Nervioso") {
      this.firebase_rdb.nervioso_pregs = pregsCnt;
      this.local_db.setItem<number>("nervioso_pregs", this.firebase_rdb.nervioso_pregs);
    }
    else if (this.sistemaSeleccionado == "Osteomuscular") {
      this.firebase_rdb.osteomuscular_pregs = pregsCnt;
      this.local_db.setItem<number>("osteomuscular_pregs", this.firebase_rdb.osteomuscular_pregs);
    }
    else if (this.sistemaSeleccionado == "Respiratorio") {
      this.firebase_rdb.respiratorio_pregs = pregsCnt;
      this.local_db.setItem<number>("respiratorio_pregs", this.firebase_rdb.respiratorio_pregs);
    }    
  }

  /**
   * Actualiza las variables de control del UI
   * @param preg La pregunta que fue seleccionada por el usuario
   * @param index La posición en el arreglo de preguntas de la pregunta seleccionada
   */
  seleccionarPregunta(preg: string, index: number) {
    this.indexActual = index;
    this.pregSeleccionada = preg;
  }

  /**
   * Actualiza el tipo de pregunta para el sistema seleccionado
   * @param tipo El nuevo tipo de pregunta (correcta, incorrecta, u omitible)
   */
  seleccionarTipo(tipo: number) {
    if(tipo == 1) {
      this.tipoActual = "Preguntas correctas";
    }
    else if (tipo == -1) {
      this.tipoActual = "Preguntas incorrectas";
    }
    else {
      this.tipoActual = "Preguntas omitibles";
    }

    this.guardarTipoSistemaActual();
  }
  
  /**
   * Indica si la pantalla se debe ajustar a móvil
   * @returns True si la pantalla corresponde a un dispositivo móvil. False en caso contrario
   */
  isMobileMenu() {
    if ($(window).width() > 991) {
        return false;
    }
    return true;
  }
}
