import { Component, OnInit } from '@angular/core';
import { RdbService } from 'app/services/firebase/rdb.service';
import { LocalStorageService } from 'app/services/storage/local-storage.service';

declare const $: any;

@Component({
  selector: 'app-crear-caso-examen-fisico',
  templateUrl: './crear-caso-examen-fisico.component.html',
  styleUrls: ['./crear-caso-examen-fisico.component.css']
})
export class CrearCasoExamenFisicoComponent implements OnInit {

  constructor(private firebase_rdb: RdbService, private local_db: LocalStorageService) { }

  resps: string[] = [];
  recursosASubir: File[] = [];
  
  emociones: string[] = ["Ninguna", "Ninguna", "Ninguna", "Ninguna", "Ninguna", "Ninguna",
                        "Ninguna", "Ninguna", "Ninguna"];

  dolor_cabeza: boolean = false;
  dolor_torax: boolean = false;
  dolor_abdomen: boolean = false;
  dolor_extremidades: boolean = false;

  alert_archivo: boolean[] = [false, false, false, false, false, false, false, false, false];

  supported_imgs: string[] = ["apng", "avif", "gif", "jpg", "jpeg", "jfif", "pjpeg", "pjp",
  "png", "svg", "webp"];
  supported_audio: string[] = ["mp3", "wav"];

  handleFileInput(file: File, index: number) {
    this.alert_archivo[index] = false;
    if (file != null) {
      var file_split: string[] = file.name.split(".");
      var file_end: string = file_split[file_split.length - 1].trim().toLowerCase();
      if (this.supported_imgs.includes(file_end) || this.supported_audio.includes(file_end)) {
        this.recursosASubir[index] = file;
      }
      else {
        this.alert_archivo[index] = true;
      }
    }
    else {
      this.recursosASubir[index] = null;
    }
  }

  guardarCambios() {
    this.firebase_rdb.examen_fisico_resps = this.resps;
    this.firebase_rdb.examen_fisico_recursos = this.recursosASubir;

    this.firebase_rdb.dolor_cabeza = this.dolor_cabeza;
    this.firebase_rdb.dolor_torax = this.dolor_torax;
    this.firebase_rdb.dolor_abdomen = this.dolor_abdomen;
    this.firebase_rdb.dolor_extremidades = this.dolor_extremidades;
    
    this.local_db.setItem<string[]>("examen_fisico_resps", this.firebase_rdb.examen_fisico_resps);
    this.local_db.setItem<boolean>("dolor_cabeza", this.firebase_rdb.dolor_cabeza);
    this.local_db.setItem<boolean>("dolor_torax", this.firebase_rdb.dolor_torax);
    this.local_db.setItem<boolean>("dolor_abdomen", this.firebase_rdb.dolor_abdomen);
    this.local_db.setItem<boolean>("dolor_extremidades", this.firebase_rdb.dolor_extremidades);
  }

  ngOnInit() {
    this.local_db.getItem<string[]>("examen_fisico_resps").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.examen_fisico_resps = resp;
          this.resps = this.firebase_rdb.examen_fisico_resps;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando examen_fisico_resps");
      }
    );

    this.local_db.getItem<boolean>("dolor_cabeza").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.dolor_cabeza = resp;
          this.dolor_cabeza = this.firebase_rdb.dolor_cabeza;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando dolor_cabeza");
      }
    );
    
    this.local_db.getItem<boolean>("dolor_torax").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.dolor_torax = resp;
          this.dolor_torax = this.firebase_rdb.dolor_torax;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando dolor_torax");
      }
    );
    
    this.local_db.getItem<boolean>("dolor_abdomen").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.dolor_abdomen = resp;
          this.dolor_abdomen = this.firebase_rdb.dolor_abdomen;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando dolor_abdomen");
      }
    );
    
    this.local_db.getItem<boolean>("dolor_extremidades").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.dolor_extremidades = resp;
          this.dolor_extremidades = this.firebase_rdb.dolor_extremidades;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando dolor_extremidades");
      }
    );

    this.recursosASubir = this.firebase_rdb.examen_fisico_recursos;
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
        return false;
    }
    return true;
  };

}
