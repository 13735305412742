import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RdbEditarCasoService } from 'app/services/firebase/rdb-editar-caso.service';

declare const $: any;

@Component({
  selector: 'app-detalle-caso-tratamiento',
  templateUrl: './detalle-caso-tratamiento.component.html',
  styleUrls: ['./detalle-caso-tratamiento.component.css']
})
export class DetalleCasoTratamientoComponent implements OnInit {

  constructor(private firebase_rdb: RdbEditarCasoService, private router: Router) { }

  //Selección actual
  tipo_tratamiento: string = "";
  tratamiento: string = "";
  tratamientos: string[] = [];
  tratamiento_pts: string = "";

  dosis: string = "";
  frecuencia: string = "";

  alert_incompleto = false;
  alert_repetido = false;

  //Tratamientos agregados
  dieta: string[] = [];
  dieta_pts: string[] = [];

  soporte_hidrico: string[] = [];
  soporte_hidrico_pts: string[] = [];

  medicamentos: string[] = [];
  medicamentos_pts: string[] = [];

  oxigeno: string[] = [];
  oxigeno_pts: string[] = [];

  medidas_generales: string[] = [];
  medidas_generales_pts: string[] = [];

  //Entra el tipo de puntaje del tratamiento y devuelve si se debe mostrar en el panel como
  //"Correcto" u "Omitible". Si se ve "Incorrecto", ocurrió un error al resolver el puntaje
  renderCorrectoOmitible(pts: string): string{
    if (pts == "positivo")
      return "Correcto";
    else if (pts == "omitible")
      return "Omitible";
    else
      return "Incorrecto";
  }

  seleccionarTipo(tipo: string) {    
    this.alert_incompleto = false;
    this.alert_repetido = false;

    this.tipo_tratamiento = tipo;
    this.tratamiento = "";
    this.tratamientos = [];
    this.dosis = "";
    this.frecuencia = "";

    this.firebase_rdb.getTratamientos(tipo).toPromise().then(
      data => {
        for (let key in data) {
          if (data.hasOwnProperty(key)) {
            this.tratamientos.push(data[key]);
          }
        }
      }
    );
  }

  agregarTratamiento() {
    this.alert_incompleto = false;
    this.alert_repetido = false;

    if (this.tipo_tratamiento == "Medicamentos") {
      if (this.tratamiento != "" && this.tratamiento_pts != "" && this.dosis != "" && this.frecuencia != "") {
        var med_aux = this.tratamiento + " " + this.dosis + " " + this.frecuencia;
        if (this.medicamentos.indexOf(med_aux) == -1) {
          this.medicamentos.push(med_aux);
          this.medicamentos_pts.push(this.tratamiento_pts);
        }
        else {
          this.alert_repetido = true;
        }
      }
      else {
        this.alert_incompleto = true;
      }
    }
    else if (this.tipo_tratamiento == "") {
      this.alert_incompleto = true;
    }
    else {
      if (this.tratamiento != "" && this.tratamiento_pts != "") {
        if (this.tipo_tratamiento == "Dieta") {
          if (this.dieta.indexOf(this.tratamiento) != -1) {
            this.alert_repetido = true;
          }
          else {
            this.dieta.push(this.tratamiento);
            this.dieta_pts.push(this.tratamiento_pts);
          }
        }
        else if (this.tipo_tratamiento == "Soporte hídrico") {
          if (this.soporte_hidrico.indexOf(this.tratamiento) != -1) {
            this.alert_repetido = true;
          }
          else {
            this.soporte_hidrico.push(this.tratamiento);
            this.soporte_hidrico_pts.push(this.tratamiento_pts);
          }
        }
        else if (this.tipo_tratamiento == "Oxígeno") {
          if (this.oxigeno.indexOf(this.tratamiento) != -1) {
            this.alert_repetido = true;
          }
          else {
            this.oxigeno.push(this.tratamiento);
            this.oxigeno_pts.push(this.tratamiento_pts);
          }
        }
        else if (this.tipo_tratamiento == "Medidas generales") {
          if (this.medidas_generales.indexOf(this.tratamiento) != -1) {
            this.alert_repetido = true;
          }
          else {
            this.medidas_generales.push(this.tratamiento);
            this.medidas_generales_pts.push(this.tratamiento_pts);
          }
        }
      }
      else {
        this.alert_incompleto = true;
      }
    }
  }

  eliminarTratamiento(i: number, tipo: string) {
    if (tipo == "Dieta") {
      this.dieta[i] = null;
      this.dieta_pts[i] = null;  
    }
    else if (tipo == "Soporte hídrico") {
      this.soporte_hidrico[i] = null;
      this.soporte_hidrico_pts[i] = null;  
    }
    else if  (tipo == "Medicamentos") {
      this.medicamentos[i] = null;
      this.medicamentos_pts[i] = null;
    }
    else if (tipo == "Oxígeno") {
      this.oxigeno[i] = null;
      this.oxigeno_pts[i] = null;
    }
    else if (tipo == "Medidas generales") {
      this.medidas_generales[i] = null;
      this.medidas_generales_pts[i] = null;
    }
  }

  cntAgregados(): number {
    var ans = 0;

    this.dieta.forEach(
      e => {
        if (e != null && e != "") {
          ans++;
        }
      }
    );

    this.soporte_hidrico.forEach(
      e => {
        if (e != null && e != "") {
          ans++;
        }
      }
    );

    this.medicamentos.forEach(
      e => {
        if (e != null && e != "") {
          ans++;
        }
      }
    );

    this.oxigeno.forEach(
      e => {
        if (e != null && e != "") {
          ans++;
        }
      }
    );

    this.medidas_generales.forEach(
      e => {
        if (e != null && e != "") {
          ans++;
        }
      }
    );

    return ans;
  }

  ngOnInit() {
    if (this.firebase_rdb.nombre_caso_seleccionado == null ||
      this.firebase_rdb.especialidad_caso_seleccionado == null ||
      this.firebase_rdb.id_caso_seleccionado == null ||
      this.firebase_rdb.json_caso_seleccionado == null) {
      
      this.firebase_rdb.clearSeleccionado();
      this.router.navigate(["/panel/casos"]);
    }

    this.dieta = this.firebase_rdb.dieta;
    this.dieta_pts = this.firebase_rdb.dieta_pts;
    
    this.soporte_hidrico = this.firebase_rdb.soporte_hidrico;
    this.soporte_hidrico_pts = this.firebase_rdb.soporte_hidrico_pts;
    
    this.medicamentos = this.firebase_rdb.medicamentos;
    this.medicamentos_pts = this.firebase_rdb.medicamentos_pts;
    
    this.oxigeno = this.firebase_rdb.oxigeno;
    this.oxigeno_pts = this.firebase_rdb.oxigeno_pts;
    
    this.medidas_generales = this.firebase_rdb.medidas_generales;
    this.medidas_generales_pts = this.firebase_rdb.medidas_generales_pts;
  }

  guardarCambios() {
    this.firebase_rdb.dieta = this.dieta;
    this.firebase_rdb.dieta_pts = this.dieta_pts;

    this.firebase_rdb.soporte_hidrico = this.soporte_hidrico;
    this.firebase_rdb.soporte_hidrico_pts = this.soporte_hidrico_pts;

    this.firebase_rdb.medicamentos = this.medicamentos;
    this.firebase_rdb.medicamentos_pts = this.medicamentos_pts;

    this.firebase_rdb.oxigeno = this.oxigeno;
    this.firebase_rdb.oxigeno_pts = this.oxigeno_pts;

    this.firebase_rdb.medidas_generales = this.medidas_generales;
    this.firebase_rdb.medidas_generales_pts = this.medidas_generales_pts;
  }

}
