import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RdbEditarCasoService } from 'app/services/firebase/rdb-editar-caso.service';

@Component({
  selector: 'app-sistemas',
  templateUrl: './sistemas.component.html',
  styleUrls: ['./sistemas.component.css']
})
export class SistemasComponent implements OnInit {

  constructor(private firebase_rdb: RdbEditarCasoService, private http: HttpClient, private router: Router) { }

  cnt_pregs: number[] = [0, 0, 0, 0, 0, 0, 0];

  sistemas: string[] = ["cardiovascular", "respiratorio", "genitourinario", "endocrino", "gastrointestinal", "osteomuscular", "nervioso"];

  ngOnInit() {
    this.firebase_rdb.sistema_actual = "";
    
    this.sistemas.forEach(
      e => {
        this.cntPregsSistema(e);
      }
    );
  }

  cntPregsSistema(pSistema: string){
    this.firebase_rdb.getPregsSistema(pSistema).toPromise().then(
      data => {
        var ans = 0;

        for(let key in data) {
          if (data.hasOwnProperty(key)) {
            ans++;
          }
        }

        this.cnt_pregs[this.sistemas.indexOf(pSistema)] = ans;
      }
    ).catch(
      error => {
        console.log("Error recuperando sistema " + pSistema);
        console.log(error);
      }
    );
  }

  verDetalleSistema(pSistema: string) {
    this.firebase_rdb.sistema_actual = pSistema;
    this.router.navigate(["/panel/detalle-sistema"]);
  }
}
