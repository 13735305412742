<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-2 text-left">
        <h3><strong>Estudiantes</strong></h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-content bg-white">
            <div class="table-responsive p-3 mx-auto">
              <table class="table table-striped table-hover">
                <thead class="thead-light">
                  <tr>
                    <th class="text-center" scope="col">
                      <p>
                        <strong>Nombre completo</strong>
                      </p>
                    </th>
                    <th class="text-center" scope="col">
                      <p>
                        <strong>Especialidad</strong>
                      </p>
                    </th>
                    <th class="text-center" scope="col">
                      <p>
                        <strong>Correo</strong>
                      </p>
                    </th>
                    <th class="text-center" scope="col">
                      <p>
                        <strong>Código</strong>
                      </p>
                    </th>
                    <th class="text-center" scope="col">
                      <p>
                        <strong>Puntaje total</strong>
                      </p>
                    </th>
                    <th class="text-center" scope="col">
                      <p>
                        <strong>Acciones</strong>
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let nom of nombres; let i = index" scope="row">
                    <td *ngIf="nom != null">
                      {{ nom }}
                    </td>
                    <td *ngIf="nom != null" class="text-center">
                      {{ especialidades[i] }}
                    </td>
                    <td *ngIf="nom != null" class="text-center">
                      {{ correos[i] }}
                    </td>
                    <td *ngIf="nom != null" class="text-center">
                      {{ codigos[i] }}
                    </td>
                    <td *ngIf="nom != null" class="text-center">
                      {{ puntajesTotales[i] }}
                    </td>
                    <td *ngIf="nom != null" class="text-center">
                      <i class="material-icons" routerLink="/panel/detalle-estudiante" (click)="renderEstudiante(i)">search</i> <i class="material-icons">delete</i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>