import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AngularFireStorage, AngularFireStorageReference } from '@angular/fire/storage';

/**
 * Este servicio maneja la información del caso que está siendo consultado y editado
 */
@Injectable({
  providedIn: 'root'
})
export class RdbEditarCasoService {

  constructor(private http: HttpClient, private router: Router, private firebase_storage: AngularFireStorage) { }

  urlFirebase = "https://medicina-uniandes-default-rtdb.firebaseio.com/";

  //Información para identificar el caso actual==========================================
  nombre_caso_seleccionado: string = null;
  especialidad_caso_seleccionado: string = null;
  id_caso_seleccionado: string = null;
  
  json_caso_seleccionado: JSON = null;

  /**
   * Reinicia todas las variables del servicio para evitar que los datos de un caso
   * aparezcan en la edición de otro caso
   */
   clearSeleccionado() {
    this.nombre_caso_seleccionado = null;
    this.especialidad_caso_seleccionado = null;
    this.id_caso_seleccionado = null;
    this.json_caso_seleccionado = null;

    this.nombre_caso = "";
    this.especialidad = "";
    this.dificultad = "";
    this.escenario = "";
    this.modo = "";

    this.nombre_paciente = "";
    this.genero = "";
    this.edad = "";
    this.peso = "";
    this.talla = "";
    this.motivo_consulta = "";
    this.embarazada = false;
    this.etnia = "";
    
    this.frecuencia_cardiaca = "";
    this.frecuencia_respiratoria = "";
    this.saturacion = "";
    this.tension_arterial = "";
    this.VAS = "";
    this.temperatura = "";

    this.paciente_listo = false;

    this.enfermedad_actual_resps = [];

    this.cardiovascular_resps = [];
    this.endocrino_resps = [];
    this.gastrointestinal_resps = [];
    this.genitourinario_resps = [];
    this.nervioso_resps = [];
    this.osteomuscular_resps = [];
    this.respiratorio_resps = [];
    
    this.cardiovascular_pregs = -1;
    this.endocrino_pregs = -1;
    this.gastrointestinal_pregs = -1;
    this.genitourinario_pregs = -1;
    this.nervioso_pregs = -1;
    this.osteomuscular_pregs = -1;
    this.respiratorio_pregs = -1;

    this.cardiovascular_tipo = "";
    this.endocrino_tipo = "";
    this.gastrointestinal_tipo = "";
    this.genitourinario_tipo = "";
    this.nervioso_tipo = "";
    this.osteomuscular_tipo = "";
    this.respiratorio_tipo = "";

    this.antecedentes_resps = [];
    this.antecedentes_tipos = [];

    this.examen_fisico_resps = [];
    this.examen_fisico_recursos = [];
    this.examen_fisico_put_archivos = [false, false, false, false, false, false, false, false, false];
    this.examen_fisico_archivos_str = [];

    this.dolor_cabeza = false;
    this.dolor_torax = false;
    this.dolor_abdomen = false;
    this.dolor_extremidades = false;

    this.diagnosticos = [];
    this.probabilidades = [];

    this.laboratorios_nombres = [];
    this.laboratorios_resps = [];
    this.laboratorios_adjuntos = [];
    this.laboratorios_pts = [];
    this.laboratorios_adjuntos_str = [];
    this.laboratorios_cambiar = [];  
    this.laboratorios_nombres_borrar = [];

    this.imagenes_diagnosticas = [];
    this.imgs_puntuaciones = [];
    this.imgs_adjuntos = [];
    this.imgs_adjuntos_str = [];
    this.imgs_cambiar = [];
    this.imgs_nombres_borrar = [];

    this.otras_nombres = [];
    this.otras_resps = [];
    this.otras_adjuntos = [];
    this.otras_pts = [];
    this.otras_pregs = [];
    this.otras_adjuntos_str = [];
    this.otras_cambiar = [];
    this.otras_nombres_borrar = [];

    this.tratamientos = [];
    this.tratamientos_pts = [];

    this.dieta = [];
    this.dieta_pts = [];

    this.soporte_hidrico = [];
    this.soporte_hidrico_pts = [];

    this.medicamentos = [];
    this.medicamentos_pts = [];

    this.oxigeno = [];
    this.oxigeno_pts = [];

    this.medidas_generales = [];
    this.medidas_generales_pts = [];

    this.lbl_diagnostico = [];
    this.motivo_diagnostico = "";
    this.bibliografia = [];
  }

  //Información general==================================================
  nombre_caso: string = "";
  especialidad: string = "";
  dificultad: string = "";
  escenario: string = "";
  modo: string = "";

  //Paciente=============================================================  
  //Datos personales
  nombre_paciente: string = "";
  genero: string = "";
  edad: string = "";
  peso: string = "";
  talla: string = "";
  motivo_consulta: string = "";
  embarazada: boolean = false;
  etnia: string = "";

  //Signos vitales
  frecuencia_cardiaca: string = "";
  frecuencia_respiratoria: string = "";
  saturacion: string = "";
  tension_arterial: string = "";
  VAS: string = "";
  temperatura: string = "";

  paciente_listo: boolean = false;

  //Anamnesis============================================================
  
  //Enfermedad actual----------------------------------------------------
  enfermedad_actual_resps: string[] = [];

  //Revisión por sistemas------------------------------------------------
  sistema_actual: string = ""; //únicamente para usar en detalle-sistema

  cardiovascular_resps: string[] = [];
  endocrino_resps: string[] = [];
  gastrointestinal_resps: string[] = [];
  genitourinario_resps: string[] = [];
  nervioso_resps: string[] = [];
  osteomuscular_resps: string[] = [];
  respiratorio_resps: string[] = [];
  
  cardiovascular_pregs = -1;
  endocrino_pregs = -1;
  gastrointestinal_pregs = -1;
  genitourinario_pregs = -1;
  nervioso_pregs = -1;
  osteomuscular_pregs = -1;
  respiratorio_pregs = -1;

  cardiovascular_tipo = "";
  endocrino_tipo = "";
  gastrointestinal_tipo = "";
  genitourinario_tipo = "";
  nervioso_tipo = "";
  osteomuscular_tipo = "";
  respiratorio_tipo = "";

  //Recupera las preguntas del sistema indicado desde Firebase y devuelve el objeto Observable
  getPregsSistema(sistema: string) {
    var urlSistema = this.urlFirebase + "preguntas/sistemas/" + sistema.toLowerCase() + "/.json";

    return this.http.get(urlSistema);
  }

  //Antecedentes---------------------------------------------------------
  antecedentes_resps: string[] = [];
  antecedentes_tipos: string[] = [];
  antecedentes_pregs: string[] = ["Patológicos", "Quirúrgicos", "Familiares", "Farmacológicos", "Ginecológicos",
                                  "Alérgicos", "Toxicológicos", "Epidemiológicos", "Perinatales"];

  //Examen físico--------------------------------------------------------
  examen_fisico_resps: string[] = [];
  examen_fisico_recursos: File[] = [];
  examen_fisico_archivos_json = null;
  examen_fisico_pregs: string[] = ["Estado general", "Cabeza y cuello", "Cardiovascular", "Respiratorio", "Abdomen",
                                  "Extremidades", "Neurológico", "Piel y anexos", "Otros"];

  
  examen_fisico_put_archivos: boolean[] = [false, false, false, false, false, false, false, false, false]; //Indica cuáles archivos deben ser actualizados
  examen_fisico_archivos_str: string[] = []; //Contiene los nombres de los recursos actuales del caso

  dolor_cabeza: boolean = false;
  dolor_torax: boolean = false;
  dolor_abdomen: boolean = false;
  dolor_extremidades: boolean = false;

  //Impresión diagnóstica================================================
  diagnosticos: string[] = [];
  probabilidades: string[] = [];

  //Ayudas diagnósticas==================================================
  ayudas_diagnosticas_json = null;
  ayudas_diagnosticas_pts_json = null;
  //Laboratorios---------------------------------------------------------
  laboratorio_seleccionado: string = ""; //Para detalle-laboratorio

  laboratorios_nombres: string[] = [];
  laboratorios_resps: string[] = [];
  laboratorios_adjuntos: File[] = [];
  laboratorios_pts: string[] = [];

  laboratorios_adjuntos_str: string[] = [];
  laboratorios_cambiar: boolean[] = [];

  laboratorios_nombres_borrar: string[] = [];

  /**
   * Recupera los laboratorios del tipo indicado desde Firebase y devuelve un objeto Observable para
   * monitorear el estado de la petición
   * @param tipo_lab El tipo de laboratorio a consultar (e.g. "Laboratorios clínicos")
   * @returns Un objeto Observable creado sobre la petición http lanzada
   */
  getLabs(tipo_lab: string) {
    var urlLabs = this.urlFirebase + "preguntas/ayudas%20diagnósticas/laboratorios/" 
                  + tipo_lab.trim().toLowerCase().replace(" ", "%20") + "/.json";

    return this.http.get(urlLabs);
  }

  //Imágenes diagnósticas------------------------------------------------
  imagenes_diagnosticas: string[] = [];
  imgs_puntuaciones: string[] = [];
  imgs_adjuntos: File[] = [];

  imgs_adjuntos_str: string[] = [];
  imgs_cambiar: boolean[] = [];

  imgs_nombres_borrar: string[] = [];

  //Otras ayudas---------------------------------------------------------
  otras_nombres: string[] = [];
  otras_resps: string[] = [];
  otras_adjuntos: File[] = [];
  otras_pts: string[] = [];
  otras_pregs: string[] = [];
  
  otras_adjuntos_str: string[] = [];
  otras_cambiar: boolean[] = [];

  otras_nombres_borrar: string[] = [];

  //Recupera los laboratorios clasificados bajo "Otras ayudas"
  getOtrasAyudas() {
    var urlOtras = this.urlFirebase + "preguntas/ayudas%20diagnósticas/otras%20ayudas/.json";

    return this.http.get(urlOtras);
  }

  //Tratamientos=========================================================
  tratamiento_seleccionado: string = "";

  tratamientos: string[] = [];
  tratamientos_pts: string[] = [];

  dieta: string[] = [];
  dieta_pts: string[] = [];

  soporte_hidrico: string[] = [];
  soporte_hidrico_pts: string[] = [];

  medicamentos: string[] = [];
  medicamentos_pts: string[] = [];

  oxigeno: string[] = [];
  oxigeno_pts: string[] = [];

  medidas_generales: string[] = [];
  medidas_generales_pts: string[] = [];

  /**
   * Toma los tratamientos que están en tratamientos y distribuye cada uno
   * en el tipo de tratamiento correcto según la información que se encuentre
   * en Firebase
   */
  clasificarTratamientos() {
    this.getAllTratamientos().toPromise().then(
      json_tratamientos => {
        var json_dieta: JSON = json_tratamientos["dieta"];
        var json_medicamentos: JSON = json_tratamientos["medicamentos"];
        var json_medidas: JSON = json_tratamientos["medidas generales"];
        var json_oxigeno: JSON = json_tratamientos["oxígeno"];
        var json_hidrico: JSON = json_tratamientos["soporte hídrico"];

        var dietas: string[] = [];
        var meds: string[] = [];
        var medidas: string[] = [];
        var oxigenos: string[] = [];
        var hidricos: string[] = [];

        var str_dieta: string;
        var str_medicamentos: string;
        var str_medidas: string;
        var str_oxigeno: string;
        var str_hidrico: string;

        var dieta_null: boolean;
        var med_null: boolean;
        var medida_null: boolean;
        var oxigeno_null: boolean;
        var hidrico_null: boolean;

        var cnt = 0;

        //Recuperar la lista de todos los tratamientos disponibles
        while(true) {
          str_dieta = json_dieta["t_" + cnt];
          str_medicamentos = json_medicamentos["t_" + cnt];
          str_medidas = json_medidas["t_" + cnt];
          str_oxigeno = json_oxigeno["t_" + cnt];
          str_hidrico = json_hidrico["t_" + cnt];

          dieta_null = str_dieta == null;
          med_null = str_medicamentos == null;
          medida_null = str_medidas == null;
          oxigeno_null = str_oxigeno == null;
          hidrico_null = str_hidrico == null;

          if (dieta_null && med_null && medida_null
            && oxigeno_null && hidrico_null) {
            break;
          }

          if (!dieta_null) {
            dietas.push(str_dieta);
          }
          if (!med_null) {
            meds.push(str_medicamentos);
          }
          if (!medida_null) {
            medidas.push(str_medidas);
          }
          if (!oxigeno_null) {
            oxigenos.push(str_oxigeno);
          }
          if (!hidrico_null) {
            hidricos.push(str_hidrico);
          }

          cnt++;
        }

        //Clasificar los tratamientos guardados
        var trat_aux: string;
        var trat_pts_aux: string;
        for(var i = 0; i < this.tratamientos.length; i++) {
          trat_aux = this.tratamientos[i];
          trat_pts_aux = this.tratamientos_pts[i];

          if (trat_aux != null && trat_pts_aux != null) {
            if (dietas.includes(trat_aux)) {
              this.dieta.push(trat_aux);
              this.dieta_pts.push(trat_pts_aux);
            }
            else if (this.isMedicamento(trat_aux, meds)) {
              this.medicamentos.push(trat_aux);
              this.medicamentos_pts.push(trat_pts_aux);
            }
            else if (medidas.includes(trat_aux)) {
              this.medidas_generales.push(trat_aux);
              this.medidas_generales_pts.push(trat_pts_aux);
            }
            else if (oxigenos.includes(trat_aux)) {
              this.oxigeno.push(trat_aux);
              this.oxigeno_pts.push(trat_pts_aux);
            }
            else if (hidricos.includes(trat_aux)) {
              this.soporte_hidrico.push(trat_aux);
              this.soporte_hidrico_pts.push(trat_pts_aux);
            }
          }
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando tratamientos: " + error);
      }
    );
  }

  /**
   * Indica si el tratamiento que entra por parámetro se puede clasificar como medicamento o no
   * @param pTrat El tratamiento a analizar
   * @param meds El arreglo que contiene todos los medicamentos conocidos por el sistema
   * @returns true si el tratamiento es un medicamento; false en caso contrario
   */
  isMedicamento(pTrat: string, meds: string[]): boolean {
    var ans = false;

    var trat_split: string[] = pTrat.split(" ");

    //Búsqueda parcial; una vez se conozca un medicamento que haga match se suspende la búsqueda
    var cnt: number;
    var trat_aux: string = "";
    for(var i = 0; i < trat_split.length && !ans; i++) {
      cnt = 0;
      trat_aux = trat_split[cnt];
      
      while(cnt != i) {
        cnt++;
        trat_aux += " " + trat_split[cnt];
      }

      ans = meds.includes(trat_aux);
    }

    return ans;
  }

  /**
   * Recupera la lista de los tratamientos del tipo indicado
   * @param pTratamiento El tipo de tratamiento a consultar
   * @returns Un objeto de tipo Observable para mirar el progreso y resultado de la consulta
   */
  getTratamientos(pTratamiento: string) {
    var urlTratamiento = this.urlFirebase + "preguntas/tratamiento/" 
    + pTratamiento.trim().toLowerCase().replace(" ", "%20") + "/.json";

    return this.http.get(urlTratamiento);
  }

  /**
   * Recupera la lista de todos los tratamientos
   * @returns Un objeto de tipo Observable para mirar el progreso y el resultado de la consulta
   */
  getAllTratamientos() {
    var urlTratamientos = this.urlFirebase + "preguntas/tratamiento/.json";

    return this.http.get(urlTratamientos);
  }

  //Diagnóstico final====================================================
  //Los diagnósticos se encuentran en la sección "Impresión diagnóstica"
  lbl_diagnostico: string[] = [];
  motivo_diagnostico: string = "";
  bibliografia: string[] = [];

  //Recupera todos los casos de la especialidad indicada
  getCasosEspecialdiad(pEspecialidad: string) {
    var urlCasos = this.urlFirebase + "especialidades/" + pEspecialidad + "/.json";

    return this.http.get(urlCasos);
  }

  deleteCasoDeEspecialidad(pTitulo: string, pEspecialidad: string) {
    var key_caso_eliminar: string = "";
    this.getCasosEspecialdiad(pEspecialidad).toPromise().then(
      data => {
        for(let key in data) {
          if(data.hasOwnProperty(key) && data[key]["título"] == pTitulo) {
            key_caso_eliminar = key;
            break;
          }
        }
        var urlCaso = this.urlFirebase + "especialidades/" + pEspecialidad + "/" + key_caso_eliminar + "/.json";
        this.http.delete(urlCaso).toPromise().then(
          () => {
            console.log("Se eliminó correctamente el caso " + pTitulo + " de " + pEspecialidad);
          }
        ).catch(
          err => {
            console.log("this.http.delete(urlCaso) lanzó error: " + err);
          }
        );
      }
    );
    //Puede fallar si el caso no tiene ningún recurso adjunto
    var refEstudio = "Estudio/" + pEspecialidad + "/" + pTitulo + "/";
    var refEvaluacion = "Evaluación/" + pEspecialidad + "/" + pTitulo + "/";
    
    var estAyudas: AngularFireStorageReference = this.firebase_storage.ref(refEstudio + "ayudas diagnósticas/");
    var estExamen: AngularFireStorageReference = this.firebase_storage.ref(refEstudio + "examen físico/");

    var evaAyudas: AngularFireStorageReference = this.firebase_storage.ref(refEvaluacion + "ayudas diagnósticas/");
    var evaExamen: AngularFireStorageReference = this.firebase_storage.ref(refEvaluacion + "examen físico/");

    //Los exámenes físicos están almacenados en una profundidad mayor a las ayudas diagnósticas
    this.borrarArchivosDeCarpeta(estAyudas, false);
    this.borrarArchivosDeCarpeta(estExamen, true);
    this.borrarArchivosDeCarpeta(evaAyudas, false);
    this.borrarArchivosDeCarpeta(evaExamen, true);
  }

  /**
   * Recibe una referencia a un directorio de Firebase Storage, lo recorre, y borra cada archivo encontrado
   * @param carpetaPadre carpeta contenedora a recorrer
   * @param esCarpeta indica si se deben explorar los contenidos de la referencia encontrada
   */
  borrarArchivosDeCarpeta(carpetaPadre: AngularFireStorageReference, esCarpeta: boolean) {
    carpetaPadre.listAll().toPromise().then(
      resp => {
        if (esCarpeta) {
          resp.prefixes.forEach(
            prefixRef => {
              prefixRef.listAll().then(
                resp2_1 => {
                  resp2_1.items.forEach(
                    itemRef2 => {
                      itemRef2.delete().then().catch(
                        err3 => {
                          console.log("Error en borrarArchivosDeCarpeta() 3: " + err3);
                        }
                      );
                    }
                  );
                }
              ).catch(
                err2_1 => {
                  console.log("Error en borrarArchivosDeCarpeta() 2_1: " + err2_1);
                }
              );
            }
          );
        }
        else {
          resp.items.forEach(
            itemRef => {
              itemRef.delete().then().catch(
                err2_2 => {
                  console.log("Error en borrarArchivosDeCarpeta() 2_2: " + err2_2)
                }
              );
            }
          );
        }
        
      }
    ).catch(
      err => {
        console.log("Error en borrarArchivosDeCarpeta(): " + err);
      }
    );
  }

  //Armar el JSON del caso y subirlo a Firebase==========================
  json_caso_actualizado = null;

  /**
   * Construye el objeto que representa el caso para guardar en Firebase
   * Luego, sube (o elimina) los archivos que requieran actualización en Firebase y
   * actualiza las referencias a estos archivos en el JSON del caso
   * Por último, sube el JSON completamente actualizado
   */
  armarJSONyGuardar() {
    this.json_caso_actualizado = {
      "antecedentes": {
        "r_0": this.antecedentes_resps[0],
        "r_1": this.antecedentes_resps[1],
        "r_2": this.antecedentes_resps[2],
        "r_3": this.antecedentes_resps[3],
        "r_4": this.antecedentes_resps[4],
        "r_5": this.antecedentes_resps[5],
        "r_6": this.antecedentes_resps[6],
        "r_7": this.antecedentes_resps[7],
        "r_8": this.antecedentes_resps[8]
      },
      "apariencia": this.apariencia(),
      "ayudas diagnósticas": this.ayudas_diagnosticas_json,
      "bibliografía": {
        "b_1": this.bibliografia[0],
        "b_2": this.bibliografia[1],
        "b_3": this.bibliografia[2],
        "b_4": this.bibliografia[3],
        "b_5": this.bibliografia[4]
      },
      "diagnóstico final": {
        "f_1": this.lbl_diagnostico[0],
        "f_2": this.lbl_diagnostico[1],
        "f_3": this.lbl_diagnostico[2],
        "f_4": this.lbl_diagnostico[3],
        "f_5": this.lbl_diagnostico[4]
      },
      "dificultad": this.dificultad,
      "dolor": {
        "abdomen": this.dolor_abdomen,
        "cabeza": this.dolor_cabeza,
        "extremidades": this.dolor_extremidades,
        "torax": this.dolor_torax
      },
      "edad": this.edad,
      "enfermedad actual": {
        "r_0": this.enfermedad_actual_resps[0],
        "r_1": this.enfermedad_actual_resps[1],
        "r_2": this.enfermedad_actual_resps[2],
        "r_3": this.enfermedad_actual_resps[3],
        "r_4": this.enfermedad_actual_resps[4],
        "r_5": this.enfermedad_actual_resps[5],
        "r_6": this.enfermedad_actual_resps[6],
        "r_7": this.enfermedad_actual_resps[7],
        "r_8": this.enfermedad_actual_resps[8],
        "r_9": this.enfermedad_actual_resps[9],
        "r_10": this.enfermedad_actual_resps[10],
        "r_11": this.enfermedad_actual_resps[11],
        "r_12": this.enfermedad_actual_resps[12],
        "r_13": this.enfermedad_actual_resps[13]
      },
      "escenario": this.escenario,
      "etnia": this.etnia,
      "examen físico": {
        "FC": this.frecuencia_cardiaca,
        "FR": this.frecuencia_respiratoria,
        "SAT": this.saturacion,
        "TA": this.tension_arterial,
        "Temperatura": this.temperatura,
        "VAS": Number.parseInt(this.VAS),
        "archivos": this.examen_fisico_archivos_json,
        "peso": this.peso,
        "respuestas": {
          "r_0": this.examen_fisico_resps[0],
          "r_1": this.examen_fisico_resps[1],
          "r_2": this.examen_fisico_resps[2],
          "r_3": this.examen_fisico_resps[3],
          "r_4": this.examen_fisico_resps[4],
          "r_5": this.examen_fisico_resps[5],
          "r_6": this.examen_fisico_resps[6],
          "r_7": this.examen_fisico_resps[7],
          "r_8": this.examen_fisico_resps[8],
        },
        "talla": this.talla
      },
      "género": this.genero,
      "impresión diagnóstica": {
        "d_1": this.diagnosticos[0],
        "d_2": this.diagnosticos[1],
        "d_3": this.diagnosticos[2],
        "d_4": this.diagnosticos[3],
        "d_5": this.diagnosticos[4],
        "p_1": this.probabilidades[0],
        "p_2": this.probabilidades[1],
        "p_3": this.probabilidades[2],
        "p_4": this.probabilidades[3],
        "p_5": this.probabilidades[4],
      },
      "modo": this.modo,
      "motivo de consulta": this.motivo_consulta,
      "motivo del diagnóstico": this.motivo_diagnostico,
      "nombre": this.nombre_paciente,
      "sistemas": this.putSistemasJSON(),
      "tipo puntuación": {
        "antecedentes": {
          "Patológicos": this.antecedentes_tipos[0],
          "Quirúrgicos": this.antecedentes_tipos[1],
          "Familiares": this.antecedentes_tipos[2],
          "Farmacológicos": this.antecedentes_tipos[3],
          "Ginecológicos": this.antecedentes_tipos[4],
          "Alérgicos": this.antecedentes_tipos[5],
          "Toxicológicos": this.antecedentes_tipos[6],
          "Epidemiológicos": this.antecedentes_tipos[7],
          "Perinatales": this.antecedentes_tipos[8]
        },
        "ayudas diagnósticas": this.ayudas_diagnosticas_pts_json,
        "sistemas": {
          "Cardiovascular": this.cardiovascular_tipo,
          "Endocrino": this.endocrino_tipo,
          "Gastrointestinal": this.gastrointestinal_tipo,
          "Genitourinario": this.genitourinario_tipo,
          "Nervioso": this.nervioso_tipo,
          "Osteomuscular": this.osteomuscular_tipo,
          "Respiratorio": this.respiratorio_tipo
        },
        "tratamiento": this.putTratamientoJSON()
      },
      "título": this.nombre_caso
    };

    //Subir los archivos a actualizar a firebase Storage
    var refEstudio = "Estudio/" + this.especialidad + "/" + this.nombre_caso + "/";
    var refEvaluacion = "Evaluación/" + this.especialidad + "/" + this.nombre_caso + "/";

    var refAdjEst: AngularFireStorageReference;
    var refAdjEva: AngularFireStorageReference;

    //Examen físico
    this.examen_fisico_recursos.forEach(
      (e,i) => {
        if (this.examen_fisico_put_archivos[i]) {
          //Borrar el archivo anterior
          refAdjEst = this.firebase_storage.ref(refEstudio + "examen físico/" + this.examen_fisico_pregs[i]);
          refAdjEva = this.firebase_storage.ref(refEvaluacion + "examen físico/" + this.examen_fisico_pregs[i]);

          this.borrarArchivosDeCarpeta(refAdjEst, false);
          this.borrarArchivosDeCarpeta(refAdjEva, false);

          //Actualizar la referencia
          if (e != null) {
            //Nuevo archivo
            refAdjEst = this.firebase_storage.ref(refEstudio + "examen físico/" + this.examen_fisico_pregs[i] + "/" + e.name);
            refAdjEva = this.firebase_storage.ref(refEvaluacion + "examen físico/" + this.examen_fisico_pregs[i] + "/" + e.name);
            
            refAdjEst.put(e);
            refAdjEva.put(e);

            this.json_caso_actualizado["examen físico"]["archivos"][this.examen_fisico_pregs[i]] = e.name;
          }
          else {
            //Referencia vacía
            this.json_caso_actualizado["examen físico"]["archivos"][this.examen_fisico_pregs[i]] = "N/A";
          }
        }
      }
    );    

    //Ayudas diagnósticas
    var file_aux: File;
    var file_split: string[];
    var nombre_aux: string;
    
    //Laboratorios
    this.laboratorios_nombres.forEach(
      (e,i) => {
        if (this.laboratorios_cambiar[i]) {
          //Borrar el archivo anterior
          if (this.laboratorios_adjuntos_str[i] != null) {
            this.firebase_storage.ref(refEstudio + "ayudas diagnósticas/" + this.laboratorios_adjuntos_str[i]).getDownloadURL().toPromise().then(
              data => {
                this.firebase_storage.refFromURL(data).delete().toPromise().then().catch(
                  error => {
                    console.log("Error eliminando archivo anterior para " + e);
                    console.log(error);
                  }
                );
              }
            ).catch(
              error => {
                console.log("Error recuperando la URL del objeto " + e);
                console.log(error);
              }
            );

            this.firebase_storage.ref(refEvaluacion + "ayudas diagnósticas/" + this.laboratorios_adjuntos_str[i]).getDownloadURL().toPromise().then(
              data => {
                this.firebase_storage.refFromURL(data).delete().toPromise().then().catch(
                  error => {
                    console.log("Error eliminando archivo anterior para " + e);
                    console.log(error);
                  }
                );
              }
            ).catch(
              error => {
                console.log("Error recuperando la URL del objeto " + e);
                console.log(error);
              }
            );
          }

          //Subir el archivo nuevo y actualizar JSON
          if (e != null) {
            //Intenta eliminar la entrada de laboratorios que indica que no son necesarios (i.e. "d_0": "No es necesario.")
            if (this.json_caso_actualizado["ayudas diagnósticas"]["laboratorios"] != null
            && this.json_caso_actualizado["ayudas diagnósticas"]["laboratorios"]["d_0"] != null){
              delete this.json_caso_actualizado["ayudas diagnósticas"]["laboratorios"]["d_0"];
            }

            //Actualiza el puntaje del laboratorio a subir
            if (this.json_caso_actualizado["tipo puntuación"]["ayudas diagnósticas"] == null) {
              this.json_caso_actualizado["tipo puntuación"]["ayudas diagnósticas"] = {
                "laboratorios": {
                }
              }
            }
            else if (this.json_caso_actualizado["tipo puntuación"]["ayudas diagnósticas"]["laboratorios"] == null) {
              this.json_caso_actualizado["tipo puntuación"]["ayudas diagnósticas"]["laboratorios"] = {
              }
            }
            this.json_caso_actualizado["tipo puntuación"]["ayudas diagnósticas"]["laboratorios"][e] = this.laboratorios_pts[i];

            file_aux = this.laboratorios_adjuntos[i];
            if (file_aux != null) {
              file_split = file_aux.name.split(".");
              nombre_aux = e + "." + file_split[file_split.length - 1];

              //Actualizar entrada en el JSON
              this.json_caso_actualizado["ayudas diagnósticas"]["laboratorios"][e] = {
                "respuesta": this.laboratorios_resps[i],
                "adjunto": nombre_aux
              }

              if (this.laboratorios_resps[i] == null || this.laboratorios_resps[i].trim() == "") {
                delete this.json_caso_actualizado["ayudas diagnósticas"]["laboratorios"][e]["respuesta"];
              }
              
              refAdjEst = this.firebase_storage.ref(refEstudio + "ayudas diagnósticas/" + nombre_aux);
              refAdjEva = this.firebase_storage.ref(refEvaluacion + "ayudas diagnósticas/" + nombre_aux);
    
              refAdjEst.put(file_aux);
              refAdjEva.put(file_aux);
            }
            else {
              //Actualizar entrada en el JSON
              this.json_caso_actualizado["ayudas diagnósticas"]["laboratorios"][e] = {
                "respuesta": this.laboratorios_resps[i], //No es necesario verificar la nulidad de resps
              }
            }
          }
        }
      }
    );

    this.laboratorios_nombres_borrar.forEach(
      (e,i) => {
        if (e != null) {
          delete this.json_caso_actualizado["ayudas diagnósticas"]["laboratorios"][e];
          delete this.json_caso_actualizado["tipo puntuación"]["ayudas diagnósticas"]["laboratorios"][e];
        }
      }
    );

    //Si no quedaron laboratorios registrados, se pone la entrada que indica que los laboratorios no son necesarios
    if(this.isEmpty(this.json_caso_actualizado["ayudas diagnósticas"]["laboratorios"])) {
      this.json_caso_actualizado["ayudas diagnósticas"]["laboratorios"] = {
        "d_0": "No es necesario."
      };
    }

    //Imágenes diagnósticas
    this.imagenes_diagnosticas.forEach(
      (e,i) => {
        if (this.imgs_cambiar[i]) {
          //Borrar el archivo anterior
          if (this.imgs_adjuntos_str[i] != null) {
            this.firebase_storage.ref(refEstudio + "ayudas diagnósticas/" + this.imgs_adjuntos_str[i]).getDownloadURL().toPromise().then(
              data => {
                this.firebase_storage.refFromURL(data).delete().toPromise().then().catch(
                  error => {
                    console.log("Error eliminando archivo anterior para " + e);
                    console.log(error);
                  }
                );
              }
            ).catch(
              error => {
                console.log("Error recuperando la URL del objeto " + e);
                console.log(error);
              }
            );

            this.firebase_storage.ref(refEvaluacion + "ayudas diagnósticas/" + this.imgs_adjuntos_str[i]).getDownloadURL().toPromise().then(
              data => {
                this.firebase_storage.refFromURL(data).delete().toPromise().then().catch(
                  error => {
                    console.log("Error eliminando archivo anterior para " + e);
                    console.log(error);
                  }
                );
              }
            ).catch(
              error => {
                console.log("Error recuperando la URL del objeto " + e);
                console.log(error);
              }
            );
          }

          //Subir el archivo nuevo y actualizar JSON
          if (e != null) {
            //Intenta eliminar la entrada de imágenes que indica que no son necesarios (i.e. "d_0": "No es necesario.")
            if (this.json_caso_actualizado["ayudas diagnósticas"]["imágenes diagnósticas"] != null
            && this.json_caso_actualizado["ayudas diagnósticas"]["imágenes diagnósticas"]["d_0"] != null){
              delete this.json_caso_actualizado["ayudas diagnósticas"]["imágenes diagnósticas"]["d_0"];
            }

            //Actualiza el puntaje de la imágen diagnóstica a subir
            if (this.json_caso_actualizado["tipo puntuación"]["ayudas diagnósticas"] == null) {
              this.json_caso_actualizado["tipo puntuación"]["ayudas diagnósticas"] = {
                "imágenes diagnósticas": {
                }
              }
            }
            else if (this.json_caso_actualizado["tipo puntuación"]["ayudas diagnósticas"]["imágenes diagnósticas"] == null) {
              this.json_caso_actualizado["tipo puntuación"]["ayudas diagnósticas"]["imágenes diagnósticas"] = {
              }
            }
            this.json_caso_actualizado["tipo puntuación"]["ayudas diagnósticas"]["imágenes diagnósticas"][e] = this.imgs_puntuaciones[i];

            file_aux = this.imgs_adjuntos[i];
            if (file_aux != null) {
              file_split = file_aux.name.split(".");
              nombre_aux = e + "." + file_split[file_split.length - 1];

              //Actualizar entrada en el JSON
              this.json_caso_actualizado["ayudas diagnósticas"]["imágenes diagnósticas"][e] = {
                "adjunto": nombre_aux
              }
              
              refAdjEst = this.firebase_storage.ref(refEstudio + "ayudas diagnósticas/" + nombre_aux);
              refAdjEva = this.firebase_storage.ref(refEvaluacion + "ayudas diagnósticas/" + nombre_aux);
    
              refAdjEst.put(file_aux);
              refAdjEva.put(file_aux);
            }
          }
        }
      }
    );

    this.imgs_nombres_borrar.forEach(
      (e,i) => {
        if (e != null) {
          delete this.json_caso_actualizado["ayudas diagnósticas"]["imágenes diagnósticas"][e];
          delete this.json_caso_actualizado["tipo puntuación"]["ayudas diagnósticas"]["imágenes diagnósticas"][e];
        }
      }
    );

    //Si no quedaron imágenes diagnósticas registradas, se pone la entrada que indica que las imágenes diagnósticas no son necesarias
    if(this.isEmpty(this.json_caso_actualizado["ayudas diagnósticas"]["imágenes diagnósticas"])) {
      this.json_caso_actualizado["ayudas diagnósticas"]["imágenes diagnósticas"] = {
        "d_0": "No es necesario."
      };
    }

    //Otras ayudas
    this.otras_nombres.forEach(
      (e,i) => {
        if (this.otras_cambiar[i]) {
          //Borrar el archivo anterior
          if (this.otras_adjuntos_str[i] != null) {
            this.firebase_storage.ref(refEstudio + "ayudas diagnósticas/" + this.otras_adjuntos_str[i]).getDownloadURL().toPromise().then(
              data => {
                this.firebase_storage.refFromURL(data).delete().toPromise().then().catch(
                  error => {
                    console.log("Error eliminando archivo anterior para " + e);
                    console.log(error);
                  }
                );
              }
            ).catch(
              error => {
                console.log("Error recuperando la URL del objeto " + e);
                console.log(error);
              }
            );

            this.firebase_storage.ref(refEvaluacion + "ayudas diagnósticas/" + this.otras_adjuntos_str[i]).getDownloadURL().toPromise().then(
              data => {
                this.firebase_storage.refFromURL(data).delete().toPromise().then().catch(
                  error => {
                    console.log("Error eliminando archivo anterior para " + e);
                    console.log(error);
                  }
                );
              }
            ).catch(
              error => {
                console.log("Error recuperando la URL del objeto " + e);
                console.log(error);
              }
            );
          }

          //Subir el archivo nuevo y actualizar JSON
          if (e != null) {
            //Intenta eliminar la entrada de otras ayudas que indica que no son necesarios (i.e. "d_0": "No es necesario.")
            if (this.json_caso_actualizado["ayudas diagnósticas"]["otras ayudas"] != null
            && this.json_caso_actualizado["ayudas diagnósticas"]["otras ayudas"]["d_0"] != null){
              delete this.json_caso_actualizado["ayudas diagnósticas"]["otras ayudas"]["d_0"];
            }

            //Actualiza el puntaje del laboratorio a subir
            if (this.json_caso_actualizado["tipo puntuación"]["ayudas diagnósticas"] == null) {
              this.json_caso_actualizado["tipo puntuación"]["ayudas diagnósticas"] = {
                "otras ayudas": {
                }
              }
            }
            else if (this.json_caso_actualizado["tipo puntuación"]["ayudas diagnósticas"]["otras ayudas"] == null) {
              this.json_caso_actualizado["tipo puntuación"]["ayudas diagnósticas"]["otras ayudas"] = {
              }
            }
            this.json_caso_actualizado["tipo puntuación"]["ayudas diagnósticas"]["otras ayudas"][e] = this.otras_pts[i];

            file_aux = this.otras_adjuntos[i];
            if (file_aux != null) {
              file_split = file_aux.name.split(".");
              nombre_aux = e + "." + file_split[file_split.length - 1];

              //Actualizar entrada en el JSON
              this.json_caso_actualizado["ayudas diagnósticas"]["otras ayudas"][e] = {
                "respuesta": this.otras_resps[i],
                "adjunto": nombre_aux
              }

              if (this.otras_resps[i] == null || this.otras_resps[i].trim() == "") {
                delete this.json_caso_actualizado["ayudas diagnósticas"]["otras ayudas"][e]["respuesta"];
              }
              
              refAdjEst = this.firebase_storage.ref(refEstudio + "ayudas diagnósticas/" + nombre_aux);
              refAdjEva = this.firebase_storage.ref(refEvaluacion + "ayudas diagnósticas/" + nombre_aux);
    
              refAdjEst.put(file_aux);
              refAdjEva.put(file_aux);
            }
            else {
              //Actualizar entrada en el JSON
              this.json_caso_actualizado["ayudas diagnósticas"]["otras ayudas"][e] = {
                "respuesta": this.otras_resps[i], //No es necesario verificar la nulidad de resps
              }
            }
          }
        }
      }
    );

    this.otras_nombres_borrar.forEach(
      (e,i) => {
        if (e != null) {
          delete this.json_caso_actualizado["ayudas diagnósticas"]["otras ayudas"][e];
          delete this.json_caso_actualizado["tipo puntuación"]["ayudas diagnósticas"]["otras ayudas"][e];
        }
      }
    );

    //Si no quedaron laboratorios registrados, se pone la entrada que indica que los laboratorios no son necesarios
    if(this.isEmpty(this.json_caso_actualizado["ayudas diagnósticas"]["otras ayudas"])) {
      this.json_caso_actualizado["ayudas diagnósticas"]["otras ayudas"] = {
        "d_0": "No es necesario."
      };
    }

    //TODO: Eliminar Debug
    console.log(this.json_caso_actualizado);
    //Actualizar el JSON del caso seleccionado en Firebase
    var urlPutCaso = this.urlFirebase + "/especialidades/" + this.especialidad + "/" +
                      this.id_caso_seleccionado + "/.json";

    this.http.put(urlPutCaso, this.json_caso_actualizado).toPromise().then(
      resp => {
        console.log("Se subieron los cambios de " + this.especialidad + "/" + this.nombre_caso + " correctamente");
        this.router.navigate(["/panel/casos"]);
      }
    )
    .catch(
      error => {
        console.log("Error subiendo los cambios de " + this.especialidad + "/" + this.id_caso_seleccionado + ":");
        console.log(error);
      }
    );
  }

  /**
   * Actualiza las respuestas registradas para la revisión por sistema
   * @returns El objeto que guarda todas las respuestas de todos los sistemas
   * para ser puesto en el JSON a subir a Firebase
   */
  putSistemasJSON() {
    var ans = {
      "cardiovascular": {},
      "endocrino": {},
      "gastrointestinal": {},
      "genitourinario": {},
      "nervioso": {},
      "osteomuscular": {},
      "respiratorio": {}
    }

    this.cardiovascular_resps.forEach(
      (e,i) => {
        ans["cardiovascular"]["r_" + i] = (e != null ? e : "N/A");
      }
    );

    this.endocrino_resps.forEach(
      (e,i) => {
        ans["endocrino"]["r_" + i] = (e != null ? e : "N/A");
      }
    );

    this.gastrointestinal_resps.forEach(
      (e,i) => {
        ans["gastrointestinal"]["r_" + i] = (e != null ? e : "N/A");
      }
    );

    this.genitourinario_resps.forEach(
      (e,i) => {
        ans["genitourinario"]["r_" + i] = (e != null ? e : "N/A");
      }
    );

    this.nervioso_resps.forEach(
      (e,i) => {
        ans["nervioso"]["r_" + i] = (e != null ? e : "N/A");
      }
    );

    this.osteomuscular_resps.forEach(
      (e,i) => {
        ans["osteomuscular"]["r_" + i] = (e != null ? e : "N/A");
      }
    );

    this.respiratorio_resps.forEach(
      (e,i) => {
        ans["respiratorio"]["r_" + i] = (e != null ? e : "N/A");
      }
    );

    return ans;
  }

  /**
   * Actualiza los tratamientos registrados
   * @returns El objeto que guarda todos los tratamientos para ser puesto
   * en el JSON a subir a Firebase
   */
  putTratamientoJSON() {
    var ans = {};

    this.dieta.forEach(
      (e,i) => {
        if (e != null) {
          ans[e] = this.dieta_pts[i];
        }
      }
    );

    this.soporte_hidrico.forEach(
      (e,i) => {
        if (e != null) {
          ans[e] = this.soporte_hidrico_pts[i];
        }
      }
    );

    this.medicamentos.forEach(
      (e,i) => {
        if (e != null) {
          ans[e] = this.medicamentos_pts[i];
        }
      }
    );

    this.oxigeno.forEach(
      (e,i) => {
        if (e != null) {
          ans[e] = this.oxigeno_pts[i];
        }
      }
    );

    this.medidas_generales.forEach(
      (e,i) => {
        if (e != null) {
          ans[e] = this.medidas_generales_pts[i];
        }
      }
    );

    return ans;
  }

  /**
   * Mira los parámetros ingresados para el paciente y asigna una apariencia
   * con base en eso
   * @returns "Recién nacido" si tiene menos de 1 mes; "Lactante" si tiene entre 1 mes y 3 años;
   * "Escolar" si tiene entre 3 y 11 años; "Adolescente" si tiene entre 11 y 15 años;
   * "Joven" si tiene entre 15 y 25 años; "Adulto" si tiene entre 25 y 50 años; 
   * "Anciano" si tiene más de 50 años; "Embarazada" si es Mujer, es al menos Adolescente y se indicó
   * en el panel del paciente que se encuentra embarazada
   */
  apariencia(): string {
    var ans = "";

    var edad_split: string[] = this.edad.split(" ")
    var edad_aux: number = parseInt(edad_split[0].trim());
    var edad_lbl: string = edad_split[1].trim();

    if (edad_lbl.startsWith("año")) {
      if (edad_aux <= 3) {
        ans = "Lactante";
      }
      else if (edad_aux > 3 && edad_aux <= 11) {
        ans = "Escolar";
      }
      else if (edad_aux > 11 && edad_aux <= 15) {
        ans = "Adolescente";
        if (this.genero.startsWith("Mujer") && this.embarazada) {
          ans = "Embarazada";
        }
      }
      else if (edad_aux > 15 && edad_aux <= 25) {
        ans = "Joven";
        if (this.genero.startsWith("Mujer") && this.embarazada) {
          ans = "Embarazada";
        }
      }
      else if (edad_aux > 25 && edad_aux <= 50) {
        ans = "Adulto";
        if (this.genero.startsWith("Mujer") && this.embarazada) {
          ans = "Embarazada";
        }
      }
      else if (edad_aux > 50) {
        ans = "Anciano";
      }
    }
    else if (edad_lbl.startsWith("mes")) {
      ans = "Lactante";
    }
    else if (edad_lbl.startsWith("semana")) {
      ans = "Recién nacido";
    }

    return ans;
  }

  /**
   * Indica si el objeto que entra por parámetro está vacío o no
   * @param obj El objeto a analizar
   * @returns true si está vacío; false en caso contrario
   */
  isEmpty(obj): boolean {
    for(var key in obj) {
      if(obj.hasOwnProperty(key))
          return false;
    }
    return true;
  }
}
