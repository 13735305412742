import { Component, OnInit } from '@angular/core';
import { RdbService } from 'app/services/firebase/rdb.service';
import { LocalStorageService } from 'app/services/storage/local-storage.service';

declare const $: any;

@Component({
  selector: 'app-crear-caso-antecedentes',
  templateUrl: './crear-caso-antecedentes.component.html',
  styleUrls: ['./crear-caso-antecedentes.component.css']
})
export class CrearCasoAntecedentesComponent implements OnInit {

  resps: string[] = [];

  tipos: string[] = [];
  tiposUI: string[] = [];

  constructor(private firebase_rdb: RdbService, private local_db: LocalStorageService) { }

  ngOnInit() {
    this.local_db.getItem<string[]>("antecedentes_resps").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.antecedentes_resps = resp;
          this.resps = this.firebase_rdb.antecedentes_resps;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando antecedentes_resps");
      }
    );

    this.local_db.getItem<string[]>("antecedentes_tipos").then(
      resp2 => {
        if (resp2 != null) {
          this.firebase_rdb.antecedentes_tipos = resp2;
          this.tipos = this.firebase_rdb.antecedentes_tipos;
  
          this.tipos.forEach(
            (e,i) => {
              if (e != null && e != "") {
                if (e == "positivo")
                  this.tiposUI[i] = "Pregunta correcta";
                else if (e == "negativo")
                  this.tiposUI[i] = "Pregunta incorrecta";
                else if (e == "omitible")
                  this.tiposUI[i] = "Pregunta omitible";
                else
                  this.tiposUI[i] = "";
              }
            }
          );
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando antecedentes_tipo");
      }
    );
  }
  
  guardarCambios() {
    this.firebase_rdb.antecedentes_resps = this.resps;
    this.firebase_rdb.antecedentes_tipos = this.tipos;

    this.local_db.setItem<string[]>("antecedentes_resps", this.resps);
    this.local_db.setItem<string[]>("antecedentes_tipos", this.tipos);
  }

  seleccionarTipo(index: number, tipo: number) {
    if (tipo == 1) {
      this.tipos[index] = "positivo";
      this.tiposUI[index] = "Pregunta correcta";
    }
    else if (tipo == -1) {
      this.tipos[index] = "negativo";
      this.tiposUI[index] = "Pregunta incorrecta";
    }
    else {
      this.tipos[index] = "omitible";
      this.tiposUI[index] = "Pregunta omitible";
    }
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
        return false;
    }
    return true;
  };

}
