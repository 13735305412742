import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RdbEditarCasoService } from 'app/services/firebase/rdb-editar-caso.service';

declare const $: any;

@Component({
  selector: 'app-detalle-caso-impresion',
  templateUrl: './detalle-caso-impresion.component.html',
  styleUrls: ['./detalle-caso-impresion.component.css']
})
export class DetalleCasoImpresionComponent implements OnInit {

  constructor(private firebase_rdb: RdbEditarCasoService, private router: Router) { }

  diagnosticos: string[] = [];
  probabilidades: string[] = [];

  ngOnInit() {
    if (this.firebase_rdb.nombre_caso_seleccionado == null ||
      this.firebase_rdb.especialidad_caso_seleccionado == null ||
      this.firebase_rdb.id_caso_seleccionado == null ||
      this.firebase_rdb.json_caso_seleccionado == null) {
      
      this.firebase_rdb.clearSeleccionado();
      this.router.navigate(["/panel/casos"]);
    }

    this.diagnosticos = this.firebase_rdb.diagnosticos;
    this.probabilidades = this.firebase_rdb.probabilidades;
  }

  guardarCambios() {
    this.firebase_rdb.diagnosticos = this.diagnosticos;
    this.firebase_rdb.probabilidades = this.probabilidades;
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
        return false;
    }
    return true;
  };
}
