import { Component, OnInit } from '@angular/core';
import { RdbUsersService } from 'app/services/firebase/rdb-users.service';

@Component({
  selector: 'app-estudiantes',
  templateUrl: './estudiantes.component.html',
  styleUrls: ['./estudiantes.component.css']
})
export class EstudiantesComponent implements OnInit {

  constructor(private firebase_rdb_users: RdbUsersService) { }

  //Todos los estudiantes
  //TODO: especialidades puede ser innecesario
  nombres: string[] = [];
  especialidades: string[] = [];
  correos: string[] = [];
  codigos: string[] = [];
  puntajesTotales: string[] = [];

  ngOnInit() {
    this.firebase_rdb_users.correoActual = "";
    this.firebase_rdb_users.nombreActual = "";

    var estudiante_actual;

    var nombre_aux: string;
    var especialidad_aux: string;
    var correo_aux: string;
    var codigo_aux: string;
    var puntajeTotalAux: string;

    this.firebase_rdb_users.getEstudiantes().toPromise().then(
      estudiantes => {
        for(let key_aux in estudiantes) {
          estudiante_actual = estudiantes[key_aux];
          if(estudiante_actual != null && key_aux) {
            nombre_aux = estudiante_actual["nombre"];
            especialidad_aux = estudiante_actual["especialidad"];
            correo_aux = key_aux.split(",").join(".");
            codigo_aux = estudiante_actual["código"];
            puntajeTotalAux = estudiante_actual["puntaje total"];

            this.nombres.push(nombre_aux != null ? nombre_aux : "No se encontró el nombre");
            this.especialidades.push(especialidad_aux != null ? especialidad_aux : "No se encontró la especialidad");
            this.correos.push(correo_aux);
            this.codigos.push(codigo_aux != null ? codigo_aux : "No se encontró el código");
            this.puntajesTotales.push(puntajeTotalAux != null ? puntajeTotalAux : "0/0");
          }
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando listado de estudiantes: " + error);
      }
    );
  }

  renderEstudiante(pIndex: number) {
    this.firebase_rdb_users.nombreActual = this.nombres[pIndex];
    this.firebase_rdb_users.correoActual = this.correos[pIndex];    
  }

}
