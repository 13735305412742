import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';

import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { DashboardComponent } from './dashboard/dashboard.component';
import { EstudiantesComponent } from './estudiantes/estudiantes.component';
import { EspecialidadesComponent } from './especialidades/especialidades.component';
import { CasosComponent } from './casos/casos.component';
import { SistemasComponent } from './sistemas/sistemas.component';
import { LaboratorioComponent } from './laboratorio/laboratorio.component';
import { TratamientoComponent } from './tratamiento/tratamiento.component';
import { CrearCasoComponent } from './crear-caso-components/crear-caso/crear-caso.component';
import { CrearCasoAntecedentesComponent } from './crear-caso-components/crear-caso-antecedentes/crear-caso-antecedentes.component';
import { CrearCasoEnfermedadActualComponent } from './crear-caso-components/crear-caso-enfermedad-actual/crear-caso-enfermedad-actual.component';
import { CrearCasoSistemasComponent } from './crear-caso-components/crear-caso-sistemas/crear-caso-sistemas.component';
import { CrearCasoExamenFisicoComponent } from './crear-caso-components/crear-caso-examen-fisico/crear-caso-examen-fisico.component';
import { CrearCasoImpresionComponent } from './crear-caso-components/crear-caso-impresion/crear-caso-impresion.component';
import { CrearCasoAyudasComponent } from './crear-caso-components/crear-caso-ayudas/crear-caso-ayudas.component';
import { CrearCasoTratamientoComponent } from './crear-caso-components/crear-caso-tratamiento/crear-caso-tratamiento.component';
import { CrearCasoDiagnosticoComponent } from './crear-caso-components/crear-caso-diagnostico/crear-caso-diagnostico.component';
import { CrearCasoPacienteComponent } from './crear-caso-components/crear-caso-paciente/crear-caso-paciente.component';
import { DetalleEstudianteComponent } from './detalle-estudiante/detalle-estudiante.component';
import { DetalleCasoComponent } from './detalle-caso-components/detalle-caso/detalle-caso.component';
import { DetalleCasoAntecedentesComponent } from './detalle-caso-components/detalle-caso-antecedentes/detalle-caso-antecedentes.component';
import { DetalleCasoDiagnosticoComponent } from './detalle-caso-components/detalle-caso-diagnostico/detalle-caso-diagnostico.component';
import { DetalleCasoEnfermedadActualComponent } from './detalle-caso-components/detalle-caso-enfermedad-actual/detalle-caso-enfermedad-actual.component';
import { DetalleCasoImpresionComponent } from './detalle-caso-components/detalle-caso-impresion/detalle-caso-impresion.component';
import { DetalleCasoPacienteComponent } from './detalle-caso-components/detalle-caso-paciente/detalle-caso-paciente.component';
import { DetalleCasoSistemasComponent } from './detalle-caso-components/detalle-caso-sistemas/detalle-caso-sistemas.component';
import { DetalleCasoTratamientoComponent } from './detalle-caso-components/detalle-caso-tratamiento/detalle-caso-tratamiento.component';
import { DetalleCasoExamenFisicoComponent } from './detalle-caso-components/detalle-caso-examen-fisico/detalle-caso-examen-fisico.component';
import { DetalleCasoAyudasComponent } from './detalle-caso-components/detalle-caso-ayudas/detalle-caso-ayudas.component';
import { DetalleSistemaComponent } from './detalle-sistema/detalle-sistema.component';
import { DetalleLaboratorioComponent } from './detalle-laboratorio/detalle-laboratorio.component';
import { DetalleEstudianteCasoComponent } from './detalle-estudiante-caso/detalle-estudiante-caso.component';
import { DetalleTratamientoComponent } from './detalle-tratamiento/detalle-tratamiento.component';

//Imports para autenticación con MSAL
import {
  MsalModule,
  MsalService,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalGuardConfiguration,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from "@azure/msal-angular";
import { 
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
} from "@azure/msal-browser";
import { LoginComponent } from './login/login.component';
import { PanelComponent } from './panel/panel.component';

export function MSALInstanceFactory(): IPublicClientApplication {

  return new PublicClientApplication({
    auth: {
      clientId: "6d504bc2-2c74-4ee7-9467-f4d75e732273",
      authority: "https://login.microsoftonline.com/fabd047c-ff48-492a-8bbb-8f98b9fb9cca/",
      //redirectUri: "https://medicina-uniandes-admin.herokuapp.com/",
      redirectUri: "https://medicinauniandes.imagineapps.co/",
      //postLogoutRedirectUri: "https://medicina-uniandes-admin.herokuapp.com/",
      postLogoutRedirectUri: "https://medicinauniandes.imagineapps.co/",
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false, // set to true for IE 11
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", ["user.read"]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ["user.read"]
    },
    loginFailedRoute: "login-failed"
  };
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    EstudiantesComponent,
    EspecialidadesComponent,
    CasosComponent,
    SistemasComponent,
    LaboratorioComponent,
    TratamientoComponent,
    CrearCasoComponent,
    CrearCasoAntecedentesComponent,
    CrearCasoEnfermedadActualComponent,
    CrearCasoSistemasComponent,
    CrearCasoExamenFisicoComponent,
    CrearCasoImpresionComponent,
    CrearCasoAyudasComponent,
    CrearCasoTratamientoComponent,
    CrearCasoDiagnosticoComponent,
    CrearCasoPacienteComponent,
    DetalleEstudianteComponent,
    DetalleCasoComponent,
    DetalleCasoAntecedentesComponent,
    DetalleCasoDiagnosticoComponent,
    DetalleCasoEnfermedadActualComponent,
    DetalleCasoImpresionComponent,
    DetalleCasoPacienteComponent,
    DetalleCasoSistemasComponent,
    DetalleCasoTratamientoComponent,
    DetalleCasoExamenFisicoComponent,
    DetalleCasoAyudasComponent,
    DetalleSistemaComponent,
    DetalleLaboratorioComponent,
    DetalleEstudianteCasoComponent,
    DetalleTratamientoComponent,
    LoginComponent,
    PanelComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    AngularFireAuthModule,
    FormsModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    MsalModule,
  ],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    MsalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    }
  ],
  bootstrap: [ AppComponent ]
})

export class AppModule { }
