import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RdbEditarCasoService } from 'app/services/firebase/rdb-editar-caso.service';

declare const $: any;

@Component({
  selector: 'app-detalle-caso',
  templateUrl: './detalle-caso.component.html',
  styleUrls: ['./detalle-caso.component.css']
})
export class DetalleCasoComponent implements OnInit {

  constructor(private firebase_caso_rdb: RdbEditarCasoService, private router: Router) { }

  nombre_caso: string = "";
  especialidad: string = "";
  dificultad: string = "";
  escenario: string = "";
  modo: string = "";

  casos_especialidad = null;

  aux_alert_nombre: string = "";

  alert_repetido: boolean = false;
  caso_listo: boolean = false;

  ngOnInit() {
    if (this.firebase_caso_rdb.nombre_caso_seleccionado == null ||
      this.firebase_caso_rdb.especialidad_caso_seleccionado == null ||
      this.firebase_caso_rdb.id_caso_seleccionado == null ||
      this.firebase_caso_rdb.json_caso_seleccionado == null) {
      
      this.firebase_caso_rdb.clearSeleccionado();
      this.router.navigate(["/panel/casos"]);
    }

    this.nombre_caso = this.firebase_caso_rdb.nombre_caso;
    this.especialidad = this.firebase_caso_rdb.especialidad;
    this.dificultad = this.firebase_caso_rdb.dificultad;
    this.escenario = this.firebase_caso_rdb.escenario;
    this.modo = this.firebase_caso_rdb.modo;

    this.alert_repetido = false;
    this.caso_listo = false;
    this.casoListo();
  }

  guardarCambios() {
    this.firebase_caso_rdb.nombre_caso = this.nombre_caso;
    this.firebase_caso_rdb.especialidad = this.especialidad;
    this.firebase_caso_rdb.dificultad = this.dificultad;
    this.firebase_caso_rdb.escenario = this.escenario;
    this.firebase_caso_rdb.modo = this.modo;
  }

  setEspecialidad(pEsp: string) {
    this.especialidad = pEsp;
    this.casos_especialidad = null;
    this.alert_repetido = false;

    this.casoListo(); //Verificar si el caso está listo con este cambio    
  }

  setDificultad(pDif: string) {
    this.dificultad = pDif;
    
    this.casoListo(); //Verificar si el caso está listo con este cambio
  }

  setEscenario(pEsc: string) {
    this.escenario = pEsc;
    
    this.casoListo(); //Verificar si el caso está listo con este cambio
  }

  verificarRepetido() {
    if (this.aux_alert_nombre != this.nombre_caso) {
      this.casoListo();
    }
    return true; //Es llamado en cada ciclo de verificación de cambios por la alerta de nombre repetido
    //Debe devolver true para entrar al ngIf y no afectar la directiva
    //Lo anterior funciona porque verificar (true && condicion) es lo mismo que verificar únicamente (condicion)
  }

  terminarCaso() {
    this.guardarCambios();
    this.firebase_caso_rdb.armarJSONyGuardar();
  }

  //Funciones para verificar si se puede o no marcar una sección como lista=====================================
  enfermedadActualListo() {
    var cnt = 0;

    this.firebase_caso_rdb.enfermedad_actual_resps.forEach((e,i) => {
      if (e != null && e != "")
      {
        cnt++;
      }
    });

    return cnt == 14;
  }

  antecedentesListo() {
    var cntResps = 0;
    var cntTipos = 0;

    this.firebase_caso_rdb.antecedentes_resps.forEach((e,i) => {
      if (e != null && e != "") {
        cntResps++;
      }
    });

    this.firebase_caso_rdb.antecedentes_tipos.forEach((e,i) => {
      if (e != null && e != "") {
        cntTipos++;
      }
    });

    return (cntResps == 9) && (cntTipos == 9);
  }

  /**
   * Verifica si el módulo de Revisión por sistemas está listo para ser subido a firebase
   * Si las preguntas de un sistema son negativas u omitibles no es necesario que tengan respuesta
   * para que el sistema esté completo. Pero si las preguntas son positivas, todas las preguntas
   * deben tener su respectiva respuesta.
   * @returns true si el módulo de Revisión por sistemas está listo; false en caso contrario
   */
  sistemasListo() {
    var cnt = 0;

    //Cardiovascular listo
    var cardiovascular = false;
    if (this.firebase_caso_rdb.cardiovascular_tipo == "negativo" || this.firebase_caso_rdb.cardiovascular_tipo == "omitible") {
      cardiovascular = true;
    }
    else if (this.firebase_caso_rdb.cardiovascular_tipo == "positivo") {
      this.firebase_caso_rdb.cardiovascular_resps.forEach(
        (e) => {
          if (e != null && e.trim() != "")
            cnt++;
        }
      );

      //Si cada pregunta tiene una respuesta válida, se aprueba el sistema
      cardiovascular = (cnt == this.firebase_caso_rdb.cardiovascular_pregs);
    }

    //Endocrino listo
    var endocrino = false;
    if (this.firebase_caso_rdb.endocrino_tipo == "negativo" || this.firebase_caso_rdb.endocrino_tipo == "omitible") {
      endocrino = true;
    }
    else if (this.firebase_caso_rdb.endocrino_tipo == "positivo") {
      cnt = 0;

      this.firebase_caso_rdb.endocrino_resps.forEach(
        (e) => {
          if (e != null && e.trim() != "")
            cnt++;
        }
      );

      //Si cada pregunta tiene una respuesta válida, se aprueba el sistema
      endocrino = (cnt == this.firebase_caso_rdb.endocrino_pregs);
    }

    //Gastrointestinal listo
    var gastrointestinal = false;
    if (this.firebase_caso_rdb.gastrointestinal_tipo == "negativo" || this.firebase_caso_rdb.gastrointestinal_tipo == "omitible") {
      gastrointestinal = true;
    }
    else if (this.firebase_caso_rdb.gastrointestinal_tipo == "positivo") {
      cnt = 0;

      this.firebase_caso_rdb.gastrointestinal_resps.forEach(
        (e) => {
          if (e != null && e.trim() != "")
            cnt++;
        }
      );

      //Si cada pregunta tiene una respuesta válida, se aprueba el sistema
      gastrointestinal = (cnt == this.firebase_caso_rdb.gastrointestinal_pregs);
    }

    //Genitourinario listo
    var genitourinario = false;
    if (this.firebase_caso_rdb.genitourinario_tipo == "negativo" || this.firebase_caso_rdb.genitourinario_tipo == "omitible") {
      genitourinario = true;
    }
    else if (this.firebase_caso_rdb.genitourinario_tipo == "positivo") {
      cnt = 0;

      this.firebase_caso_rdb.genitourinario_resps.forEach(
        (e) => {
          if (e != null && e.trim() != "")
            cnt++;
        }
      );

      //Si cada pregunta tiene una respuesta válida, se aprueba el sistema
      genitourinario = (cnt == this.firebase_caso_rdb.genitourinario_pregs);
    }

    //Nervioso listo
    var nervioso = false;
    if (this.firebase_caso_rdb.nervioso_tipo == "negativo" || this.firebase_caso_rdb.nervioso_tipo == "omitible") {
      nervioso = true;
    }
    else if (this.firebase_caso_rdb.nervioso_tipo == "positivo") {
      cnt = 0;

      this.firebase_caso_rdb.nervioso_resps.forEach(
        (e) => {
          if (e != null && e.trim() != "")
            cnt++;
        }
      );

      //Si cada pregunta tiene una respuesta válida, se aprueba el sistema
      nervioso = (cnt == this.firebase_caso_rdb.nervioso_pregs);
    }

    //Osteomuscular listo
    var osteomuscular = false;
    if (this.firebase_caso_rdb.osteomuscular_tipo == "negativo" || this.firebase_caso_rdb.osteomuscular_tipo == "omitible") {
      osteomuscular = true;
    }
    else if (this.firebase_caso_rdb.osteomuscular_tipo == "positivo") {
      cnt = 0;

      this.firebase_caso_rdb.osteomuscular_resps.forEach(
        (e) => {
          if (e != null && e.trim() != "")
            cnt++;
        }
      );

      //Si cada pregunta tiene una respuesta válida, se aprueba el sistema
      osteomuscular = (cnt == this.firebase_caso_rdb.osteomuscular_pregs);
    }

    //Respiratorio listo
    var respiratorio = false;
    if (this.firebase_caso_rdb.respiratorio_tipo == "negativo" || this.firebase_caso_rdb.respiratorio_tipo == "omitible") {
      respiratorio = true;
    }
    else if (this.firebase_caso_rdb.respiratorio_tipo == "positivo") {
      cnt = 0;

      this.firebase_caso_rdb.respiratorio_resps.forEach(
        (e) => {
          if (e != null && e.trim() != "")
            cnt++;
        }
      );

      //Si cada pregunta tiene una respuesta válida, se aprueba el sistema
      respiratorio = (cnt == this.firebase_caso_rdb.respiratorio_pregs);
    }

    //Todos los sistemas listos
    return (cardiovascular && endocrino && gastrointestinal && genitourinario && nervioso 
            && osteomuscular && respiratorio);
  }

  examenListo() {
    var cnt_examen = 0;

    this.firebase_caso_rdb.examen_fisico_resps.forEach(
      e => {
        if (e != null && e != "") {
          cnt_examen++;
        }
      }
    );

    return cnt_examen == 9;
  }

  impresionListo() {
    var cnt_diag = 0;
    var cnt_prob = 0;

    this.firebase_caso_rdb.diagnosticos.forEach(
      e => {
        if (e != null && e != "") {
          cnt_diag ++;
        }
      }
    );

    this.firebase_caso_rdb.probabilidades.forEach(
      e => {
        if (e != null && e != "") {
          cnt_prob ++;
        }
      }
    );

    return (cnt_diag == 5) && (cnt_prob == 5);
  }

  //No es necesario agregar ninguna ayuda diagnóstica
  ayudasListo() {
    return true;
  }

  //No es necesario agregar ningún tratamiento
  tratamientoListo() {
    return true;
  }

  diagnosticoListo() {
    var cnt_diag = 0;
    var cnt_lbl = 0;
    var cnt_biblio = 0;

    var ans_diag = false;
    var ans_lbl = false;
    var ans_biblio = false;
    var ans_razon = this.firebase_caso_rdb.motivo_diagnostico != null && this.firebase_caso_rdb.motivo_diagnostico != "";

    this.firebase_caso_rdb.diagnosticos.forEach(
      e => {
        if (e != null && e != "") {
          cnt_diag ++;
        }
      }
    );

    ans_diag = cnt_diag == 5;

    this.firebase_caso_rdb.lbl_diagnostico.forEach(
      e => {
        if (e != null && e != "") {
          cnt_lbl ++;
        }
      }
    );

    ans_lbl = cnt_lbl == 5;

    this.firebase_caso_rdb.bibliografia.forEach(
      e => {
        if (e != null && e != "") {
          cnt_biblio ++;
        }
      }
    );

    ans_biblio = cnt_biblio == 5;

    return ans_razon && ans_diag && ans_lbl && ans_biblio;
  }

  pacienteListo() {
    return this.firebase_caso_rdb.paciente_listo;
  }

  /**
   * Se llama cada que se detecta un cambio en los datos del caso. Si se cumplen todas las condiciones,
   * se habilita el botón para terminar de editar el caso y subirlo a Firebase
   */
  casoListo() {
    var ans = true;

    if (ans) {
      this.alert_repetido = false;
      //setTimeout(() => {this.alert_repetido = false;}, 0); //Ponerlo en timeout evita el error NG0100

      ans = (this.modo != "") && (this.escenario != "") && (this.dificultad != "") && this.antecedentesListo()
      && this.ayudasListo() && this.diagnosticoListo() && this.enfermedadActualListo() && this.examenListo()
      && this.impresionListo() && this.pacienteListo() && this.sistemasListo() && this.tratamientoListo();
    }

    this.caso_listo = ans;
  }

  /**
   * Indica si el usuario requiere una vista móvil dependiendo del tamaño de su dispositivo
   * @returns true si el anchodel dispositivo coinciden con nuestro criterio de si es o no
   * un dispositivo móvil; false en caso contrario
   */
  isMobileMenu() {
    if ($(window).width() > 991) {
        return false;
    }
    return true;
  };
}
