import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { EstudiantesComponent } from './estudiantes/estudiantes.component';
import { EspecialidadesComponent } from './especialidades/especialidades.component';
import { CasosComponent } from './casos/casos.component';
import { SistemasComponent } from './sistemas/sistemas.component';
import { LaboratorioComponent } from './laboratorio/laboratorio.component';
import { TratamientoComponent } from './tratamiento/tratamiento.component';
import { CrearCasoComponent } from './crear-caso-components/crear-caso/crear-caso.component';
import { CrearCasoAntecedentesComponent } from './crear-caso-components/crear-caso-antecedentes/crear-caso-antecedentes.component';
import { CrearCasoEnfermedadActualComponent } from './crear-caso-components/crear-caso-enfermedad-actual/crear-caso-enfermedad-actual.component';
import { CrearCasoSistemasComponent } from './crear-caso-components/crear-caso-sistemas/crear-caso-sistemas.component';
import { CrearCasoExamenFisicoComponent } from './crear-caso-components/crear-caso-examen-fisico/crear-caso-examen-fisico.component';
import { CrearCasoImpresionComponent } from './crear-caso-components/crear-caso-impresion/crear-caso-impresion.component';
import { CrearCasoAyudasComponent } from './crear-caso-components/crear-caso-ayudas/crear-caso-ayudas.component';
import { CrearCasoTratamientoComponent } from './crear-caso-components/crear-caso-tratamiento/crear-caso-tratamiento.component';
import { CrearCasoDiagnosticoComponent } from './crear-caso-components/crear-caso-diagnostico/crear-caso-diagnostico.component';
import { CrearCasoPacienteComponent } from './crear-caso-components/crear-caso-paciente/crear-caso-paciente.component';
import { DetalleEstudianteComponent } from './detalle-estudiante/detalle-estudiante.component';
import { DetalleCasoComponent } from './detalle-caso-components/detalle-caso/detalle-caso.component';
import { DetalleCasoAntecedentesComponent } from './detalle-caso-components/detalle-caso-antecedentes/detalle-caso-antecedentes.component';
import { DetalleCasoDiagnosticoComponent } from './detalle-caso-components/detalle-caso-diagnostico/detalle-caso-diagnostico.component';
import { DetalleCasoEnfermedadActualComponent } from './detalle-caso-components/detalle-caso-enfermedad-actual/detalle-caso-enfermedad-actual.component';
import { DetalleCasoImpresionComponent } from './detalle-caso-components/detalle-caso-impresion/detalle-caso-impresion.component';
import { DetalleCasoPacienteComponent } from './detalle-caso-components/detalle-caso-paciente/detalle-caso-paciente.component';
import { DetalleCasoSistemasComponent } from './detalle-caso-components/detalle-caso-sistemas/detalle-caso-sistemas.component';
import { DetalleCasoTratamientoComponent } from './detalle-caso-components/detalle-caso-tratamiento/detalle-caso-tratamiento.component';
import { DetalleCasoExamenFisicoComponent } from './detalle-caso-components/detalle-caso-examen-fisico/detalle-caso-examen-fisico.component';
import { DetalleCasoAyudasComponent } from './detalle-caso-components/detalle-caso-ayudas/detalle-caso-ayudas.component';
import { DetalleSistemaComponent } from './detalle-sistema/detalle-sistema.component';
import { DetalleLaboratorioComponent } from './detalle-laboratorio/detalle-laboratorio.component';
import { DetalleEstudianteCasoComponent } from './detalle-estudiante-caso/detalle-estudiante-caso.component';
import { DetalleTratamientoComponent } from './detalle-tratamiento/detalle-tratamiento.component';
import { MsalGuard } from './msal.guard';
import { LoginComponent } from './login/login.component';
import { PanelComponent } from './panel/panel.component';

const routes: Routes =[
    { path: 'panel', component: PanelComponent, canActivate: [MsalGuard],
      children: [
        { path: 'dashboard', component: DashboardComponent, canActivate: [MsalGuard]},

        { path: 'estudiantes', component: EstudiantesComponent, canActivate: [MsalGuard]},
        { path: 'detalle-estudiante', component: DetalleEstudianteComponent, canActivate: [MsalGuard]},
        { path: 'detalle-estudiante-caso', component: DetalleEstudianteCasoComponent, canActivate: [MsalGuard]},
        
        { path: 'especialidades', component: EspecialidadesComponent, canActivate: [MsalGuard]},
    
        { path: 'casos', component: CasosComponent, canActivate: [MsalGuard]},
    
        { path: 'detalle-caso', component: DetalleCasoComponent, canActivate: [MsalGuard]},
        { path: 'detalle-caso-antecedentes', component: DetalleCasoAntecedentesComponent, canActivate: [MsalGuard]},
        { path: 'detalle-caso-enfermedad-actual', component: DetalleCasoEnfermedadActualComponent, canActivate: [MsalGuard]},
        { path: 'detalle-caso-sistemas', component: DetalleCasoSistemasComponent, canActivate: [MsalGuard]},
        { path: 'detalle-caso-examen-fisico', component: DetalleCasoExamenFisicoComponent, canActivate: [MsalGuard]},
        { path: 'detalle-caso-impresion', component: DetalleCasoImpresionComponent, canActivate: [MsalGuard]},
        { path: 'detalle-caso-ayudas', component: DetalleCasoAyudasComponent, canActivate: [MsalGuard]},
        { path: 'detalle-caso-tratamiento', component: DetalleCasoTratamientoComponent, canActivate: [MsalGuard]},
        { path: 'detalle-caso-diagnostico', component: DetalleCasoDiagnosticoComponent, canActivate: [MsalGuard]},
        { path: 'detalle-caso-paciente', component: DetalleCasoPacienteComponent, canActivate: [MsalGuard]},
    
        { path: 'crear-caso', component: CrearCasoComponent, canActivate: [MsalGuard]},
        { path: 'crear-caso-antecedentes', component: CrearCasoAntecedentesComponent, canActivate: [MsalGuard]},
        { path: 'crear-caso-enfermedad-actual', component: CrearCasoEnfermedadActualComponent, canActivate: [MsalGuard]},
        { path: 'crear-caso-sistemas', component: CrearCasoSistemasComponent, canActivate: [MsalGuard]},
        { path: 'crear-caso-examen-fisico', component: CrearCasoExamenFisicoComponent, canActivate: [MsalGuard]},
        { path: 'crear-caso-impresion', component: CrearCasoImpresionComponent, canActivate: [MsalGuard]},
        { path: 'crear-caso-ayudas', component: CrearCasoAyudasComponent, canActivate: [MsalGuard]},
        { path: 'crear-caso-tratamiento', component: CrearCasoTratamientoComponent, canActivate: [MsalGuard]},
        { path: 'crear-caso-diagnostico', component: CrearCasoDiagnosticoComponent, canActivate: [MsalGuard]},
        { path: 'crear-caso-paciente', component: CrearCasoPacienteComponent, canActivate: [MsalGuard]},
    
        { path: 'sistemas', component: SistemasComponent, canActivate: [MsalGuard]},
        { path: 'detalle-sistema', component: DetalleSistemaComponent, canActivate: [MsalGuard]},
        
        { path: 'laboratorio', component: LaboratorioComponent, canActivate: [MsalGuard]},
        { path: 'detalle-laboratorio', component: DetalleLaboratorioComponent, canActivate: [MsalGuard]},
        
        { path: 'tratamiento', component: TratamientoComponent, canActivate: [MsalGuard]},
        { path: 'detalle-tratamiento', component: DetalleTratamientoComponent, canActivate: [MsalGuard]}
      ]
    },

    { path: '', redirectTo: 'login', pathMatch: 'full' },

    { path: 'login', component: LoginComponent}
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
