import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RdbEditarCasoService } from 'app/services/firebase/rdb-editar-caso.service';

@Component({
  selector: 'app-detalle-caso-ayudas',
  templateUrl: './detalle-caso-ayudas.component.html',
  styleUrls: ['./detalle-caso-ayudas.component.css']
})
export class DetalleCasoAyudasComponent implements OnInit {

  renderLabs = true;
  renderImgs = false;
  renderOtras = false;

  alert_archivo = false;

  supported_imgs: string[] = ["apng", "avif", "gif", "jpg", "jpeg", "jfif", "pjpeg", "pjp",
  "png", "svg", "webp"];
  supported_audio: string[] = ["mp3", "wav"];

  navegarALabs() {
    this.renderLabs = true;
    this.renderImgs = false;
    this.renderOtras = false;

    this.alert_archivo = false;

    this.guardarCambios();
  }

  navegarAImgs() {
    this.renderLabs = false;
    this.renderImgs = true;
    this.renderOtras = false;

    this.alert_archivo = false;

    this.guardarCambios();
  }

  navegarAOtras() {
    this.renderLabs = false;
    this.renderImgs = false;
    this.renderOtras = true;

    this.alert_archivo = false;

    this.guardarCambios();
  }

  constructor(private firebase_rdb: RdbEditarCasoService, private router: Router) { }

  //Laboratorios==============================================================================
  
  //Selección actual
  laboratorios_tipo_actual: string = "";
  laboratorios_lab_actual: string = "";
  laboratorios_pregs_actuales: string[] = [];
  laboratorios_resp_actual: string = "";
  laboratorios_adjunto_actual: File = null;
  laboratorios_pt_actual: string = "";

  alert_incompleto = false;
  alert_repetido = false;

  //Laboratorios agregados
  laboratorios_nombres: string[] = [];
  laboratorios_resps: string[] = [];
  laboratorios_adjuntos: File[] = [];
  laboratorios_pts: string[] = [];

  laboratorios_adjuntos_str: string[] = [];
  laboratorios_cambiar: boolean[] = [];

  laboratorios_nombres_borrar: string[] = [];

  seleccionarTipoLab(tipo_lab: string) {
    this.laboratorios_tipo_actual = tipo_lab;
    this.laboratorios_lab_actual = "";
    this.laboratorios_pregs_actuales = [];

    this.firebase_rdb.getLabs(tipo_lab.toLowerCase()).toPromise().then(
      data => {
        for (let key in data) {
          if (data.hasOwnProperty(key) && data[key] != "No es necesario") {
            this.laboratorios_pregs_actuales.push(data[key]);
          }
        }
      }
    );
  }

  seleccionarLab(lab: string, index: number) {
    this.laboratorios_lab_actual = lab;
  }

  handleFileLabInput(file: File) {
    this.alert_archivo = false;
    if (file != null) {
      var file_split: string[] = file.name.split(".");
      var file_end: string = file_split[file_split.length - 1].trim().toLowerCase();
      if (this.supported_imgs.includes(file_end) || this.supported_audio.includes(file_end)) {
        this.laboratorios_adjunto_actual = file;
      }
      else {
        this.alert_archivo = true;
      }
    }
    else {
      this.laboratorios_adjunto_actual = null;
    }
  }

  cntLabsAgregados(): number {
    var ans = 0;

    this.laboratorios_nombres.forEach(
      e => {
        if (e != null && e != "") {
          ans++;
        }
      }
    );

    return ans;
  }

  agregarLab() {
    this.alert_incompleto = false;
    this.alert_repetido = false;

    //Si todos los campos han sido llenados
    if (this.laboratorios_lab_actual != "" && this.laboratorios_pt_actual != ""
    && (this.laboratorios_resp_actual != "" || this.laboratorios_adjunto_actual != null)) {      
      //Si el laboratorio ya tiene respuesta
      if (this.laboratorios_nombres.indexOf(this.laboratorios_lab_actual) != -1) {
        this.alert_repetido = true;
      }
      //Agregar el laboratorio con su respuesta a la lista
      else {
        this.laboratorios_nombres.push(this.laboratorios_lab_actual);
        this.laboratorios_pts.push(this.laboratorios_pt_actual);
        this.laboratorios_resps.push(this.laboratorios_resp_actual);
        this.laboratorios_adjuntos.push(this.laboratorios_adjunto_actual);

        this.laboratorios_adjuntos_str.push(null);
        this.laboratorios_cambiar.push(true);
      }
    }
    else {
      this.alert_incompleto = true;
    }
  }

  eliminarLab(i: number) {
    this.laboratorios_nombres_borrar.push(this.laboratorios_nombres[i]);

    this.laboratorios_nombres[i] = null;
    this.laboratorios_resps[i] = null;
    this.laboratorios_adjuntos[i] = null;
    this.laboratorios_pts[i] = null;

    this.laboratorios_cambiar[i] = true;
  }

  //Entra el tipo de puntaje del laboratorio y devuelve si se debe mostrar en el panel como
  //"Correcto" u "Omitible". Si se ve "Incorrecto", ocurrió un error al resolver el puntaje
  renderCorrectoOmitible(tipo_lab: string): string{
    if (tipo_lab == "positivo")
      return "Correcto";
    else if (tipo_lab == "omitible")
      return "Omitible";
    else
      return "Incorrecto";
  }

  //Imágenes diagnósticas=====================================================================

  //Selección actual
  imgs_tipo: string = "";
  imgs_ubicacion: string = "";
  imgs_lateralidad: string = "";
  imgs_proyeccion: string = "";
  imgs_contraste: string = "";
  imgs_puntuacion: string = "";
  imgs_adjunta_actual: File = null;

  alert_img_repetida = false;
  alert_img_incompleta = false;

  //Imágenes diagnósticas agregadas
  imgs_tipos: string[] = [];
  imgs_ubicaciones: string[] = [];
  imgs_lateralidades: string[] = [];
  imgs_proyecciones: string[] = [];
  imgs_contrastes: string[] = [];
  
  imagenes_diagnosticas: string[] = [];

  imgs_puntuaciones: string[] = [];
  imgs_adjuntos: File[] = [];

  imgs_adjuntos_str: string[] = [];
  imgs_cambiar: boolean[] = [];

  imgs_nombres_borrar: string[] = [];


  /**
   * Entra por parámetro el tipo de puntaje del laboratorio y devuelve si se debe mostrar en el panel como
   * "Correcto" u "Omitible". Si se ve "Incorrecto", ocurrió un error al resolver el puntaje
   * @param tipo_img La puntuación a revisar: "positivo" u "omitible"
   * @returns "Correcto" u "Omitible" si entra "positivo" u "omitible" por parámetro. O "Incorrecta" si entró
   * un parámetro no esperado
   */
  renderImgsCorrectoOmitible(tipo_img: string): string{
    if (tipo_img == "positivo")
      return "Correcta";
    else if (tipo_img == "omitible")
      return "Omitible";
    else
      return "Incorrecta";
  }

  handleFileImgInput(file: File) {
    this.alert_archivo = false;
    if (file != null) {
      var file_split: string[] = file.name.split(".");
      var file_end: string = file_split[file_split.length - 1].trim().toLowerCase();
      if (this.supported_imgs.includes(file_end) || this.supported_audio.includes(file_end)) {
        this.imgs_adjunta_actual = file;
      }
      else {
        this.alert_archivo = true;
      }
    }
    else {
      this.imgs_adjunta_actual = null;
    }
  }

  agregarImg() {
    this.alert_img_incompleta = false;
    this.alert_img_repetida = false;

    //Revisa que todos los campos hayan sido llenados
    if (this.imgs_tipo != "" && this.imgs_ubicacion != "" && this.imgs_lateralidad != ""
    && this.imgs_proyeccion != "" && this.imgs_contraste != "" && this.imgs_puntuacion != ""
    && this.imgs_adjunta_actual != null) {
      var img_aux = this.imgs_tipo + " - " + this.imgs_ubicacion + " - " + this.imgs_lateralidad
      + " - " + this.imgs_proyeccion + " - " + this.imgs_contraste;

      //Revisa que la imagen diagnóstica no haya sido agregada antes
      if (this.imagenes_diagnosticas.indexOf(img_aux) == -1) {
        this.imagenes_diagnosticas.push(img_aux);

        this.imgs_tipos.push(this.imgs_tipo);
        this.imgs_ubicaciones.push(this.imgs_ubicacion);
        this.imgs_lateralidades.push(this.imgs_lateralidad);
        this.imgs_proyecciones.push(this.imgs_proyeccion);
        this.imgs_contrastes.push(this.imgs_contraste);
        this.imgs_puntuaciones.push(this.imgs_puntuacion);
        this.imgs_adjuntos.push(this.imgs_adjunta_actual);

        this.imgs_adjuntos_str.push(null);
        this.imgs_cambiar.push(true);
      }
      else {
        //Lanza una alerta si la imagen diagnóstica ya está registrada
        this.alert_img_repetida = true;
      }
    }
    else {
      //Lanza una alerta si falta algún campo por llenar
      this.alert_img_incompleta = true;
    }
  }

  cntImgsAgregadas(): number {
    var ans = 0;

    this.imagenes_diagnosticas.forEach(
      e => {
        if (e != null) {
          ans++;
        }
      }
    );

    return ans;
  }

  eliminarImg(i:number) {
    this.imgs_nombres_borrar.push(this.imagenes_diagnosticas[i]);
    this.imgs_cambiar[i] = true;

    this.imgs_tipos[i] = null;
    this.imgs_ubicaciones[i] = null;
    this.imgs_lateralidades[i] = null;
    this.imgs_proyecciones[i] = null;
    this.imgs_contrastes[i] = null;
    this.imagenes_diagnosticas[i] = null;
    this.imgs_puntuaciones[i] = null;
    this.imgs_adjuntos[i] = null;
  }

  //Otras ayudas==============================================================================
  
  //Selección actual
  otras_lab_actual: string = "";
  otras_pregs: string[] = [];
  otras_resp_actual: string = "";
  otras_adjunto_actual: File = null;
  otras_pt_actual: string = "";

  alert_otras_incompleto = false;
  alert_otras_repetido = false;

  //Laboratorios agregados
  otras_nombres: string[] = [];
  otras_resps: string[] = [];
  otras_adjuntos: File[] = [];
  otras_pts: string[] = [];

  otras_adjuntos_str: string[] = [];
  otras_cambiar: boolean[] = [];

  otras_nombres_borrar: string[] = [];

  handleFileOtrasInput(file: File) {
    this.alert_archivo = false;
    if (file != null) {
      var file_split: string[] = file.name.split(".");
      var file_end: string = file_split[file_split.length - 1].trim().toLowerCase();
      if (this.supported_imgs.includes(file_end) || this.supported_audio.includes(file_end)) {
        this.otras_adjunto_actual = file;
      }
      else {
        this.alert_archivo = true;
      }
    }
    else {
      this.otras_adjunto_actual = null;
    }
  }

  agregarOtra() {
    this.alert_otras_incompleto = false;
    this.alert_otras_repetido = false;

    if (this.otras_lab_actual != "" && this.otras_pt_actual != "" 
    && (this.otras_adjunto_actual != null || this.otras_resp_actual != "")) {
      if (this.otras_nombres.indexOf(this.otras_lab_actual) == -1) {
        this.otras_nombres.push(this.otras_lab_actual);
        this.otras_resps.push(this.otras_resp_actual);
        this.otras_adjuntos.push(this.otras_adjunto_actual);
        this.otras_pts.push(this.otras_pt_actual);

        this.otras_adjuntos_str.push(null);
        this.otras_cambiar.push(true);
      }
      else {
        this.alert_otras_repetido = true;
      }
    }
    else {
      this.alert_otras_incompleto = true;
    }
  }

  cntOtras(): number {
    var ans = 0;

    this.otras_nombres.forEach(
      e => {
        if (e != null && e != "") {
          ans++;
        }
      }
    );

    return ans;
  }

  eliminarOtra(i: number) {
    this.otras_nombres_borrar.push(this.otras_nombres[i]);
    this.otras_cambiar[i] = true;

    this.otras_nombres[i] = null;
    this.otras_resps[i] = null;
    this.otras_adjuntos[i] = null;
    this.otras_pts[i] = null;
  }

  //Iniciar y finalizar panel=================================================================
  ngOnInit() {
    if (this.firebase_rdb.nombre_caso_seleccionado == null ||
      this.firebase_rdb.especialidad_caso_seleccionado == null ||
      this.firebase_rdb.id_caso_seleccionado == null ||
      this.firebase_rdb.json_caso_seleccionado == null) {
      
      this.firebase_rdb.clearSeleccionado();
      this.router.navigate(["/panel/casos"]);
    }

    //Laboratorios
    this.laboratorios_adjuntos = this.firebase_rdb.laboratorios_adjuntos;
    this.laboratorios_nombres = this.firebase_rdb.laboratorios_nombres;
    this.laboratorios_resps = this.firebase_rdb.laboratorios_resps;
    this.laboratorios_pts = this.firebase_rdb.laboratorios_pts;

    this.laboratorios_adjuntos_str = this.firebase_rdb.laboratorios_adjuntos_str;
    this.laboratorios_cambiar = this.firebase_rdb.laboratorios_cambiar;

    this.laboratorios_nombres_borrar = this.firebase_rdb.laboratorios_nombres_borrar;

    //Imágenes diagnósticas
    var img_aux: string[] = [];
    
    this.imgs_tipos = [];
    this.imgs_ubicaciones = [];
    this.imgs_lateralidades = [];
    this.imgs_proyecciones = [];
    this.imgs_contrastes = [];

    this.imagenes_diagnosticas = this.firebase_rdb.imagenes_diagnosticas;
    this.firebase_rdb.imagenes_diagnosticas.map(
      e => {
        if (e != null) {
          img_aux = e.split(" - ");
          
          this.imgs_tipos.push(img_aux[0]);
          this.imgs_ubicaciones.push(img_aux[1]);
          this.imgs_lateralidades.push(img_aux[2]);
          this.imgs_proyecciones.push(img_aux[3]);
          this.imgs_contrastes.push(img_aux[4]);
        }
        else {
          this.imgs_tipos.push(null);
          this.imgs_ubicaciones.push(null);
          this.imgs_lateralidades.push(null);
          this.imgs_proyecciones.push(null);
          this.imgs_contrastes.push(null);
        }
      }
    );

    this.imgs_adjuntos = this.firebase_rdb.imgs_adjuntos;
    this.imgs_puntuaciones = this.firebase_rdb.imgs_puntuaciones;

    this.imgs_adjuntos_str = this.firebase_rdb.imgs_adjuntos_str;
    this.imgs_cambiar = this.firebase_rdb.imgs_cambiar;
    
    this.imgs_nombres_borrar = this.firebase_rdb.imgs_nombres_borrar;

    //Otras ayudas
    this.otras_adjuntos = this.firebase_rdb.otras_adjuntos;
    this.otras_nombres = this.firebase_rdb.otras_nombres;
    this.otras_resps = this.firebase_rdb.otras_resps;
    this.otras_pts = this.firebase_rdb.otras_pts;
    
    this.otras_adjuntos_str = this.firebase_rdb.otras_adjuntos_str;
    this.otras_cambiar = this.firebase_rdb.otras_cambiar;

    this.otras_nombres_borrar = this.firebase_rdb.otras_nombres_borrar;
    
    if (this.firebase_rdb.otras_pregs.length == 0) {
      this.firebase_rdb.getOtrasAyudas().toPromise().then(
        data => {
          for (let key in data) {
            if (data.hasOwnProperty(key) && data[key] != "No es necesario") {
              this.otras_pregs.push(data[key]);
            }
          }
        }
      );

      this.firebase_rdb.otras_pregs = this.otras_pregs;
    }
    else {
      this.otras_pregs = this.firebase_rdb.otras_pregs;
    }
  }

  guardarCambios() {
    //Laboratorios
    this.firebase_rdb.laboratorios_nombres = this.laboratorios_nombres;
    this.firebase_rdb.laboratorios_resps = this.laboratorios_resps;
    this.firebase_rdb.laboratorios_adjuntos = this.laboratorios_adjuntos;
    this.firebase_rdb.laboratorios_pts = this.laboratorios_pts;

    this.firebase_rdb.laboratorios_cambiar = this.laboratorios_cambiar;
    this.firebase_rdb.laboratorios_adjuntos_str = this.laboratorios_adjuntos_str;
    
    this.firebase_rdb.laboratorios_nombres_borrar = this.laboratorios_nombres_borrar;

    //Imágenes diagnósticas
    this.firebase_rdb.imagenes_diagnosticas = this.imagenes_diagnosticas;
    this.firebase_rdb.imgs_puntuaciones = this.imgs_puntuaciones;
    this.firebase_rdb.imgs_adjuntos = this.imgs_adjuntos;

    this.firebase_rdb.imgs_adjuntos_str = this.firebase_rdb.imgs_adjuntos_str;
    this.firebase_rdb.imgs_cambiar = this.imgs_cambiar;

    this.firebase_rdb.imgs_nombres_borrar = this.imgs_nombres_borrar;

    //Otras ayudas
    this.firebase_rdb.otras_nombres = this.otras_nombres;
    this.firebase_rdb.otras_resps = this.otras_resps;
    this.firebase_rdb.otras_adjuntos = this.otras_adjuntos;
    this.firebase_rdb.otras_pts = this.otras_pts;

    this.firebase_rdb.otras_adjuntos_str = this.otras_adjuntos_str;
    this.firebase_rdb.otras_cambiar = this.otras_cambiar;

    this.firebase_rdb.otras_nombres_borrar = this.otras_nombres_borrar;
  }

}
