import { Component, OnInit } from '@angular/core';
import { RdbService } from 'app/services/firebase/rdb.service';
import { LocalStorageService } from 'app/services/storage/local-storage.service';

declare const $: any;

@Component({
  selector: 'app-crear-caso-diagnostico',
  templateUrl: './crear-caso-diagnostico.component.html',
  styleUrls: ['./crear-caso-diagnostico.component.css']
})
export class CrearCasoDiagnosticoComponent implements OnInit {

  constructor(private firebase_rdb: RdbService, private local_db: LocalStorageService) { }

  diagnosticos: string[] = [];
  lbl_diagnostico: string[] = [];
  razon: string = "";
  bibliografia: string[] = [];

  alert_repetido: boolean = false;

  setLblDiagnostico(i: number, lbl: string) {
    this.alert_repetido = false;
    if (lbl == "Diagnóstico final" && this.lbl_diagnostico.indexOf(lbl) != -1) {
      this.alert_repetido = true;
    }
    else {
      this.lbl_diagnostico[i] = lbl;
    }
  }

  ngOnInit() {
    this.local_db.getItem<string[]>("diagnosticos").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.diagnosticos = resp;
          this.diagnosticos = this.firebase_rdb.diagnosticos;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando diagnosticos");
      }
    );

    this.local_db.getItem<string[]>("lbl_diagnostico").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.lbl_diagnostico = resp;
          this.lbl_diagnostico = this.firebase_rdb.lbl_diagnostico;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando lbl_diagnostico");
      }
    );
    
    this.local_db.getItem<string>("razon").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.razon = resp;
          this.razon = this.firebase_rdb.razon;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando razon");
      }
    );
    
    this.local_db.getItem<string[]>("bibliografia").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.bibliografia = resp;
          this.bibliografia = this.firebase_rdb.bibliografia;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando bibliografia");
      }
    );
  }

  guardarCambios() {
    this.firebase_rdb.diagnosticos = this.diagnosticos;
    this.firebase_rdb.lbl_diagnostico = this.lbl_diagnostico;
    this.firebase_rdb.razon = this.razon;
    this.firebase_rdb.bibliografia = this.bibliografia;
    
    this.local_db.setItem<string[]>("diagnosticos", this.diagnosticos);
    this.local_db.setItem<string[]>("lbl_diagnostico", this.lbl_diagnostico);
    this.local_db.setItem<string>("razon", this.razon);
    this.local_db.setItem<string[]>("bibliografia", this.bibliografia);
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
        return false;
    }
    return true;
  };

}
