import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AngularFireStorage, AngularFireStorageReference } from '@angular/fire/storage';
import { LocalStorageService } from '../storage/local-storage.service';

/**
 * Este servicio maneja la información del caso que está siendo construido
 */
@Injectable({
  providedIn: 'root'
})
export class RdbService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private local_db: LocalStorageService,
    private firebase_storage: AngularFireStorage
  ) {}

  urlFirebase = "https://medicina-uniandes-default-rtdb.firebaseio.com/";

  //Información general==================================================
  nombre_caso: string = "";
  especialidad: string = "";
  dificultad: string = "";
  escenario: string = "";
  modo: string = "";

  //Paciente=============================================================  
  //Datos personales
  nombre_paciente: string = "";
  genero: string = "";
  edad: string = "";
  peso: string = "";
  talla: string = "";
  motivo_consulta: string = "";
  embarazada: boolean = false;
  etnia: string = "";

  //Signos vitales
  frecuencia_cardiaca: string = "";
  frecuencia_respiratoria: string = "";
  saturacion: string = "";
  tension_arterial: string = "";
  VAS: string = "";
  temperatura: string = "";

  paciente_listo: boolean = false;

  //Anamnesis============================================================
  
  //Enfermedad actual----------------------------------------------------
  enfermedad_actual_resps: string[] = [];

  //Revisión por sistemas------------------------------------------------
  cardiovascular_resps: string[] = [];
  endocrino_resps: string[] = [];
  gastrointestinal_resps: string[] = [];
  genitourinario_resps: string[] = [];
  nervioso_resps: string[] = [];
  osteomuscular_resps: string[] = [];
  respiratorio_resps: string[] = [];
  
  cardiovascular_pregs = -1;
  endocrino_pregs = -1;
  gastrointestinal_pregs = -1;
  genitourinario_pregs = -1;
  nervioso_pregs = -1;
  osteomuscular_pregs = -1;
  respiratorio_pregs = -1;

  cardiovascular_tipo = "";
  endocrino_tipo = "";
  gastrointestinal_tipo = "";
  genitourinario_tipo = "";
  nervioso_tipo = "";
  osteomuscular_tipo = "";
  respiratorio_tipo = "";

  //Recupera las preguntas del sistema indicado desde Firebase y devuelve el objeto Observable
  getPregsSistema(sistema: string) {
    var urlSistema = this.urlFirebase + "preguntas/sistemas/" + sistema.toLowerCase() + "/.json";

    return this.http.get(urlSistema);
  }

  //Antecedentes---------------------------------------------------------
  antecedentes_resps: string[] = [];
  antecedentes_tipos: string[] = [];

  //Examen físico--------------------------------------------------------
  examen_fisico_resps: string[] = [];
  examen_fisico_recursos: File[] = [];
  examen_fisico_pregs: string[] = ["Estado general", "Cabeza y cuello", "Cardiovascular", "Respiratorio", "Abdomen",
                                  "Extremidades", "Neurológico", "Piel y anexos", "Otros"];
  examen_fisico_emociones: string[] = ["Ninguna", "Ninguna", "Ninguna", "Ninguna", "Ninguna", "Ninguna", "Ninguna",
                                      "Ninguna", "Ninguna"];
  
  dolor_cabeza: boolean = false;
  dolor_torax: boolean = false;
  dolor_abdomen: boolean = false;
  dolor_extremidades: boolean = false;

  //Impresión diagnóstica================================================
  diagnosticos: string[] = [];
  probabilidades: string[] = [];

  //Ayudas diagnósticas==================================================
  //Laboratorios---------------------------------------------------------
  laboratorios_nombres: string[] = [];
  laboratorios_resps: string[] = [];
  laboratorios_adjuntos: File[] = [];
  laboratorios_pts: string[] = [];

  //Recupera los laboratorios del tipo indicado desde Firebase y devuelve
  getLabs(tipo_lab: string) {
    var urlLabs = this.urlFirebase + "preguntas/ayudas%20diagnósticas/laboratorios/" 
                  + tipo_lab.trim().toLowerCase().replace(" ", "%20") + "/.json";

    return this.http.get(urlLabs);
  }

  //Imágenes diagnósticas------------------------------------------------
  imagenes_diagnosticas: string[] = [];
  imgs_puntuaciones: string[] = [];
  imgs_adjuntos: File[] = [];

  //Otras ayudas---------------------------------------------------------
  otras_nombres: string[] = [];
  otras_resps: string[] = [];
  otras_adjuntos: File[] = [];
  otras_pts: string[] = [];
  otras_pregs: string[] = [];

  //Recupera los laboratorios clasificados bajo "Otras ayudas"
  getOtrasAyudas() {
    var urlOtras = this.urlFirebase + "preguntas/ayudas%20diagnósticas/otras%20ayudas/.json";

    return this.http.get(urlOtras);
  }

  //Tratamientos=========================================================
  dieta: string[] = [];
  dieta_pts: string[] = [];

  soporte_hidrico: string[] = [];
  soporte_hidrico_pts: string[] = [];

  medicamentos: string[] = [];
  medicamentos_pts: string[] = [];

  oxigeno: string[] = [];
  oxigeno_pts: string[] = [];

  medidas_generales: string[] = [];
  medidas_generales_pts: string[] = [];

  //Recupera la lista de los tratamientos del tipo indicado
  getTratamientos(pTratamiento: string) {
    var urlTratamiento = this.urlFirebase + "preguntas/tratamiento/" 
    + pTratamiento.trim().toLowerCase().replace(" ", "%20") + "/.json";

    return this.http.get(urlTratamiento);
  }

  //Diagnóstico final====================================================
  //Los diagnósticos se encuentran en la sección "Impresión diagnóstica"
  lbl_diagnostico: string[] = [];
  razon: string = "";
  bibliografia: string[] = [];

  //Recupera todos los casos de la especialidad indicada
  getCasosEspecialdiad(pEspecialidad: string) {
    var urlCasos = this.urlFirebase + "especialidades/" + pEspecialidad + "/.json";

    return this.http.get(urlCasos);
  }

  getAllCasos() {
    var urlCasos = this.urlFirebase + "especialidades/.json";

    return this.http.get(urlCasos);
  }

  deleteCasoDeEspecialidad(pTitulo: string, pEspecialidad: string) {
    var key_caso_eliminar: string = "";
    this.getCasosEspecialdiad(pEspecialidad).toPromise().then(
      data => {
        for(let key in data) {
          if(data.hasOwnProperty(key) && data[key]["título"] == pTitulo) {
            key_caso_eliminar = key;
            break;
          }
        }
        var urlCaso = this.urlFirebase + "especialidades/" + pEspecialidad + "/" + key_caso_eliminar + "/.json";
        this.http.delete(urlCaso).toPromise().then().catch(
          err => {
            console.log("this.http.delete(urlCaso) lanzó error: " + err);
          }
        );
      }
    );
    //Puede fallar si el caso no tiene ningún recurso adjunto
    var refEstudio = "Estudio/" + pEspecialidad + "/" + pTitulo + "/";
    var refEvaluacion = "Evaluación/" + pEspecialidad + "/" + pTitulo + "/";
    
    var estAyudas: AngularFireStorageReference = this.firebase_storage.ref(refEstudio + "ayudas diagnósticas/");
    var estExamen: AngularFireStorageReference = this.firebase_storage.ref(refEstudio + "examen físico/");

    var evaAyudas: AngularFireStorageReference = this.firebase_storage.ref(refEvaluacion + "ayudas diagnósticas/");
    var evaExamen: AngularFireStorageReference = this.firebase_storage.ref(refEvaluacion + "examen físico/");

    //Los exámenes físicos están almacenados en una profundidad mayor a las ayudas diagnósticas
    this.borrarArchivosDeCarpeta(estAyudas, false);
    this.borrarArchivosDeCarpeta(estExamen, true);
    this.borrarArchivosDeCarpeta(evaAyudas, false);
    this.borrarArchivosDeCarpeta(evaExamen, true);
  }

  //Recibe una referencia a un directorio de Firebase Storage, lo recorre, y borra cada archivo encontrado
  //"esCarpeta" indica si se deben explorar los contenidos de la referencia encontrada
  borrarArchivosDeCarpeta(carpetaPadre: AngularFireStorageReference, esCarpeta: boolean) {
    carpetaPadre.listAll().toPromise().then(
      resp => {
        if (esCarpeta) {
          resp.prefixes.forEach(
            prefixRef => {
              prefixRef.listAll().then(
                resp2_1 => {
                  resp2_1.items.forEach(
                    itemRef2 => {
                      itemRef2.delete().then(
                        resp3 => {}
                      ).catch(
                        err3 => {
                          console.log("Error en borrarArchivosDeCarpeta() 3: " + err3);
                        }
                      );
                    }
                  );
                }
              ).catch(
                err2_1 => {
                  console.log("Error en borrarArchivosDeCarpeta() 2_1: " + err2_1);
                }
              );
            }
          );
        }
        else {
          resp.items.forEach(
            itemRef => {
              itemRef.delete().then().catch(
                err2_2 => {
                  console.log("Error en borrarArchivosDeCarpeta() 2_2: " + err2_2)
                }
              );
            }
          );
        }
        
      }
    ).catch(
      err => {
        console.log("Error en borrarArchivosDeCarpeta(): " + err);
      }
    );
  }

  //Armar el JSON del caso y subirlo a Firebase==========================
  json_caso: any = {};
  str_caso: string = "";

  armarJSONyGuardar() {
    this.nombre_caso = this.nombre_caso.split(/  +/g).join(' ');

    this.str_caso = 
    "{"
      +
      "\"antecedentes\": {" +
        this.antecedentesJSON() +
      "},"
      +
      "\"apariencia\": \"" + this.apariencia() + "\","
      +
      "\"ayudas diagnósticas\": {"
        +
        "\"imágenes diagnósticas\": {" +
          this.imagenesDiagnosticasJSON() +
        "},"
        +
        "\"laboratorios\": {" +
          this.laboratoriosJSON() +
        "},"
        +
        "\"otras ayudas\": {" +
          this.otrasAyudasJSON() +
        "}" +
      "},"
      +
      "\"bibliografía\": {" +
        this.bibliografiaJSON() +
      "},"
      +
      "\"diagnóstico final\": {" +
        this.diagnosticoFinalJSON() +
      "},"
      +
      "\"dificultad\": " + "\"" + this.dificultad + "\","
      +
      "\"dolor\": {" +
        this.dolorJSON() +
      "},"
      +
      "\"edad\": " + "\"" + this.edad + "\","
      +
      "\"enfermedad actual\": {" +
        this.enfermedadActualJSON() +
      "},"
      +
      "\"escenario\": \"" + this.escenario + "\","
      +
      "\"etnia\": \"" + this.etnia + "\","
      +
      "\"examen físico\": {" +
        this.examenFisicoJSON() +
      "},"
      +
      "\"género\": \"" + this.genero + "\","
      +
      "\"impresión diagnóstica\": {" +
        this.impresionDiagnosticaJSON() +
      "},"
      +
      "\"modo\": \"" + this.modo + "\","
      +
      "\"motivo de consulta\": \"" + this.motivo_consulta.split("\"").join("") + "\","
      +
      "\"motivo del diagnóstico\": \"" + this.razon.split("\"").join("") + "\","
      +
      "\"nombre\": \"" + this.nombre_paciente + "\","
      +
      "\"sistemas\": {" +
        this.sistemasJSON() +
      "},"
      +
      "\"tipo puntuación\": {" +
        this.tipoPuntuacionJSON() +
      "},"
      +
      "\"título\": \"" + this.nombre_caso.split("\"").join("") + "\""
      +
    "}";

    //TODO: Eliminar Debug
    this.str_caso = this.str_caso.split("\n").join("\\n")
                                  .split("\r").join("\\r")
                                  .split("\t").join("\\t")
                                  //.split("\"").join("")
                                  //.split("\'").join("")
                                  .split(/  +/g).join(' ');
    console.log(this.str_caso);
    this.json_caso = JSON.parse(this.str_caso);
    console.log(this.json_caso);

    //Buscar la primera posición vacía dentro de la especialidad indicada
    var cnt_casos = 0;
    this.getCasosEspecialdiad(this.especialidad).toPromise().then(
      data => {
        for (let key in data) {
          if (data["caso_" + cnt_casos.toString()] != null) {
            cnt_casos++;
          }
          else {
            break;
          }
        }
        //Subir la información del caso a Firebase
        var urlPut = this.urlFirebase + "/especialidades/" + this.especialidad + "/caso_" + cnt_casos + "/.json";
        this.http.put(urlPut, this.json_caso).toPromise().then(
          () => {
            this.local_db.clearLocalStorage();
            this.router.navigate(["/panel/casos"]);
          }
        );
      }
    );

    //Subir los archivos a firebase Storage
    var refEstudio = "Estudio/" + this.especialidad + "/" + this.nombre_caso + "/";
    var refEvaluacion = "Evaluación/" + this.especialidad + "/" + this.nombre_caso + "/";

    var refAdjEst: AngularFireStorageReference;
    var refAdjEva: AngularFireStorageReference;
    var file_split: string[];
    var nombre_aux: string;

    //Ayudas diagnósticas
    this.imgs_adjuntos.forEach(
      (e,i) => {
        if (e != null) {
          file_split = e.name.split(".");
          nombre_aux = this.imagenes_diagnosticas[i] + "." + file_split[file_split.length - 1];
          
          if (this.modo == "Evaluación") {
            refAdjEva = this.firebase_storage.ref(refEvaluacion + "ayudas diagnósticas/" + nombre_aux);
            refAdjEva.put(e);
          }
          else if (this.modo == "Estudio") {
            refAdjEst = this.firebase_storage.ref(refEstudio + "ayudas diagnósticas/" + nombre_aux);
            refAdjEst.put(e);
          }

        }
      }
    );
    this.laboratorios_adjuntos.forEach(
      (e,i) => {
        if (e != null) {
          file_split = e.name.split(".");
          nombre_aux = this.laboratorios_nombres[i] + "." + file_split[file_split.length - 1];
          
          if (this.modo == "Evaluación") {
            refAdjEva = this.firebase_storage.ref(refEvaluacion + "ayudas diagnósticas/" + nombre_aux);
            refAdjEva.put(e);
          }
          else if (this.modo == "Estudio") {
            refAdjEst = this.firebase_storage.ref(refEstudio + "ayudas diagnósticas/" + nombre_aux);
            refAdjEst.put(e);
          }
        }
      }
    );
    this.otras_adjuntos.forEach(
      (e,i) => {
        if (e != null) {
          file_split = e.name.split(".");
          nombre_aux = this.otras_nombres[i] + "." + file_split[file_split.length - 1];
          
          if (this.modo == "Evaluación") {
            refAdjEva = this.firebase_storage.ref(refEvaluacion + "ayudas diagnósticas/" + nombre_aux);
            refAdjEva.put(e);
          }
          else if (this.modo == "Estudio") {
            refAdjEst = this.firebase_storage.ref(refEstudio + "ayudas diagnósticas/" + nombre_aux);
            refAdjEst.put(e);
          }
        }
      }
    );

    //Examen físico
    this.examen_fisico_recursos.forEach(
      (e,i) => {
        if (e != null) {

          if (this.modo == "Evaluación") {
            refAdjEva = this.firebase_storage.ref(refEvaluacion + "examen físico/" + this.examen_fisico_pregs[i] + "/" + e.name);
            refAdjEva.put(e);
          }
          else if (this.modo == "Estudio") {
            refAdjEst = this.firebase_storage.ref(refEstudio + "examen físico/" + this.examen_fisico_pregs[i] + "/" + e.name);
            refAdjEst.put(e);
          }

        }
      }
    );
  }

  cleanString(input: string): string {
    return input
      .split("\"").join("")
      .split("\'").join("")
      .split("\n").join("\\n")
      .split("\r").join("\\r")
      .split("\t").join("\\t")
      .split(/  +/g).join(' ');
  }

  //Funciones auxiliares para agregar cada sección al JSON del caso
  antecedentesJSON(): string {
    // var ans_obj = {
    //   r_0: this.cleanString(this.antecedentes_resps[0]),
    //   r_1: this.cleanString(this.antecedentes_resps[1]),
    //   r_2: this.cleanString(this.antecedentes_resps[2]),
    //   r_3: this.cleanString(this.antecedentes_resps[3]),
    //   r_4: this.cleanString(this.antecedentes_resps[4]),
    //   r_5: this.cleanString(this.antecedentes_resps[5]),
    //   r_6: this.cleanString(this.antecedentes_resps[6]),
    //   r_7: this.cleanString(this.antecedentes_resps[7]),
    //   r_8: this.cleanString(this.antecedentes_resps[8]),
    // }

    
    return ("\"r_0\": \"" + this.cleanString(this.antecedentes_resps[0]) + "\"," +
    "\"r_1\": \"" + this.cleanString(this.antecedentes_resps[1]) + "\"," +
    "\"r_2\": \"" + this.cleanString(this.antecedentes_resps[2]) + "\"," +
    "\"r_3\": \"" + this.cleanString(this.antecedentes_resps[3]) + "\"," +
    "\"r_4\": \"" + this.cleanString(this.antecedentes_resps[4]) + "\"," +
    "\"r_5\": \"" + this.cleanString(this.antecedentes_resps[5]) + "\"," +
    "\"r_6\": \"" + this.cleanString(this.antecedentes_resps[6]) + "\"," +
    "\"r_7\": \"" + this.cleanString(this.antecedentes_resps[7]) + "\"," +
    "\"r_8\": \"" + this.cleanString(this.antecedentes_resps[8]) + "\"");
  }

  apariencia(): string {
    var ans = "";

    var edad_split: string[] = this.edad.split(" ")
    var edad_aux: number = parseInt(edad_split[0].trim());
    var edad_lbl: string = edad_split[1].trim();

    if (edad_lbl.startsWith("año")) {
      if (edad_aux <= 3) {
        ans = "Lactante";
      }
      else if (edad_aux > 3 && edad_aux <= 11) {
        ans = "Escolar";
      }
      else if (edad_aux > 11 && edad_aux <= 15) {
        ans = "Adolescente";
        if ((this.genero.startsWith("Mujer") || this.genero.endsWith("transgénero")) && this.embarazada) {
          ans = "Embarazada";
        }
      }
      else if (edad_aux > 15 && edad_aux <= 25) {
        ans = "Joven";
        if ((this.genero.startsWith("Mujer") || this.genero.endsWith("transgénero")) && this.embarazada) {
          ans = "Embarazada";
        }
      }
      else if (edad_aux > 25 && edad_aux <= 50) {
        ans = "Adulto";
        if ((this.genero.startsWith("Mujer") || this.genero.endsWith("transgénero")) && this.embarazada) {
          ans = "Embarazada";
        }
      }
      else if (edad_aux > 50) {
        ans = "Anciano";
      }
    }
    else if (edad_lbl.startsWith("mes")) {
      ans = "Lactante";
    }
    else if (edad_lbl.startsWith("semana")) {
      ans = "Recién nacido";
    }

    return ans;
  }

  bibliografiaJSON(): string {
    // var ans_obj: any = {
    //   b_1: this.cleanString(this.bibliografia[0]),
    //   b_2: this.cleanString(this.bibliografia[1]),
    //   b_3: this.cleanString(this.bibliografia[2]),
    //   b_4: this.cleanString(this.bibliografia[3]),
    //   b_5: this.cleanString(this.bibliografia[4])
    // }
 
    return ("\"b_1\": \"" + this.cleanString(this.bibliografia[0]) + "\"," +
    "\"b_2\": \"" + this.cleanString(this.bibliografia[1]) + "\"," +
    "\"b_3\": \"" + this.cleanString(this.bibliografia[2]) + "\"," +
    "\"b_4\": \"" + this.cleanString(this.bibliografia[3]) + "\"," +
    "\"b_5\": \"" + this.cleanString(this.bibliografia[4]) + "\"");
  }

  imagenesDiagnosticasJSON(): string {
    var ans = "";
    var ans_obj: any = {};

    var cnt_imgs = 0;
    this.imagenes_diagnosticas.forEach(
      e => {
        if (e != null && e.trim() != "") {
          cnt_imgs ++;
        }
      }
    );

    if (cnt_imgs != 0) {
      var img_aux: string = "";
      var file_aux: File = null;
      var file_aux_split: string[] = [];
      for(var i = 0; i < cnt_imgs; i++) {
        img_aux = this.imagenes_diagnosticas[i];
        file_aux = this.imgs_adjuntos[i];
        if (img_aux != null && img_aux.trim() != "" && file_aux != null) {
          file_aux_split = file_aux.name.split(".");
          ans_obj.img_aux = {
            adjunto: `${img_aux}.${file_aux_split[file_aux_split.length - 1]}`
          };
          ans += "\"" + img_aux + "\": { \"adjunto\": \"" + img_aux + "." + file_aux_split[file_aux_split.length - 1] + "\"}";
          if (i != cnt_imgs - 1) {
            ans += ","; //Se agrega la "," sólo si este no es el último elemento de la lista
          }
        }
        else {
          cnt_imgs++;
          //Si llegó a este punto es porque encontró una entrada vacía en el arreglo, por lo que se debe extender el bucle para encontrar la entrada
          //no-vacía que fue contada en cnt_imgs
        }
      }
    }
    else {
      ans_obj = {
        "No es necesario": true
      }
      ans = "\"No es necesario\": true";
    }

    return ans;
  }

  laboratoriosJSON(): string {
    var ans = "";

    var cnt_labs = 0;
    this.laboratorios_nombres.forEach(
      e => {
        if (e != null && e.trim() != "") {
          cnt_labs ++;
        }
      }
    );

    if (cnt_labs != 0) {
      var lab_aux: string = "";
      var lab_adj: File = null;
      var split_adj: string[] = [];
      var lab_resp: string = "";
      for (var i = 0; i < cnt_labs; i++) {
        lab_aux = this.laboratorios_nombres[i];
        lab_adj = this.laboratorios_adjuntos[i];
        lab_resp = this.cleanString(this.laboratorios_resps[i]);
        if (lab_aux != null && lab_aux.trim() != "") {
          ans += "\"" + lab_aux + "\": {";
          if (lab_adj != null) {
            ans += "\"adjunto\": \"";

            split_adj = lab_adj.name.split(".");
            ans += lab_aux + "." + split_adj[split_adj.length - 1] + "\"";
          }
          if (lab_resp != null && lab_resp.trim() != "") {
            if (lab_adj != null) {
              ans += ",";
              //Se pone "," sólo si se agregó un adjunto antes de esta respuesta
            }
            ans += "\"respuesta\": \"" + lab_resp + "\"";
          }
          ans += "}";
          if (i != cnt_labs - 1) {
            ans += ","; //Se agrega la "," sólo si este no es el último elemento de la lista
          }
        }
        else {
          cnt_labs++;
          //Si llegó a este punto es porque encontró una entrada vacía en el arreglo, por lo que se debe extender el bucle para encontrar la entrada
          //no-vacía que fue contada en cnt_labs
        }
      }
    }
    else {
      ans = "\"No es necesario\": true";
    }

    return ans;
  }

  otrasAyudasJSON(): string {
    var ans = "";

    var cnt_labs = 0;
    this.otras_nombres.forEach(
      e => {
        if (e != null && e.trim() != "") {
          cnt_labs ++;
        }
      }
    );

    if (cnt_labs != 0) {
      var lab_aux: string = "";
      var lab_adj: File = null;
      var split_adj: string[] = [];
      var lab_resp: string = "";
      for (var i = 0; i < cnt_labs; i++) {
        lab_aux = this.otras_nombres[i];
        lab_adj = this.otras_adjuntos[i];
        lab_resp = this.cleanString(this.otras_resps[i]);
        if (lab_aux != null && lab_aux.trim() != "") {
          ans += "\"" + lab_aux + "\": {";
          if (lab_adj != null) {
            ans += "\"adjunto\": \"";

            split_adj = lab_adj.name.split(".");
            ans += lab_aux + "." + split_adj[split_adj.length - 1] + "\"";
          }
          if (lab_resp != null && lab_resp.trim() != "") {
            if (lab_adj != null) {
              ans += ",";
              //Se pone "," sólo si se agregó un adjunto antes de esta respuesta
            }
            ans += "\"respuesta\": \"" + lab_resp + "\"";
          }
          ans += "}";
          if (i != cnt_labs - 1) {
            ans += ","; //Se agrega la "," sólo si este no es el último elemento de la lista
          }
        }
        else {
          cnt_labs++;
          //Si llegó a este punto es porque encontró una entrada vacía en el arreglo, por lo que se debe extender el bucle para encontrar la entrada
          //no-vacía que fue contada en cnt_labs
        }
      }
    }
    else {
      ans = "\"No es necesario\": true";
    }

    return ans;
  }

  diagnosticoFinalJSON(): string {
    //Hay una correspondencia 1-1 entre estas etiquetas y los diagnósticos en "Impresión diagnóstica"
    // var ans_obj: any = {
    //   f_1: this.lbl_diagnostico[0],
    //   f_2: this.lbl_diagnostico[1],
    //   f_3: this.lbl_diagnostico[2],
    //   f_4: this.lbl_diagnostico[3],
    //   f_5: this.lbl_diagnostico[4],
    // };
 
    return ("\"f_1\": \"" + this.lbl_diagnostico[0] + "\"," +
    "\"f_2\": \"" + this.lbl_diagnostico[1] + "\"," +
    "\"f_3\": \"" + this.lbl_diagnostico[2] + "\"," +
    "\"f_4\": \"" + this.lbl_diagnostico[3] + "\"," +
    "\"f_5\": \"" + this.lbl_diagnostico[4] + "\"");
  }

  dolorJSON(): string {
    // var ans_obj: any = {
    //   abdomen: this.dolor_abdomen,
    //   cabeza: this.dolor_cabeza,
    //   extremidades: this.dolor_extremidades,
    //   torax: this.dolor_torax
    // };
 
    return ("\"abdomen\": " + this.dolor_abdomen + "," +
    "\"cabeza\": " + this.dolor_cabeza + "," +
    "\"extremidades\": " + this.dolor_extremidades + "," +
    "\"torax\": " + this.dolor_torax);
  }

  enfermedadActualJSON(): string {
    // var ans_obj: any = {
    //   r_0: this.cleanString(this.enfermedad_actual_resps[0]),
    //   r_1: this.cleanString(this.enfermedad_actual_resps[1]),
    //   r_2: this.cleanString(this.enfermedad_actual_resps[2]),
    //   r_3: this.cleanString(this.enfermedad_actual_resps[3]),
    //   r_4: this.cleanString(this.enfermedad_actual_resps[4]),
    //   r_5: this.cleanString(this.enfermedad_actual_resps[5]),
    //   r_6: this.cleanString(this.enfermedad_actual_resps[6]),
    //   r_7: this.cleanString(this.enfermedad_actual_resps[7]),
    //   r_8: this.cleanString(this.enfermedad_actual_resps[8]),
    //   r_9: this.cleanString(this.enfermedad_actual_resps[9]),
    //   r_10: this.cleanString(this.enfermedad_actual_resps[10]),
    //   r_11: this.cleanString(this.enfermedad_actual_resps[11]),
    //   r_12: this.cleanString(this.enfermedad_actual_resps[12]),
    //   r_13: this.cleanString(this.enfermedad_actual_resps[13]),
    // };

    return ("\"r_0\": \"" + this.cleanString(this.enfermedad_actual_resps[0]) + "\"," +
    "\"r_1\": \"" + this.cleanString(this.enfermedad_actual_resps[1]) + "\"," +
    "\"r_2\": \"" + this.cleanString(this.enfermedad_actual_resps[2]) + "\"," +
    "\"r_3\": \"" + this.cleanString(this.enfermedad_actual_resps[3]) + "\"," +
    "\"r_4\": \"" + this.cleanString(this.enfermedad_actual_resps[4]) + "\"," +
    "\"r_5\": \"" + this.cleanString(this.enfermedad_actual_resps[5]) + "\"," +
    "\"r_6\": \"" + this.cleanString(this.enfermedad_actual_resps[6]) + "\"," +
    "\"r_7\": \"" + this.cleanString(this.enfermedad_actual_resps[7]) + "\"," +
    "\"r_8\": \"" + this.cleanString(this.enfermedad_actual_resps[8]) + "\"," +
    "\"r_9\": \"" + this.cleanString(this.enfermedad_actual_resps[9]) + "\"," +
    "\"r_10\": \"" + this.cleanString(this.enfermedad_actual_resps[10]) + "\"," +
    "\"r_11\": \"" + this.cleanString(this.enfermedad_actual_resps[11]) + "\"," +
    "\"r_12\": \"" + this.cleanString(this.enfermedad_actual_resps[12]) + "\"," +
    "\"r_13\": \"" + this.cleanString(this.enfermedad_actual_resps[13]) + "\"");
  }

  examenFisicoJSON(): string {
    // var ans_obj: any = {
    //   FC: this.frecuencia_cardiaca,
    //   FR: this.frecuencia_respiratoria,
    //   SAT: this.saturacion,
    //   TA: this.tension_arterial,
    //   Temperatura: this.temperatura,
    //   VAS: this.VAS,
    //   archivos: {
    //     Abdomen: this.examen_fisico_recursos[4] != null ? this.examen_fisico_recursos[4].name : "N/A",
    //     "Cabeza y cuello": this.examen_fisico_recursos[1] != null ? this.examen_fisico_recursos[1].name : "N/A",
    //     Cardiovascular: this.examen_fisico_recursos[2] != null ? this.examen_fisico_recursos[2].name : "N/A",
    //     "Estado general": this.examen_fisico_recursos[0] != null ? this.examen_fisico_recursos[0].name : "N/A",
    //     Extremidades: this.examen_fisico_recursos[5] != null ? this.examen_fisico_recursos[5].name : "N/A",
    //     Neurológico: this.examen_fisico_recursos[6] != null ? this.examen_fisico_recursos[6].name : "N/A",
    //     Otros: this.examen_fisico_recursos[8] != null ? this.examen_fisico_recursos[8].name : "N/A",
    //     "Piel y anexos": this.examen_fisico_recursos[7] != null ? this.examen_fisico_recursos[7].name : "N/A",
    //     Respiratorio: this.examen_fisico_recursos[3] != null ? this.examen_fisico_recursos[3].name : "N/A"
    //   },
    //   peso: this.peso,
    //   respuestas: {
    //     r_0: this.cleanString(this.examen_fisico_resps[0]),
    //     r_1: this.cleanString(this.examen_fisico_resps[1]),
    //     r_2: this.cleanString(this.examen_fisico_resps[2]),
    //     r_3: this.cleanString(this.examen_fisico_resps[3]),
    //     r_4: this.cleanString(this.examen_fisico_resps[4]),
    //     r_5: this.cleanString(this.examen_fisico_resps[5]),
    //     r_6: this.cleanString(this.examen_fisico_resps[6]),
    //     r_7: this.cleanString(this.examen_fisico_resps[7]),
    //     r_8: this.cleanString(this.examen_fisico_resps[8]),
    //   },
    //   talla: this.talla,
    //   emociones: {
    //     e_0: this.cleanString(this.examen_fisico_emociones[0]),
    //     e_1: this.cleanString(this.examen_fisico_emociones[1]),
    //     e_2: this.cleanString(this.examen_fisico_emociones[2]),
    //     e_3: this.cleanString(this.examen_fisico_emociones[3]),
    //     e_4: this.cleanString(this.examen_fisico_emociones[4]),
    //     e_5: this.cleanString(this.examen_fisico_emociones[5]),
    //     e_6: this.cleanString(this.examen_fisico_emociones[6]),
    //     e_7: this.cleanString(this.examen_fisico_emociones[7]),
    //     e_8: this.cleanString(this.examen_fisico_emociones[8]),
    //   }
    // };

    return ("\"FC\": \"" + this.frecuencia_cardiaca + "\"," +
    "\"FR\": \"" + this.frecuencia_respiratoria + "\"," +
    "\"SAT\": \"" + this.saturacion + "\"," +
    "\"TA\": \"" + this.tension_arterial + "\"," +
    "\"Temperatura\": \"" + this.temperatura + "\"," +
    "\"VAS\":" + this.VAS + "," +
    "\"archivos\": {" +
      //El orden está dado por el componente crear-caso-examen-fisico
      "\"Abdomen\": \"" + (this.examen_fisico_recursos[4] != null ? this.examen_fisico_recursos[4].name : "N/A") + "\"," +
      "\"Cabeza y cuello\": \"" + (this.examen_fisico_recursos[1] != null ? this.examen_fisico_recursos[1].name : "N/A") + "\"," +
      "\"Cardiovascular\": \"" + (this.examen_fisico_recursos[2] != null ? this.examen_fisico_recursos[2].name : "N/A") + "\"," +
      "\"Estado general\": \"" + (this.examen_fisico_recursos[0] != null ? this.examen_fisico_recursos[0].name : "N/A") + "\"," +
      "\"Extremidades\": \"" + (this.examen_fisico_recursos[5] != null ? this.examen_fisico_recursos[5].name : "N/A") + "\"," +
      "\"Neurológico\": \"" + (this.examen_fisico_recursos[6] != null ? this.examen_fisico_recursos[6].name : "N/A") + "\"," +
      "\"Otros\": \"" + (this.examen_fisico_recursos[8] != null ? this.examen_fisico_recursos[8].name : "N/A") + "\"," +
      "\"Piel y anexos\": \"" + (this.examen_fisico_recursos[7] != null ? this.examen_fisico_recursos[7].name : "N/A") + "\"," +
      "\"Respiratorio\": \"" + (this.examen_fisico_recursos[3] != null ? this.examen_fisico_recursos[3].name : "N/A") + "\"" +
    "}," +
    "\"peso\": \"" + this.peso + "\"," +
    "\"respuestas\": {" +
      "\"r_0\": \"" + this.cleanString(this.examen_fisico_resps[0]) + "\"," +
      "\"r_1\": \"" + this.cleanString(this.examen_fisico_resps[1]) + "\"," +
      "\"r_2\": \"" + this.cleanString(this.examen_fisico_resps[2]) + "\"," +
      "\"r_3\": \"" + this.cleanString(this.examen_fisico_resps[3]) + "\"," +
      "\"r_4\": \"" + this.cleanString(this.examen_fisico_resps[4]) + "\"," +
      "\"r_5\": \"" + this.cleanString(this.examen_fisico_resps[5]) + "\"," +
      "\"r_6\": \"" + this.cleanString(this.examen_fisico_resps[6]) + "\"," +
      "\"r_7\": \"" + this.cleanString(this.examen_fisico_resps[7]) + "\"," +
      "\"r_8\": \"" + this.cleanString(this.examen_fisico_resps[8]) + "\"" +
    "}," + 
    "\"talla\": \"" + this.talla + "\"," +
    "\"emociones\": {" +
      "\"e_0\": \"" + this.cleanString(this.examen_fisico_emociones[0]) + "\"," +
      "\"e_1\": \"" + this.cleanString(this.examen_fisico_emociones[1]) + "\"," +
      "\"e_2\": \"" + this.cleanString(this.examen_fisico_emociones[2]) + "\"," +
      "\"e_3\": \"" + this.cleanString(this.examen_fisico_emociones[3]) + "\"," +
      "\"e_4\": \"" + this.cleanString(this.examen_fisico_emociones[4]) + "\"," +
      "\"e_5\": \"" + this.cleanString(this.examen_fisico_emociones[5]) + "\"," +
      "\"e_6\": \"" + this.cleanString(this.examen_fisico_emociones[6]) + "\"," +
      "\"e_7\": \"" + this.cleanString(this.examen_fisico_emociones[7]) + "\"," +
      "\"e_8\": \"" + this.cleanString(this.examen_fisico_emociones[8]) + "\"" +
    "}");
  }

  impresionDiagnosticaJSON(): string {
    // var ans_obj: any = {
    //   d_1: this.cleanString(this.diagnosticos[0]),
    //   d_2: this.cleanString(this.diagnosticos[1]),
    //   d_3: this.cleanString(this.diagnosticos[2]),
    //   d_4: this.cleanString(this.diagnosticos[3]),
    //   d_5: this.cleanString(this.diagnosticos[4]),
    //   p_1: this.probabilidades[0],
    //   p_2: this.probabilidades[1],
    //   p_3: this.probabilidades[2],
    //   p_4: this.probabilidades[3],
    //   p_5: this.probabilidades[4],
    // }
 
    return ("\"d_1\": \"" + this.cleanString(this.diagnosticos[0]) + "\"," +
    "\"d_2\": \"" + this.cleanString(this.diagnosticos[1]) + "\"," +
    "\"d_3\": \"" + this.cleanString(this.diagnosticos[2]) + "\"," +
    "\"d_4\": \"" + this.cleanString(this.diagnosticos[3]) + "\"," +
    "\"d_5\": \"" + this.cleanString(this.diagnosticos[4]) + "\"," +
    "\"p_1\": \"" + this.probabilidades[0] + "\"," +
    "\"p_2\": \"" + this.probabilidades[1] + "\"," +
    "\"p_3\": \"" + this.probabilidades[2] + "\"," +
    "\"p_4\": \"" + this.probabilidades[3] + "\"," +
    "\"p_5\": \"" + this.probabilidades[4] + "\"");
  }

  sistemasJSON(): string {
    // var ans_obj: any = {
    //   cardiovascular: this.sistemasPorNombreJSON("cardiovascular"),
    //   endocrino: this.sistemasPorNombreJSON("endocrino"),
    //   gastrointestinal: this.sistemasPorNombreJSON("gastrointestinal"),
    //   genitourinario: this.sistemasPorNombreJSON("genitourinario"),
    //   nervioso: this.sistemasPorNombreJSON("nervioso"),
    //   osteomuscular: this.sistemasPorNombreJSON("osteomuscular"),
    //   respiratorio: this.sistemasPorNombreJSON("respiratorio"),
    // }

    return ("\"cardiovascular\": {" +
      this.sistemasPorNombreJSON("cardiovascular") +
    "}," +
    "\"endocrino\": {" +
      this.sistemasPorNombreJSON("endocrino") +
    "}," +
    "\"gastrointestinal\": {" +
      this.sistemasPorNombreJSON("gastrointestinal") +
    "}," +
    "\"genitourinario\": {" +
      this.sistemasPorNombreJSON("genitourinario") +
    "}," +
    "\"nervioso\": {" +
      this.sistemasPorNombreJSON("nervioso") +
    "}," +
    "\"osteomuscular\": {" +
      this.sistemasPorNombreJSON("osteomuscular") +
    "}," +
    "\"respiratorio\": {" +
      this.sistemasPorNombreJSON("respiratorio") +
    "}");
  }

  //Las preguntas de cada sistema pueden cambiar con el tiempo, por lo que se deben agregar dinámicamente
  sistemasPorNombreJSON(pSistema: string): string {
    var ans = "";
    var ans_obj: any = {};
    var resps_aux: string[] = [];
    var pregs_aux: number = -1; //Para saber cuántas respuestas deben ser agregadas

    if (pSistema == "cardiovascular") {
      resps_aux = this.cardiovascular_resps;
      pregs_aux = this.cardiovascular_pregs;
    }
    else if (pSistema == "endocrino") {
      resps_aux = this.endocrino_resps;
      pregs_aux = this.endocrino_pregs;
    }
    else if (pSistema == "gastrointestinal") {
      resps_aux = this.gastrointestinal_resps;
      pregs_aux = this.gastrointestinal_pregs;
    }
    else if (pSistema == "genitourinario") {
      resps_aux = this.genitourinario_resps;
      pregs_aux = this.genitourinario_pregs;
    }
    else if (pSistema == "nervioso") {
      resps_aux = this.nervioso_resps;
      pregs_aux = this.nervioso_pregs;
    }
    else if (pSistema == "osteomuscular") {
      resps_aux = this.osteomuscular_resps;
      pregs_aux = this.osteomuscular_pregs;
    }
    else if (pSistema == "respiratorio") {
      resps_aux = this.respiratorio_resps;
      pregs_aux = this.respiratorio_pregs;
    }

    var row_aux = "";
    for(var i = 0; i < pregs_aux; i++) {
      row_aux = "\"r_" + i.toString() + "\": \"" + ((resps_aux[i] != null && resps_aux[i].trim() != "") ? this.cleanString(resps_aux[i]) : "N/A") + "\"";
      ans_obj["r_" + i] = (resps_aux[i] != null && resps_aux[i].trim() != "") ? this.cleanString(resps_aux[i]) : "N/A";
      if (i != pregs_aux - 1) {
        row_aux += ","; //El último elemento no lleva "," al final porque no hay un siguiente elemento a agregar
      }
      ans += row_aux;
    }

    return ans;
  }

  tipoPuntuacionJSON(): string {
    return ("\"antecedentes\": {" +
      "\"Patológicos\": \"" + this.antecedentes_tipos[0] + "\"," +
      "\"Quirúrgicos\": \"" + this.antecedentes_tipos[1] + "\"," +
      "\"Familiares\": \"" + this.antecedentes_tipos[2] + "\"," +
      "\"Farmacológicos\": \"" + this.antecedentes_tipos[3] + "\"," +
      "\"Ginecológicos\": \"" + this.antecedentes_tipos[4] + "\"," +
      "\"Alérgicos\": \"" + this.antecedentes_tipos[5] + "\"," +
      "\"Toxicológicos\": \"" + this.antecedentes_tipos[6] + "\"," +
      "\"Epidemiológicos\": \"" + this.antecedentes_tipos[7] + "\"," +
      "\"Perinatales\": \"" + this.antecedentes_tipos[8] + "\"" +
    "},"
    +
    this.ayudasDiagnosticasPuntuacionJSON()
    +
    "\"sistemas\": {" +
      "\"Cardiovascular\": \"" + this.cardiovascular_tipo + "\"," +
      "\"Endocrino\": \"" + this.endocrino_tipo + "\"," +
      "\"Gastrointestinal\": \"" + this.gastrointestinal_tipo + "\"," +
      "\"Genitourinario\": \"" + this.genitourinario_tipo + "\"," +
      "\"Nervioso\": \"" + this.nervioso_tipo + "\"," +
      "\"Osteomuscular\": \"" + this.osteomuscular_tipo + "\"," +
      "\"Respiratorio\": \"" + this.respiratorio_tipo + "\"" +
    "}"
    +
    this.tratamientosPuntuacionJSON());
  }

  ayudasDiagnosticasPuntuacionJSON(): string {
    var ans = 
    "\"ayudas diagnósticas\": {";

    //Imágenes diagnósticas
    var cnt_imgs = 0;
    this.imagenes_diagnosticas.forEach(
      e => {
        if (e != null && e.trim() != "") {
          cnt_imgs++;
        }
      }
    );
    if (cnt_imgs > 0) {
      ans += 
      "\"imágenes diagnósticas\": {";

      var img_actual = "";
      var pts_actual = "";
      for(var i = 0; i < cnt_imgs; i++) {
        img_actual = this.imagenes_diagnosticas[i];
        pts_actual = this.imgs_puntuaciones[i];
        if ((img_actual != null && img_actual.trim() != "") && (pts_actual != null && pts_actual != "")) {
          ans += "\"" + img_actual + "\": \"" + pts_actual + "\"";
          if (i != cnt_imgs - 1) {
            ans += ","; //Se agrega la "," sólo si no es el último elemento a agregar
          }
        }
        else {
          cnt_imgs++;
          //Si llegó a este punto es porque encontró una entrada vacía en el arreglo, por lo que se debe extender el bucle para encontrar la entrada
          //no-vacía que fue contada en cnt_imgs
        }
      }

      ans += "}"  //La "," final se agrega únicamente si hay un siguiente elemento a agregar (véase "Laboratorios" y/o "Otras ayudas")
    }

    //Laboratorios
    var cnt_labs = 0;
    this.laboratorios_nombres.forEach(
      e => {
        if (e != null && e.trim() != "") {
          cnt_labs++;
        }
      }
    );
    if (cnt_labs > 0) {
      ans += 
      ",\"laboratorios\": {";

      var lab_actual = "";
      var pts_actual = "";
      for(var i = 0; i < cnt_labs; i++) {
        lab_actual = this.laboratorios_nombres[i];
        pts_actual = this.laboratorios_pts[i];
        if ((lab_actual != null && lab_actual.trim() != "") && (pts_actual != null && pts_actual != "")) {
          ans += "\"" + lab_actual + "\": \"" + pts_actual + "\"";
          if (i != cnt_labs - 1) {
            ans += ","; //Se agrega la "," sólo si no es el último elemento a agregar
          }
        }
        else {
          cnt_labs++;
          //Si llegó a este punto es porque encontró una entrada vacía en el arreglo, por lo que se debe extender el bucle para encontrar la entrada
          //no-vacía que fue contada en cnt_labs
        }
      }

      ans += "}"
    }

    //Otras ayudas
    var cnt_otras = 0;
    this.otras_nombres.forEach(
      e => {
        if (e != null && e.trim() != "") {
          cnt_otras++;
        }
      }
    );
    if (cnt_otras > 0) {
      ans += 
      ",\"otras ayudas\": {";

      var otras_actual = "";
      var pts_actual = "";
      for(var i = 0; i < cnt_otras; i++) {
        otras_actual = this.otras_nombres[i];
        pts_actual = this.otras_pts[i];
        if ((otras_actual != null && otras_actual.trim() != "") && (pts_actual != null && pts_actual != "")) {
          ans += "\"" + otras_actual + "\": \"" + pts_actual + "\"";
          if (i != cnt_otras - 1) {
            ans += ","; //Se agrega la "," sólo si no es el último elemento a agregar
          }
        }
        else {
          cnt_otras++;
          //Si llegó a este punto es porque encontró una entrada vacía en el arreglo, por lo que se debe extender el bucle para encontrar la entrada
          //no-vacía que fue contada en cnt_otras
        }
      }

      ans += "}";
    }

    if (cnt_imgs == 0 && cnt_labs == 0 && cnt_otras == 0) {
      ans = "";
    }
    else {
      ans += "},"
    }

    return ans;
  }

  tratamientosPuntuacionJSON(): string {
    var ans = 
    ",\"tratamiento\": {";

    var cnt_dieta = 0;
    this.dieta.forEach(
      e => {
        if (e != null && e.trim() != "") {
          cnt_dieta++;
        }
      }
    );

    var cnt_medicamentos = 0;
    this.medicamentos.forEach(
      e => {
        if (e != null && e.trim() != "") {
          cnt_medicamentos++;
        }
      }
    );

    var cnt_medidas = 0;
    this.medidas_generales.forEach(
      e => {
        if (e != null && e.trim() != "") {
          cnt_medidas++;
        }
      }
    );

    var cnt_oxigeno = 0;
    this.oxigeno.forEach(
      e => {
        if (e != null && e.trim() != "") {
          cnt_oxigeno++;
        }
      }
    );

    var cnt_hidrico = 0;
    this.soporte_hidrico.forEach(
      e => {
        if (e != null && e.trim() != "") {
          cnt_hidrico++;
        }
      }
    );

    if (cnt_dieta != 0 || cnt_medicamentos != 0 ||
      cnt_medidas != 0 || cnt_oxigeno != 0 || cnt_hidrico == 0) {
      
      var cnt_total = cnt_dieta + cnt_medicamentos + cnt_medidas + cnt_oxigeno + cnt_hidrico;
      var cnt_actual = 0;

      var trat_actual = "";
      var pts_actual = "";
      for(var i = 0; i < cnt_dieta && cnt_actual != cnt_total; i++) {
        trat_actual = this.dieta[i];
        pts_actual = this.dieta_pts[i];
        if ((trat_actual != null && trat_actual.trim() != "") && (pts_actual != null && pts_actual.trim() != "")) {
          ans += "\"" + trat_actual + "\": \"" + pts_actual + "\"";
          cnt_actual++;
          if (cnt_actual != cnt_total) {
            ans += ","; //Se agrega la "," si aún hay tratamientos por agregar
          }
        }
        else {
          cnt_dieta++;
          //Si llegó a este punto es porque encontró una entrada vacía en el arreglo, por lo que se debe extender el bucle para encontrar la entrada
          //no-vacía que fue contada en cnt_dieta
        }
      }

      for(var i = 0; i < cnt_medicamentos && cnt_actual != cnt_total; i++) {
        trat_actual = this.medicamentos[i];
        pts_actual = this.medicamentos_pts[i];
        if ((trat_actual != null && trat_actual.trim() != "") && (pts_actual != null && pts_actual.trim() != "")) {
          ans += "\"" + trat_actual + "\": \"" + pts_actual + "\"";
          cnt_actual++;
          if (cnt_actual != cnt_total) {
            ans += ","; //Se agrega la "," si aún hay tratamientos por agregar
          }
        }
        else {
          cnt_medicamentos++;
          //Si llegó a este punto es porque encontró una entrada vacía en el arreglo, por lo que se debe extender el bucle para encontrar la entrada
          //no-vacía que fue contada en cnt_medicamentos
        }
      }

      for(var i = 0; i < cnt_medidas && cnt_actual != cnt_total; i++) {
        trat_actual = this.medidas_generales[i];
        pts_actual = this.medidas_generales_pts[i];
        if ((trat_actual != null && trat_actual.trim() != "") && (pts_actual != null && pts_actual.trim() != "")) {
          ans += "\"" + trat_actual + "\": \"" + pts_actual + "\"";
          cnt_actual++;
          if (cnt_actual != cnt_total) {
            ans += ","; //Se agrega la "," si aún hay tratamientos por agregar
          }
        }
        else {
          cnt_medidas++;
          //Si llegó a este punto es porque encontró una entrada vacía en el arreglo, por lo que se debe extender el bucle para encontrar la entrada
          //no-vacía que fue contada en cnt_medidas
        }
      }

      for(var i = 0; i < cnt_oxigeno && cnt_actual != cnt_total; i++) {
        trat_actual = this.oxigeno[i];
        pts_actual = this.oxigeno_pts[i];
        if ((trat_actual != null && trat_actual.trim() != "") && (pts_actual != null && pts_actual.trim() != "")) {
          ans += "\"" + trat_actual + "\": \"" + pts_actual + "\"";
          cnt_actual++;
          if (cnt_actual != cnt_total) {
            ans += ","; //Se agrega la "," si aún hay tratamientos por agregar
          }
        }
        else {
          cnt_oxigeno++;
          //Si llegó a este punto es porque encontró una entrada vacía en el arreglo, por lo que se debe extender el bucle para encontrar la entrada
          //no-vacía que fue contada en cnt_oxigeno
        }
      }

      for(var i = 0; i < cnt_hidrico && cnt_actual != cnt_total; i++) {
        trat_actual = this.soporte_hidrico[i];
        pts_actual = this.soporte_hidrico_pts[i];
        if ((trat_actual != null && trat_actual.trim() != "") && (pts_actual != null && pts_actual.trim() != "")) {
          ans += "\"" + trat_actual + "\": \"" + pts_actual + "\"";
          cnt_actual++;
          if (cnt_actual != cnt_total) {
            ans += ","; //Se agrega la "," si aún hay tratamientos por agregar
          }
        }
        else {
          cnt_hidrico++;
          //Si llegó a este punto es porque encontró una entrada vacía en el arreglo, por lo que se debe extender el bucle para encontrar la entrada
          //no-vacía que fue contada en cnt_hidrico
        }
      }

      ans += "}";
    }
    else {
      ans = "";
    }

    return ans;
  }
}