import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RdbEditarCasoService } from 'app/services/firebase/rdb-editar-caso.service';

declare const $: any;

@Component({
  selector: 'app-detalle-caso-enfermedad-actual',
  templateUrl: './detalle-caso-enfermedad-actual.component.html',
  styleUrls: ['./detalle-caso-enfermedad-actual.component.css']
})
export class DetalleCasoEnfermedadActualComponent implements OnInit {

  resps: string[] = [];

  constructor(private firebase_rdb: RdbEditarCasoService, private router: Router) { }

  ngOnInit() {
    if (this.firebase_rdb.nombre_caso_seleccionado == null ||
      this.firebase_rdb.especialidad_caso_seleccionado == null ||
      this.firebase_rdb.id_caso_seleccionado == null ||
      this.firebase_rdb.json_caso_seleccionado == null) {
      
      this.firebase_rdb.clearSeleccionado();
      this.router.navigate(["/panel/casos"]);
    }

    //Recupera el último estado guardado del componente    
    this.resps = this.firebase_rdb.enfermedad_actual_resps;
  }

  guardarCambios() {
    //Guarda el estado del componente
    this.firebase_rdb.enfermedad_actual_resps = this.resps;    
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
        return false;
    }
    return true;
  };

}
