import { Component, OnInit } from '@angular/core';
import { RdbService } from 'app/services/firebase/rdb.service';
import { LocalStorageService } from 'app/services/storage/local-storage.service';

declare const $: any;

@Component({
  selector: 'app-crear-caso-impresion',
  templateUrl: './crear-caso-impresion.component.html',
  styleUrls: ['./crear-caso-impresion.component.css']
})
export class CrearCasoImpresionComponent implements OnInit {

  constructor(private firebase_rdb: RdbService, private local_db: LocalStorageService) { }

  diagnosticos: string[] = [];
  probabilidades: string[] = [];

  ngOnInit() {
    this.local_db.getItem<string[]>("diagnosticos").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.diagnosticos = resp;
          this.diagnosticos = this.firebase_rdb.diagnosticos;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando diagnosticos");
      }
    );

    this.local_db.getItem<string[]>("probabilidades").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.probabilidades = resp;
          this.probabilidades = this.firebase_rdb.probabilidades;
        }
      }
    ).catch(
      error => {
        console.log("Error recuperando diagnosticos");
      }
    );
  }

  guardarCambios() {
    this.firebase_rdb.diagnosticos = this.diagnosticos;
    this.firebase_rdb.probabilidades = this.probabilidades;
    
    this.local_db.setItem<string[]>("diagnosticos", this.firebase_rdb.diagnosticos);
    this.local_db.setItem<string[]>("probabilidades", this.firebase_rdb.probabilidades);
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
        return false;
    }
    return true;
  };

}
