import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RdbEditarCasoService } from 'app/services/firebase/rdb-editar-caso.service';

declare const $: any;

@Component({
  selector: 'app-detalle-caso-sistemas',
  templateUrl: './detalle-caso-sistemas.component.html',
  styleUrls: ['./detalle-caso-sistemas.component.css']
})
export class DetalleCasoSistemasComponent implements OnInit {

  constructor(private firebase_rdb: RdbEditarCasoService, private router: Router) { }

  sistemaSeleccionado = "Cardiovascular";

  indexActual = -1;
  pregSeleccionada = "";

  pregsActuales: string[] = [];

  respsActuales: string[] = [];

  tipoActual = "";

  ngOnInit() {
    if (this.firebase_rdb.nombre_caso_seleccionado == null ||
      this.firebase_rdb.especialidad_caso_seleccionado == null ||
      this.firebase_rdb.id_caso_seleccionado == null ||
      this.firebase_rdb.json_caso_seleccionado == null) {
      
      this.firebase_rdb.clearSeleccionado();
      this.router.navigate(["/panel/casos"]);
    }

    this.cargarPregsSistema();

    this.cargarRespuestasSistemaActual();
    this.cargarTipoSistemaActual();
  }

  /**
   * Guarda el estado de las respuestas ingresadas por el usuario para el sistema indicado 
   */
  guardarRespuestasSistemaActual() {
    if (this.sistemaSeleccionado == "Cardiovascular") {
      this.firebase_rdb.cardiovascular_resps = this.respsActuales;
    }
    else if (this.sistemaSeleccionado == "Endocrino") {
      this.firebase_rdb.endocrino_resps = this.respsActuales;
    }
    else if (this.sistemaSeleccionado == "Gastrointestinal") {
      this.firebase_rdb.gastrointestinal_resps = this.respsActuales;
    }
    else if (this.sistemaSeleccionado == "Genitourinario") {
      this.firebase_rdb.genitourinario_resps = this.respsActuales;
    }
    else if (this.sistemaSeleccionado == "Nervioso") {
      this.firebase_rdb.nervioso_resps = this.respsActuales;
    }
    else if (this.sistemaSeleccionado == "Osteomuscular") {
      this.firebase_rdb.osteomuscular_resps = this.respsActuales;
    }
    else if (this.sistemaSeleccionado == "Respiratorio") {
      this.firebase_rdb.respiratorio_resps = this.respsActuales;
    }
    
  }

  /**
   * Recupera las respuestas ya ingresadas por el usuario para el sistema indicado
   */
  cargarRespuestasSistemaActual() {
    if (this.sistemaSeleccionado == "Cardiovascular")
      this.respsActuales = this.firebase_rdb.cardiovascular_resps;
    else if (this.sistemaSeleccionado == "Endocrino")
      this.respsActuales = this.firebase_rdb.endocrino_resps;
    else if (this.sistemaSeleccionado == "Gastrointestinal")
      this.respsActuales = this.firebase_rdb.gastrointestinal_resps;
    else if (this.sistemaSeleccionado == "Genitourinario")
      this.respsActuales = this.firebase_rdb.genitourinario_resps;
    else if (this.sistemaSeleccionado == "Nervioso")
      this.respsActuales = this.firebase_rdb.nervioso_resps;
    else if (this.sistemaSeleccionado == "Osteomuscular")
      this.respsActuales = this.firebase_rdb.osteomuscular_resps;
    else if (this.sistemaSeleccionado == "Respiratorio")
      this.respsActuales = this.firebase_rdb.respiratorio_resps;
  }

  /**
   * Guarda el tipo (correcto, incorrecto, omitible) de preguntas para el sistema indicado
   */
  guardarTipoSistemaActual() {
    var tipoAux = "";

    //tipoActual es cómo se ve en la interfaz, pero tipoAux es como se debe guardar en la DB
    if (this.tipoActual == "Preguntas correctas")
      tipoAux = "positivo";
    else if (this.tipoActual == "Preguntas incorrectas")
      tipoAux = "negativo";
    else
      tipoAux = "omitible";

    if (this.sistemaSeleccionado == "Cardiovascular") {
      this.firebase_rdb.cardiovascular_tipo = tipoAux;
    }
    else if (this.sistemaSeleccionado == "Endocrino") {
      this.firebase_rdb.endocrino_tipo = tipoAux;
    }
    else if (this.sistemaSeleccionado == "Gastrointestinal") {
      this.firebase_rdb.gastrointestinal_tipo = tipoAux;
    }
    else if (this.sistemaSeleccionado == "Genitourinario") {
      this.firebase_rdb.genitourinario_tipo = tipoAux;
    }
    else if (this.sistemaSeleccionado == "Nervioso") {
      this.firebase_rdb.nervioso_tipo = tipoAux;
    }
    else if (this.sistemaSeleccionado == "Osteomuscular") {
      this.firebase_rdb.osteomuscular_tipo = tipoAux;
    }
    else if (this.sistemaSeleccionado == "Respiratorio") {
      this.firebase_rdb.respiratorio_tipo = tipoAux;
    }
    
  }

  /**
   * Recupera el tipo de preguntas antes indicado por el usuario para el sistema seleccionado
   */
  cargarTipoSistemaActual() {
    var tipoAux = "";

    if (this.sistemaSeleccionado == "Cardiovascular")
      tipoAux = this.firebase_rdb.cardiovascular_tipo;
    else if (this.sistemaSeleccionado == "Endocrino")
      tipoAux = this.firebase_rdb.endocrino_tipo;
    else if (this.sistemaSeleccionado == "Gastrointestinal")
      tipoAux = this.firebase_rdb.gastrointestinal_tipo;
    else if (this.sistemaSeleccionado == "Genitourinario")
      tipoAux = this.firebase_rdb.genitourinario_tipo;
    else if (this.sistemaSeleccionado == "Nervioso")
      tipoAux = this.firebase_rdb.nervioso_tipo;
    else if (this.sistemaSeleccionado == "Osteomuscular")
      tipoAux = this.firebase_rdb.osteomuscular_tipo;
    else if (this.sistemaSeleccionado == "Respiratorio")
      tipoAux = this.firebase_rdb.respiratorio_tipo;

    if (tipoAux == "positivo")
      this.tipoActual = "Preguntas correctas";
    else if (tipoAux == "negativo")
      this.tipoActual = "Preguntas incorrectas";
    else if (tipoAux == "omitible")
      this.tipoActual = "Preguntas omitibles";
    else
      this.tipoActual = "";

  }

  /**
   * Guarda el estado del panel y transiciona hacia el nuevo sistema seleccionado
   * @param sistema El sistema a cargar
   */
  seleccionarSistema(sistema: string) {
    this.guardarRespuestasSistemaActual();

    this.sistemaSeleccionado = sistema;

    this.respsActuales = [];
    this.pregsActuales = [];
    this.indexActual = -1;
    this.pregSeleccionada = "";

    this.tipoActual = "";

    this.cargarPregsSistema();

    this.cargarRespuestasSistemaActual();
    this.cargarTipoSistemaActual();
  }

  /**
   * Se para recuperar las preguntas del sistema desde Firebase
   */
  cargarPregsSistema() {
    this.firebase_rdb.getPregsSistema(this.sistemaSeleccionado).toPromise().then(
      data => {
        let i = 0;
        for (let key in data) {
          if (data.hasOwnProperty(key)) {
            this.pregsActuales.push(data[`p_${i}`]);
            i++;
          }
        }
    
        this.contarPregsSistema();
      }
    );
  }

  /**
   * Guarda el número de preguntas encontradas para el sistema seleccionado
   * Se hace con el fin de verificar que el panel esté completo y se pueda subir el caso
   */
  contarPregsSistema() {
    var pregsCnt = this.pregsActuales.length;

    if (this.sistemaSeleccionado == "Cardiovascular") {
      this.firebase_rdb.cardiovascular_pregs = pregsCnt;
    }
    else if (this.sistemaSeleccionado == "Endocrino") {
      this.firebase_rdb.endocrino_pregs = pregsCnt;
    }
    else if (this.sistemaSeleccionado == "Gastrointestinal") {
      this.firebase_rdb.gastrointestinal_pregs = pregsCnt;
    }
    else if (this.sistemaSeleccionado == "Genitourinario") {
      this.firebase_rdb.genitourinario_pregs = pregsCnt;
    }
    else if (this.sistemaSeleccionado == "Nervioso") {
      this.firebase_rdb.nervioso_pregs = pregsCnt;
    }
    else if (this.sistemaSeleccionado == "Osteomuscular") {
      this.firebase_rdb.osteomuscular_pregs = pregsCnt;
    }
    else if (this.sistemaSeleccionado == "Respiratorio") {
      this.firebase_rdb.respiratorio_pregs = pregsCnt;
    }    
  }

  /**
   * Actualiza la pregunta seleccionada por el usuario para mostrar en el template
   * @param preg La pregnuta seleccionada
   * @param index La posición en el arreglo de preguntas de la pregunta seleccionada
   */
  seleccionarPregunta(preg: string, index: number) {
    this.indexActual = index;
    this.pregSeleccionada = preg;
  }

  /**
   * Actualiza el tipo de pregunta para el sistema seleccionado
   * @param tipo El tipo seleccionado; 1 indica que es correcto, -1 es incorrecto y 0 es omitible
   */
  seleccionarTipo(tipo: number) {
    if(tipo == 1) {
      this.tipoActual = "Preguntas correctas";
    }
    else if (tipo == -1) {
      this.tipoActual = "Preguntas incorrectas";
    }
    else {
      this.tipoActual = "Preguntas omitibles";
    }

    this.guardarTipoSistemaActual();
  }
  
  /**
   * Indica si la pantalla se debe ajustar a móvil
   * @returns True si la pantalla corresponde a un dispositivo móvil. False en caso contrario
   */
  isMobileMenu() {
    if ($(window).width() > 991) {
        return false;
    }
    return true;
  }
}
