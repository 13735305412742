import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RdbEditarCasoService } from 'app/services/firebase/rdb-editar-caso.service';

declare const $: any;

@Component({
  selector: 'app-laboratorio',
  templateUrl: './laboratorio.component.html',
  styleUrls: ['./laboratorio.component.css']
})
export class LaboratorioComponent implements OnInit {

  constructor(private router: Router, private firebase_rdb: RdbEditarCasoService, private http: HttpClient) { }

  cnt_labs: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  tipo_labs: string[] = ["inmunología", "inmunología rutinaria", "laboratorios clínicos", "laboratorios hematológicos",
  "marcadores tumorales", "mediciones farmacológicas", "minerales y vitaminas", "niveles de anticuerpos bacterianos",
  "pruebas de coprología", "pruebas de enzimas", "pruebas de hematología rutinarias", "pruebas de orina", 
  "pruebas inmunológicas para microorganismos", "test endocrinología", "toxicología"];

  ngOnInit() {
    this.firebase_rdb.laboratorio_seleccionado = "";

    this.cntLabs();
  }

  cntLabs() {
    this.tipo_labs.forEach(
      (e,i) => {
        if (e != null) {
          this.firebase_rdb.getLabs(e).toPromise().then(
            data => {
              var cnt = 0;
              for(let key in data) {
                if (data.hasOwnProperty(key)) {
                  cnt++;
                }
              }
              this.cnt_labs[this.tipo_labs.indexOf(e)] = cnt;
            }
          ).catch(
            error => {
              console.log("Error recuperando los laboratorios de tipo " + e);
              console.log(error);
            }
          );
        }
      }
    );

    this.firebase_rdb.getOtrasAyudas().toPromise().then(
      data => {
        var cnt = 0;
        for (let key in data) {
          if (data.hasOwnProperty(key)) {
            cnt++;
          }
        }
        this.cnt_labs[15] = cnt;
      }
    ).catch(
      error => {
        console.log("Error recuperando Otras ayudas");
        console.log(error);
      }
    );
  }

  verDetalleLab(pLab: string) {
    this.firebase_rdb.laboratorio_seleccionado = pLab;

    this.router.navigate(["/panel/detalle-laboratorio"]);
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
        return false;
    }
    return true;
  };

}
