<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 text-left">
        <h3><strong>Impresión diagnóstica</strong></h3>
      </div>
      <div *ngIf="isMobileMenu();then content_mobile else content_web"></div>
      <ng-template #content_web>
        <div class="col-md-6 text-right">
          <a routerLink="/panel/crear-caso">
            <button class="btn btn-success" (click)="guardarCambios()">Guardar cambios</button>
          </a>
        </div>
      </ng-template>
      <ng-template #content_mobile>
        <div class="col-md-6 text-left">
          <a routerLink="/panel/crear-caso">
            <button class="btn btn-success" (click)="guardarCambios()">Guardar cambios</button>
          </a>
        </div>
      </ng-template>
    </div>
    <div class="row">
      <div class="col-md-12 text-left">
        <p><a (click)="guardarCambios()" routerLink="/panel/casos">Casos</a>/<a (click)="guardarCambios()" routerLink="/panel/crear-caso">Crear caso</a>/<strong>Impresión diagnóstica</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-7">
        <h5>Diagnóstico</h5>
        <br *ngIf="probabilidades[0] != null" />
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="diagnosticos[0]" class="form-control" placeholder="Escribe un diagnóstico" rows="1"></textarea> 
            </div>
          </div>
        </div>   
      </div>
      <div class="col-md-5">
        <h5>Probabilidad</h5>
        <p>{{probabilidades[0]}}</p>
        <div class="dropdown">
          <a class="btn dropdown-toggle" data-toggle="dropdown">
              Selecciona la probabilidad correcta
              <b class="caret"></b>
          </a>
          <ul class="dropdown-menu">
            <li><a (click)="probabilidades[0] = 'Muy alta probabilidad'">Muy alta probabilidad</a></li>
            <li><a (click)="probabilidades[0] = 'Alta probabilidad'">Alta probabilidad</a></li>
            <li><a (click)="probabilidades[0] = 'Baja probabilidad'">Baja probabilidad</a></li>
            <li><a (click)="probabilidades[0] = 'Muy baja probabilidad'">Muy baja probabilidad</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-7">
        <h5>Diagnóstico</h5>
        <br *ngIf="probabilidades[1] != null" />
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="diagnosticos[1]" class="form-control" placeholder="Escribe un diagnóstico" rows="1"></textarea> 
            </div>
          </div>
        </div>   
      </div>
      <div class="col-md-5">
        <h5>Probabilidad</h5>
        <p>{{probabilidades[1]}}</p>
        <div class="dropdown">
          <a class="btn dropdown-toggle" data-toggle="dropdown">
              Selecciona la probabilidad correcta
              <b class="caret"></b>
          </a>
          <ul class="dropdown-menu">
            <li><a (click)="probabilidades[1] = 'Muy alta probabilidad'">Muy alta probabilidad</a></li>
            <li><a (click)="probabilidades[1] = 'Alta probabilidad'">Alta probabilidad</a></li>
            <li><a (click)="probabilidades[1] = 'Baja probabilidad'">Baja probabilidad</a></li>
            <li><a (click)="probabilidades[1] = 'Muy baja probabilidad'">Muy baja probabilidad</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-7">
        <h5>Diagnóstico</h5>
        <br *ngIf="probabilidades[2] != null" />
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="diagnosticos[2]" class="form-control" placeholder="Escribe un diagnóstico" rows="1"></textarea> 
            </div>
          </div>
        </div>   
      </div>
      <div class="col-md-5">
        <h5>Probabilidad</h5>
        <p>{{probabilidades[2]}}</p>
        <div class="dropdown">
          <a class="btn dropdown-toggle" data-toggle="dropdown">
              Selecciona la probabilidad correcta
              <b class="caret"></b>
          </a>
          <ul class="dropdown-menu">
            <li><a (click)="probabilidades[2] = 'Muy alta probabilidad'">Muy alta probabilidad</a></li>
            <li><a (click)="probabilidades[2] = 'Alta probabilidad'">Alta probabilidad</a></li>
            <li><a (click)="probabilidades[2] = 'Baja probabilidad'">Baja probabilidad</a></li>
            <li><a (click)="probabilidades[2] = 'Muy baja probabilidad'">Muy baja probabilidad</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-7">
        <h5>Diagnóstico</h5>
        <br *ngIf="probabilidades[3] != null" />
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="diagnosticos[3]" class="form-control" placeholder="Escribe un diagnóstico" rows="1"></textarea> 
            </div>
          </div>
        </div>   
      </div>
      <div class="col-md-5">
        <h5>Probabilidad</h5>
        <p>{{probabilidades[3]}}</p>
        <div class="dropdown">
          <a class="btn dropdown-toggle" data-toggle="dropdown">
              Selecciona la probabilidad correcta
              <b class="caret"></b>
          </a>
          <ul class="dropdown-menu">
            <li><a (click)="probabilidades[3] = 'Muy alta probabilidad'">Muy alta probabilidad</a></li>
            <li><a (click)="probabilidades[3] = 'Alta probabilidad'">Alta probabilidad</a></li>
            <li><a (click)="probabilidades[3] = 'Baja probabilidad'">Baja probabilidad</a></li>
            <li><a (click)="probabilidades[3] = 'Muy baja probabilidad'">Muy baja probabilidad</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-7">
        <h5>Diagnóstico</h5>
        <br *ngIf="probabilidades[4] != null" />
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="diagnosticos[4]" class="form-control" placeholder="Escribe un diagnóstico" rows="1"></textarea> 
            </div>
          </div>
        </div>   
      </div>
      <div class="col-md-5">
        <h5>Probabilidad</h5>
        <p>{{probabilidades[4]}}</p>
        <div class="dropdown">
          <a class="btn dropdown-toggle" data-toggle="dropdown">
              Selecciona la probabilidad correcta
              <b class="caret"></b>
          </a>
          <ul class="dropdown-menu">
            <li><a (click)="probabilidades[4] = 'Muy alta probabilidad'">Muy alta probabilidad</a></li>
            <li><a (click)="probabilidades[4] = 'Alta probabilidad'">Alta probabilidad</a></li>
            <li><a (click)="probabilidades[4] = 'Baja probabilidad'">Baja probabilidad</a></li>
            <li><a (click)="probabilidades[4] = 'Muy baja probabilidad'">Muy baja probabilidad</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>