<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-2 text-left">
        <h3><strong>Casos</strong></h3>
      </div>
      <div *ngIf="isMobileMenu();then content_mobile else content_web"></div>
      <ng-template #content_web>
        <div class="col-md-10 text-right">
          <button class="btn btn-info">Exportar datos</button>
          <a routerLink="/panel/crear-caso">
            <button class="btn btn-success">Crear caso</button>
          </a>
        </div>
      </ng-template>
      <ng-template #content_mobile>
        <div class="col-md-10 text-left">
          <button class="btn btn-info">Exportar datos</button>
          <a routerLink="/panel/crear-caso">
            <button class="btn btn-success">Crear caso</button>
          </a>
        </div>
      </ng-template>
    </div>
    <div *ngIf="cntCasos() != 0" class="row">
      
      <div class="col-md-12">
        <div class="card">
          <div class="card-content bg-white">
            <div class="table-responsive p-3 mx-auto">
              <table class="table table-striped table-hover">
                <thead class="thead-light">
                  <tr>
                    <th class="text-center" scope="col">
                      <p>
                        <strong>Nombre</strong>
                      </p>
                    </th>
                    <th class="text-center" scope="col">
                      <p>
                        <strong>Modo</strong>
                      </p>
                    </th>
                    <th class="text-center" scope="col">
                      <p>
                        <strong>Especialidad</strong>
                      </p>
                    </th>
                    <th class="text-center" scope="col">
                      <p>
                        <strong>Dificultad</strong>
                      </p>
                    </th>
                    <th class="text-center" scope="col">
                      <p>
                        <strong>Diagnóstico final</strong>
                      </p>
                    </th>
                    <th class="text-center" scope="col">
                      <p>
                        <strong>Acciones</strong>
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let nom of nombres; let i = index" scope="row">
                    <td *ngIf="nom != null">
                      {{nom}}
                    </td>
                    <td *ngIf="nom != null" class="text-center">
                      {{modos[i]}}
                    </td>
                    <td *ngIf="nom != null" class="text-center">
                      {{especialidades[i]}}
                    </td>
                    <td *ngIf="nom != null" class="text-center">
                      {{dificultades[i]}}
                    </td>
                    <td *ngIf="nom != null" class="text-center">
                      {{diagnosticos_finales[i]}}
                    </td>
                    <td *ngIf="nom != null" class="text-center">
                      <i class="material-icons" (click)="verDetalleCaso(i)">search</i>
                      <i class="material-icons" (click)="deleteCaso(i)">delete</i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="cntCasos() == 0" class="row text-center">
      <h3>
          Aún no hay casos agregados. ¡Haz click en "Crear caso" para subir el primer caso!
      </h3>
    </div>
  </div>
</div>