import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RdbStatsService {

  constructor(private http: HttpClient) { }

  urlStats: string = "https://medicina-uniandes-default-rtdb.firebaseio.com/estadísticas/";

  urlCasosRealizadosPorDia: string = this.urlStats + "Casos%20realizados%20por%20día/";

  /**
   * Devuelve un objeto Observable donde la respuesta debería ser el JSON de las estadísticas
   * guardadas en Firebase
   */
  getAllEstadisticas() {
    return this.http.get(this.urlStats + ".json");
  }

  getCasosRealizadosPorDia() {
    return this.http.get(this.urlCasosRealizadosPorDia + ".json");
  }
}
