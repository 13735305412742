<div class="main-content">
  <div class="container-fluid">
      <div class="row">
        <div class="col-md-2 text-left">
          <h3><strong>Especialidades</strong></h3>
        </div>
      </div>
      <div class="row">
          <div class="col-md-12">
            <h1>Especialidades works!</h1>
          </div>
      </div>
  </div>
</div>