<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 text-left">
        <h3><strong>Examen físico</strong></h3>
      </div>
      <div *ngIf="isMobileMenu();then content_mobile else content_web"></div>
      <ng-template #content_web>
        <div class="col-md-6 text-right">
          <a routerLink="/panel/detalle-caso">
            <button class="btn btn-success" (click)="guardarCambios()">Guardar cambios</button>
          </a>
        </div>
      </ng-template>
      <ng-template #content_mobile>
        <div class="col-md-6 text-left">
          <a routerLink="/panel/detalle-caso">
            <button class="btn btn-success" (click)="guardarCambios()">Guardar cambios</button>
          </a>
        </div>
      </ng-template>
    </div>
    <div class="row">
      <div class="col-md-12 text-left">
        <p><a (click)="guardarCambios()" routerLink="/panel/casos">Casos</a>/<a (click)="guardarCambios()" routerLink="/panel/detalle-caso">Editar caso</a>/<strong>Examen físico</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-9">
        <h5>Estado general</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[0]" class="form-control" placeholder="Escribe aquí el resultado de este examen físico" rows="5"></textarea> 
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <h5>Recursos</h5>
        <p *ngIf="recursosASubir[0] != null && recursosAActualizar[0]">
          {{recursosASubir[0].name}}
          <a><i class="material-icons" (click)="handleFileInput(null, 0)">delete</i></a>
        </p>
        <p *ngIf="recursosActuales[0] != null && !recursosAActualizar[0]">
          {{recursosActuales[0]}}
          <a><i class="material-icons" (click)="handleFileInput(null, 0)">delete</i></a>
        </p>
        <div class="form-group adjuntar-recursos">
          <label for="file">Adjunta un recurso</label>
          <input type="file" 
                class="file-upload"
                (change)="handleFileInput($event.target.files[0], 0)"
          #fileUpload0 />
        </div>
        <button class="btn btn-adjuntar" (click)="fileUpload0.click()">Adjunta un recurso</button>
      </div>
    </div>
    <div *ngIf="alert_archivo[0]" class="row">
      <br />
      <div class="col-md-1">
      </div>
      <div class="alert alert-danger col-md-10">
        <h4>
          <strong>Error:</strong>
          El formato del archivo seleccionado no es válido. Si es una imagen, recomendamos que esté en formato .png o .jpg, y si es un audio 
          el formato deseado es .mp3 o .wav
          <i class="material-icons cerrar_alerta" (click)="alert_archivo[0] = false">close</i>
        </h4>
      </div>
    </div>

    <hr />
    
    <div class="row">
      <div class="col-md-9">
        <h5>Cabeza y cuello</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[1]" class="form-control" placeholder="Escribe aquí el resultado de este examen físico" rows="5"></textarea> 
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <h5>Recursos</h5>
        <p *ngIf="recursosASubir[1] != null && recursosAActualizar[1]">
          {{recursosASubir[1].name}}
          <a><i class="material-icons" (click)="handleFileInput(null, 1)">delete</i></a>
        </p>
        <p *ngIf="recursosActuales[1] != null && !recursosAActualizar[1]">
          {{recursosActuales[1]}}
          <a><i class="material-icons" (click)="handleFileInput(null, 1)">delete</i></a>
        </p>
        <div class="form-group adjuntar-recursos">
          <label for="file">Adjunta un recurso</label>
          <input type="file" 
                class="file-upload"
                (change)="handleFileInput($event.target.files[0], 1)"
          #fileUpload1 />
        </div>
        <button class="btn btn-adjuntar" (click)="fileUpload1.click()">Adjunta un recurso</button>
      </div>
    </div>
    <div *ngIf="alert_archivo[1]" class="row">
      <br />
      <div class="col-md-1">
      </div>
      <div class="alert alert-danger col-md-10">
        <h4>
          <strong>Error:</strong>
          El formato del archivo seleccionado no es válido. Si es una imagen, recomendamos que esté en formato .png o .jpg, y si es un audio 
          el formato deseado es .mp3 o .wav
          <i class="material-icons cerrar_alerta" (click)="alert_archivo[1] = false">close</i>
        </h4>
      </div>
    </div>

    <hr />
    
    <div class="row">
      <div class="col-md-9">
        <h5>Cardiovascular</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[2]" class="form-control" placeholder="Escribe aquí el resultado de este examen físico" rows="5"></textarea> 
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <h5>Recursos</h5>
        <p *ngIf="recursosASubir[2] != null && recursosAActualizar[2]">
          {{recursosASubir[2].name}}
          <a><i class="material-icons" (click)="handleFileInput(null, 2)">delete</i></a>
        </p>
        <p *ngIf="recursosActuales[2] != null && !recursosAActualizar[2]">
          {{recursosActuales[2]}}
          <a><i class="material-icons" (click)="handleFileInput(null, 2)">delete</i></a>
        </p>
        <div class="form-group adjuntar-recursos">
          <label for="file">Adjunta un recurso</label>
          <input type="file" 
                class="file-upload"
                (change)="handleFileInput($event.target.files[0], 2)"
          #fileUpload2 />
        </div>
        <button class="btn btn-adjuntar" (click)="fileUpload2.click()">Adjunta un recurso</button>
      </div>
    </div>
    <div *ngIf="alert_archivo[2]" class="row">
      <br />
      <div class="col-md-1">
      </div>
      <div class="alert alert-danger col-md-10">
        <h4>
          <strong>Error:</strong>
          El formato del archivo seleccionado no es válido. Si es una imagen, recomendamos que esté en formato .png o .jpg, y si es un audio 
          el formato deseado es .mp3 o .wav
          <i class="material-icons cerrar_alerta" (click)="alert_archivo[2] = false">close</i>
        </h4>
      </div>
    </div>

    <hr />
    
    <div class="row">
      <div class="col-md-9">
        <h5>Respiratorio</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[3]" class="form-control" placeholder="Escribe aquí el resultado de este examen físico" rows="5"></textarea> 
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <h5>Recursos</h5>
        <p *ngIf="recursosASubir[3] != null && recursosAActualizar[3]">
          {{recursosASubir[3].name}}
          <a><i class="material-icons" (click)="handleFileInput(null, 3)">delete</i></a>
        </p>
        <p *ngIf="recursosActuales[3] != null && !recursosAActualizar[3]">
          {{recursosActuales[3]}}
          <a><i class="material-icons" (click)="handleFileInput(null, 3)">delete</i></a>
        </p>
        <div class="form-group adjuntar-recursos">
          <label for="file">Adjunta un recurso</label>
          <input type="file" 
                class="file-upload"
                (change)="handleFileInput($event.target.files[0], 3)"
          #fileUpload3 />
        </div>
        <button class="btn btn-adjuntar" (click)="fileUpload3.click()">Adjunta un recurso</button>
      </div>
    </div>
    <div *ngIf="alert_archivo[3]" class="row">
      <br />
      <div class="col-md-1">
      </div>
      <div class="alert alert-danger col-md-10">
        <h4>
          <strong>Error:</strong>
          El formato del archivo seleccionado no es válido. Si es una imagen, recomendamos que esté en formato .png o .jpg, y si es un audio 
          el formato deseado es .mp3 o .wav
          <i class="material-icons cerrar_alerta" (click)="alert_archivo[3] = false">close</i>
        </h4>
      </div>
    </div>

    <hr />
    
    <div class="row">
      <div class="col-md-9">
        <h5>Abdomen</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[4]" class="form-control" placeholder="Escribe aquí el resultado de este examen físico" rows="5"></textarea> 
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <h5>Recursos</h5>
        <p *ngIf="recursosASubir[4] != null && recursosAActualizar[4]">
          {{recursosASubir[4].name}}
          <a><i class="material-icons" (click)="handleFileInput(null, 4)">delete</i></a>
        </p>
        <p *ngIf="recursosActuales[4] != null && !recursosAActualizar[4]">
          {{recursosActuales[4]}}
          <a><i class="material-icons" (click)="handleFileInput(null, 4)">delete</i></a>
        </p>
        <div class="form-group adjuntar-recursos">
          <label for="file">Adjunta un recurso</label>
          <input type="file" 
                class="file-upload"
                (change)="handleFileInput($event.target.files[0], 4)"
          #fileUpload4 />
        </div>
        <button class="btn btn-adjuntar" (click)="fileUpload4.click()">Adjunta un recurso</button>
      </div>
    </div>
    <div *ngIf="alert_archivo[4]" class="row">
      <br />
      <div class="col-md-1">
      </div>
      <div class="alert alert-danger col-md-10">
        <h4>
          <strong>Error:</strong>
          El formato del archivo seleccionado no es válido. Si es una imagen, recomendamos que esté en formato .png o .jpg, y si es un audio 
          el formato deseado es .mp3 o .wav
          <i class="material-icons cerrar_alerta" (click)="alert_archivo[4] = false">close</i>
        </h4>
      </div>
    </div>

    <hr />
    
    <div class="row">
      <div class="col-md-9">
        <h5>Extremidades</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[5]" class="form-control" placeholder="Escribe aquí el resultado de este examen físico" rows="5"></textarea> 
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <h5>Recursos</h5>
        <p *ngIf="recursosASubir[5] != null && recursosAActualizar[5]">
          {{recursosASubir[5].name}}
          <a><i class="material-icons" (click)="handleFileInput(null, 5)">delete</i></a>
        </p>
        <p *ngIf="recursosActuales[5] != null && !recursosAActualizar[5]">
          {{recursosActuales[5]}}
          <a><i class="material-icons" (click)="handleFileInput(null, 5)">delete</i></a>
        </p>
        <div class="form-group adjuntar-recursos">
          <label for="file">Adjunta un recurso</label>
          <input type="file" 
                class="file-upload"
                (change)="handleFileInput($event.target.files[0], 5)"
          #fileUpload5 />
        </div>
        <button class="btn btn-adjuntar" (click)="fileUpload5.click()">Adjunta un recurso</button>
      </div>      
    </div>
    <div *ngIf="alert_archivo[5]" class="row">
      <br />
      <div class="col-md-1">
      </div>
      <div class="alert alert-danger col-md-10">
        <h4>
          <strong>Error:</strong>
          El formato del archivo seleccionado no es válido. Si es una imagen, recomendamos que esté en formato .png o .jpg, y si es un audio 
          el formato deseado es .mp3 o .wav
          <i class="material-icons cerrar_alerta" (click)="alert_archivo[5] = false">close</i>
        </h4>
      </div>
    </div>

    <hr />
    
    <div class="row">
      <div class="col-md-9">
        <h5>Neurológico</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[6]" class="form-control" placeholder="Escribe aquí el resultado de este examen físico" rows="5"></textarea> 
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <h5>Recursos</h5>
        <p *ngIf="recursosASubir[6] != null && recursosAActualizar[6]">
          {{recursosASubir[6].name}}
          <a><i class="material-icons" (click)="handleFileInput(null, 6)">delete</i></a>
        </p>
        <p *ngIf="recursosActuales[6] != null && !recursosAActualizar[6]">
          {{recursosActuales[6]}}
          <a><i class="material-icons" (click)="handleFileInput(null, 6)">delete</i></a>
        </p>
        <div class="form-group adjuntar-recursos">
          <label for="file">Adjunta un recurso</label>
          <input type="file" 
                class="file-upload"
                (change)="handleFileInput($event.target.files[0], 6)"
          #fileUpload6 />
        </div>
        <button class="btn btn-adjuntar" (click)="fileUpload6.click()">Adjunta un recurso</button>
      </div>
    </div>
    <div *ngIf="alert_archivo[6]" class="row">
      <br />
      <div class="col-md-1">
      </div>
      <div class="alert alert-danger col-md-10">
        <h4>
          <strong>Error:</strong>
          El formato del archivo seleccionado no es válido. Si es una imagen, recomendamos que esté en formato .png o .jpg, y si es un audio 
          el formato deseado es .mp3 o .wav
          <i class="material-icons cerrar_alerta" (click)="alert_archivo[6] = false">close</i>
        </h4>
      </div>
    </div>

    <hr />
    
    <div class="row">
      <div class="col-md-9">
        <h5>Piel y anexos</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[7]" class="form-control" placeholder="Escribe aquí el resultado de este examen físico" rows="5"></textarea> 
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <h5>Recursos</h5>
        <p *ngIf="recursosASubir[7] != null && recursosAActualizar[7]">
          {{recursosASubir[7].name}}
          <a><i class="material-icons" (click)="handleFileInput(null, 7)">delete</i></a>
        </p>
        <p *ngIf="recursosActuales[7] != null && !recursosAActualizar[7]">
          {{recursosActuales[7]}}
          <a><i class="material-icons" (click)="handleFileInput(null, 7)">delete</i></a>
        </p>
        <div class="form-group adjuntar-recursos">
          <label for="file">Adjunta un recurso</label>
          <input type="file" 
                class="file-upload"
                (change)="handleFileInput($event.target.files[0], 7)"
          #fileUpload7 />
        </div>
        <button class="btn btn-adjuntar" (click)="fileUpload7.click()">Adjunta un recurso</button>
      </div>
    </div>
    <div *ngIf="alert_archivo[7]" class="row">
      <br />
      <div class="col-md-1">
      </div>
      <div class="alert alert-danger col-md-10">
        <h4>
          <strong>Error:</strong>
          El formato del archivo seleccionado no es válido. Si es una imagen, recomendamos que esté en formato .png o .jpg, y si es un audio 
          el formato deseado es .mp3 o .wav
          <i class="material-icons cerrar_alerta" (click)="alert_archivo[7] = false">close</i>
        </h4>
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col-md-9">
        <h5>Otros</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[8]" class="form-control" placeholder="Escribe aquí el resultado de este examen físico" rows="5"></textarea> 
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <h5>Recursos</h5>
        <p *ngIf="recursosASubir[8] != null && recursosAActualizar[8]">
          {{recursosASubir[8].name}}
          <a><i class="material-icons" (click)="handleFileInput(null, 8)">delete</i></a>
        </p>
        <p *ngIf="recursosActuales[8] != null && !recursosAActualizar[8]">
          {{recursosActuales[8]}}
          <a><i class="material-icons" (click)="handleFileInput(null, 8)">delete</i></a>
        </p>
        <div class="form-group adjuntar-recursos">
          <label for="file">Adjunta un recurso</label>
          <input type="file" 
                class="file-upload"
                (change)="handleFileInput($event.target.files[0], 8)"
          #fileUpload8 />
        </div>
        <button class="btn btn-adjuntar" (click)="fileUpload8.click()">Adjunta un recurso</button>
      </div>      
    </div>
    <div *ngIf="alert_archivo[8]" class="row">
      <br />
      <div class="col-md-1">
      </div>
      <div class="alert alert-danger col-md-10">
        <h4>
          <strong>Error:</strong>
          El formato del archivo seleccionado no es válido. Si es una imagen, recomendamos que esté en formato .png o .jpg, y si es un audio 
          el formato deseado es .mp3 o .wav
          <i class="material-icons cerrar_alerta" (click)="alert_archivo[8] = false">close</i>
        </h4>
      </div>
    </div>

    <hr />

    <div>
      <h5>Indica si el paciente siente dolor en:</h5>
        <div class="checkbox">
          <label>
            <input type="checkbox" [(ngModel)]="dolor_cabeza">
            Cabeza y cuello
          </label>
        </div>
        <div class="checkbox">
          <label>
            <input type="checkbox" [(ngModel)]="dolor_torax">
            Tórax
          </label>
        </div>
        <div class="checkbox">
          <label>
            <input type="checkbox" [(ngModel)]="dolor_abdomen">
            Abdomen
          </label>
        </div>
        <div class="checkbox">
          <label>
            <input type="checkbox" [(ngModel)]="dolor_extremidades">
            Extremidades
          </label>
        </div>
    </div>

    <hr />

    <div class="row">
      <div class="col-md-12 text-right">
        <a routerLink="/panel/detalle-caso">
          <button class="btn btn-success" (click)="guardarCambios()">Guardar cambios</button>
        </a>
      </div>
    </div>
  </div>
</div>