<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-2 text-left">
        <h3><strong>Paciente</strong></h3>
      </div>
      <div *ngIf="isMobileMenu();then content_mobile else content_web"></div>
      <ng-template #content_web>
        <div class="col-md-10 text-right">
          <a routerLink="/panel/detalle-caso">
            <button class="btn btn-success" (click)="guardarCambios()">Guardar cambios</button>
          </a>
        </div>
      </ng-template>
      <ng-template #content_mobile>
        <div class="col-md-10 text-left">
          <a routerLink="/panel/detalle-caso">
            <button class="btn btn-success" (click)="guardarCambios()">Guardar cambios</button>
          </a>
        </div>
      </ng-template>
    </div>
    <div class="row">
      <div class="col-md-12 text-left">
        <p><a routerLink="/panel/casos" (click)="guardarCambios()">Casos</a>/<a routerLink="/panel/detalle-caso" (click)="guardarCambios()">Editar caso</a>/<strong>Paciente</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <h5>Nombre del paciente</h5>
        <div class="card">
          <div class="card-content bg-white">
            <div class="form-group">
              <input [(ngModel)]="nombre_paciente" type="text" value="" placeholder="Escribe acá el nombre del paciente" class="form-control" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <h5>Género</h5>
        <p *ngIf="isMobileMenu()">{{genero}}</p>
        <div class="dropdown">
          <a class="btn dropdown-toggle" data-toggle="dropdown">
              Selecciona el género
              <b class="caret"></b>
          </a>
          <ul class="dropdown-menu">
            <li><a (click)="genero='Mujer transgénero'">Mujer trasngénero</a></li>
            <li><a (click)="genero='Hombre transgénero'">Hombre transgénero</a></li>
            <li><a (click)="genero='Mujer cisgénero'">Mujer cisgénero</a></li>
            <li><a (click)="genero='Hombre cisgénero'">Hombre cisgénero</a></li>
          </ul>
        </div>
        <p *ngIf="!isMobileMenu()">{{genero}}</p>
      </div>
      <div *ngIf="posibleEmbarazada()" class="col-md-4">
        <h5>Embarazada</h5>
        <div class="checkbox">
          <label>
            <input type="checkbox" [(ngModel)]="embarazada">
            {{msg_embarazo}}
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <h5>Edad</h5>
        <div class="card">
          <div class="card-content bg-white">
            <div class="form-group">
              <input [(ngModel)]="edad" type="text" value="" placeholder="E.g. '44 años', '6 meses'" class="form-control" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <h5>Peso</h5>
        <div class="card">
          <div class="card-content bg-white">
            <div class="form-group">
              <input [(ngModel)]="peso" type="text" value="" placeholder="E.g. '64kg'" class="form-control" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <h5>Talla</h5>
        <div class="card">
          <div class="card-content bg-white">
            <div class="form-group">
              <input [(ngModel)]="talla" type="text" value="" placeholder="E.g. '1.73m'" class="form-control" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <h5>Grupo étnico</h5>
        <p *ngIf="isMobileMenu()">{{etnia}}</p>
        <div class="dropdown">
          <a class="btn dropdown-toggle" data-toggle="dropdown">
              Selecciona el grupo étnico
              <b class="caret"></b>
          </a>
          <ul class="dropdown-menu">
            <li><a (click)="seleccionarEtnia('Ninguno')">Ninguno</a></li>
            <li><a (click)="seleccionarEtnia('Afrocolombianx')">Afrocolombianx</a></li>
            <li><a (click)="seleccionarEtnia('Indígena')">Indígena</a></li>
          </ul>
        </div>
        <p *ngIf="!isMobileMenu()">{{etnia}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h5>Motivo de consulta</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="motivo_consulta" class="form-control" placeholder="Escribe aquí el motivo por el que paciente consulta" rows="5"></textarea> 
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-4">
        <h5>Frecuencia cardiaca</h5>
        <div class="card">
          <div class="card-content bg-white">
            <div class="form-group">
              <input [(ngModel)]="frecuencia_cardiaca" type="text" value="" placeholder="E.g. '90bpm'" class="form-control" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <h5>Frecuencia respiratoria</h5>
        <div class="card">
          <div class="card-content bg-white">
            <div class="form-group">
              <input [(ngModel)]="frecuencia_respiratoria" type="text" value="" placeholder="E.g. '16rpm'" class="form-control" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <h5>Saturación</h5>
        <div class="card">
          <div class="card-content bg-white">
            <div class="form-group">
              <input [(ngModel)]="saturacion" type="text" value="" placeholder="E.g. '94%'" class="form-control" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <h5>Tensión arterial</h5>
        <div class="card">
          <div class="card-content bg-white">
            <div class="form-group">
              <input [(ngModel)]="tension_arterial" type="text" value="" placeholder="E.g. '135/85'" class="form-control" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <h5>VAS</h5>
        <div class="card">
          <div class="card-content bg-white">
            <div class="form-group">
              <input [(ngModel)]="VAS" type="text" value="" placeholder="E.g. '9'" class="form-control" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <h5>Temperatura</h5>
        <div class="card">
          <div class="card-content bg-white">
            <div class="form-group">
              <input [(ngModel)]="temperatura" type="text" value="" placeholder="E.g. '36.1ºC'" class="form-control" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="edad.trim() != '' && !edadValida()" class="row">
      <br />
      <div class="alert alert-danger col-md-12">
        <h4>
          <strong>Error:</strong>
          La edad "{{edad}}" no es válida. Ejemplos de edades válidas son "45 años", "2 semanas", y "1 mes".
        </h4>
      </div>
    </div>

    <div *ngIf="peso.trim() != '' && !pesoValido()" class="row">
      <br />
      <div class="alert alert-danger col-md-12">
        <h4>
          <strong>Error:</strong>
          El peso "{{peso}}" no es válido. Ejemplos de pesos válidos son "30kg", "2kg", y "287kg".
        </h4>
      </div>
    </div>

    <div *ngIf="talla.trim() != '' && !tallaValida()" class="row">
      <br />
      <div class="alert alert-danger col-md-12">
        <h4>
          <strong>Error:</strong>
          La talla "{{talla}}" no es válida. Ejemplos de tallas válidas son "1.80m", "0.50m", y "2.60m".
        </h4>
      </div>
    </div>
    
    <div *ngIf="frecuencia_cardiaca.trim() != '' && !frecuenciaValida('cardiaca')" class="row">
      <br />
      <div class="alert alert-danger col-md-12">
        <h4>
          <strong>Error:</strong>
          La frecuencia cardiaca "{{frecuencia_cardiaca}}" no es válida. Ejemplos de frecuencias cardiacas válidas son "90bpm", "120bpm", y "42bpm".
        </h4>
      </div>
    </div>

    <div *ngIf="frecuencia_respiratoria.trim() != '' && !frecuenciaValida('respiratoria')" class="row">
      <br />
      <div class="alert alert-danger col-md-12">
        <h4>
          <strong>Error:</strong>
          La frecuencia respiratoria "{{frecuencia_respiratoria}}" no es válida. Ejemplos de frecuencias respiratorias válidas son "10rpm", "12rpm", y "5rpm".
        </h4>
      </div>
    </div>

    <div *ngIf="saturacion.trim() != '' && !saturacionValida()" class="row">
      <br />
      <div class="alert alert-danger col-md-12">
        <h4>
          <strong>Error:</strong>
          La saturación "{{saturacion}}" no es válida. Ejemplos de saturaciones válidas son "95%", "2%", y "80%".
        </h4>
      </div>
    </div>

    <div *ngIf="tension_arterial.trim() != '' && !tensionArterialValida()" class="row">
      <br />
      <div class="alert alert-danger col-md-12">
        <h4>
          <strong>Error:</strong>
          La tensión arterial "{{tension_arterial}}" no es válida. Ejemplos de tensiones arteriales válidas son "135/85", y "120/80".
        </h4>
      </div>
    </div>

    <div *ngIf="VAS.trim() != '' && !VASValido()" class="row">
      <br />
      <div class="alert alert-danger col-md-12">
        <h4>
          <strong>Error:</strong>
          El VAS "{{VAS}}" no es válido. Ejemplos de VAS válidos son "9", "10", y "1".
        </h4>
      </div>
    </div>

    <div *ngIf="temperatura.trim() != '' && !temperaturaValida()" class="row">
      <br />
      <div class="alert alert-danger col-md-12">
        <h4>
          <strong>Error:</strong>
          La temperatura "{{temperatura}}" no es válida. Ejemplos de temperaturas válidas son "32.1ºC", "42.5ºC", y "35.0ºC".
        </h4>
      </div>
    </div>

  </div>
</div>