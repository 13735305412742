<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 text-left">
        <h3><strong>Ayudas diagnósticas</strong></h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-left">
        <p><a (click)="guardarCambios()" routerLink="/panel/casos">Casos</a>/<a (click)="guardarCambios()" routerLink="/panel/crear-caso">Crear caso</a>/<strong>Ayudas diagnósticas</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 text-center nav-col">
        <div *ngIf="!renderLabs" class="card nav-unselected" (click)="navegarALabs()">
          <div class="card-content">
            <h4 class="nav-title"><strong>Laboratorios</strong></h4>
          </div>
        </div>
        <div *ngIf="renderLabs" class="card nav-selected">
          <div class="card-content">
            <h4 class="nav-title"><strong>Laboratorios</strong></h4>
          </div>
        </div>
      </div>
      <div class="col-md-4 text-center nav-col">
        <div *ngIf="!renderImgs" class="card nav-unselected" (click)="navegarAImgs()">
          <div class="card-content">
            <h4 class="nav-title"><strong>Imágenes diagnósticas</strong></h4>
          </div>
        </div>
        <div *ngIf="renderImgs" class="card nav-selected">
          <div class="card-content">
            <h4 class="nav-title"><strong>Imágenes diagnósticas</strong></h4>
          </div>
        </div>
      </div>
      <div class="col-md-4 text-center nav-col">
        <div *ngIf="!renderOtras" class="card nav-unselected" (click)="navegarAOtras()">
          <div class="card-content">
            <h4 class="nav-title"><strong>Otras ayudas</strong></h4>
          </div>
        </div>
        <div *ngIf="renderOtras" class="card nav-selected">
          <div class="card-content">
            <h4 class="nav-title"><strong>Otras ayudas</strong></h4>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <!--Laboratorios---------------------------------------------------------------------------->
      <div *ngIf="renderLabs" class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <h4>Seleccionar el tipo de laboratorio</h4>
            <p>{{laboratorios_tipo_actual}}</p>
            <div class="dropdown">
              <a class="btn dropdown-toggle" data-toggle="dropdown">
                  Selecciona el tipo de laboratorio
                  <b class="caret"></b>
              </a>
              <ul class="dropdown-menu">
                <li><a (click)="seleccionarTipoLab('Laboratorios clínicos')">Laboratorios clínicos</a></li>
                <li><a (click)="seleccionarTipoLab('Pruebas de hematología rutinarias')">Pruebas de hematología rutinarias</a></li>
                <li><a (click)="seleccionarTipoLab('Pruebas de orina')">Pruebas de orina</a></li>
                <li><a (click)="seleccionarTipoLab('Inmunología rutinaria')">Inmunología rutinaria</a></li>
                <li><a (click)="seleccionarTipoLab('Laboratorios hematológicos')">Laboratorios hematológicos</a></li>
                <li><a (click)="seleccionarTipoLab('Pruebas de enzimas')">Pruebas de enzimas</a></li>
                <li><a (click)="seleccionarTipoLab('Minerales y vitaminas')">Minerales y vitaminas</a></li>
                <li><a (click)="seleccionarTipoLab('Test endocrinología')">Test endocrinología</a></li>
                <li><a (click)="seleccionarTipoLab('Toxicología')">Toxicología</a></li>
                <li><a (click)="seleccionarTipoLab('Mediciones farmacológicas')">Mediciones farmacológicas</a></li>
                <li><a (click)="seleccionarTipoLab('Niveles de anticuerpos bacterianos')">Niveles de anticuerpos bacterianos</a></li>
                <li><a (click)="seleccionarTipoLab('Inmunología')">Inmunología</a></li>
                <li><a (click)="seleccionarTipoLab('Marcadores tumorales')">Marcadores tumorales</a></li>
                <li><a (click)="seleccionarTipoLab('Pruebas de coprología')">Pruebas de coprología</a></li>
                <li><a (click)="seleccionarTipoLab('Pruebas inmunológicas para microorganismos')">Pruebas inmunológicas para microorganismos</a></li>
              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <h4>Adjuntar un recurso</h4>
            <div *ngIf="laboratorios_adjunto_actual != null">
              <p>
                {{laboratorios_adjunto_actual.name}}
                <a><i class="material-icons" (click)="handleFileLabInput(null)">delete</i></a>
              </p>
            </div>
            <div class="form-group adjuntar-recursos">
              <label for="file">Adjunta un recurso</label>
              <input type="file" 
                    class="file-upload"
                    (change)="handleFileLabInput($event.target.files[0])"
              #fileUploadLab />
            </div>
            <button class="btn btn-adjuntar" (click)="fileUploadLab.click()">
              Adjunta un audio o una foto para este laboratorio
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <h4>Seleccionar un laboratorio</h4>
            <p>{{laboratorios_lab_actual}}</p>
            <div class="dropdown">
              <a class="btn dropdown-toggle" data-toggle="dropdown">
                  Selecciona un laboratorio
                  <b class="caret"></b>
              </a>
              <ul  *ngIf="laboratorios_pregs_actuales.length != 0" class="dropdown-menu">
                <li *ngFor="let lab of laboratorios_pregs_actuales; let i = index">
                  <a (click)="seleccionarLab(lab, i)">
                    {{lab}}
                  </a>
                </li>
              </ul>

              <ul  *ngIf="laboratorios_pregs_actuales.length == 0" class="dropdown-menu">
                <li>
                  <a>
                    <strong>
                      Primero selecciona un tipo de laboratorio arriba
                    </strong>
                  </a>
                </li>
              </ul>
            </div>

            <h4>Correcto u omitible</h4>
            <p *ngIf="laboratorios_pt_actual != null && laboratorios_pt_actual != ''">
              Laboratorio {{renderCorrectoOmitible(laboratorios_pt_actual).toLowerCase()}}
            </p>
            <div class="dropdown">
              <a class="btn dropdown-toggle" data-toggle="dropdown">
                  Indica si el laboratorio aporta puntos o no
                  <b class="caret"></b>
              </a>
              <ul class="dropdown-menu">
                <li><a (click)="laboratorios_pt_actual = 'positivo'">Laboratorio correcto</a></li>
                <li><a (click)="laboratorios_pt_actual = 'omitible'">Laboratorio omitible</a></li>
              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <h4>Resultado del laboratorio</h4>
            <div class="card">
              <div class="card-content">
                <div class="form-group">
                  <textarea [(ngModel)]="laboratorios_resp_actual" class="form-control" placeholder="Escribe el resultado de este laboratorio" rows="7"></textarea> 
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />
    
        <div class="row">
          <div class="col-md-12 text-left">
            <a routerLink="/panel/crear-caso-ayudas">
              <button class="btn btn-success" (click)="agregarLab()">Agregar laboratorio</button>
            </a>
          </div>
        </div>

        <div *ngIf="alert_incompleto" class="row ">
          <br />
          <div class="col-md-4">
          </div>
          <div class="alert alert-danger col-md-4">
            <h4>
              <strong>Error:</strong> Faltan campos por llenar.
              <i class="material-icons cerrar_alerta" (click)="alert_incompleto = false">close</i>
            </h4>
          </div>
        </div>

        <div *ngIf="alert_repetido" class="row">
          <br />
          <div class="col-md-1">
          </div>
          <div class="alert alert-danger col-md-10">
            <h4>
              <strong>Error:</strong>
              El laboratorio seleccionado ya tiene respuesta en la tabla de abajo.
              <i class="material-icons cerrar_alerta" (click)="alert_repetido = false">close</i>
            </h4>
          </div>
        </div>

        <div *ngIf="cntLabsAgregados() != 0" class="row">
          <hr />

          <div class="col-md-12">
            <div class="card">
              <div class="card-content bg-white">
                <div class="table-responsive p-3 mx-auto">
                  <table class="table table-striped table-hover">
                    <thead class="thead-light">
                      <tr>
                        <th class="text-center" scope="col">
                          <p>
                            <strong>Laboratorio</strong>
                          </p>
                        </th>
                        <th class="text-center" scope="col">
                          <p>
                            <strong>Correcto/Omitible</strong>
                          </p>
                        </th>
                        <th class="text-center" scope="col">
                          <p>
                            <strong>Resultado</strong>
                          </p>
                        </th>
                        <th class="text-center" scope="col">
                          <p>
                            <strong>Adjunto</strong>
                          </p>
                        </th>
                        <th class="text-center" scope="col">
                          <p>
                            <strong>Eliminar</strong>
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let lab of laboratorios_nombres; let i = index" scope="row">
                        <td *ngIf="lab != null" class="text-center">
                          {{lab}}
                        </td>
                        <td *ngIf="lab != null" class="text-center">
                          {{renderCorrectoOmitible(laboratorios_pts[i])}}
                        </td>
                        <td *ngIf="lab != null && laboratorios_resps[i] != null && laboratorios_resps[i] != ''" class="text-center">
                          {{laboratorios_resps[i]}}
                        </td>
                        <td *ngIf="lab != null && (laboratorios_resps[i] == null || laboratorios_resps[i] == '')" class="text-center">
                          No hay respuesta para este laboratorio
                        </td>
                        <td *ngIf="lab != null && laboratorios_adjuntos[i] != null && laboratorios_adjuntos[i].name != ''" class="text-center">
                          {{laboratorios_adjuntos[i].name}}
                        </td>
                        <td *ngIf="lab != null && (laboratorios_adjuntos[i] == null || laboratorios_adjuntos[i].name == '')" class="text-center">
                          No hay archivo adjunto para este laboratorio
                        </td>
                        <td *ngIf="lab != null" class="text-center">
                          <i class="material-icons" (click)="eliminarLab(i)">delete</i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <!--Imágenes diagnósticas------------------------------------------------------------------->
      <div *ngIf="renderImgs" class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <h4>Seleccionar el tipo de la imagen</h4>
            <p>{{imgs_tipo}}</p>
            <div class="dropdown">
              <a class="btn dropdown-toggle" data-toggle="dropdown">
                  Tipo
                  <b class="caret"></b>
              </a>
              <ul class="dropdown-menu">
                <li><a (click)="imgs_tipo = 'Radiografía'">Radiografía</a></li>
                <li><a (click)="imgs_tipo = 'Ultrasonografía'">Ultrasonografía</a></li>
                <li><a (click)="imgs_tipo = 'Tomografía'">Tomografía</a></li>
                <li><a (click)="imgs_tipo = 'Resonancia magnética'">Resonancia magnética</a></li>
              </ul>
            </div>

            <h4>Seleccionar la lateralidad de la imagen</h4>
            <p>{{imgs_lateralidad}}</p>
            <div class="dropdown">
              <a class="btn dropdown-toggle" data-toggle="dropdown">
                  Lateralidad
                  <b class="caret"></b>
              </a>
              <ul class="dropdown-menu">
                <li><a (click)="imgs_lateralidad = 'No es necesario'">No es necesario</a></li>
                <li><a (click)="imgs_lateralidad = 'Derecha'">Derecha</a></li>
                <li><a (click)="imgs_lateralidad = 'Izquierda'">Izquierda</a></li>
              </ul>
            </div>

            <h4>Seleccionar el contraste a usar</h4>
            <p>{{imgs_contraste}}</p>
            <div class="dropdown">
              <a class="btn dropdown-toggle" data-toggle="dropdown">
                  Uso de contraste
                  <b class="caret"></b>
              </a>
              <ul class="dropdown-menu">
                <li><a (click)="imgs_contraste = 'Simple'">Simple</a></li>
                <li><a (click)="imgs_contraste = 'Con contraste'">Con contraste</a></li>
              </ul>
            </div>

            <h4>Adjuntar un recurso</h4>
            <div *ngIf="imgs_adjunta_actual != null">
              <p>
                {{imgs_adjunta_actual.name}}
                <a><i class="material-icons" (click)="handleFileImgInput(null)">delete</i></a>
              </p>
            </div>
            <div class="form-group adjuntar-recursos">
              <label for="file">Adjunta un recurso</label>
              <input type="file" 
                    class="file-upload"
                    (change)="handleFileImgInput($event.target.files[0])"
              #fileUploadImg />
            </div>
            <button class="btn btn-adjuntar" (click)="fileUploadImg.click()">
              Adjunta una imagen para este examen
            </button>
          </div>

          <div class="col-md-6">
            <h4>Seleccionar la ubicación de la imagen</h4>
            <p>{{imgs_ubicacion}}</p>
            <div class="dropdown">
              <a class="btn dropdown-toggle" data-toggle="dropdown">
                  Ubicación
                  <b class="caret"></b>
              </a>
              <ul class="dropdown-menu">
                <li><a (click)="imgs_ubicacion = 'Craneo'">Craneo</a></li>
                <li><a (click)="imgs_ubicacion = 'Columna cervical'">Columna cervical</a></li>
                <li><a (click)="imgs_ubicacion = 'Tórax'">Tórax</a></li>
                <li><a (click)="imgs_ubicacion = 'Columna torácica'">Columna torácica</a></li>
                <li><a (click)="imgs_ubicacion = 'Abdomen'">Abdomen</a></li>
                <li><a (click)="imgs_ubicacion = 'Columna toracolumbar'">Columna toracolumbar</a></li>
                <li><a (click)="imgs_ubicacion = 'Vías urinarias'">Vías urinarias</a></li>
                <li><a (click)="imgs_ubicacion = 'Columna lumbosacra'">Columna lumbosacra</a></li>
                <li><a (click)="imgs_ubicacion = 'Pelvis'">Pelvis</a></li>
                <li><a (click)="imgs_ubicacion = 'Dedos'">Dedos</a></li>
                <li><a (click)="imgs_ubicacion = 'Mano'">Mano</a></li>
                <li><a (click)="imgs_ubicacion = 'Manos comparativas'">Manos comparativas</a></li>
                <li><a (click)="imgs_ubicacion = 'Antebrazo'">Antebrazo</a></li>
                <li><a (click)="imgs_ubicacion = 'Codo'">Codo</a></li>
                <li><a (click)="imgs_ubicacion = 'Húmero'">Húmero</a></li>
                <li><a (click)="imgs_ubicacion = 'Pie'">Pie</a></li>
                <li><a (click)="imgs_ubicacion = 'Pies comparativos'">Pies comparativos</a></li>
                <li><a (click)="imgs_ubicacion = 'Tobillo'">Tobillo</a></li>
                <li><a (click)="imgs_ubicacion = 'Tibia-peroné'">Tibia-peroné</a></li>
                <li><a (click)="imgs_ubicacion = 'Fémur'">Fémur</a></li>
              </ul>
            </div>

            <h4>Seleccionar el tipo de proyección de la imagen</h4>
            <p>{{imgs_proyeccion}}</p>
            <div class="dropdown">
              <a class="btn dropdown-toggle" data-toggle="dropdown">
                  Tipo de proyección
                  <b class="caret"></b>
              </a>
              <ul class="dropdown-menu">
                <li><a (click)="imgs_proyeccion = 'AP'">AP</a></li>
                <li><a (click)="imgs_proyeccion = 'Lateral'">Lateral</a></li>
                <li><a (click)="imgs_proyeccion = 'De pie'">De pie</a></li>
                <li><a (click)="imgs_proyeccion = 'Acostado'">Acostado</a></li>
                <li><a (click)="imgs_proyeccion = 'Oblicuo'">Oblicuo</a></li>
                <li><a (click)="imgs_proyeccion = 'Otra'">Otra</a></li>
              </ul>
            </div>

            <h4>Correcto u omitible</h4>
            <p *ngIf="imgs_puntuacion != ''">
              Imagen diagnóstica {{renderImgsCorrectoOmitible(imgs_puntuacion).toLowerCase()}}
            </p>
            <div class="dropdown">
              <a class="btn dropdown-toggle" data-toggle="dropdown">
                  Indica si la imagen aporta puntos o no
                  <b class="caret"></b>
              </a>
              <ul class="dropdown-menu">
                <li><a (click)="imgs_puntuacion = 'positivo'">Imagen correcta</a></li>
                <li><a (click)="imgs_puntuacion = 'omitible'">Imagen omitible</a></li>
              </ul>
            </div>
          </div>
        </div>

        <hr />
    
        <div class="row">
          <div class="col-md-12 text-left">
            <a routerLink="/panel/crear-caso-ayudas">
              <button class="btn btn-success" (click)="agregarImg()">Agregar imagen diagnóstica</button>
            </a>
          </div>
        </div>

        <div *ngIf="alert_img_incompleta" class="row">
          <br />
          <div class="col-md-1">
          </div>
          <div class="alert alert-danger col-md-10">
            <h4>
              <strong>Error:</strong>
              Debes llenar todos los campos antes de agregar el estudio.
              <i class="material-icons cerrar_alerta" (click)="alert_img_incompleta = false">close</i>
            </h4>
          </div>
        </div>

        <div *ngIf="alert_img_repetida" class="row">
          <br />
          <div class="col-md-1">
          </div>
          <div class="alert alert-danger col-md-10">
            <h4>
              <strong>Error:</strong>
              El estudio seleccionado ya fue agregado en la tabla de abajo.
              <i class="material-icons cerrar_alerta" (click)="alert_img_repetida = false">close</i>
            </h4>
          </div>
        </div>

        <div *ngIf="cntImgsAgregadas() != 0" class="row">
          <hr />

          <div class="col-md-12">
            <div class="card">
              <div class="card-content bg-white">
                <div class="table-responsive p-3 mx-auto">
                  <table class="table table-striped table-hover">
                    <thead class="thead-light">
                      <tr>
                        <th class="text-center" scope="col">
                          <p>
                            <strong>Tipo</strong>
                          </p>
                        </th>
                        <th class="text-center" scope="col">
                          <p>
                            <strong>Ubicación</strong>
                          </p>
                        </th>
                        <th class="text-center" scope="col">
                          <p>
                            <strong>Lateralidad</strong>
                          </p>
                        </th>
                        <th class="text-center" scope="col">
                          <p>
                            <strong>Proyección</strong>
                          </p>
                        </th>
                        <th class="text-center" scope="col">
                          <p>
                            <strong>Contraste</strong>
                          </p>
                        </th>
                        <th class="text-center" scope="col">
                          <p>
                            <strong>Correcta/Omitible</strong>
                          </p>
                        </th>
                        <th class="text-center" scope="col">
                          <p>
                            <strong>Adjunto</strong>
                          </p>
                        </th>
                        <th class="text-center" scope="col">
                          <p>
                            <strong>Eliminar</strong>
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let img of imgs_tipos; let i = index" scope="row">
                        <td *ngIf="img != null" class="text-center">
                          {{img}}
                        </td>
                        <td *ngIf="img != null" class="text-center">
                          {{imgs_ubicaciones[i]}}
                        </td>
                        <td *ngIf="img != null" class="text-center">
                          {{imgs_lateralidades[i]}}
                        </td>
                        <td *ngIf="img != null" class="text-center">
                          {{imgs_proyecciones[i]}}
                        </td>
                        <td *ngIf="img != null" class="text-center">
                          {{imgs_contrastes[i]}}
                        </td>
                        <td *ngIf="img != null" class="text-center">
                          {{renderImgsCorrectoOmitible(imgs_puntuaciones[i])}}
                        </td>
                        <td *ngIf="img != null" class="text-center">
                          {{imgs_adjuntos[i].name}}
                        </td>
                        <td *ngIf="img != null" class="text-center">
                          <i class="material-icons" (click)="eliminarImg(i)">delete</i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <!--Otras ayudas---------------------------------------------------------------------------->

      <div *ngIf="renderOtras" class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <h4>Seleccionar un laboratorio</h4>
            <p>{{otras_lab_actual}}</p>
            <div class="dropdown">
              <a class="btn dropdown-toggle" data-toggle="dropdown">
                  Selecciona un laboratorio
                  <b class="caret"></b>
              </a>
              <ul class="dropdown-menu">
                <li *ngFor="let lab of otras_pregs; let i = index">
                  <a (click)="otras_lab_actual = lab">{{lab}}</a>
                </li>
              </ul>
            </div>

            <h4>Correcto u omitible</h4>
            <p *ngIf="otras_pt_actual != ''">
              Laboratorio {{renderCorrectoOmitible(otras_pt_actual).toLowerCase()}}
            </p>
            <div class="dropdown">
              <a class="btn dropdown-toggle" data-toggle="dropdown">
                  Indica si el laboratorio aporta puntos o no
                  <b class="caret"></b>
              </a>
              <ul class="dropdown-menu">
                <li><a (click)="otras_pt_actual = 'positivo'">Laboratorio correcto</a></li>
                <li><a (click)="otras_pt_actual = 'omitible'">Laboratorio omitible</a></li>
              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <h4>Adjuntar un recurso</h4>
            <div *ngIf="otras_adjunto_actual != null">
              <p>
                {{otras_adjunto_actual.name}}
                <a><i class="material-icons" (click)="handleFileOtrasInput(null)">delete</i></a>
              </p>
            </div>
            <div class="form-group adjuntar-recursos">
              <label for="file">Adjunta un recurso</label>
              <input type="file" 
                    class="file-upload"
                    (change)="handleFileOtrasInput($event.target.files[0])"
              #fileUploadOtras />
            </div>
            <button class="btn btn-adjuntar" (click)="fileUploadOtras.click()">
              Adjunta un audio o una foto para este laboratorio
            </button>
            <h4>Resultado del laboratorio</h4>
            <div class="card">
              <div class="card-content">
                <div class="form-group">
                  <textarea [(ngModel)]="otras_resp_actual" class="form-control" placeholder="Escribe el resultado de este laboratorio" rows="7"></textarea> 
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-md-12 text-left">
            <a routerLink="/panel/crear-caso-ayudas">
              <button class="btn btn-success" (click)="agregarOtra()">Agregar laboratorio</button>
            </a>
          </div>
        </div>

        <div *ngIf="alert_otras_incompleto" class="row">
          <br />
          <div class="col-md-1">
          </div>
          <div class="alert alert-danger col-md-10">
            <h4>
              <strong>Error:</strong>
              Debes llenar todos los campos antes de agregar el laboratorio.
              <i class="material-icons cerrar_alerta" (click)="alert_otras_incompleto = false">close</i>
            </h4>
          </div>
        </div>

        <div *ngIf="alert_otras_repetido" class="row">
          <br />
          <div class="col-md-1">
          </div>
          <div class="alert alert-danger col-md-10">
            <h4>
              <strong>Error:</strong>
              El laboratorio seleccionado ya fue agregado en la tabla de abajo.
              <i class="material-icons cerrar_alerta" (click)="alert_otras_repetido = false">close</i>
            </h4>
          </div>
        </div>

        <div *ngIf="cntOtras() != 0" class="row">
          <hr />

          <div class="col-md-12">
            <div class="card">
              <div class="card-content bg-white">
                <div class="table-responsive p-3 mx-auto">
                  <table class="table table-striped table-hover">
                    <thead class="thead-light">
                      <tr>
                        <th class="text-center" scope="col">
                          <p>
                            <strong>Laboratorio</strong>
                          </p>
                        </th>
                        <th class="text-center" scope="col">
                          <p>
                            <strong>Correcto/Omitible</strong>
                          </p>
                        </th>
                        <th class="text-center" scope="col">
                          <p>
                            <strong>Resultado</strong>
                          </p>
                        </th>
                        <th class="text-center" scope="col">
                          <p>
                            <strong>Adjunto</strong>
                          </p>
                        </th>
                        <th class="text-center" scope="col">
                          <p>
                            <strong>Eliminar</strong>
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let lab of otras_nombres; let i = index" scope="row">
                        <td *ngIf="lab != null" class="text-center">
                          {{lab}}
                        </td>
                        <td *ngIf="lab != null" class="text-center">
                          {{renderCorrectoOmitible(otras_pts[i])}}
                        </td>
                        <td *ngIf="lab != null && otras_resps[i] != null && otras_resps[i] != ''" class="text-center">
                          {{otras_resps[i]}}
                        </td>
                        <td *ngIf="lab != null && (otras_resps[i] == null || otras_resps[i] == '')" class="text-center">
                          No hay respuesta para esta ayuda
                        </td>
                        <td *ngIf="lab != null && otras_adjuntos[i] != null && otras_adjuntos[i].name != ''" class="text-center">
                          {{otras_adjuntos[i].name}}
                        </td>
                        <td *ngIf="lab != null && (otras_adjuntos[i] == null || otras_adjuntos[i].name == '')" class="text-center">
                          No hay adjunto para esta ayuda
                        </td>
                        <td *ngIf="lab != null" class="text-center">
                          <i class="material-icons" (click)="eliminarOtra(i)">delete</i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div *ngIf="alert_archivo" class="row">
      <br />
      <div class="col-md-1">
      </div>
      <div class="alert alert-danger col-md-10">
        <h4>
          <strong>Error:</strong>
          El formato del archivo seleccionado no es válido. Si es una imagen, recomendamos que esté en formato .png o .jpg, y si es un audio 
          el formato deseado es .mp3 o .wav
          <i class="material-icons cerrar_alerta" (click)="alert_archivo = false">close</i>
        </h4>
      </div>
    </div>
    
  </div>
</div>