<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 text-left">
        <h3><strong>Tratamiento</strong></h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-left">
        <p><a routerLink="/panel/casos" (click)="guardarCambios()">Casos</a>/<a routerLink="/panel/detalle-caso" (click)="guardarCambios()">Editar caso</a>/<strong>Tratamiento</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <h4>Seleccionar el tipo de tratamiento</h4>
        <p>{{tipo_tratamiento}}</p>
        <div class="dropdown">
          <a class="btn dropdown-toggle" data-toggle="dropdown">
              Tipo de tratamiento
              <b class="caret"></b>
          </a>
          <ul class="dropdown-menu">
            <li><a (click)="seleccionarTipo('Dieta')">Dieta</a></li>
            <li><a (click)="seleccionarTipo('Soporte hídrico')">Soporte hídrico</a></li>
            <li><a (click)="seleccionarTipo('Medicamentos')">Medicamentos</a></li>
            <li><a (click)="seleccionarTipo('Oxígeno')">Oxígeno</a></li>
            <li><a (click)="seleccionarTipo('Medidas generales')">Medidas generales</a></li>
          </ul>
        </div>
      </div>

      <div *ngIf="tipo_tratamiento == 'Medicamentos'" class="col-md-6">
        <div class="row">
          <div class="col-md-6">
            <h4>Dosis</h4>
            <p>{{dosis}}</p>
            <div class="dropdown">
              <a class="btn dropdown-toggle" data-toggle="dropdown">
                  Dosis
                  <b class="caret"></b>
              </a>
              <ul class="dropdown-menu">
                <li><a (click)="dosis = '1 vez'">1 vez</a></li>
                <li><a (click)="dosis = '2 veces'">2 veces</a></li>
                <li><a (click)="dosis = '3 veces'">3 veces</a></li>
                <li><a (click)="dosis = '4 veces'">4 veces</a></li>
                <li><a (click)="dosis = '5 veces'">5 veces</a></li>
                <li><a (click)="dosis = '6 veces'">6 veces</a></li>
                <li><a (click)="dosis = '7 veces'">7 veces</a></li>
                <li><a (click)="dosis = '8 veces'">8 veces</a></li>
                <li><a (click)="dosis = '9 veces'">9 veces</a></li>
                <li><a (click)="dosis = '10 veces'">10 veces</a></li>
                <li><a (click)="dosis = '11 veces'">11 veces</a></li>
                <li><a (click)="dosis = '12 veces'">12 veces</a></li>
              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <h4>Frecuencia</h4>
            <p>{{frecuencia}}</p>
            <div class="dropdown">
              <a class="btn dropdown-toggle" data-toggle="dropdown">
                  Frecuencia
                  <b class="caret"></b>
              </a>
              <ul class="dropdown-menu">
                <li><a (click)="frecuencia = 'al día'">al día</a></li>
                <li><a (click)="frecuencia = 'a la semana'">a la semana</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="row">

      <div class="col-md-6">
        <h4>Seleccionar un tratamiento</h4>
        <p>{{tratamiento}}</p>
        
        <div *ngIf="tipo_tratamiento == ''" class="dropdown">
          <a class="btn dropdown-toggle" data-toggle="dropdown">
              Tratamiento
              <b class="caret"></b>
          </a>
          <ul class="dropdown-menu">
            <li><a><strong>
              Primero selecciona un tipo de tratamiento arriba
            </strong></a></li>
          </ul>
        </div>

        <div *ngIf="tipo_tratamiento != ''" class="dropdown">
          <a class="btn dropdown-toggle" data-toggle="dropdown">
              Tratamiento
              <b class="caret"></b>
          </a>
          <ul class="dropdown-menu">
            <li *ngFor="let trat of tratamientos; let i = index"><a (click)="tratamiento = trat">
              {{trat}}
            </a></li>
          </ul>
        </div>

      </div>

      <div class="col-md-6">
        <h4>Correcto u omitible</h4>
        <p *ngIf="tratamiento_pts != ''">
          Tratamiento {{renderCorrectoOmitible(tratamiento_pts).toLowerCase()}}
        </p>
        <div class="dropdown">
          <a class="btn dropdown-toggle" data-toggle="dropdown">
              Indica si el tratamiento aporta puntos o no
              <b class="caret"></b>
          </a>
          <ul class="dropdown-menu">
            <li><a (click)="tratamiento_pts = 'positivo'">Tratamiento correcto</a></li>
            <li><a (click)="tratamiento_pts = 'omitible'">Tratamiento omitible</a></li>
          </ul>
        </div>
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col-md-12 text-left">
        <button class="btn btn-success" (click)="agregarTratamiento()">
          Agregar tratamiento
        </button>
      </div>
    </div>

    <div *ngIf="alert_incompleto" class="row">
      <br />
      <div class="col-md-1">
      </div>
      <div class="alert alert-danger col-md-10">
        <h4>
          <strong>Error:</strong>
          Debes llenar todos los campos antes de agregar el tratamiento.
          <i class="material-icons cerrar_alerta" (click)="alert_incompleto = false">close</i>
        </h4>
      </div>
    </div>

    <div *ngIf="alert_repetido" class="row">
      <br />
      <div class="col-md-1">
      </div>
      <div class="alert alert-danger col-md-10">
        <h4>
          <strong>Error:</strong>
          El tratamiento seleccionado ya fue agregado en la tabla de abajo.
          <i class="material-icons cerrar_alerta" (click)="alert_repetido = false">close</i>
        </h4>
      </div>
    </div>

    <div *ngIf="cntAgregados() != 0" class="row">
      <hr />

      <div class="col-md-12">
        <div class="card">
          <div class="card-content bg-white">
            <div class="table-responsive p-3 mx-auto">
              <table class="table table-striped table-hover">
                <thead class="thead-light">
                  <tr>
                    <th class="text-center" scope="col">
                      <p>
                        <strong>Tipo</strong>
                      </p>
                    </th>
                    <th class="text-center" scope="col">
                      <p>
                        <strong>Tratamiento</strong>
                      </p>
                    </th>
                    <th class="text-center" scope="col">
                      <p>
                        <strong>Correcto/Omitible</strong>
                      </p>
                    </th>
                    <th class="text-center" scope="col">
                      <p>
                        <strong>Eliminar</strong>
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let t of dieta; let i = index" scope="row">
                    <td *ngIf="t != null" class="text-center">
                      Dieta
                    </td>
                    <td *ngIf="t != null" class="text-center">
                      {{t}}
                    </td>
                    <td *ngIf="t != null" class="text-center">
                      {{renderCorrectoOmitible(dieta_pts[i])}}
                    </td>
                    <td *ngIf="t != null" class="text-center">
                      <i class="material-icons" (click)="eliminarTratamiento(i, 'Dieta')">delete</i>
                    </td>
                  </tr>
                  <tr *ngFor="let t of soporte_hidrico; let i = index" scope="row">
                    <td *ngIf="t != null" class="text-center">
                      Soporte hídrico
                    </td>
                    <td *ngIf="t != null" class="text-center">
                      {{t}}
                    </td>
                    <td *ngIf="t != null" class="text-center">
                      {{renderCorrectoOmitible(soporte_hidrico_pts[i])}}
                    </td>
                    <td *ngIf="t != null" class="text-center">
                      <i class="material-icons" (click)="eliminarTratamiento(i, 'Soporte hídrico')">delete</i>
                    </td>
                  </tr>
                  <tr *ngFor="let t of medicamentos; let i = index" scope="row">
                    <td *ngIf="t != null" class="text-center">
                      Medicamentos
                    </td>
                    <td *ngIf="t != null" class="text-center">
                      {{t}}
                    </td>
                    <td *ngIf="t != null" class="text-center">
                      {{renderCorrectoOmitible(medicamentos_pts[i])}}
                    </td>
                    <td *ngIf="t != null" class="text-center">
                      <i class="material-icons" (click)="eliminarTratamiento(i, 'Medicamentos')">delete</i>
                    </td>
                  </tr>
                  <tr *ngFor="let t of oxigeno; let i = index" scope="row">
                    <td *ngIf="t != null" class="text-center">
                      Oxígeno
                    </td>
                    <td *ngIf="t != null" class="text-center">
                      {{t}}
                    </td>
                    <td *ngIf="t != null" class="text-center">
                      {{renderCorrectoOmitible(oxigeno_pts[i])}}
                    </td>
                    <td *ngIf="t != null" class="text-center">
                      <i class="material-icons" (click)="eliminarTratamiento(i, 'Oxígeno')">delete</i>
                    </td>
                  </tr>
                  <tr *ngFor="let t of medidas_generales; let i = index" scope="row">
                    <td *ngIf="t != null" class="text-center">
                      Medidas generales
                    </td>
                    <td *ngIf="t != null" class="text-center">
                      {{t}}
                    </td>
                    <td *ngIf="t != null" class="text-center">
                      {{renderCorrectoOmitible(medidas_generales_pts[i])}}
                    </td>
                    <td *ngIf="t != null" class="text-center">
                      <i class="material-icons" (click)="eliminarTratamiento(i, 'Medidas generales')">delete</i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>