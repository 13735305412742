// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBaEWla7NUcrfFIgW61DWCFhSSZZXU54E8",
    authDomain: "medicina-uniandes.firebaseapp.com",
    databaseURL: "https://medicina-uniandes-default-rtdb.firebaseio.com",
    projectId: "medicina-uniandes",
    storageBucket: "medicina-uniandes.appspot.com",
    messagingSenderId: "370430901065",
    appId: "1:370430901065:web:ab36fa3f35bd0ca4a87898",
    measurementId: "G-68C9LFVSF1"
  }
};