<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 text-left">
        <h3><strong>Crear caso</strong></h3>
      </div>
      <div *ngIf="isMobileMenu();then content_mobile else content_web"></div>
      <ng-template #content_web>
        <div *ngIf="caso_listo && !alert_repetido" class="col-md-6 text-right">
          <a routerLink="/panel/crear-caso">
            <button class="btn btn-success" (click)="terminarCaso()">Terminar caso y subir</button>
          </a>
        </div>
      </ng-template>
      <ng-template #content_mobile>
        <div *ngIf="caso_listo && !alert_repetido" class="col-md-6 text-left">
          <a routerLink="/panel/crear-caso">
            <button class="btn btn-success" (click)="terminarCaso()">Terminar caso y subir</button>
          </a>
        </div>
      </ng-template>
    </div>
    
    <div class="row">
      <div class="col-md-12 text-left">
        <p><a routerLink="/panel/casos" (click)="guardarCambios()">Casos</a>/<strong>Crear caso</strong></p>
      </div>
    </div>
    <div class="row">
        <div class="col-md-4 col-sm-12">
          <h5>Nombre del caso</h5>
          <div class="card">
            <div class="card-content bg-white">
              <div class="form-group">
                <input [(ngModel)]="nombre_caso" type="text" value="" placeholder="Escribe acá el nombre del caso" class="form-control" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-12">
          <h5>Modo</h5>
          <div class="dropdown">
            <a class="btn dropdown-toggle" data-toggle="dropdown">
              Selecciona un modo
              <b class="caret"></b>
            </a>
            <ul class="dropdown-menu">
              <li><a (click)="setModo('Evaluación')">Evaluación</a></li>
              <li><a (click)="setModo('Estudio')">Estudio</a></li>
            </ul>
          </div>
          <p>{{modo}}</p>
        </div>
        <div class="col-md-4 col-sm-12">
          <h5>Especialidad</h5>
          <div class="dropdown">
            <a class="btn dropdown-toggle" data-toggle="dropdown">
              Selecciona una especialidad
              <b class="caret"></b>
            </a>
            <ul class="dropdown-menu">
              <li><a (click)="setEspecialidad('Anestesiología')">Anestesiología</a></li>
              <li><a (click)="setEspecialidad('Atención primaria')">Atención primaria</a></li>
              <li><a (click)="setEspecialidad('Cirugía')">Cirugía</a></li>
              <li><a (click)="setEspecialidad('Ginecología')">Ginecología</a></li>
              <li><a (click)="setEspecialidad('Medicina interna')">Medicina interna</a></li>
              <li><a (click)="setEspecialidad('Neurología')">Neurología</a></li>
              <li><a (click)="setEspecialidad('Oftalmología')">Oftalmología</a></li>
              <li><a (click)="setEspecialidad('Oncología')">Oncología</a></li>
              <li><a (click)="setEspecialidad('Ortopedia')">Ortopedia</a></li>
              <li><a (click)="setEspecialidad('Otorrinolaringología')">Otorrinolaringología</a></li>
              <li><a (click)="setEspecialidad('Pediatría')">Pediatría</a></li>
              <li><a (click)="setEspecialidad('Rehabilitación')">Rehabilitación</a></li>
              <li><a (click)="setEspecialidad('Urgencias')">Urgencias</a></li>
            </ul>
          </div>
          <p>{{especialidad}}</p>
        </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <h5>Dificultad</h5>
        <div class="dropdown">
          <a class="btn dropdown-toggle" data-toggle="dropdown">
            Selecciona una dificultad
            <b class="caret"></b>
          </a>
          <ul class="dropdown-menu">
            <li><a (click)="setDificultad('Fácil')">Fácil</a></li>
            <li><a (click)="setDificultad('Intermedio')">Intermedio</a></li>
            <li><a (click)="setDificultad('Avanzado')">Avanzado</a></li>
          </ul>
        </div>
        <p>{{dificultad}}</p>
      </div>
      <div class="col-md-6 col-sm-12">
        <h5>Escenario</h5>
        <div class="dropdown">
          <a class="btn dropdown-toggle" data-toggle="dropdown">
            Selecciona el escenario
            <b class="caret"></b>
          </a>
          <ul class="dropdown-menu">
            <li><a (click)="setEscenario('Cirugía')">Cirugía</a></li>
            <li><a (click)="setEscenario('Consultorio')">Consultorio</a></li>
            <li><a (click)="setEscenario('Hospitalización')">Hospitalización</a></li>
            <li><a (click)="setEscenario('Urgencias')">Urgencias</a></li>
          </ul>
        </div>
        <p>{{escenario}}</p>
      </div>
    </div>
    <br />
    <div *ngIf="verificarRepetido() && alert_repetido" class="row">
      <br />
      <div class="col-md-1">
      </div>
      <div class="alert alert-danger col-md-10">
        <h4>
          <strong>Error:</strong>
          Ya existe un caso con el nombre "{{this.aux_alert_nombre}}" en {{this.especialidad}}
          <i class="material-icons cerrar_alerta" (click)="alert_repetido = false">close</i>
        </h4>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-6">
        <br />
        <div class="card">
          <a routerLink="/panel/crear-caso-paciente" (click)="guardarCambios()">
            <div class="card-content card-secciones bg-white">
              <h4 class="title-seccion"><strong>Paciente</strong></h4>

              <img *ngIf="pacienteListo()"
              class="img-listo text-right"
              src="../../assets/img/crear-caso-listo.png"
              alt="Sección diagnóstico final lista"
              />
            </div>
          </a>
        </div>
      </div>
      <div class="col-md-6">
        <br />
        <div class="card">
          <a routerLink="/panel/crear-caso-enfermedad-actual" (click)="guardarCambios()">
            <div class="card-content card-secciones bg-white">
                <h4 class="title-seccion"><strong>Enfermedad actual</strong></h4>

                <img *ngIf="enfermedadActualListo()"
                class="img-listo text-right"
                src="../../assets/img/crear-caso-listo.png"
                alt="Sección enfermedad actual lista"
                />
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <br />
        <div class="card">
          <a routerLink="/panel/crear-caso-antecedentes" (click)="guardarCambios()">
            <div class="card-content card-secciones bg-white">
              <h4 class="title-seccion"><strong>Antecedentes</strong></h4>

              <img *ngIf="antecedentesListo()"
              class="img-listo text-right"
              src="../../assets/img/crear-caso-listo.png"
              alt="Sección antecedentes lista"
              />
            </div>
          </a>
        </div>
      </div>
      <div class="col-md-6">
        <br />
        <div class="card">
          <a routerLink="/panel/crear-caso-sistemas" (click)="guardarCambios()">
            <div class="card-content card-secciones bg-white">
              <h4 class="title-seccion"><strong>Revisión por sistemas</strong></h4>

              <img *ngIf="sistemasListo()"
              class="img-listo text-right"
              src="../../assets/img/crear-caso-listo.png"
              alt="Sección revisión por sistemas lista"
              />
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <br />
        <div class="card">
          <a routerLink="/panel/crear-caso-examen-fisico" (click)="guardarCambios()">
            <div class="card-content card-secciones bg-white">
              <h4 class="title-seccion"><strong>Examen físico</strong></h4>

              <img *ngIf="examenListo()"
              class="img-listo text-right"
              src="../../assets/img/crear-caso-listo.png"
              alt="Sección examen físico lista"
              />
            </div>
          </a>
        </div>
      </div>
      <div class="col-md-6">
        <br />
        <div class="card">
          <a routerLink="/panel/crear-caso-impresion" (click)="guardarCambios()">
            <div class="card-content card-secciones bg-white">
              <h4 class="title-seccion"><strong>Impresión diagnóstica</strong></h4>

              <img *ngIf="impresionListo()"
              class="img-listo text-right"
              src="../../assets/img/crear-caso-listo.png"
              alt="Sección impresión diagnóstica lista"
              />
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <br />
        <div class="card">
          <a routerLink="/panel/crear-caso-ayudas" (click)="guardarCambios()">
            <div class="card-content card-secciones bg-white">
              <h4 class="title-seccion"><strong>Ayudas diagnósticas</strong></h4>

              <img *ngIf="ayudasListo()"
              class="img-listo text-right"
              src="../../assets/img/crear-caso-listo.png"
              alt="Sección ayudas diangósticas lista"
              />
            </div>
          </a>
        </div>
      </div>
      <div class="col-md-6">
        <br />
        <div class="card">
          <a routerLink="/panel/crear-caso-tratamiento" (click)="guardarCambios()">
            <div class="card-content card-secciones bg-white">
              <h4 class="title-seccion"><strong>Tratamiento</strong></h4>

              <img *ngIf="tratamientoListo()"
              class="img-listo text-right"
              src="../../assets/img/crear-caso-listo.png"
              alt="Sección tratamiento lista"
              />
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <br />
        <div class="card">
          <a routerLink="/panel/crear-caso-diagnostico" (click)="guardarCambios()">
            <div class="card-content card-secciones bg-white">
              <h4 class="title-seccion"><strong>Diagnóstico final</strong></h4>

              <img *ngIf="diagnosticoListo()"
              class="img-listo text-right"
              src="../../assets/img/crear-caso-listo.png"
              alt="Sección diagnóstico final lista"
              />
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>