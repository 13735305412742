import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'app/services/storage/local-storage.service';

import { RdbService } from '../../services/firebase/rdb.service';

@Component({
  selector: 'app-crear-caso-ayudas',
  templateUrl: './crear-caso-ayudas.component.html',
  styleUrls: ['./crear-caso-ayudas.component.css']
})
export class CrearCasoAyudasComponent implements OnInit {

  renderLabs = true;
  renderImgs = false;
  renderOtras = false;

  alert_archivo = false;

  supported_imgs: string[] = ["apng", "avif", "gif", "jpg", "jpeg", "jfif", "pjpeg", "pjp",
  "png", "svg", "webp"];
  supported_audio: string[] = ["mp3", "wav"];

  navegarALabs() {
    this.renderLabs = true;
    this.renderImgs = false;
    this.renderOtras = false;

    this.alert_archivo = false;

    this.guardarCambios();
  }

  navegarAImgs() {
    this.renderLabs = false;
    this.renderImgs = true;
    this.renderOtras = false;

    this.alert_archivo = false;

    this.guardarCambios();
  }

  navegarAOtras() {
    this.renderLabs = false;
    this.renderImgs = false;
    this.renderOtras = true;

    this.alert_archivo = false;

    this.guardarCambios();
  }

  constructor(private firebase_rdb: RdbService, private local_db: LocalStorageService) { }

  //Laboratorios==============================================================================
  
  //Selección actual
  laboratorios_tipo_actual: string = "";
  laboratorios_lab_actual: string = "";
  laboratorios_pregs_actuales: string[] = [];
  laboratorios_resp_actual: string = "";
  laboratorios_adjunto_actual: File = null;
  laboratorios_pt_actual: string = "";

  alert_incompleto = false;
  alert_repetido = false;

  //Laboratorios agregados
  laboratorios_nombres: string[] = [];
  laboratorios_resps: string[] = [];
  laboratorios_adjuntos: File[] = [];
  laboratorios_pts: string[] = [];

  seleccionarTipoLab(tipo_lab: string) {
    this.laboratorios_tipo_actual = tipo_lab;
    this.laboratorios_lab_actual = "";
    this.laboratorios_pregs_actuales = [];

    this.firebase_rdb.getLabs(tipo_lab.toLowerCase()).toPromise().then(
      data => {
        for (let key in data) {
          if (data.hasOwnProperty(key) && data[key] != "No es necesario") {
            this.laboratorios_pregs_actuales.push(data[key]);
          }
        }
      }
    );
  }

  seleccionarLab(lab: string, index: number) {
    this.laboratorios_lab_actual = lab;
  }

  handleFileLabInput(file: File) {
    this.alert_archivo = false;
    if (file != null) {
      var file_split: string[] = file.name.split(".");
      var file_end: string = file_split[file_split.length - 1].trim().toLowerCase();
      if (this.supported_imgs.includes(file_end) || this.supported_audio.includes(file_end)) {
        this.laboratorios_adjunto_actual = file;
      }
      else {
        this.alert_archivo = true;
      }
    }
    else {
      this.laboratorios_adjunto_actual = null;
    }
  }

  cntLabsAgregados(): number {
    var ans = 0;

    this.laboratorios_nombres.forEach(
      e => {
        if (e != null && e != "") {
          ans++;
        }
      }
    );

    return ans;
  }

  agregarLab() {
    this.alert_incompleto = false;
    this.alert_repetido = false;

    //Si todos los campos han sido llenados
    if (this.laboratorios_lab_actual != "" && this.laboratorios_pt_actual != ""
    && (this.laboratorios_resp_actual != "" || this.laboratorios_adjunto_actual != null)) {      
      //Si el laboratorio ya tiene respuesta
      if (this.laboratorios_nombres.indexOf(this.laboratorios_lab_actual) != -1) {
        this.alert_repetido = true;
      }
      //Agregar el laboratorio con su respuesta a la lista
      else {
        this.laboratorios_nombres.push(this.laboratorios_lab_actual);
        this.laboratorios_pts.push(this.laboratorios_pt_actual);
        this.laboratorios_resps.push(this.laboratorios_resp_actual);
        this.laboratorios_adjuntos.push(this.laboratorios_adjunto_actual);
      }
    }
    else {
      this.alert_incompleto = true;
    }
  }

  eliminarLab(i: number) {
    this.laboratorios_nombres[i] = null;
    this.laboratorios_resps[i] = null;
    this.laboratorios_adjuntos[i] = null;
    this.laboratorios_pts[i] = null;
  }

  //Entra el tipo de puntaje del laboratorio y devuelve si se debe mostrar en el panel como
  //"Correcto" u "Omitible". Si se ve "Incorrecto", ocurrió un error al resolver el puntaje
  renderCorrectoOmitible(tipo_lab: string): string{
    if (tipo_lab == "positivo")
      return "Correcto";
    else if (tipo_lab == "omitible")
      return "Omitible";
    else
      return "Incorrecto";
  }

  //Imágenes diagnósticas=====================================================================

  //Selección actual
  imgs_tipo: string = "";
  imgs_ubicacion: string = "";
  imgs_lateralidad: string = "";
  imgs_proyeccion: string = "";
  imgs_contraste: string = "";
  imgs_puntuacion: string = "";
  imgs_adjunta_actual: File = null;

  alert_img_repetida = false;
  alert_img_incompleta = false;

  //Imágenes diagnósticas agregadas
  imgs_tipos: string[] = [];
  imgs_ubicaciones: string[] = [];
  imgs_lateralidades: string[] = [];
  imgs_proyecciones: string[] = [];
  imgs_contrastes: string[] = [];
  
  imagenes_diagnosticas: string[] = [];

  imgs_puntuaciones: string[] = [];
  imgs_adjuntos: File[] = [];

  //Entra el tipo de puntaje del laboratorio y devuelve si se debe mostrar en el panel como
  //"Correcto" u "Omitible". Si se ve "Incorrecto", ocurrió un error al resolver el puntaje
  renderImgsCorrectoOmitible(tipo_img: string): string{
    if (tipo_img == "positivo")
      return "Correcta";
    else if (tipo_img == "omitible")
      return "Omitible";
    else
      return "Incorrecta";
  }

  handleFileImgInput(file: File) {
    this.alert_archivo = false;
    if (file != null) {
      var file_split: string[] = file.name.split(".");
      var file_end: string = file_split[file_split.length - 1].trim().toLowerCase();
      if (this.supported_imgs.includes(file_end) || this.supported_audio.includes(file_end)) {
        this.imgs_adjunta_actual = file;
      }
      else {
        this.alert_archivo = true;
      }
    }
    else {
      this.imgs_adjunta_actual = null;
    }
  }

  agregarImg() {
    this.alert_img_incompleta = false;
    this.alert_img_repetida = false;

    if (this.imgs_tipo != "" && this.imgs_ubicacion != "" && this.imgs_lateralidad != ""
    && this.imgs_proyeccion != "" && this.imgs_contraste != "" && this.imgs_puntuacion != ""
    && this.imgs_adjunta_actual != null) {
      var img_aux = this.imgs_tipo + " - " + this.imgs_ubicacion + " - " + this.imgs_lateralidad
      + " - " + this.imgs_proyeccion + " - " + this.imgs_contraste;

      if (this.imagenes_diagnosticas.indexOf(img_aux) == -1) {
        this.imagenes_diagnosticas.push(img_aux);
        this.imgs_tipos.push(this.imgs_tipo);
        this.imgs_ubicaciones.push(this.imgs_ubicacion);
        this.imgs_lateralidades.push(this.imgs_lateralidad);
        this.imgs_proyecciones.push(this.imgs_proyeccion);
        this.imgs_contrastes.push(this.imgs_contraste);
        this.imgs_puntuaciones.push(this.imgs_puntuacion);
        this.imgs_adjuntos.push(this.imgs_adjunta_actual);
      }
      else {
        this.alert_img_repetida = true;
      }
    }
    else {
      this.alert_img_incompleta = true;
    }
  }

  cntImgsAgregadas(): number {
    var ans = 0;

    this.imagenes_diagnosticas.forEach(
      e => {
        if (e != null) {
          ans++;
        }
      }
    );

    return ans;
  }

  eliminarImg(i:number) {
    this.imgs_tipos[i] = null;
    this.imgs_ubicaciones[i] = null;
    this.imgs_lateralidades[i] = null;
    this.imgs_proyecciones[i] = null;
    this.imgs_contrastes[i] = null;
    this.imagenes_diagnosticas[i] = null;
    this.imgs_puntuaciones[i] = null;
    this.imgs_adjuntos[i] = null;
  }

  //Otras ayudas==============================================================================
  
  //Selección actual
  otras_lab_actual: string = "";
  otras_pregs: string[] = [];
  otras_resp_actual: string = "";
  otras_adjunto_actual: File = null;
  otras_pt_actual: string = "";

  alert_otras_incompleto = false;
  alert_otras_repetido = false;

  //Laboratorios agregados
  otras_nombres: string[] = [];
  otras_resps: string[] = [];
  otras_adjuntos: File[] = [];
  otras_pts: string[] = [];

  handleFileOtrasInput(file: File) {
    this.alert_archivo = false;
    if (file != null) {
      var file_split: string[] = file.name.split(".");
      var file_end: string = file_split[file_split.length - 1].trim().toLowerCase();
      if (this.supported_imgs.includes(file_end) || this.supported_audio.includes(file_end)) {
        this.otras_adjunto_actual = file;
      }
      else {
        this.alert_archivo = true;
      }
    }
    else {
      this.otras_adjunto_actual = null;
    }
  }

  agregarOtra() {
    this.alert_otras_incompleto = false;
    this.alert_otras_repetido = false;

    if (this.otras_lab_actual != "" && this.otras_pt_actual != "" 
    && (this.otras_adjunto_actual != null || this.otras_resp_actual != "")) {
      if (this.otras_nombres.indexOf(this.otras_lab_actual) == -1) {
        this.otras_nombres.push(this.otras_lab_actual);
        this.otras_resps.push(this.otras_resp_actual);
        this.otras_adjuntos.push(this.otras_adjunto_actual);
        this.otras_pts.push(this.otras_pt_actual);
      }
      else {
        this.alert_otras_repetido = true;
      }
    }
    else {
      this.alert_otras_incompleto = true;
    }
  }

  cntOtras(): number {
    var ans = 0;

    this.otras_nombres.forEach(
      e => {
        if (e != null && e != "") {
          ans++;
        }
      }
    );

    return ans;
  }

  eliminarOtra(i: number) {
    this.otras_nombres[i] = null;
    this.otras_resps[i] = null;
    this.otras_adjuntos[i] = null;
    this.otras_pts[i] = null;
  }

  //Iniciar y finalizar panel=================================================================
  ngOnInit() {
    //Laboratorios
    this.laboratorios_adjuntos = this.firebase_rdb.laboratorios_adjuntos;

    this.local_db.getItem<string[]>("laboratorios_nombres").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.laboratorios_nombres = resp;
          this.laboratorios_nombres = this.firebase_rdb.laboratorios_nombres;
        }

        this.local_db.getItem<string[]>("laboratorios_resps").then(
          resp2 => {
            if (resp2 != null) {
              this.firebase_rdb.laboratorios_resps = resp2;
              this.laboratorios_resps = this.firebase_rdb.laboratorios_resps;
            }

            this.local_db.getItem<string[]>("laboratorios_pts").then(
              resp3 => {
                if (resp3 != null) {
                  this.firebase_rdb.laboratorios_pts = resp3;
                  this.laboratorios_pts = this.firebase_rdb.laboratorios_pts;
                }

                for (var i = 0; i < this.laboratorios_nombres.length; i++) {
                  if (this.laboratorios_nombres[i] != null && this.laboratorios_resps[i] == null && this.laboratorios_adjuntos[i] == null) {
                    this.laboratorios_nombres[i] = null;
                    this.laboratorios_pts[i] = null;
                    //Dado que los archivos adjuntos no se pueden guardar en localstorage, se deben eliminar las entradas
                    //que no tengan ni respuesta ni archivo
                  }
                }
              }
            ).catch(
              error => {
                console.log("Error recuperando laboratorios_pts");
              }
            );
          }
        ).catch(
          error => {
            console.log("Error recuperando laboratorios_resps");
          }
        );
      }
    ).catch(
      error => {
        console.log("Error recuperando laboratorios_nombres");
      }
    );

    //Imágenes diagnósticas
    //Todas las imágenes diagnósticas requieren un archivo, entonces no es posible guardar y recuperar de localstorage
    var img_aux: string[] = [];
    
    this.imgs_tipos = [];
    this.imgs_ubicaciones = [];
    this.imgs_lateralidades = [];
    this.imgs_proyecciones = [];
    this.imgs_contrastes = [];

    this.imagenes_diagnosticas = [];
    this.firebase_rdb.imagenes_diagnosticas.forEach(
      e => {
        if (e != null) {
          this.imagenes_diagnosticas.push(e);

          img_aux = e.split(" - ");
          
          this.imgs_tipos.push(img_aux[0]);
          this.imgs_ubicaciones.push(img_aux[1]);
          this.imgs_lateralidades.push(img_aux[2]);
          this.imgs_proyecciones.push(img_aux[3]);
          this.imgs_contrastes.push(img_aux[4]);
        }
      }
    );
    this.firebase_rdb.imagenes_diagnosticas = this.imagenes_diagnosticas;

    this.imgs_adjuntos = [];
    this.firebase_rdb.imgs_adjuntos.forEach(
      e => {
        if (e != null) {
          this.imgs_adjuntos.push(e);
        }
      }
    );
    this.firebase_rdb.imgs_adjuntos = this.imgs_adjuntos;

    this.imgs_puntuaciones = [];
    this.firebase_rdb.imgs_puntuaciones.forEach(
      e => {
        if (e != null) {
          this.imgs_puntuaciones.push(e);
        }
      }
    );
    this.firebase_rdb.imgs_puntuaciones = this.imgs_puntuaciones;

    //Otras ayudas
    this.otras_adjuntos = this.firebase_rdb.otras_adjuntos;

    this.local_db.getItem<string[]>("otras_nombres").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.otras_nombres = resp;
          this.otras_nombres = this.firebase_rdb.otras_nombres;
        }

        this.local_db.getItem<string[]>("otras_resps").then(
          resp2 => {
            if (resp2 != null) {
              this.firebase_rdb.otras_resps = resp2;
              this.otras_resps = this.firebase_rdb.otras_resps;
            }
            
            this.local_db.getItem<string[]>("otras_pts").then(
              resp3 => {
                if (resp3 != null) {
                  this.firebase_rdb.otras_pts = resp3;
                  this.otras_pts = this.firebase_rdb.otras_pts;
                }

                for (var i = 0; i < this.otras_nombres.length; i++) {
                  if (this.otras_nombres[i] != null && this.otras_resps[i] == null && this.otras_adjuntos[i] == null) {
                    this.otras_nombres[i] = null;
                    this.otras_pts[i] = null;
                    //Dado que los archivos adjuntos no se pueden guardar en localstorage, se deben eliminar las entradas
                    //que no tengan ni respuesta ni archivo
                  }
                }
              }
            ).catch(
              error => {
                console.log("Error recuperando otras_pts");
              }
            );
          }
        ).catch(
          error => {
            console.log("Error recuperando otras_resps");
          }
        );
      }
    ).catch(
      error => {
        console.log("Error recuperando otros_nombres");
      }
    );
    
    if (this.firebase_rdb.otras_pregs.length == 0) {
      this.firebase_rdb.getOtrasAyudas().toPromise().then(
        data => {
          for (let key in data) {
            if (data.hasOwnProperty(key) && data[key] != "No es necesario") {
              this.otras_pregs.push(data[key]);
            }
          }
        }
      );

      this.firebase_rdb.otras_pregs = this.otras_pregs;
    }
    else {
      this.otras_pregs = this.firebase_rdb.otras_pregs;
    }
  }

  guardarCambios() {
    //Laboratorios
    this.firebase_rdb.laboratorios_nombres = this.laboratorios_nombres;
    this.firebase_rdb.laboratorios_resps = this.laboratorios_resps;
    this.firebase_rdb.laboratorios_adjuntos = this.laboratorios_adjuntos;
    this.firebase_rdb.laboratorios_pts = this.laboratorios_pts;
    
    this.local_db.setItem<string[]>("laboratorios_nombres", this.laboratorios_nombres);
    this.local_db.setItem<string[]>("laboratorios_resps", this.laboratorios_resps);
    this.local_db.setItem<string[]>("laboratorios_pts", this.laboratorios_pts);

    //Imágenes diagnósticas
    this.firebase_rdb.imagenes_diagnosticas = this.imagenes_diagnosticas;
    this.firebase_rdb.imgs_puntuaciones = this.imgs_puntuaciones;
    this.firebase_rdb.imgs_adjuntos = this.imgs_adjuntos;

    //Otras ayudas
    this.firebase_rdb.otras_nombres = this.otras_nombres;
    this.firebase_rdb.otras_resps = this.otras_resps;
    this.firebase_rdb.otras_adjuntos = this.otras_adjuntos;
    this.firebase_rdb.otras_pts = this.otras_pts;
    
    this.local_db.setItem<string[]>("otras_nombres", this.otras_nombres);
    this.local_db.setItem<string[]>("otras_resps", this.otras_resps);
    this.local_db.setItem<string[]>("otras_pts", this.otras_pts);
  }

}
