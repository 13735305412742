import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RdbEditarCasoService } from 'app/services/firebase/rdb-editar-caso.service';

declare const $: any;

@Component({
  selector: 'app-detalle-caso-examen-fisico',
  templateUrl: './detalle-caso-examen-fisico.component.html',
  styleUrls: ['./detalle-caso-examen-fisico.component.css']
})
export class DetalleCasoExamenFisicoComponent implements OnInit {

  constructor(private firebase_rdb: RdbEditarCasoService, private router: Router) { }

  resps: string[] = [];
  recursosASubir: File[] = [];

  recursosAActualizar: boolean[] = [];
  recursosActuales: string[] = [];
  
  dolor_cabeza: boolean = false;
  dolor_torax: boolean = false;
  dolor_abdomen: boolean = false;
  dolor_extremidades: boolean = false;

  alert_archivo: boolean[] = [false, false, false, false, false, false, false, false, false];

  supported_imgs: string[] = ["apng", "avif", "gif", "jpg", "jpeg", "jfif", "pjpeg", "pjp",
  "png", "svg", "webp"];
  supported_audio: string[] = ["mp3", "wav"];

  handleFileInput(file: File, index: number) {
    this.alert_archivo[index] = false;

    this.recursosAActualizar[index] = true;
    
    if (file != null) {
      var file_split: string[] = file.name.split(".");
      var file_end: string = file_split[file_split.length - 1].trim().toLowerCase();
      if (this.supported_imgs.includes(file_end) || this.supported_audio.includes(file_end)) {
        this.recursosASubir[index] = file;
      }
      else {
        this.alert_archivo[index] = true;
      }
    }
    else {
      this.recursosASubir[index] = null;
    }
  }

  guardarCambios() {
    this.firebase_rdb.examen_fisico_resps = this.resps;
    this.firebase_rdb.examen_fisico_recursos = this.recursosASubir;

    this.firebase_rdb.dolor_cabeza = this.dolor_cabeza;
    this.firebase_rdb.dolor_torax = this.dolor_torax;
    this.firebase_rdb.dolor_abdomen = this.dolor_abdomen;
    this.firebase_rdb.dolor_extremidades = this.dolor_extremidades;

    this.firebase_rdb.examen_fisico_put_archivos = this.recursosAActualizar;
    this.firebase_rdb.examen_fisico_archivos_str = this.recursosActuales;
  }

  ngOnInit() {
    if (this.firebase_rdb.nombre_caso_seleccionado == null ||
      this.firebase_rdb.especialidad_caso_seleccionado == null ||
      this.firebase_rdb.id_caso_seleccionado == null ||
      this.firebase_rdb.json_caso_seleccionado == null) {
      
      this.firebase_rdb.clearSeleccionado();
      this.router.navigate(["/panel/casos"]);
    }
    
    this.resps = this.firebase_rdb.examen_fisico_resps;
    
    this.dolor_cabeza = this.firebase_rdb.dolor_cabeza;
    this.dolor_torax = this.firebase_rdb.dolor_torax;
    this.dolor_abdomen = this.firebase_rdb.dolor_abdomen;
    this.dolor_extremidades = this.firebase_rdb.dolor_extremidades;

    this.recursosASubir = this.firebase_rdb.examen_fisico_recursos;
    
    this.recursosAActualizar = this.firebase_rdb.examen_fisico_put_archivos;
    this.recursosActuales = this.firebase_rdb.examen_fisico_archivos_str;
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
        return false;
    }
    return true;
  };


}
