import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RdbEditarCasoService } from 'app/services/firebase/rdb-editar-caso.service';

declare const $: any;

@Component({
  selector: 'app-detalle-caso-paciente',
  templateUrl: './detalle-caso-paciente.component.html',
  styleUrls: ['./detalle-caso-paciente.component.css']
})
export class DetalleCasoPacienteComponent implements OnInit {

  constructor(private firebase_rdb: RdbEditarCasoService, private router: Router) { }

  //Datos personales
  nombre_paciente: string = "";
  genero: string = "";
  edad: string = "";
  peso: string = "";
  talla: string = "";
  motivo_consulta: string = "";
  embarazada: boolean = false;
  etnia: string = "";

  //Signos vitales
  frecuencia_cardiaca: string = "";
  frecuencia_respiratoria: string = "";
  saturacion: string = "";
  tension_arterial: string = "";
  VAS: string = "";
  temperatura: string = "";

  componente_listo: boolean = false;

  msg_embarazo: string = "";

  /**
   * Dada la configuración actual del paciente, indica si puede entrar o no en estado de embarazo
   * @returns true si es posible; false en caso contrario
   */
  posibleEmbarazada(): boolean {
    var ans = false;

    //Es posible para las mujeres y las personas
    if ((this.genero.startsWith("Mujer") || this.genero.endsWith("transgénero")) && this.edadValida()) {
      var edad_aux: string[] = this.edad.split(" ");
      if (edad_aux[1] == "años" && parseInt(edad_aux[0]) >= 12) {
        ans = true;
        //Considerando que tanto mujeres cisgénero como personas transgénero pueden quedar embarazadas, se debe
        //ajustar el mensaje del checkbox acorde con los pronombres de el/la paciente
        if (this.genero.startsWith("Mujer")) {
          this.msg_embarazo = "La paciente se encuentra embarazada";
        }
        else if (this.genero.startsWith("Hombre")) {
          this.msg_embarazo = "El paciente se encuentra embarazado";
        }
      }
    }

    return ans;
  }

  /**
   * Actualiza la etnia seleccionada con la que entra por parámetro
   * @param pEtnia Etnia a asignar al paciente
   */
  seleccionarEtnia(pEtnia: string) {
    var msg: string = "Si la etnia seleccionada no está disponible para este tipo "
                    + "de paciente, el simulador seleccionará una etnia que esté disponible "
                    + "para el paciente descrito. ¿Desea continuar?";
    if (pEtnia == this.etnia) {
      return;
    }
    else if (confirm(msg)) {
      this.etnia = pEtnia;
    }
  }

  /**
   * Indica si la edad ingresada es un input válido o no
   * @returns true si la edad es válida según las reglas definidas; false en caso contrario
   */
  edadValida(): boolean {
    var ans = false;

    setTimeout(() => {
      this.edad = this.edad.replace(".", "").replace(",", "").replace("  ", " ");
    }, 0);

    var edad_split: string[] = this.edad.split(" ");
    if (edad_split.length == 2) {
      if (edad_split[1].startsWith("año") || edad_split[1].startsWith("mes") || edad_split[1].startsWith("semana")) {
        var edad_aux: number = -1;
        try {
          edad_aux = parseInt(edad_split[0]);
        } catch(error) {
          console.log(error);
        }
        if (edad_aux > 0) {
          ans = true;
        }
      }
    }

    return ans;
  }

  /**
   * Indica si el peso ingresado es un input válido o no
   * @returns true si el peso es válido según las reglas definidas; false en caso contrario
   */
  pesoValido(): boolean {
    var ans = false;

    //Elimina los espacios en blanco. Debe ser asíncrono para evitar el error NG0100
    setTimeout(() => {
      this.peso = this.peso.replace(" ", "");
    }, 0);

    if (this.peso.length > 2) {
      //Si es válido, toma el peso hasta "kg"
      var str_peso_aux = this.peso.substr(0, this.peso.length-2);
      var peso_aux = -1;
      try {
        peso_aux = parseInt(str_peso_aux);
      } catch(error) {
        console.log(error);
      }
      if (peso_aux > 0) {
        //Esta parte debería ser igual a "kg"
        var kg_aux = this.peso.substr(this.peso.length-2, 2);
        if (kg_aux == "kg") {
          ans = true;
        }
      }
    }

    return ans;
  }

  /**
   * Indica si la talla ingresada es un input válido o no
   * @returns true si la talla es válida según las reglas definidas; false en caso contrario
   */
  tallaValida(): boolean {
    var ans = false;

    setTimeout(() => {
      this.talla = this.talla.replace(" ", "").replace(",", ".").replace("..", ".");
    }, 0);

    var talla_split: string[] = this.talla.split(".");
    if(talla_split.length == 2) {
      if (talla_split[0].length == 1 && talla_split[1].length==3) {
        var metros: number = -1;
        var cm: number = -1;
        var end: string = talla_split[1].charAt(talla_split[1].length-1);
        try {
          metros = parseInt(talla_split[0]);
          cm = parseInt(talla_split[1].substring(0, 2), 10);
        } catch(error) {
          console.log(error);
        }
        if (metros >= 0 && cm >= 0 && end == "m") {
          ans = true;
        }
      }
    }

    return ans;
  }

  /**
   * Indica si la frecuencia ingresada es un input válido o no
   * @param param Indica si se deben usar las reglas de una frecuencia cardiaca o respiratoria
   * @returns true si la frecuencia es válida según las reglas definidas; false en caso contrario
   */
  frecuenciaValida(param: string): boolean {
    var ans = false;

    var frecuencia_str: string = "";

    setTimeout(() => {
      this.frecuencia_cardiaca = this.frecuencia_cardiaca.replace(" ", "").replace(".", "").replace(",", "");
      this.frecuencia_respiratoria = this.frecuencia_respiratoria.replace(" ", "").replace(".", "").replace(",", "");
    },0);

    if (param =="cardiaca") {
      frecuencia_str = this.frecuencia_cardiaca;
    }
    else if (param == "respiratoria") {
      frecuencia_str = this.frecuencia_respiratoria;
    }

    if (frecuencia_str.length > 3) {
      var bpm: string = frecuencia_str.substr(frecuencia_str.length - 3, 3);
      var freq: number = -1;
      try {
        freq = parseInt(frecuencia_str.substr(0, frecuencia_str.length - 3));
      } catch (error) {
        console.log(error);
      }
      if (freq >= 0) {
        if (param == "cardiaca" && bpm == "bpm") {
          ans = true;
        }
        else if (param == "respiratoria" && bpm == "rpm") {
          ans = true;
        }
      }
    }

    return ans;
  }

  /**
   * Indica si la saturación ingresada es un input válido o no
   * @returns true si la saturación es válida según las reglas definidas; false en caso contrario
   */
  saturacionValida(): boolean {
    var ans = false;

    setTimeout(() => {
      this.saturacion = this.saturacion.replace(" ", "").replace(".", "").replace(",", "");
    },0);

    if (this.saturacion.length >=  2) {
      var sat: number = -1;
      var prc: string = this.saturacion.substr(this.saturacion.length - 1, 1);
      try {
        sat = parseInt(this.saturacion.substr(0, this.saturacion.length - 1));
      } catch (error) {
        console.log(error);
      }
      if (sat <= 100 && sat >= 0 && prc == "%") {
        ans = true;
      }
    }

    return ans;
  }

  /**
   * Indica si la tensión arterial ingresada es un input válido o no
   * @returns true si la tensión arterial es válida según las reglas definidas; false en caso contrario
   */
  tensionArterialValida(): boolean {
    var ans = false;

    setTimeout(() => {
      this.tension_arterial = this.tension_arterial.replace(" ", "").replace(".", "").replace(",", "");
    },0);

    if (this.tension_arterial.length >= 3) {
      var tension_split: string[] = this.tension_arterial.split("/");
      if (tension_split.length == 2) {
        var num1: number = -1;
        var num2: number = -1;
        try {
          num1 = parseInt(tension_split[0]);
          num2 = parseInt(tension_split[1]);
        } catch (error) {
          console.log(error);
        }
        if (num1 >= 0 && num2 >= 0) {
          ans = true;
        }
      }
    }

    return ans;
  }

  /**
   * Indica si el VAS ingresado es un input válido o no
   * @returns true si el VAS es válido según las reglas definidas; false en caso contrario
   */
  VASValido(): boolean {
    var ans = false;
    
    setTimeout(() => {
      this.VAS = this.VAS.replace(" ", "").replace(".", "").replace(",", "");
    },0);

    var vas_aux: number = -1;

    try {
      vas_aux = parseInt(this.VAS);
    } catch(error) {
      console.log(error);
    }

    if (vas_aux != -1) {
      if (vas_aux >= 1 && vas_aux <= 10) {
        ans = true;
      }
    }

    return ans;
  }

  /**
   * Indica si la temperatura ingresada es un input válido o no
   * @returns true si la temperatura es válida según las reglas definidas; false en caso contrario
   */
  temperaturaValida(): boolean {
    var ans = false;

    setTimeout(() => {
      this.temperatura = this.temperatura.replace(" ", "").replace(",", ".").replace("..", ".");
    },0);

    if (this.temperatura.length >= 3) {
      var temp_split = this.temperatura.split("º");
      if (temp_split.length == 2) {
        var temp: number = -1;
        try {
          temp = parseFloat(temp_split[0]);
        } catch (error) {
          console.log(error);
        }
        if (temp >= 0 && (temp_split[1] == "C" || temp_split[1] == "F")) {
          ans = true;
        }
      }
    }

    return ans;
  }

  ngOnInit(): void {
    if (this.firebase_rdb.nombre_caso_seleccionado == null ||
      this.firebase_rdb.especialidad_caso_seleccionado == null ||
      this.firebase_rdb.id_caso_seleccionado == null ||
      this.firebase_rdb.json_caso_seleccionado == null) {
      
      this.firebase_rdb.clearSeleccionado();
      this.router.navigate(["/panel/casos"]);
    }

    this.nombre_paciente = this.firebase_rdb.nombre_paciente;

    this.genero = this.firebase_rdb.genero;
    this.edad = this.firebase_rdb.edad;
    this.peso = this.firebase_rdb.peso;
    this.talla = this.firebase_rdb.talla;
    this.etnia = this.firebase_rdb.etnia;

    this.motivo_consulta = this.firebase_rdb.motivo_consulta;

    this.embarazada = this.firebase_rdb.embarazada;

    this.frecuencia_cardiaca = this.firebase_rdb.frecuencia_cardiaca;
    this.frecuencia_respiratoria = this.firebase_rdb.frecuencia_respiratoria;
    this.saturacion = this.firebase_rdb.saturacion;
    this.tension_arterial = this.firebase_rdb.tension_arterial;
    this.VAS = this.firebase_rdb.VAS;
    this.temperatura = this.firebase_rdb.temperatura;
  }

  guardarCambios() {
    if (this.edadValida()) {
      var edad_split: string[] = this.edad.split(" ");
      var num: number = parseInt(edad_split[0]);
      var cnt: string = edad_split[1];
      if (num > 1) {
        if (cnt == "año" || cnt == "semana") {
          this.edad += "s";
        }
        else if (cnt == "mes") {
          this.edad += "es";
        }
      }
    }

    this.firebase_rdb.nombre_paciente = this.nombre_paciente;
    this.firebase_rdb.genero = this.genero;
    this.firebase_rdb.edad = this.edad;
    this.firebase_rdb.peso = this.peso;
    this.firebase_rdb.talla = this.talla;
    this.firebase_rdb.motivo_consulta = this.motivo_consulta;
    this.firebase_rdb.embarazada = this.embarazada;
    this.firebase_rdb.etnia = this.etnia;

    this.firebase_rdb.frecuencia_cardiaca = this.frecuencia_cardiaca;
    this.firebase_rdb.frecuencia_respiratoria = this.frecuencia_respiratoria;
    this.firebase_rdb.saturacion = this.saturacion;
    this.firebase_rdb.tension_arterial = this.tension_arterial;
    this.firebase_rdb.VAS = this.VAS;
    this.firebase_rdb.temperatura = this.temperatura;

    this.componente_listo = (this.nombre_paciente != "" && this.genero != "" && this.edadValida()
    && this.pesoValido() && this.tallaValida() && this.motivo_consulta != ""
    && this.frecuenciaValida('cardiaca') && this.frecuenciaValida('respiratoria')
    && this.saturacionValida() && this.tensionArterialValida() && this.VASValido()
    && this.temperaturaValida() && this.etnia != "");

    this.firebase_rdb.paciente_listo = this.componente_listo;
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
        return false;
    }
    return true;
  };

}
