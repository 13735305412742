import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RdbEditarCasoService } from 'app/services/firebase/rdb-editar-caso.service';

@Component({
  selector: 'app-tratamiento',
  templateUrl: './tratamiento.component.html',
  styleUrls: ['./tratamiento.component.css']
})
export class TratamientoComponent implements OnInit {

  constructor(private firebase_rdb: RdbEditarCasoService, private http: HttpClient, private router: Router) { }

  cnt_trat: number[] = [0, 0, 0, 0, 0, 0, 0];

  tratamientos: string[] = ["dieta", "soporte hídrico", "medicamentos", "oxígeno", "medidas generales"];

  ngOnInit() {
    this.firebase_rdb.tratamiento_seleccionado = "";
    
    this.tratamientos.forEach(
      e => {
        this.cntTratamientos(e);
      }
    );
  }

  cntTratamientos(pTratamiento: string){
    this.firebase_rdb.getTratamientos(pTratamiento).toPromise().then(
      data => {
        var ans = 0;

        for(let key in data) {
          if (data.hasOwnProperty(key)) {
            ans++;
          }
        }

        this.cnt_trat[this.tratamientos.indexOf(pTratamiento)] = ans;
      }
    ).catch(
      error => {
        console.log("Error recuperando tratamientos de tipo " + pTratamiento);
        console.log(error);
      }
    );
  }

  verDetalleTratamiento(pTratamiento: string) {
    this.firebase_rdb.tratamiento_seleccionado = pTratamiento;
    this.router.navigate(["/panel/detalle-tratamiento"]);
  }

}
