import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RdbEditarCasoService } from 'app/services/firebase/rdb-editar-caso.service';

declare const $: any;

@Component({
  selector: 'app-detalle-caso-diagnostico',
  templateUrl: './detalle-caso-diagnostico.component.html',
  styleUrls: ['./detalle-caso-diagnostico.component.css']
})
export class DetalleCasoDiagnosticoComponent implements OnInit {

  constructor(private firebase_rdb: RdbEditarCasoService, private router: Router) { }

  diagnosticos: string[] = [];
  lbl_diagnostico: string[] = [];
  razon: string = "";
  bibliografia: string[] = [];

  alert_repetido: boolean = false;

  setLblDiagnostico(i: number, lbl: string) {
    this.alert_repetido = false;
    if (lbl == "Diagnóstico final" && this.lbl_diagnostico.indexOf(lbl) != -1) {
      this.alert_repetido = true;
    }
    else {
      this.lbl_diagnostico[i] = lbl;
    }
  }

  ngOnInit() {
    if (this.firebase_rdb.nombre_caso_seleccionado == null ||
      this.firebase_rdb.especialidad_caso_seleccionado == null ||
      this.firebase_rdb.id_caso_seleccionado == null ||
      this.firebase_rdb.json_caso_seleccionado == null) {
      
      this.firebase_rdb.clearSeleccionado();
      this.router.navigate(["/panel/casos"]);
    }

    this.diagnosticos = this.firebase_rdb.diagnosticos;
    this.lbl_diagnostico = this.firebase_rdb.lbl_diagnostico;
    
    this.razon = this.firebase_rdb.motivo_diagnostico;
    this.bibliografia = this.firebase_rdb.bibliografia;
  }

  guardarCambios() {
    this.firebase_rdb.diagnosticos = this.diagnosticos;
    this.firebase_rdb.lbl_diagnostico = this.lbl_diagnostico;
    this.firebase_rdb.motivo_diagnostico = this.razon;
    this.firebase_rdb.bibliografia = this.bibliografia;
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
        return false;
    }
    return true;
  };
}
