<div class="main-content">
  <div class="container-fluid">
      <div class="row">
        <div class="col-md-2 text-left">
          <h3><strong>Tratamientos</strong></h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-content bg-white">
              <div class="table-responsive p-3 mx-auto">
                <table class="table table-striped table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th class="text-center" scope="col">
                        <p>
                          <strong>Tipo de tratamiento</strong>
                        </p>
                      </th>
                      <th class="text-center" scope="col">
                        <p>
                          <strong>Número de tratamientos</strong>
                        </p>
                      </th>
                      <th class="text-center" scope="col">
                        <p>
                          <strong>Acciones</strong>
                        </p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr scope="row">
                      <td>
                        Dieta
                      </td>
                      <td class="text-center">
                        {{cnt_trat[0]}}
                      </td>
                      <td class="text-center">
                        <i class="material-icons" (click)="verDetalleTratamiento('Dieta')">search</i>
                      </td>
                    </tr>
                    <tr scope="row">
                      <td>
                        Soporte hídrico
                      </td>
                      <td class="text-center">
                        {{cnt_trat[1]}}
                      </td>
                      <td class="text-center">
                        <i class="material-icons" (click)="verDetalleTratamiento('Soporte hídrico')">search</i>
                      </td>
                    </tr>
                    <!-- Se omiten goteos a petición de la clienta-->
                    <tr scope="row">
                      <td>
                        Medicamentos
                      </td>
                      <td class="text-center">
                        {{cnt_trat[2]}}
                      </td>
                      <td class="text-center">
                        <i class="material-icons" (click)="verDetalleTratamiento('Medicamentos')">search</i>
                      </td>
                    </tr>
                    <tr scope="row">
                      <td>
                        Oxígeno
                      </td>
                      <td class="text-center">
                        {{cnt_trat[3]}}
                      </td>
                      <td class="text-center">
                        <i class="material-icons" (click)="verDetalleTratamiento('Oxígeno')">search</i>
                      </td>
                    </tr>
                    <tr scope="row">
                      <td>
                        Medidas generales
                      </td>
                      <td class="text-center">
                        {{cnt_trat[4]}}
                      </td>
                      <td class="text-center">
                        <i class="material-icons" (click)="verDetalleTratamiento('Medidas generales')">search</i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>