import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RdbEditarCasoService } from 'app/services/firebase/rdb-editar-caso.service';

declare const $: any;

@Component({
  selector: 'app-detalle-laboratorio',
  templateUrl: './detalle-laboratorio.component.html',
  styleUrls: ['./detalle-laboratorio.component.css']
})
export class DetalleLaboratorioComponent implements OnInit {

  constructor(private router: Router, private firebase_rdb: RdbEditarCasoService, private http: HttpClient) { }

  laboratorio_seleccionado: string = "";

  labs: string[] = [];

  urlLabs: string = "https://medicina-uniandes-default-rtdb.firebaseio.com/preguntas/ayudas%20diagnósticas/laboratorios/";
  urlOtras: string = "https://medicina-uniandes-default-rtdb.firebaseio.com/preguntas/ayudas%20diagnósticas/otras%20ayudas/.json";

  ngOnInit(): void {
    if (this.firebase_rdb.laboratorio_seleccionado == "") {
      this.router.navigate(["/panel/laboratorio"]);
    }
    else {
      this.labs = [];

      this.laboratorio_seleccionado = this.firebase_rdb.laboratorio_seleccionado;

      if (this.laboratorio_seleccionado == "Otras ayudas") {
        this.firebase_rdb.getOtrasAyudas().toPromise().then(
          data => {
            for (let key in data) {
              if (data.hasOwnProperty(key)) {
                this.labs.push(data[key]);
              }
            }
          }
        ).catch(
          error => {
            console.log("Error recuperando Otras ayudas");
            console.log(error);
          }
        );
      }
      else {
        this.firebase_rdb.getLabs(this.laboratorio_seleccionado).toPromise().then(
          data => {
            for (let key in data) {
              if (data.hasOwnProperty(key)) {
                this.labs.push(data[key]);
              }
            }
          }
        ).catch(
          error => {
            console.log("Error recuperando labs de " + this.laboratorio_seleccionado);
            console.log(error);
          }
        );
      }
    }
  }
  
  guardarCambios() {
    var url: string = "";
    if (this.laboratorio_seleccionado == "Otras ayudas") {
      url = this.urlOtras;
    }
    else {
      url = this.urlLabs + this.laboratorio_seleccionado.toLowerCase().trim().split(" ").join("%20") + "/.json";
    }

    var put_labs = {};

    var labs_aux: string[] = [];

    var e_aux: string = "";
    this.labs.forEach(
      (e,i) => {
        if (e != null && e.trim() != "") {
          //El último split/join hace que los espacios múltiples se vuelvan sólo uno
          //Los demás eliminan los caracteres no permitidos por Firebase
          e_aux = e.trim()
          .split(".").join("")
          .split("$").join("")
          .split("#").join("")
          .split("[").join("")
          .split("]").join("")
          .split("/").join("")
          .split(/  +/g).join(" ");;
          labs_aux.push(e);
        }
      }
    );

    this.labs = labs_aux;

    this.labs.forEach(
      (e,i) => {
        put_labs["l_" + i] = e;
      }
    );

    this.http.put(url, put_labs).toPromise().then(
      resp => {
        this.router.navigate(["/panel/laboratorio"]);
      }
    ).catch(
      error => {
        console.log("Error subiendo cambios a " + this.laboratorio_seleccionado);
        console.log(error);
      }
    );
  }

  agregarLab() {
    this.labs.push("");
  }

  eliminarLab(index: number) {
    this.labs[index] = null;

    var labs_aux: string[] = [];

    this.labs.forEach(
      (e,i) => {
        if (e != null) {
          labs_aux.push(e);
        }
      }
    );

    this.labs = labs_aux;
  }

  trackByFn(index, item) {
    return index;
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
        return false;
    }
    return true;
  };
}
