import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    //{ path: 'table-list', title: 'Table',  icon: 'android', class: ''},
    { path: 'dashboard', title: 'Dashboard', icon: 'dashboard', class: ''},
    { path: 'estudiantes', title: 'Estudiantes', icon: 'face', class: ''},
    //{ path: 'especialidades', title: 'Especialidades', icon: 'add_circle', class: ''},
    { path: 'casos', title: 'Casos', icon: 'wysiwyg', class: ''},
    { path: 'sistemas', title: 'Sistemas', icon: 'timeline', class: ''},
    { path: 'laboratorio', title: 'Laboratorio', icon: 'zoom_in', class: ''},
    { path: 'tratamiento', title: 'Tratamiento', icon: 'medication', class: ''},
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor(
    private msalService: MsalService
  ) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }

  logout() {
    this.msalService.logout().toPromise().then(
      () => {    
        this.msalService.instance.setActiveAccount(null);
      }
    );
  }

  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
