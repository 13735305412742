<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 text-left">
        <h3><strong>Revisión por sistemas</strong></h3>
      </div>
      <div *ngIf="isMobileMenu();then content_mobile else content_web"></div>
      <ng-template #content_web>
        <div class="col-md-6 text-right">
          <a routerLink="/panel/detalle-caso">
            <button class="btn btn-success" (click)="guardarRespuestasSistemaActual()">Guardar cambios</button>
          </a>
        </div>
      </ng-template>
      <ng-template #content_mobile>
        <div class="col-md-6 text-left">
          <a routerLink="/panel/detalle-caso">
            <button class="btn btn-success" (click)="guardarRespuestasSistemaActual()">Guardar cambios</button>
          </a>
        </div>
      </ng-template>
    </div>
    <div class="row">
      <div class="col-md-12 text-left">
        <p><a (click)="guardarRespuestasSistemaActual()" routerLink="/panel/casos">Casos</a>/<a (click)="guardarRespuestasSistemaActual()" routerLink="/panel/detalle-caso">Editar caso</a>/<strong>Revisión por sistemas</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <h5>Seleccionar un sistema</h5>
        <div class="dropdown">
          <a class="btn dropdown-toggle" data-toggle="dropdown">
              {{sistemaSeleccionado}}
              <b class="caret"></b>
          </a>
          <ul class="dropdown-menu">
            <li><a (click)="seleccionarSistema('Cardiovascular')">Cardiovascular</a></li>
            <li><a (click)="seleccionarSistema('Endocrino')">Endocrino</a></li>
            <li><a (click)="seleccionarSistema('Gastrointestinal')">Gastrointestinal</a></li>
            <li><a (click)="seleccionarSistema('Genitourinario')">Genitourinario</a></li>
            <li><a (click)="seleccionarSistema('Nervioso')">Nervioso</a></li>
            <li><a (click)="seleccionarSistema('Osteomuscular')">Osteomuscular</a></li>
            <li><a (click)="seleccionarSistema('Respiratorio')">Respiratorio</a></li>
          </ul>
        </div>
        
        <h5>Seleccionar una pregunta</h5>
        <p *ngIf="indexActual != -1">{{indexActual+1}}. {{pregSeleccionada}}</p>
        <div class="dropdown">
          <a class="btn dropdown-toggle" data-toggle="dropdown">
              Selecciona una pregunta
              <b class="caret"></b>
          </a>
          <ul class="dropdown-menu">
            <li *ngFor="let preg of pregsActuales; let i = index">
              <a (click)="seleccionarPregunta(preg, i)">{{i+1}}. {{preg}}</a>
            </li>
          </ul>
        </div>
        
        <h5>Tipo de preguntas</h5>
        <p>{{tipoActual}}</p>
        <div class="dropdown">
          <a class="btn dropdown-toggle" data-toggle="dropdown">
              Indica el tipo de las preguntas de este sistema
              <b class="caret"></b>
          </a>
          <ul class="dropdown-menu">
            <li><a (click)="seleccionarTipo(1)">Preguntas correctas</a></li>
            <li><a (click)="seleccionarTipo(-1)">Preguntas incorrectas</a></li>
            <li><a (click)="seleccionarTipo(0)">Preguntas omitibles</a></li>
          </ul>
        </div>
      </div>
      <div class="col-md-6">
        <h5>Respuesta</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea *ngIf="indexActual >= 0" [(ngModel)]="respsActuales[indexActual]" class="form-control" placeholder="Escribe la respuesta del paciente" rows="13"></textarea> 
              <textarea *ngIf="indexActual < 0" class="form-control" placeholder="Escribe la respuesta del paciente" rows="13"></textarea> 
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col-md-12 text-right">
        <a routerLink="/panel/detalle-caso">
          <button class="btn btn-success" (click)="guardarRespuestasSistemaActual()">Guardar cambios</button>
        </a>
      </div>
    </div>
  </div>
</div>