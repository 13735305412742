<div class="main-content">
  <div class="container-fluid">
      <div class="row">
        <div class="col-md-2 text-left">
          <h3><strong>Dashboard</strong></h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 col-sm-12">
          <div class="card">
            <div class="card-content bg-white">
              <h4>
                <strong>
                  Casos completados
                </strong>
              </h4>
              <h5>
                <i class="material-icons">task</i>
                <strong>
                  {{ casos_completados }}
                </strong>
              </h5>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-12">
          <div class="card">
            <div class="card-content bg-white">
              <h4>
                <strong>
                  Estudiantes activos
                </strong>
              </h4>
              <h5>
                <i class="material-icons">face</i>
                <strong>
                  {{ estudiantes_activos }}
                </strong>
              </h5>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-12">
          <div class="card">
            <div class="card-content bg-white">
              <h4>
                <strong>
                  Especialidades
                </strong>
              </h4>
              <h5>
                <i class="material-icons">add_circle</i>
                <strong>
                  {{ especialidades }}
                </strong>
              </h5>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-12">
          <div class="card">
            <div class="card-content bg-white">
              <h4>
                <strong>
                  Casos creados
                </strong>
              </h4>
              <h5>
                <i class="material-icons">medical_services</i>
                <strong>
                  {{ casos_creados }}
                </strong>
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="card">
            <div class="card-content bg-white">
              <h4>
                <strong>
                  Tipo de caso
                </strong>
              </h4>
              <canvas #graficoTipoCasos></canvas>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="card">
            <div class="card-content bg-white">
              <h4>
                <strong>
                  Dificultad
                </strong>
              </h4>
              <canvas #graficoDificultad></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="card">
            <div class="card-content bg-white">
              <h4>
                <strong>
                  Especialidad
                </strong>
              </h4>
              <canvas #graficoEspecialidad></canvas>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="card">
            <div class="card-content bg-white">
              <h4>
                <strong>
                  Casos realizados por día
                </strong>
              </h4>
              <canvas #graficoCasosPorDia></canvas>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>