import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RdbEditarCasoService } from 'app/services/firebase/rdb-editar-caso.service';

declare const $: any;

@Component({
  selector: 'app-detalle-caso-antecedentes',
  templateUrl: './detalle-caso-antecedentes.component.html',
  styleUrls: ['./detalle-caso-antecedentes.component.css']
})
export class DetalleCasoAntecedentesComponent implements OnInit {

  resps: string[] = [];

  tipos: string[] = [];
  tiposUI: string[] = [];

  constructor(private firebase_rdb: RdbEditarCasoService, private router: Router) { }

  ngOnInit() {
    if (this.firebase_rdb.nombre_caso_seleccionado == null ||
      this.firebase_rdb.especialidad_caso_seleccionado == null ||
      this.firebase_rdb.id_caso_seleccionado == null ||
      this.firebase_rdb.json_caso_seleccionado == null) {
      
      this.firebase_rdb.clearSeleccionado();
      this.router.navigate(["/panel/casos"]);
    }

    this.resps = this.firebase_rdb.antecedentes_resps;

    this.tipos = this.firebase_rdb.antecedentes_tipos;
  
    this.tipos.map(
      (e,i) => {
        if (e != null && e != "") {
          if (e == "positivo")
            this.tiposUI[i] = "Pregunta correcta";
          else if (e == "negativo")
            this.tiposUI[i] = "Pregunta incorrecta";
          else if (e == "omitible")
            this.tiposUI[i] = "Pregunta omitible";
          else
            this.tiposUI[i] = "";
        }
      }
    );
  }
  
  guardarCambios() {
    this.firebase_rdb.antecedentes_resps = this.resps;
    this.firebase_rdb.antecedentes_tipos = this.tipos;
  }

  seleccionarTipo(index: number, tipo: number) {
    if (tipo == 1) {
      this.tipos[index] = "positivo";
      this.tiposUI[index] = "Pregunta correcta";
    }
    else if (tipo == -1) {
      this.tipos[index] = "negativo";
      this.tiposUI[index] = "Pregunta incorrecta";
    }
    else {
      this.tipos[index] = "omitible";
      this.tiposUI[index] = "Pregunta omitible";
    }
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
        return false;
    }
    return true;
  };

}
