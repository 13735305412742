<div class="logo">
    <a href="https://medicina.uniandes.edu.co/" class="simple-text">
        <div class="logo-img" style="width: auto !important">
            <img style="height: 36px !important; width: auto !important;" src="/assets/img/logo-grande.png"/>
        </div>
        <!-- Admin - Imagine -->
    </a>
</div>
<div class="sidebar-wrapper">
    <!--
    <form class="navbar-form navbar-right" role="search" *ngIf="isMobileMenu()">
        <div class="form-group form-black is-empty">
            <input type="text" class="form-control" placeholder="Search">
            <span class="material-input"></span>
        </div>
        <button type="submit" class="btn btn-white btn-round btn-just-icon">
            <i class="material-icons">search</i><div class="ripple-container"></div>
        </button>
    </form>
    -->
    <div class="nav-container">
        <ul class="nav">
            <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}}">
                <a  [routerLink]="[menuItem.path]">
                    <i class="material-icons">{{menuItem.icon}}</i>
                    <p>{{menuItem.title}}</p>
                </a>
            </li>
            <li (click)="logout()">
                <a href="#">
                    <i class="material-icons">logout</i>
                    <p>Salir</p>
                </a>
            </li>
        </ul>
    </div>
</div>
