import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'app/services/storage/local-storage.service';
import { RdbService } from '../../services/firebase/rdb.service';

declare const $: any;

@Component({
  selector: 'app-crear-caso',
  templateUrl: './crear-caso.component.html',
  styleUrls: ['./crear-caso.component.css']
})
export class CrearCasoComponent implements OnInit {

  constructor(private firebase_rdb: RdbService, private indexed_db: LocalStorageService) { }

  nombre_caso: string = "";
  especialidad: string = "";
  dificultad: string = "";
  escenario: string = "";
  modo: string = "";

  casos_especialidad = null;

  aux_alert_nombre: string = "";

  alert_repetido: boolean = false;
  caso_listo: boolean = false;

  ngOnInit() {    
    this.indexed_db.getItem<string>("nombre_caso").then(
      resp => {
        if (resp != null) {
          this.firebase_rdb.nombre_caso = resp;
          this.nombre_caso = this.firebase_rdb.nombre_caso;
        }

        this.indexed_db.getItem<string>("especialidad").then(
          resp2 => {
            if (resp2 != null) {
              this.firebase_rdb.especialidad = resp2;
              this.especialidad = this.firebase_rdb.especialidad;
            }

            this.indexed_db.getItem<string>("dificultad").then(
              resp3 => {
                if (resp3 != null) {
                  this.firebase_rdb.dificultad = resp3;
                  this.dificultad = this.firebase_rdb.dificultad;
                }

                this.indexed_db.getItem<string>("escenario").then(
                  resp4 => {
                    if (resp4 != null) {
                      this.firebase_rdb.escenario = resp4;
                      this.escenario = this.firebase_rdb.escenario;
                    }

                    this.indexed_db.getItem<string>("modo").then(
                      resp5 => {
                        if (resp5 != null) {
                          this.firebase_rdb.modo = resp5;
                          this.modo = this.firebase_rdb.modo;
                        }

                        this.alert_repetido = false;
                        this.caso_listo = false;
                        this.casoListo();
                      }
                    ).catch(
                      error => {
                        console.log("Error recuperando modo");
                      }
                    );
                  }
                ).catch(
                  error => {
                    console.log("Error recuperando escenario");
                  }
                );

              }
            ).catch(
              error => {
                console.log("Error recuperando dificultad");
              }
            );

          }
        ).catch(
          error => {
            console.log("Error recuperando especialidad");
          }
        );

      }
    ).catch(
      error => {
        console.log("Error recuperando nombre_caso");
      }
    );
  }

  guardarCambios() {
    this.firebase_rdb.nombre_caso = this.nombre_caso;
    this.firebase_rdb.especialidad = this.especialidad;
    this.firebase_rdb.dificultad = this.dificultad;
    this.firebase_rdb.escenario = this.escenario;
    this.firebase_rdb.modo = this.modo;
    
    this.indexed_db.setItem<string>("nombre_caso", this.nombre_caso);
    this.indexed_db.setItem<string>("especialidad", this.especialidad);
    this.indexed_db.setItem<string>("dificultad", this.dificultad);
    this.indexed_db.setItem<string>("escenario", this.escenario);
    this.indexed_db.setItem<string>("modo", this.modo);
  }

  verificarRepetido() {
    if (this.aux_alert_nombre != this.nombre_caso) {
      this.indexed_db.setItem<string>("nombre_caso", this.nombre_caso);
      this.casoListo();
    }
    return true; //Es llamado en cada ciclo de verificación de cambios por la alerta de nombre repetido
    //Debe devolver true para entrar al ngIf y no afectar la directiva
    //Lo anterior funciona porque verificar (true && condicion) es lo mismo que verificar únicamente (condicion)
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
        return false;
    }
    return true;
  };

  setEspecialidad(pEsp: string) {
    this.especialidad = pEsp;
    this.casos_especialidad = null;
    this.alert_repetido = false;

    this.indexed_db.setItem<string>("especialidad", this.especialidad);

    this.casoListo(); //Verificar si el caso está listo con este cambio    
  }

  setDificultad(pDif: string) {
    this.dificultad = pDif;
    
    this.indexed_db.setItem<string>("dificultad", this.dificultad);

    this.casoListo(); //Verificar si el caso está listo con este cambio
  }

  setModo(pModo: string) {
    this.modo = pModo;

    this.indexed_db.setItem<string>("modo", this.modo);

    this.casoListo(); //Verificar si el caso está listo con este cambio
  }

  setEscenario(pEsc: string) {
    this.escenario = pEsc;

    this.indexed_db.setItem<string>("escenario", this.escenario);
    
    this.casoListo(); //Verificar si el caso está listo con este cambio
  }

  //Todas las funcionas de aquí en adelante verifican si se debe o no marcar una sección como lista
  enfermedadActualListo() {
    var cnt = 0;

    this.firebase_rdb.enfermedad_actual_resps.forEach((e,i) => {
      if (e != null && e != "")
      {
        cnt++;
      }
    });

    return cnt == 14;
  }

  antecedentesListo() {
    var cntResps = 0;
    var cntTipos = 0;

    this.firebase_rdb.antecedentes_resps.forEach((e,i) => {
      if (e != null && e != "")
      {
        cntResps++;
      }
    });

    this.firebase_rdb.antecedentes_tipos.forEach((e,i) => {
      if (e != null && e != "")
      {
        cntTipos++;
      }
    });

    return (cntResps == 9) && (cntTipos == 9);
  }

  //Si las preguntas de un sistema son negativas u omitibles no es necesario que tengan respuesta
  //para que el sistema esté completo. Pero si las preguntas son positivas, todas las preguntas
  //deben tener su respectiva respuesta.
  sistemasListo() {
    var cnt = 0;

    //Cardiovascular listo
    var cardiovascular = false;
    if (this.firebase_rdb.cardiovascular_tipo == "negativo" || this.firebase_rdb.cardiovascular_tipo == "omitible") {
      cardiovascular = true;
    }
    else if (this.firebase_rdb.cardiovascular_tipo == "positivo") {
      this.firebase_rdb.cardiovascular_resps.forEach(
        (e) => {
          if (e != null && e.trim() != "")
            cnt++;
        }
      );

      //Si cada pregunta tiene una respuesta válida, se aprueba el sistema
      cardiovascular = (cnt == this.firebase_rdb.cardiovascular_pregs);
    }

    //Endocrino listo
    var endocrino = false;
    if (this.firebase_rdb.endocrino_tipo == "negativo" || this.firebase_rdb.endocrino_tipo == "omitible") {
      endocrino = true;
    }
    else if (this.firebase_rdb.endocrino_tipo == "positivo") {
      cnt = 0;

      this.firebase_rdb.endocrino_resps.forEach(
        (e) => {
          if (e != null && e.trim() != "")
            cnt++;
        }
      );

      //Si cada pregunta tiene una respuesta válida, se aprueba el sistema
      endocrino = (cnt == this.firebase_rdb.endocrino_pregs);
    }

    //Gastrointestinal listo
    var gastrointestinal = false;
    if (this.firebase_rdb.gastrointestinal_tipo == "negativo" || this.firebase_rdb.gastrointestinal_tipo == "omitible") {
      gastrointestinal = true;
    }
    else if (this.firebase_rdb.gastrointestinal_tipo == "positivo") {
      cnt = 0;

      this.firebase_rdb.gastrointestinal_resps.forEach(
        (e) => {
          if (e != null && e.trim() != "")
            cnt++;
        }
      );

      //Si cada pregunta tiene una respuesta válida, se aprueba el sistema
      gastrointestinal = (cnt == this.firebase_rdb.gastrointestinal_pregs);
    }

    //Genitourinario listo
    var genitourinario = false;
    if (this.firebase_rdb.genitourinario_tipo == "negativo" || this.firebase_rdb.genitourinario_tipo == "omitible") {
      genitourinario = true;
    }
    else if (this.firebase_rdb.genitourinario_tipo == "positivo") {
      cnt = 0;

      this.firebase_rdb.genitourinario_resps.forEach(
        (e) => {
          if (e != null && e.trim() != "")
            cnt++;
        }
      );

      //Si cada pregunta tiene una respuesta válida, se aprueba el sistema
      genitourinario = (cnt == this.firebase_rdb.genitourinario_pregs);
    }

    //Nervioso listo
    var nervioso = false;
    if (this.firebase_rdb.nervioso_tipo == "negativo" || this.firebase_rdb.nervioso_tipo == "omitible") {
      nervioso = true;
    }
    else if (this.firebase_rdb.nervioso_tipo == "positivo") {
      cnt = 0;

      this.firebase_rdb.nervioso_resps.forEach(
        (e) => {
          if (e != null && e.trim() != "")
            cnt++;
        }
      );

      //Si cada pregunta tiene una respuesta válida, se aprueba el sistema
      nervioso = (cnt == this.firebase_rdb.nervioso_pregs);
    }

    //Osteomuscular listo
    var osteomuscular = false;
    if (this.firebase_rdb.osteomuscular_tipo == "negativo" || this.firebase_rdb.osteomuscular_tipo == "omitible") {
      osteomuscular = true;
    }
    else if (this.firebase_rdb.osteomuscular_tipo == "positivo") {
      cnt = 0;

      this.firebase_rdb.osteomuscular_resps.forEach(
        (e) => {
          if (e != null && e.trim() != "")
            cnt++;
        }
      );

      //Si cada pregunta tiene una respuesta válida, se aprueba el sistema
      osteomuscular = (cnt == this.firebase_rdb.osteomuscular_pregs);
    }

    //Respiratorio listo
    var respiratorio = false;
    if (this.firebase_rdb.respiratorio_tipo == "negativo" || this.firebase_rdb.respiratorio_tipo == "omitible") {
      respiratorio = true;
    }
    else if (this.firebase_rdb.respiratorio_tipo == "positivo") {
      cnt = 0;

      this.firebase_rdb.respiratorio_resps.forEach(
        (e) => {
          if (e != null && e.trim() != "")
            cnt++;
        }
      );

      //Si cada pregunta tiene una respuesta válida, se aprueba el sistema
      respiratorio = (cnt == this.firebase_rdb.respiratorio_pregs);
    }

    //Todos los sistemas listos
    return (cardiovascular && endocrino && gastrointestinal && genitourinario && nervioso 
            && osteomuscular && respiratorio);
  }

  examenListo() {
    var cnt_examen = 0;

    this.firebase_rdb.examen_fisico_resps.forEach(
      e => {
        if (e != null && e != "") {
          cnt_examen++;
        }
      }
    );

    return cnt_examen == 9;
  }

  impresionListo() {
    var cnt_diag = 0;
    var cnt_prob = 0;

    this.firebase_rdb.diagnosticos.forEach(
      e => {
        if (e != null && e != "") {
          cnt_diag ++;
        }
      }
    );

    this.firebase_rdb.probabilidades.forEach(
      e => {
        if (e != null && e != "") {
          cnt_prob ++;
        }
      }
    );

    return (cnt_diag == 5) && (cnt_prob == 5);
  }

  //No es necesario agregar ninguna ayuda diagnóstica
  ayudasListo() {
    return true;
  }

  //No es necesario agregar ningún tratamiento
  tratamientoListo() {
    return true;
  }

  diagnosticoListo() {
    var cnt_diag = 0;
    var cnt_lbl = 0;
    var cnt_biblio = 0;

    var ans_diag = false;
    var ans_lbl = false;
    var ans_biblio = false;
    var ans_razon = this.firebase_rdb.razon != null && this.firebase_rdb.razon != "";

    this.firebase_rdb.diagnosticos.forEach(
      e => {
        if (e != null && e != "") {
          cnt_diag ++;
        }
      }
    );

    ans_diag = cnt_diag == 5;

    this.firebase_rdb.lbl_diagnostico.forEach(
      e => {
        if (e != null && e != "") {
          cnt_lbl ++;
        }
      }
    );

    ans_lbl = cnt_lbl == 5;

    this.firebase_rdb.bibliografia.forEach(
      e => {
        if (e != null && e != "") {
          cnt_biblio ++;
        }
      }
    );

    ans_biblio = cnt_biblio == 5;

    return ans_razon && ans_diag && ans_lbl && ans_biblio;
  }

  pacienteListo() {
    return this.firebase_rdb.paciente_listo;
  }

  //Es llamado cada que ocurre un cambio en el formulario
  casoListo() {
    var ans = true;

    //Verificar que el nombre del caso ingresado sea único en la especialidad indicada
    if (this.casos_especialidad == null) { //Evita hacer múltiples peticiones sobre los mismos datos
      this.firebase_rdb.getCasosEspecialdiad(this.especialidad).toPromise().then(
        data => {
          this.casos_especialidad = data;
          ans = this.nombreYaExiste();
        }
      );
    }
    else {
      ans = this.nombreYaExiste();
    }
    this.aux_alert_nombre = this.nombre_caso;

    if (ans) {
      this.alert_repetido = false;
      //setTimeout(() => {this.alert_repetido = false;}, 0); //Ponerlo en timeout evita el error NG0100

      ans = (this.modo != "") && (this.escenario != "") && (this.dificultad != "") && this.antecedentesListo()
      && this.ayudasListo() && this.diagnosticoListo() && this.enfermedadActualListo() && this.examenListo()
      && this.impresionListo() && this.pacienteListo() && this.sistemasListo() && this.tratamientoListo();
    }

    this.caso_listo = ans;
  }

  //Indica si el nombre ingresado ya existe en la especialidad seleccionada, y lanza un error si es el caso
  nombreYaExiste(): boolean {
    var ans = true;
    
    this.nombre_caso = this.nombre_caso.split(/  +/g).join(' ');

    for (let key in this.casos_especialidad) {
      if (this.casos_especialidad.hasOwnProperty(key) && this.casos_especialidad[key]["título"] == this.nombre_caso
      || this.casos_especialidad.hasOwnProperty(key) && this.casos_especialidad[key]["título"] == this.nombre_caso.trim()
      || this.casos_especialidad.hasOwnProperty(key) && this.casos_especialidad[key]["título"].split(/  +/g).join(' ') == this.nombre_caso.trim()
      || this.casos_especialidad.hasOwnProperty(key) && this.casos_especialidad[key]["título"].split(/  +/g).join(' ') == this.nombre_caso) {
        ans = false;
        this.alert_repetido = true;
        break;
      }
    }

    return ans;
  }

  terminarCaso() {
    this.guardarCambios();
    this.firebase_rdb.armarJSONyGuardar();
  }
}
