<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 text-left">
        <h3><strong>Antecedentes</strong></h3>
      </div>
      <div *ngIf="isMobileMenu();then content_mobile else content_web"></div>
      <ng-template #content_web>
        <div class="col-md-6 text-right">
          <a routerLink="/panel/detalle-caso">
            <button class="btn btn-success" (click)="guardarCambios()">Guardar cambios</button>
          </a>
        </div>
      </ng-template>
      <ng-template #content_mobile>
        <div class="col-md-6 text-left">
          <a routerLink="/panel/detalle-caso">
            <button class="btn btn-success" (click)="guardarCambios()">Guardar cambios</button>
          </a>
        </div>
      </ng-template>
    </div>
    <div class="row">
      <div class="col-md-12 text-left">
        <p><a (click)="guardarCambios()" routerLink="/panel/casos">Casos</a>/<a (click)="guardarCambios()" routerLink="/panel/detalle-caso">Editar caso</a>/<strong>Antecedentes</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h5>Patológicos</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[0]" class="form-control" placeholder="Escribe aquí los antecedentes patológicos del paciente" rows="5"></textarea> 
            </div>
          </div>
        </div>
        <h5>Tipo de pregunta</h5>
        <p>{{tiposUI[0]}}</p>
        <div class="dropdown">
          <a class="btn dropdown-toggle" data-toggle="dropdown">
              Indique el tipo de pregunta
              <b class="caret"></b>
          </a>
          <ul class="dropdown-menu">
            <li><a (click)="seleccionarTipo(0,1)">Pregunta correcta</a></li>
            <li><a (click)="seleccionarTipo(0,-1)">Pregunta incorrecta</a></li>
            <li><a (click)="seleccionarTipo(0,0)">Pregunta omitible</a></li>
          </ul>
        </div>

        <hr />

        <h5>Quirúrgicos</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[1]" class="form-control" placeholder="Escribe aquí los antecedentes quirúrgicos del paciente" rows="5"></textarea> 
            </div>
          </div>
        </div>
        <h5>Tipo de pregunta</h5>
        <p>{{tiposUI[1]}}</p>
        <div class="dropdown">
          <a class="btn dropdown-toggle" data-toggle="dropdown">
              Indique el tipo de pregunta
              <b class="caret"></b>
          </a>
          <ul class="dropdown-menu">
            <li><a (click)="seleccionarTipo(1,1)">Pregunta correcta</a></li>
            <li><a (click)="seleccionarTipo(1,-1)">Pregunta incorrecta</a></li>
            <li><a (click)="seleccionarTipo(1,0)">Pregunta omitible</a></li>
          </ul>
        </div>

        <hr />

        <h5>Familiares</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[2]" class="form-control" placeholder="Escribe aquí los antecedentes familiares del paciente" rows="5"></textarea> 
            </div>
          </div>
        </div>
        <h5>Tipo de pregunta</h5>
        <p>{{tiposUI[2]}}</p>
        <div class="dropdown">
          <a class="btn dropdown-toggle" data-toggle="dropdown">
              Indique el tipo de pregunta
              <b class="caret"></b>
          </a>
          <ul class="dropdown-menu">
            <li><a (click)="seleccionarTipo(2,1)">Pregunta correcta</a></li>
            <li><a (click)="seleccionarTipo(2,-1)">Pregunta incorrecta</a></li>
            <li><a (click)="seleccionarTipo(2,0)">Pregunta omitible</a></li>
          </ul>
        </div>

        <hr />

        <h5>Farmacológicos</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[3]" class="form-control" placeholder="Escribe aquí los antecedentes farmacológicos del paciente" rows="5"></textarea> 
            </div>
          </div>
        </div>
        <h5>Tipo de pregunta</h5>
        <p>{{tiposUI[3]}}</p>
        <div class="dropdown">
          <a class="btn dropdown-toggle" data-toggle="dropdown">
              Indique el tipo de pregunta
              <b class="caret"></b>
          </a>
          <ul class="dropdown-menu">
            <li><a (click)="seleccionarTipo(3,1)">Pregunta correcta</a></li>
            <li><a (click)="seleccionarTipo(3,-1)">Pregunta incorrecta</a></li>
            <li><a (click)="seleccionarTipo(3,0)">Pregunta omitible</a></li>
          </ul>
        </div>

        <hr />

        <h5>Ginecológicos</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[4]" class="form-control" placeholder="Escribe aquí los antecedentes ginecológicos del paciente" rows="5"></textarea> 
            </div>
          </div>
        </div>
        <h5>Tipo de pregunta</h5>
        <p>{{tiposUI[4]}}</p>
        <div class="dropdown">
          <a class="btn dropdown-toggle" data-toggle="dropdown">
              Indique el tipo de pregunta
              <b class="caret"></b>
          </a>
          <ul class="dropdown-menu">
            <li><a (click)="seleccionarTipo(4,1)">Pregunta correcta</a></li>
            <li><a (click)="seleccionarTipo(4,-1)">Pregunta incorrecta</a></li>
            <li><a (click)="seleccionarTipo(4,0)">Pregunta omitible</a></li>
          </ul>
        </div>

        <hr />

        <h5>Alérgicos</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[5]" class="form-control" placeholder="Escribe aquí los antecedentes alérgicos del paciente" rows="5"></textarea> 
            </div>
          </div>
        </div>
        <h5>Tipo de pregunta</h5>
        <p>{{tiposUI[5]}}</p>
        <div class="dropdown">
          <a class="btn dropdown-toggle" data-toggle="dropdown">
              Indique el tipo de pregunta
              <b class="caret"></b>
          </a>
          <ul class="dropdown-menu">
            <li><a (click)="seleccionarTipo(5,1)">Pregunta correcta</a></li>
            <li><a (click)="seleccionarTipo(5,-1)">Pregunta incorrecta</a></li>
            <li><a (click)="seleccionarTipo(5,0)">Pregunta omitible</a></li>
          </ul>
        </div>

        <hr />

        <h5>Toxicológicos</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[6]" class="form-control" placeholder="Escribe aquí los antecedentes toxicológicos del paciente" rows="5"></textarea> 
            </div>
          </div>
        </div>
        <h5>Tipo de pregunta</h5>
        <p>{{tiposUI[6]}}</p>
        <div class="dropdown">
          <a class="btn dropdown-toggle" data-toggle="dropdown">
              Indique el tipo de pregunta
              <b class="caret"></b>
          </a>
          <ul class="dropdown-menu">
            <li><a (click)="seleccionarTipo(6,1)">Pregunta correcta</a></li>
            <li><a (click)="seleccionarTipo(6,-1)">Pregunta incorrecta</a></li>
            <li><a (click)="seleccionarTipo(6,0)">Pregunta omitible</a></li>
          </ul>
        </div>

        <hr />

        <h5>Epidemiológicos</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[7]" class="form-control" placeholder="Escribe aquí los antecedentes epidemiológicos del paciente" rows="5"></textarea> 
            </div>
          </div>
        </div>
        <h5>Tipo de pregunta</h5>
        <p>{{tiposUI[7]}}</p>
        <div class="dropdown">
          <a class="btn dropdown-toggle" data-toggle="dropdown">
              Indique el tipo de pregunta
              <b class="caret"></b>
          </a>
          <ul class="dropdown-menu">
            <li><a (click)="seleccionarTipo(7,1)">Pregunta correcta</a></li>
            <li><a (click)="seleccionarTipo(7,-1)">Pregunta incorrecta</a></li>
            <li><a (click)="seleccionarTipo(7,0)">Pregunta omitible</a></li>
          </ul>
        </div>

        <hr />

        <h5>Perinatales</h5>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <textarea [(ngModel)]="resps[8]" class="form-control" placeholder="Escribe aquí los antecedentes perinatales del paciente" rows="5"></textarea> 
            </div>
          </div>
        </div>
        <h5>Tipo de pregunta</h5>
        <p>{{tiposUI[8]}}</p>
        <div class="dropdown">
          <a class="btn dropdown-toggle" data-toggle="dropdown">
              Indique el tipo de pregunta
              <b class="caret"></b>
          </a>
          <ul class="dropdown-menu">
            <li><a (click)="seleccionarTipo(8,1)">Pregunta correcta</a></li>
            <li><a (click)="seleccionarTipo(8,-1)">Pregunta incorrecta</a></li>
            <li><a (click)="seleccionarTipo(8,0)">Pregunta omitible</a></li>
          </ul>
        </div>

        <hr />

      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-right">
        <a routerLink="/panel/detalle-caso">
          <button class="btn btn-success" (click)="guardarCambios()">Guardar cambios</button>
        </a>
      </div>
    </div>
  </div>
</div>